import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    imageCaptionButtonArea:{
        display: "flex",
        flexDirection: "row",
        alignContent: "flex-start",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px",
        '& .MuiGrid-grid-xs-10':{
            display: "block",
            overflowWrap: "break-word",
            textOverflow: "clip",
        }
    },
}));
export default function PreviewImageDialog({ confirmEvent, open, setOpen, caption, src}) {
    const classes = useStyles();
    const handleClose = () => {
        setOpen(false);
    };
    
    return (
        <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <Box className={classes.imageCaptionButtonArea}>
                    <Grid item xs={10}>
                        <Typography>{caption}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton onClick={handleClose} color="primary">
                            <ClearIcon/>
                        </IconButton>
                    </Grid>
                </Box>
                <Box component='img' src={src} width="100%" height="100%"></Box>
            </DialogContent>
        </Dialog>
        </div>
    );
}