// AWS
import { API } from "aws-amplify";

// GraphQL
import { 
    createScheduleEvents, 
    createCorporations, 
} from '../../graphql/mutations';
import { 
    listCorporations,
} from "../../graphql/queries";

// React
import { 
    useEffect,
    useState, 
} from 'react';

// Material-ui
import { makeStyles } from '@material-ui/core/styles';
import { 
    TextField, 
    Modal, 
    FormGroup, 
    FormControl, 
    Button, 
    InputLabel, 
    Select, 
    MenuItem,
} from '@material-ui/core';

// Self Making Files
import { AMAZON_COGNITO_USER_POOLS, BUTTON_CAPTION_CREATE, DIALOG_EVENT_CREATE_ATTRIBUTE_EVENT_TYPE, EVENT_CARD_CAPTION_EVENT_NAME, EVENT_CARD_CAPTION_EVENT_START_DATETIME, EVENT_TYPE, MESSAGE_EMPTY_NOT_REGISTRATION } from '../../Commons/Consts';
import CorporationNameInput from '../../components/SelectionSituationCalender/CorporationNameInput.js';
import { getCurrentUserData } from "../../Commons/CommonFunctions";
import { ColorSelector } from "../../Commons/ColoerSelector";

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    tabMenu:{
        minWidth: "100%",
        maxWidth: "100%",
        width: "100%",
        height: '5vh',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
    },
    modalFormControl:{
        margin: "5px",
    },
    colorBox:{
        width:"15px",
        height:"15px",
    },
    selectedColorBox:{
        width:"15px",
        height:"15px",
        border:"Solid #2a3eb1 2px",
    },
}));

// List all items
function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
}
      
export function CreateEventModal({ open, setOpen, handleClose, hiddenEventForCorpIds, setDate }){
    const classes = useStyles();
    const initDate = new Date();
    initDate.setHours(19);
    initDate.setMinutes(0);
    const selectedDateValue = setDate === void 0 ? initDate.toISOString().substr(0, 16) : setDate.toISOString().substr(0, 16);
    const [modalStyle] = useState(getModalStyle);
    const [selectedDate, setSelectedDate] = useState(selectedDateValue);
    const [newEventCorporationId, setNewEventCorporationId] = useState('');
    const [inputCorporationName, setInputCorporationName] = useState('');
    const [newEventType, setNewEventType] = useState(0);
    const [newEventName, setNewEventName] = useState('');
    const [errorIsCorpNameNone, setErrorIsCorpNameNone] = useState(false);
    const [errorIsNewEventNameNone, setErrorIsNewEventNameNone] = useState(false);
    const [eventColor, setEventColor] = useState(); 

    const createScheduleEvent = async () =>{
        // 必須チェック
        if(inputCorporationName === null || inputCorporationName === ''){
            setErrorIsCorpNameNone(true);
            return;
        }
        if(newEventName === null || newEventName === ''){
            setErrorIsNewEventNameNone(true);
            return;
        }
        
        // 登録処理
        const currentUserData = await getCurrentUserData();
        const filter = {
            corporationName: {contains: inputCorporationName}
        }
        const corpRes = await API.graphql({
            query: listCorporations,
            variables: { filter: filter, limit:10000 },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
        if(0 < corpRes.data.listCorporations.items.length){
            await API.graphql({
                query: createScheduleEvents,
                variables: {
                    input: {
                        eventName: newEventName,
                        eventType: newEventType,
                        status: 33,
                        deadlineDateTime: selectedDate + ':00.000Z',
                        deadlineTimestamp: new Date(selectedDate).valueOf(),
                        formType: "",
                        eventColorcode: eventColor,
                        corporationsID: newEventCorporationId,
                        userDatasID: currentUserData.userId
                    }
                }
            });
        }else{
            const createCorpRes = await API.graphql({
                query: createCorporations,
                variables: {
                    input: {corporationName: inputCorporationName}
                }
            });
            await API.graphql({
                query: createScheduleEvents,
                variables: {
                    input: {
                        eventName: newEventName,
                        eventType: newEventType,
                        status: 33,
                        deadlineDateTime: selectedDate + ':00.000Z',
                        deadlineTimestamp: new Date(selectedDate).valueOf(),
                        formType: "",
                        eventColorcode: eventColor,
                        corporationsID: createCorpRes.data.createCorporations.id,
                        userDatasID: currentUserData.userId
                    }
                }
            });
        }
        setOpen(false);
    }
    
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handleCompanyNameChange = (value) =>{
        setNewEventCorporationId(value);
    }

    const handleCompanyNameInput = (value) =>{
        setInputCorporationName(value);
    }

    const handleEventnameChange = (event) =>{
        setNewEventName(event.target.value);
    }

    const onCloseLocal = (event) =>{
        setErrorIsCorpNameNone(false);
        setErrorIsNewEventNameNone(false);
        handleClose();
    }

    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();
        setSelectedDate(selectedDateValue);
        
        // アンマウント時処理
        return() => {
            abortCtrl.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    // getModalStyle is not a pure function, we roll the style only on the first render
    return (
        <div>
        <Modal
            open={open}
            onClose={onCloseLocal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <FormGroup>
                    <FormControl className={classes.modalFormControl}>
                        <CorporationNameInput
                            required 
                            setCorporationId={handleCompanyNameChange}
                            setInputName={handleCompanyNameInput}
                            hiddenEventForCorpIds={hiddenEventForCorpIds}
                            error={errorIsCorpNameNone}
                        />
                    </FormControl>
                    <FormControl className={classes.modalFormControl}>
                        <TextField 
                            label={EVENT_CARD_CAPTION_EVENT_NAME}
                            required
                            error={errorIsNewEventNameNone}
                            helperText={errorIsNewEventNameNone ? MESSAGE_EMPTY_NOT_REGISTRATION : ''}
                            InputLabelProps={{ shrink: true }}
                            value={newEventName}
                            onChange={handleEventnameChange}
                        />
                    </FormControl>
                    <FormControl variant="outlined" className={classes.modalFormControl}>
                        <InputLabel id="event-type">{DIALOG_EVENT_CREATE_ATTRIBUTE_EVENT_TYPE}</InputLabel>
                        <Select
                            label={DIALOG_EVENT_CREATE_ATTRIBUTE_EVENT_TYPE}
                            labelId="event-type"
                            id='select-event-type'
                            value={newEventType}
                            onChange={(event) => setNewEventType(event.target.value)}
                        >
                            <MenuItem value={0}>{EVENT_TYPE.Intern.Label}</MenuItem>
                            <MenuItem value={1}>{EVENT_TYPE.EntrySheet.Label}</MenuItem>
                            <MenuItem value={2}>{EVENT_TYPE.Interview.Label}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className={classes.modalFormControl}>
                        <TextField
                            id="datetime-local"
                            label={EVENT_CARD_CAPTION_EVENT_START_DATETIME}
                            type="datetime-local"
                            defaultValue={selectedDate}
                            className={classes.textField}
                            onChange={handleDateChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                    <FormControl className={classes.modalFormControl}>
                        <ColorSelector setColor={setEventColor}></ColorSelector>
                    </FormControl>
                    <FormControl className={classes.modalFormControl}>
                        <Button
                            onClick={() => createScheduleEvent()}
                            variant="contained"
                        >
                            {BUTTON_CAPTION_CREATE}
                        </Button>
                    </FormControl>
                </FormGroup>
            </div>
        </Modal>
        </div>
    );
}

