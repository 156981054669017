// AWS
import { API } from "aws-amplify";

// React
import { 
    useEffect, 
    useReducer, 
    Fragment, 
    useState, 
} from 'react';
import { useHistory } from "react-router";

// Material UI
import { 
    Typography, 
    Container, 
    Box, 
} from '@material-ui/core';

// GraphQL
import { listTraineeRules } from "../../graphql/queries";

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import * as Consts from '../../Commons/Consts';
import { 
    getCurrentUserData, 
    redirectForUserRoleToRedorectPages, 
    consoleLog, 
} from "../../Commons/CommonFunctions";
import { traineeRulesReducer, userReducer } from '../../Commons/Reducers';
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import SideMenu from "../../Commons/SideMenu";

export default function TraineeRules(){
    const commonStyle = commonStyles();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [traineeRules, dispatch] = useReducer(traineeRulesReducer, []);
    const [userData, dispatchCurrentUserData] = useReducer(userReducer, []);
    const initTraineeRules = async (type) =>{
        try {
            const currentUserData = await getCurrentUserData();
            redirectForUserRoleToRedorectPages(history, currentUserData, Consts.USER_ROLE.traineeRules);
            dispatchCurrentUserData({ type: Consts.INITIAL_QUERY, user: currentUserData });
            
            const traineeRuleRes = await API.graphql({
                query: listTraineeRules,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            });
            dispatch({ type: Consts.INITIAL_QUERY, traineeRules:traineeRuleRes.data.listTraineeRules.items });
            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }

    const LineTextComponent = ({ msg }) => {
        const texts = msg.split(/(\n)/).map((item, index) => {
            return (
                <Fragment key={index}>
                { item.match(/\n/) ? <br /> : item }
                </Fragment>
            );
        });
        return <div>{texts}</div>;
    }
        
    useEffect(() => {
        let abortCtrl = new AbortController();

        initTraineeRules();
        // アンマウント時処理
        return() => {
            abortCtrl.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header title="トレーニー十戒"/>
            {isLoading ?
            <LoadingIcon/>
            :
            <Box>
                <SideMenu/>
                <Box className={commonStyle.pageContent} >
                    <Container pt={2}>
                        {traineeRules.sort((a, b) => {return a.number < b.number ? -1 : 1}).map(data => (
                            <Box className={commonStyle.prefixedText}>
                                <Typography variant='h5'>
                                    {data.number}．{data.caption}
                                </Typography>
                                <Container className={commonStyle.marginTop}>
                                    <Typography variant='h6'><LineTextComponent msg={data.body}/></Typography>
                                </Container>
                                <Box className={commonStyle.marginBottomRow} />
                            </Box>
                        ))}
                        <Box className={commonStyle.endBox}/>
                    </Container>
                </Box>
            </Box>
            }
            <Footer/>
        </Box>
    );
}
