import { API, Auth } from "aws-amplify";
import { createConectIsReadUnreadToNotifications } from "../../graphql/mutations";
import { getNotifications, listConectIsReadUnreadToNotifications } from "../../graphql/queries";
import { useEffect, useReducer } from 'react';
import { Typography, Container, Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { commonStyles } from "../../Commons/CommonStyles";
import SideMenu from "../../Commons/SideMenu";


const SUBSCRIPTION = 'SUBSCRIPTION';
const INITIAL_QUERY = 'INITIAL_QUERY';
const ADDITIONAL_QUERY = 'ADDITIONAL_QUERY';

const notificationReducer = (state, action) => {
    switch (action.type) {
      case INITIAL_QUERY:
        return action.notifications;
      case ADDITIONAL_QUERY:
        return [...state, ...action.notifications]
      case SUBSCRIPTION:
        return [action.notifications, ...state]
      default:
        return state;
    }
};  

function getStringFromDate(date) {
    var year_str = date.getFullYear();
    var month_str = 1 + date.getMonth(); //月だけ+1すること
    var day_str = date.getDate();
    var hour_str = date.getHours();
    var minute_str = date.getMinutes();
    
    month_str = ('0' + month_str).slice(-2);
    day_str = ('0' + day_str).slice(-2);
    hour_str = ('0' + hour_str).slice(-2);
    minute_str = ('0' + minute_str).slice(-2);
    
    // フォーマット
    var format_str = 'YYYY-MM-DD hh:mm';
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    format_str = format_str.replace(/hh/g, hour_str);
    format_str = format_str.replace(/mm/g, minute_str);
    
    return format_str;
};

export default function NotificationDetial(){    
    const param = useParams();
    const commonStyle = commonStyles();
    
    const [notifications, dispatch] = useReducer(notificationReducer, []);
    const initData = async (type) =>{
        const res = await API.graphql({
            query: getNotifications,
            variables: { id: param.notifId },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
        });        
        dispatch({ type: type, notifications:res.data.getNotifications });
    }

    const checkReadUnread = async () =>{
        Auth.currentAuthenticatedUser().then((user) =>{
            const filter = {
                notificationsID: {contains: param.notifId}, 
                userdatasID: {contains: user.username}
            }
            API.graphql({
                query: listConectIsReadUnreadToNotifications,
                variables: { filter: filter, limit:1000 },
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            }).then((res) => {
                if(res.data.listConectIsReadUnreadToNotifications.items.length <= 0){
                    API.graphql({
                        query: createConectIsReadUnreadToNotifications,
                        variables: {
                            input: {
                                notificationsID: param.notifId, 
                                userdatasID: user.username,
                            },
                        },
                        authMode: 'AMAZON_COGNITO_USER_POOLS',
                    });
                }
            });
        })
    }
    
    useEffect(() => {
        // マウント時処理
        initData(INITIAL_QUERY);
        checkReadUnread();
        // アンマウント時処理
        return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header title="通知一覧"/>
                <SideMenu/>
                <Container className={commonStyle.pageContent} >
                    <Box pt={2}></Box>
                    <Typography
                        variant='h5'
                    >{notifications.notificationTitle}</Typography>
                    <Typography 
                        variant='caption'
                    >
                        {
                            notifications.notificationDateTime != null ? 
                            getStringFromDate(new Date(notifications.notificationDateTime))
                            :
                            <Box></Box>
                        }
                    </Typography>
                    { 
                        notifications.body != null ? 
                        notifications.body.split('<br/>').map(char =>(
                            <Typography variant='h6'>{char}</Typography>
                        ))
                        :
                        <Box></Box>
                    }
                </Container>
            <Footer/>
        </Box>
    );
}
