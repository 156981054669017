// AWS React
import { 
    useEffect, 
    useState, 
    useReducer,
    Fragment, 
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
    Typography, 
    Box, 
    Grid, 
    Avatar, 
    Badge, 
    IconButton,
    withStyles, 
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

// React-Icons
import { ArrowForwardIos } from '@material-ui/icons';

// Self Making Files
import * as Consts from '../../Commons/Consts';
import { 
    getStringFromDateForFormatString,
    getUserDataById, 
    getUserIconImage,
    consoleLog, 
    recursiveGrapheql, 
} from '../../Commons/CommonFunctions';
import { 
    chatDatasReducer, 
    chatroomMemberReducer,
    commonDBDataReducer, 
} from "../../Commons/Reducers";
import { API } from 'aws-amplify';
import { listChatDatas, listCoachDatas, listTraineeDatas } from '../../graphql/queries';
import { TagsViewer } from './ChatroomDetailViewer';

const NoSendBadge = withStyles((theme) => ({
    badge: {
        right: 23,
        top: -12,
        border: `2px solid ${theme.palette.background.paper}`,
        backgroundColor: "#ff3366",
        color: "#ffffff",
    },
}))(Badge);

const SendingBadge = withStyles((theme) => ({
    badge: {
        right: 23,
        top: -12,
        border: `2px solid ${theme.palette.background.paper}`,
        backgroundColor: "#33ffaa",
        color: "#000000",
    },
}))(Badge);

const UnReadBadge = withStyles((theme) => ({
    badge: {
        right: 23,
        top: -12,
        border: `2px solid ${theme.palette.background.paper}`,
        backgroundColor: "#ffee33",
        color: "#000000",
    },
}))(Badge);

const NoMessageBadge = withStyles((theme) => ({
    badge: {
        right: 23,
        top: -12,
        border: `2px solid ${theme.palette.background.paper}`,
        backgroundColor: "#505050",
        color: "#000000",
    },
}))(Badge);


const localStyles = makeStyles(theme => ({
    avater:{ 
        [theme.breakpoints.down('sm')]: {
            width: 40,
            height: 40,
        },
        [theme.breakpoints.up('md')]: {
            width: 48,
            height: 48,
        },
    },
    roomCard:{
        padding: "5px",
        marginBottom: "0.1vh",
        cursor: "pointer",
        minHeight: "11vh",
        backgroundColor: theme.palette.background.paper,
        border: "solid #AAAAAA 0.5px",
    },
    selectRoomCard:{
        padding: "5px",
        marginBottom: "0.1vh",
        cursor: "pointer",
        minHeight: "11vh",
        backgroundColor: "#CCCCCC",
        border: "solid #AAAAAA 0.5px",
    },
    roomStatusTextArea:{
        [theme.breakpoints.down('sm')]: {
            '& .MuiTypography-h5':{
                fontSize: "4vmin",
                fontWeight: "500",
            },
            '& .MuiTypography-h6':{
                fontSize: "4vmin",
            },
        },
        [theme.breakpoints.up('md')]: {
            '& .MuiTypography-h5':{
                fontSize: "2vmin",
                fontWeight: "500",
            },
            '& .MuiTypography-h6':{
                fontSize: "2vmin",
            },
        }
    },
    centerContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
    loader: {
        display: 'flex',
        alignContent: 'stretch',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        paddingBottom: "12px",
    },
}));

export function CustomerChatRoomList({ chatrooms, customerChatrooms, user, setSendToUserData, dispatchChatDatas, setSelectId, selectId, csType, userType, setSendFromUserData }){
    const sortingRooms = chatrooms.sort((chatroomA, chatroomB) => {
        if(chatroomA.updatedAt < chatroomB.updatedAt){
            if(chatroomB.chatDatas !== null){
                return 1;
            }
            else{
                return -1;
            }
        }
        else{
            if(chatroomA.chatDatas !== null){
                return -1;
            }
            else{
                return -1;
            }
        }
    });

    return (
        <div>
            {sortingRooms.map((chatroom) => {
                    const csChatroom = customerChatrooms.find((cscroom) => cscroom.chatroomsID === chatroom.id)
                    if(csChatroom !== undefined){
                        if(setSendToUserData){
                            return (<PCChatRoomListItem
                                chatroom={chatroom}
                                user={user}
                                setSendToUserData={setSendToUserData}
                                dispatchChatDatas={dispatchChatDatas}
                                setSelectId={setSelectId}
                                selectId={selectId}
                                tags={csChatroom.tag}
                                csType={csType}
                                userType={userType}
                                setSendFromUserData={setSendFromUserData}
                            />);
                        }
                        else{
                            return (<ChatRoomListItem
                                chatroom={chatroom}
                                user={user}
                            />);
                        }
                    }
                    else{
                        return (<Fragment></Fragment>);
                    }
                })
            }
        </div>
    );
}

function ChatRoomListItem({ chatroom, user }){
    const history = useHistory();
    const localStyle = localStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [imageLoading] = useState(false);
    const [chatData, dispatchChatDatas] = useReducer(chatDatasReducer, []);
    const [chatroomMember, dispatchChatroomMember] = useReducer(chatroomMemberReducer, []);
    const [unreadCount, setUnreadCount] = useState(chatroom.ChatDatas.items.filter(item => {return item.checkOn === false && item.SendFromID === chatroom.roomMasterUserDatasID}).length);
    const [previewImage, setPreviewImage] = useState();
    const initChatroomDetail = async () =>{
        try{
            setUnreadCount(chatroom.ChatDatas.items.filter(item => {return item.checkOn === false && item.SendFromID === chatroom.roomMasterUserDatasID}).length)
            var memberUserData = null
            if(user.userRole === Consts.USER_ROLE.Trainee){
                // トレーニーの場合は、チェットルームのデータからメンバーデータを抜き出し、その情報を基づいてメンバーのユーザー情報を取得し、State変数へ保持
                memberUserData  = await getUserDataById(chatroom.ConectIsChatroomMembers.items[0].chatroomMemberUserDatasID);
                dispatchChatroomMember({ type: Consts.INITIAL_QUERY, chatroomMember:memberUserData });
            }else{
                // トレーニー以外の場合は、チャットルームIDからチャットルームメンバー中間テーブルからメンバーのユーザー情報を取得し、State変数へ保持
                memberUserData = await getUserDataById(chatroom.roomMasterUserDatasID);
                dispatchChatroomMember({ type: Consts.INITIAL_QUERY, chatroomMember:memberUserData });
            }
            // チャットルームデータからチャットデータを取得し、State変数へ保持
            dispatchChatDatas({ type: Consts.INITIAL_QUERY, chatData:chatroom.ChatDatas.items });

            // アイコンデータの取得
            const previewImage = await getUserIconImage(memberUserData);
            setPreviewImage(previewImage);
            setIsLoading(false);
        } catch (e) {
            if (e.name === Consts.ERROR_TYPE_ABORTERROR) {
                // something to do when error occur
            }
        }
    }

    const onClickChatroomGird = async () => {
        history.push(Consts.URL_PATH_CHATROOMS_DETAIL + Consts.URL_PARTS_SLASH + chatroom.id);
    }
    
    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();

        initChatroomDetail();
        
        // アンマウント時処理
        return () => {
            abortCtrl.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={localStyle.roomCard}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            onClick={onClickChatroomGird}
        >
            {isLoading?
            <Box className={localStyle.loader}>   
            </Box>
            :
            <Grid container>
                <Grid item xs={2} className={localStyle.centerContents}>
                    {/* <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                    >                                  
                    </StyledBadge> */}

                    {unreadCount <= 0 ?
                        imageLoading? 
                            <Box className={localStyle.loader}>   
                            </Box>
                        :
                            <Avatar
                                src={previewImage}
                                className={localStyle.avater}
                            />
                    :
                        <Badge 
                            badgeContent={unreadCount}
                            color="secondary"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <p>{unreadCount}</p>
                            {imageLoading? 
                            <Box className={localStyle.loader}>   
                            </Box>
                            :
                            <Avatar
                                src={previewImage}
                                className={localStyle.avater}
                            />
                            }
                        </Badge>
                    }
                </Grid>
                <Grid container xs={9} className={localStyle.roomStatusTextArea}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={6}>
                        <Typography variant='h5' >
                            {chatroomMember.displayName === null ?
                            chatroomMember.lastName + ' ' + chatroomMember.firstName
                            :
                            chatroomMember.displayName
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant='caption' >
                            {chatroom.updatedAt}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item zeroMinWidth xs={11}>
                        <Typography variant='h6' noWrap>
                            {chatData.length <= 0 ?
                            'メッセージはありません。'
                            :
                            chatData.sort((a,b) => {return a.chatTimestamp < b.chatTimestamp ? 1 : -1})[0].chatMessage
                            }
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={1} className={localStyle.centerContents}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                    >
                        <ArrowForwardIos />
                    </IconButton>
                </Grid>
            </Grid>
            }
        </Grid>
    );
}

function PCChatRoomListItem({ chatroom, user, dispatchChatDatas, setSendToUserData, setSelectId, selectId, tags, csType, userType, setSendFromUserData }){
    const localStyle = localStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [imageLoading] = useState(false);
    const [chatroomToUser, dispatchChatroomToUser] = useReducer(commonDBDataReducer, []);
    const [chatroomFromUser, dispatchChatroomFromUser] = useReducer(commonDBDataReducer, []);
    const [chatDatas, setChatDatas] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [sendingStatus, setSendingStatus] = useState(false);
    const [previewImage, setPreviewImage] = useState();
    const [localTags, setLocalTags] = useState('');
    const [badgeStatus, setBadgeStatus] = useState(3);
    const checkDate = new Date(chatroom.updatedAt)
    const gmtDT = new Date(checkDate.setHours(checkDate.getHours() + 9));
    const [chatroomUpdateDT, setChatroomUpdateDT] = useState(gmtDT);
    
    const initPCChatRoomListItem = async () =>{
        try {
            const checkDate = new Date(chatroom.updatedAt)
            const gmtDT = new Date(checkDate.setHours(checkDate.getHours() + 9));
            setChatroomUpdateDT(gmtDT);
            // メンバーデータの把握
            var memberToUserData = null
            var memberFromUserData = null
            if(user.userRole === Consts.USER_ROLE.Trainee){
                // トレーニーの場合は、チェットルームのデータからメンバーデータを抜き出し、その情報を基づいてメンバーのユーザー情報を取得し、State変数へ保持
                memberToUserData  = await getUserDataById(chatroom.ConectIsChatroomMembers.items[0].chatroomMemberUserDatasID);
                dispatchChatroomToUser({ type: Consts.INITIAL_QUERY, data:memberToUserData });
                memberFromUserData = await getUserDataById(chatroom.roomMasterUserDatasID);
                dispatchChatroomFromUser({ type: Consts.INITIAL_QUERY, data:memberFromUserData });
            }else{
                // トレーニー以外の場合は、チャットルームIDからチャットルームメンバー中間テーブルからメンバーのユーザー情報を取得し、State変数へ保持
                memberToUserData = await getUserDataById(chatroom.roomMasterUserDatasID);
                dispatchChatroomToUser({ type: Consts.INITIAL_QUERY, data:memberToUserData });
                memberFromUserData = await getUserDataById(chatroom.ConectIsChatroomMembers.items[0].chatroomMemberUserDatasID);
                dispatchChatroomFromUser({ type: Consts.INITIAL_QUERY, data:memberFromUserData });
            }
            //setSendToUserData(chatroomToUser);

            if(userType === Consts.USER_TYPE.TRAINEE){
                const traineeFilter = {
                    userdatasID: {contains: chatroom.roomMasterUserDatasID },
                };
                const res = await API.graphql({
                    query: listTraineeDatas,
                    variables: { filter: traineeFilter, limit: 10000 },
                    authMode: Consts.AMAZON_COGNITO_USER_POOLS,
                });
                if(res.data.listTraineeDatas.items.at(0).tag !== null){
                    setLocalTags(res.data.listTraineeDatas.items.at(0).tag);
                }
            }else if(userType === Consts.USER_TYPE.COACH){
                const traineeFilter = {
                    userdatasID: {contains: chatroom.roomMasterUserDatasID },
                };
                const res = await API.graphql({
                    query: listCoachDatas,
                    variables: { filter: traineeFilter, limit: 10000 },
                    authMode: Consts.AMAZON_COGNITO_USER_POOLS,
                });
                if(res.data.listCoachDatas.items.at(0).tag !== null){
                    setLocalTags(res.data.listCoachDatas.items.at(0).tag);
                }
            }
        
            const csSendReadStatus = (chatroom.unReadFlg & 48) >> 4;
            const traineeSendReadStatus = (chatroom.unReadFlg & 3);
            const coachSendReadStatus = (chatroom.unReadFlg & 12) >> 2;
            
            if(chatroom.unReadFlg === 0){
                setBadgeStatus(3);
            }else if(((csSendReadStatus >> 1 ) & 1) === 1){ // CS送信済
                setBadgeStatus(0);
            }else if((csSendReadStatus & 1) === 1){ // 既読済
                if(((traineeSendReadStatus >> 1) & 1) === 1){ // 送信済
                    setBadgeStatus(2);
                }
                else if(((coachSendReadStatus >> 1) & 1) === 1){ // 送信済
                    setBadgeStatus(1);
                }
                else{
                    setBadgeStatus(3);
                }
            }else{ // 未送信未読
                if(((traineeSendReadStatus >> 1) & 1) === 1){ // 送信済
                    setBadgeStatus(1);
                }
                else if(((coachSendReadStatus >> 1) & 1) === 1){ // 送信済
                    setBadgeStatus(1);
                }
                else{
                    setBadgeStatus(3);
                }
            }
            
            // チャットルームデータからチャットデータを取得し、State変数へ保持
            const chatData = chatroom.ChatDatas.items;
            //dispatchChatDatas({ type: Consts.INITIAL_QUERY, data:chatData });
            setChatDatas(chatData);
            setUnreadCount(chatData.filter(item => {return item.checkOn === false && item.SendFromID !== user.userId}).length);
            
            if(0 < chatData.length){
                if(chatData.sort((a,b) => a.chatTimestamp < b.chatTimestamp ? 1 : -1)[0].SendFromID !== chatroom.roomMasterUserDatasID){
                    setSendingStatus(true);
                }else{
                    setSendingStatus(false);
                }
            }else{
                setSendingStatus(false);
            }
            
            // アイコンデータの取得
            const previewImage = await getUserIconImage(memberToUserData);
            setPreviewImage(previewImage);
            setIsLoading(false);
        } catch (e) {
            if (e.name === Consts.ERROR_TYPE_ABORTERROR) {
                // something to do when error occur
                consoleLog(user.userRole, {ABORTERROR:e});
            }
        }
    }

    const onClickChatroomGird = async () => {
        const filter = {
            chatroomID: {contains: chatroom.id },
        };
        await recursiveGrapheql(listChatDatas, 'listChatDatas', { filter: filter , limit:100000 , nextToken: null}, dispatchChatDatas);
        setSelectId(chatroom.id);
        setSendToUserData(chatroomToUser);
        setSendFromUserData(chatroomFromUser);
        
        if(csType === Consts.CustomerChatroomTypes.CUSTOMER_SUCCESS){
            if(userType === Consts.USER_TYPE.TRAINEE){
                history.push(Consts.URL_PATH_CS_CHAT_SUPPORT + Consts.URL_PARTS_SLASH + chatroom.id);
            }
            else{
                history.push(Consts.URL_PATH_CS_CHAT_SUPPORT_COACH + Consts.URL_PARTS_SLASH + chatroom.id);
            }
        }    
        else if(csType === Consts.CustomerChatroomTypes.OPERATIONS){
            if(userType === Consts.USER_TYPE.TRAINEE){
                history.push(Consts.URL_PATH_CS_CHAT_OPERATION + Consts.URL_PARTS_SLASH + chatroom.id);
            }
            else{
                history.push(Consts.URL_PATH_CS_CHAT_OPERATION_COACH + Consts.URL_PARTS_SLASH + chatroom.id);
            }
        }
        else if(csType === Consts.CustomerChatroomTypes.APP_SUPPORT){
            if(userType === Consts.USER_TYPE.TRAINEE){
                history.push(Consts.URL_PATH_CS_CHAT_APP_SUPPORT + Consts.URL_PARTS_SLASH + chatroom.id);
            }
            else{
                history.push(Consts.URL_PATH_CS_CHAT_APP_SUPPORT_COACH + Consts.URL_PARTS_SLASH + chatroom.id);
            }
        }
        else if(csType === Consts.CustomerChatroomTypes.COCHING_CHAT){
            history.push(Consts.URL_PATH_CS_CHAT_COACHING + Consts.URL_PARTS_SLASH + chatroom.id);
        }
    }
    
    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();

        initPCChatRoomListItem();
        // アンマウント時処理
        return () => {
            abortCtrl.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={chatroom.id === selectId ? localStyle.selectRoomCard : localStyle.roomCard}
            rowSacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            onClick={onClickChatroomGird}
        >
            {isLoading?
            <Box className={localStyle.loader}>   
            </Box>
            :
            <Grid container>
                <Grid item xs={2} className={localStyle.centerContents}>
                    {/* <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                    >                                  
                    </StyledBadge> */}
                    {user.userRole === Consts.USER_ROLE.CustomerSuccess || user.userRole === Consts.USER_ROLE.CustomerSuccess ?
                        badgeStatus === 0?
                            <SendingBadge 
                                badgeContent={''}
                            >
                            </SendingBadge>
                        :badgeStatus === 1?
                            <UnReadBadge 
                                badgeContent={''}
                            >
                            </UnReadBadge>
                        :badgeStatus === 2?
                            <NoSendBadge 
                                badgeContent={''}
                            >
                            </NoSendBadge>
                        :
                            <NoMessageBadge 
                                badgeContent={''}
                            >
                            </NoMessageBadge>
                    :
                    <Fragment></Fragment>
                    } 
                    {unreadCount <= 0 ?
                        sendingStatus?
                            <SendingBadge 
                                badgeContent={'→'}
                            >
                                {imageLoading? 
                                <Box className={localStyle.loader}>   
                                </Box>
                                :
                                <Avatar
                                    src={previewImage}
                                    className={localStyle.avater}
                                />
                                }
                            </SendingBadge>
                        :
                            <UnReadBadge 
                                badgeContent={'→'}
                            >
                                {imageLoading? 
                                <Box className={localStyle.loader}>   
                                </Box>
                                :
                                <Avatar
                                    src={previewImage}
                                    className={localStyle.avater}
                                />
                                }
                            </UnReadBadge>
                    :
                        <NoSendBadge 
                            badgeContent={unreadCount}
                        >
                            {imageLoading? 
                            <Box className={localStyle.loader}>   
                            </Box>
                            :
                            <Avatar
                                src={previewImage}
                                className={localStyle.avater}
                            />
                            }
                        </NoSendBadge>
                    }
                                  
                </Grid>
                <Grid container xs={9} className={localStyle.roomStatusTextArea}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={11}>
                        <Typography variant='caption' >
                            {getStringFromDateForFormatString(new Date(chatroomUpdateDT), Consts.FORMAT_STRING_MMDDHHMM)}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={11}>
                        <Typography variant='h6' >
                            {chatroomToUser.displayName === null ?
                            chatroomToUser.lastName + ' ' + chatroomToUser.firstName
                            :
                            chatroomToUser.displayName
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item zeroMinWidth xs={11}>
                        <Typography variant='h6' noWrap>
                            {chatroom.lastMessage === '' ?
                            'メッセージはありません。'
                            :
                            chatroom.lastMessage
                            }
                        </Typography>
                    </Grid>
                    <TagsViewer tags={localTags}></TagsViewer>
                </Grid>
                <Grid item xs={1} className={localStyle.centerContents}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                    >
                        <ArrowForwardIos />
                    </IconButton>
                </Grid>
            </Grid>
            }
        </Grid>
    );
}
  