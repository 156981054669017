// AWS React
import { API } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Grid, Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

// Self Making Files
import { 
    checkContentTypeIsImagefile, 
    consoleLog, 
    getContentType, 
    getCurrentUserData, 
    getStorageDownloadObject, 
    getStorageObject, 
    getStringFromDateForFormatString, 
    getUserIconImage, 
    LineTextComponent 
} from "../../Commons/CommonFunctions";
import { createRef, Fragment, useCallback, useEffect, useState } from 'react';
import { AMAZON_COGNITO_USER_POOLS, CHAT_STATUS_ALREADY_READ, CHAT_STATUS_READED, FORMAT_STRING_YYYYMMDDHHMM, USER_ROLE } from '../../Commons/Consts';
import PreviewImageDialog from '../../Commons/PreviewImageDialog';
import { updateChatDatas, updateChatrooms } from '../../graphql/mutations';
import { commonStyles } from '../../Commons/CommonStyles';

const localStyles = makeStyles(theme => ({
    listRoot:{
        padding: "10px",
    },
    messsageCardRoot:{
        marginTop: "10px",
    },
    fromMessageCardGrid:{
        position: "relative",
        '&::before':{
            content: '""',
            position: "absolute",
            width: 0,
            height: 0,
            right: "-10px",
            top: "-10px",
            borderBottom: "20px solid #CDB59B",
            borderRight: "10px solid transparent",
            borderLeft: "10px solid transparent",
            transform: "rotate(45deg)",
        },
    },
    fromMessageCard:{
        borderRadius: "10px",
        padding: "10px",
        backgroundColor: "#CDB59B",
        [theme.breakpoints.down('sm')]: {
            '& .MuiTypography-root':{
                fontSize: "4vmin",
            },
        },
        [theme.breakpoints.up('md')]: {
            '& .MuiTypography-root':{
                fontSize: "2vmin",
            },
        },
    },
    toMessageCardGrid:{
        position: "relative",
        '&::before':{
            content: '""',
            position: "absolute",
            width: 0,
            height: 0,
            left: "-10px",
            top: "-10px",
            borderBottom: "20px solid #FFFFFF",
            borderRight: "10px solid transparent",
            borderLeft: "10px solid transparent",
            transform: "rotate(-45deg)",
        },
    },
    toMessageCard:{
        borderRadius: "10px",
        padding: "10px",
        backgroundColor: "#FFFFFF",
        [theme.breakpoints.down('sm')]: {
            '& .MuiTypography-root':{
                fontSize: "4vmin",
            },
        },
        [theme.breakpoints.up('md')]: {
            '& .MuiTypography-root':{
                fontSize: "2vmin",
            },
        },
    },
    centerContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-start",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    rightContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-start",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "flex-end",
    },
    contentCard:{
        margin: "2px",
        padding: "5px",
        borderRadius: "10px",
        backgroundColor: "#555555",
        color: "#EEEEEE",
        width: "98%",
        display: "flex",
        flexDirection: "row",
        alignContent: "flex-start",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "center",
        '& .MuiAvatar-square':{
            marginLeft: "4px",
            marginRight: "4px",
        },
        '& .MuiTypography-root':{
            fontSize: "2vmin",
        },
        '& a':{
            display: "flex",
            flexDirection: "row",
            alignContent: "flex-start",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            alignItems: "center",
            color: "white",
            cursor: "pointer",
            textDecoration:"none",
            '& .MuiSvgIcon-root':{
                width: "40px",
                height: "40px",
            },
        },
    },
    endBox:{
        [theme.breakpoints.down('sm')]: {
            height: "40vh",
        },
        [theme.breakpoints.up('md')]: {
            height: "30%",
        },
    },
}));

function PreviewImageBox({ imageKey, userData }){
    const localStyle = localStyles();
    const [contentType] = useState(getContentType(imageKey));
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState();
    const [downloadContent, setDownloadContent] = useState({});
    
    const initPreviewImageBox = async () => {
        if(checkContentTypeIsImagefile(contentType)){
            const res = await getStorageObject(userData, imageKey);
            consoleLog(userData.userRole, res);
            setImage(res);
        }else{
            const res = await getStorageDownloadObject(userData, imageKey);
            consoleLog(userData.userRole, res);
            setDownloadContent(res);
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        initPreviewImageBox();
        // アンマウント時処理
        return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <div>
            {checkContentTypeIsImagefile(contentType)?
            <Box className={localStyle.contentCard}>
                <Avatar variant="square" src={image} onClick={handleOpen}></Avatar>
                <Typography>{imageKey}</Typography>
                <PreviewImageDialog
                    open={open} 
                    setOpen={setOpen} 
                    handleClose={handleClose} 
                    caption={imageKey}
                    src={image}
                />
            </Box>
            :
            <Box className={localStyle.contentCard}>
                <a 
                    rel="noreferrer" 
                    target="_blank" 
                    href={downloadContent.href} 
                    download={downloadContent.download}
                >
                    <GetAppIcon/>
                    <Typography>{imageKey}</Typography>
                </a>
            </Box>
            }

        </div>);
}

export function ChatDataList({chatData, sendFromUserData, sendToUserData, chatroom}){
    const localStyle = localStyles();
    const commonStyle = commonStyles();
    const [sendToUserIcon, setSendToUserIcon] = useState();
    // ref を作成しスクロールさせたい場所にある Element にセット
    const ref = createRef();
    // このコールバックを呼び出して ref.current.scrollIntoView() を呼び出してスクロール
    const scrollToBottomOfList = useCallback(() => {
    ref.current.scrollIntoView({
        behavior: 'auto',
        block: 'end',
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ ref ]);

    const initChatIcons = async () => {
        setSendToUserIcon(await getUserIconImage(sendToUserData));
    }

    const readChatMessage = async (chat) =>{
        await API.graphql({
            query: updateChatDatas,
            variables: {
                input: {
                    id: chat.id,
                    checkOn: true,
                }        
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
        
    }
    const updateUnReadFlg = async () => {
        const currentUserData = getCurrentUserData();
        var updateUnReadFlg = 0;
        if(currentUserData.userRole === USER_ROLE.Trainee){
            updateUnReadFlg = chatroom.unReadFlg | 1
        }
        else if(currentUserData.userRole === USER_ROLE.Coach){
            updateUnReadFlg = chatroom.unReadFlg | 4
        }
        else if(currentUserData.userRole === USER_ROLE.CustomerSuccess || 
            currentUserData.userRole === USER_ROLE.SuperAdministrator){
            updateUnReadFlg = chatroom.unReadFlg | 16
        }
        const res = await API.graphql({
            query: updateChatrooms,
            variables: {
                input: {
                    id: chatroom.id,
                    unReadFlg: updateUnReadFlg,
                }        
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
        consoleLog(sendFromUserData.userRole, res);
    }
    useEffect(() => {
        initChatIcons();
        scrollToBottomOfList();
        for(const chat of chatData){
            if(chat.checkOn === false && chat.SendFromID !== sendFromUserData.userId){
                readChatMessage(chat);
            } 
        }
        updateUnReadFlg();

        // アンマウント時処理
        return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatData]);

    return(
        <Box className={localStyle.listRoot}>
            {chatData.sort((a,b) => {return a.chatTimestamp < b.chatTimestamp ? -1 : 1}).map((data) => {
                const checkDate = new Date(data.chatDateTime);
                const gmtDate = new Date(checkDate.setHours(checkDate.getHours() + 9));
                return(
                data.SendFromID === sendFromUserData.userId ?
                <div>
                    <Box className={commonStyle.mobileModeDisplay}>
                        <Grid container className={localStyle.messsageCardRoot} >
                            <Grid item xs={4}/>
                            <Grid item xs={8} className={localStyle.fromMessageCardGrid}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    className={localStyle.fromMessageCard}
                                    rowSacing={1}
                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                    {data.chatOnImage === null ?
                                    <Fragment>{data.chatOnImage}</Fragment>
                                    :
                                    <PreviewImageBox imageKey={data.chatOnImage} userData={sendFromUserData} />
                                    }
                                    <Grid item xs={12}>
                                        <Typography><LineTextComponent msg={data.chatMessage}></LineTextComponent></Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={localStyle.rightContents}>
                                    <Typography variant='caption'>{getStringFromDateForFormatString(gmtDate, FORMAT_STRING_YYYYMMDDHHMM)}{sendFromUserData.userRole === USER_ROLE.Coach && data.checkOn === true ? CHAT_STATUS_READED : ''}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className={commonStyle.pcModeDisplay}>
                        <Grid container className={localStyle.messsageCardRoot} >
                            <Grid item xs={1}/>
                            <Grid item xs={11} className={localStyle.fromMessageCardGrid}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    className={localStyle.fromMessageCard}
                                    rowSacing={1}
                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                    {data.chatOnImage === null ?
                                    <Fragment>{data.chatOnImage}</Fragment>
                                    :
                                    <PreviewImageBox imageKey={data.chatOnImage} userData={sendFromUserData} />
                                    }
                                    <Grid item xs={12}>
                                        <Typography><LineTextComponent msg={data.chatMessage}></LineTextComponent></Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={localStyle.rightContents}>
                                    <Typography variant='caption'>{getStringFromDateForFormatString(gmtDate, FORMAT_STRING_YYYYMMDDHHMM)}{sendFromUserData.userRole === USER_ROLE.Coach && data.checkOn === true ? CHAT_STATUS_READED : ''}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
                :
                data.chatDataStatus !== 1? 
                <div>
                    <Box className={commonStyle.mobileModeDisplay}>
                        <Grid container className={localStyle.messsageCardRoot} >
                            <Grid item xs={1} className={localStyle.centerContents}>
                                <Avatar
                                    src={sendToUserIcon}
                                />
                            </Grid>
                            <Grid item xs={1}/>
                            <Grid item xs={8} className={localStyle.toMessageCardGrid} >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    className={localStyle.toMessageCard}
                                    rowSacing={1}
                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                    {data.chatOnImage === null ?
                                    <Fragment>{data.chatOnImage}</Fragment>
                                    :
                                    <PreviewImageBox imageKey={data.chatOnImage} userData={sendToUserData} />
                                    }
                                    <Grid item xs={12}>
                                        <Typography><LineTextComponent msg={data.chatMessage}></LineTextComponent></Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='caption'>{getStringFromDateForFormatString(gmtDate, FORMAT_STRING_YYYYMMDDHHMM)}{sendFromUserData.userRole === USER_ROLE.Coach && data.checkOn === true ? CHAT_STATUS_ALREADY_READ : ''}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}/>
                        </Grid>
                    </Box>
                    <Box className={commonStyle.pcModeDisplay}>
                        <Grid container className={localStyle.messsageCardRoot} >
                            <Grid item xs={10} className={localStyle.toMessageCardGrid} >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    className={localStyle.toMessageCard}
                                    rowSacing={1}
                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                    {data.chatOnImage === null ?
                                    <Fragment>{data.chatOnImage}</Fragment>
                                    :
                                    <PreviewImageBox imageKey={data.chatOnImage} userData={sendToUserData} />
                                    }
                                    <Grid item xs={12}>
                                        <Typography><LineTextComponent msg={data.chatMessage}></LineTextComponent></Typography>
                                    </Grid>
                                </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant='caption'>{getStringFromDateForFormatString(gmtDate, FORMAT_STRING_YYYYMMDDHHMM)}{sendFromUserData.userRole === USER_ROLE.Coach && data.checkOn === true ? CHAT_STATUS_ALREADY_READ : ''}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}/>
                        </Grid>
                    </Box>
                </div>
                :
                <Fragment></Fragment>
            )})}
            <Box className={localStyle.endBox}></Box>
            <div id="endBox" ref={ref}></div>
        </Box>
    )
}

