/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCSMemberDatas = /* GraphQL */ `
  subscription OnCreateCSMemberDatas(
    $filter: ModelSubscriptionCSMemberDatasFilterInput
  ) {
    onCreateCSMemberDatas(filter: $filter) {
      id
      userdatasID
      userName
      csChatroomType
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCSMemberDatas = /* GraphQL */ `
  subscription OnUpdateCSMemberDatas(
    $filter: ModelSubscriptionCSMemberDatasFilterInput
  ) {
    onUpdateCSMemberDatas(filter: $filter) {
      id
      userdatasID
      userName
      csChatroomType
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCSMemberDatas = /* GraphQL */ `
  subscription OnDeleteCSMemberDatas(
    $filter: ModelSubscriptionCSMemberDatasFilterInput
  ) {
    onDeleteCSMemberDatas(filter: $filter) {
      id
      userdatasID
      userName
      csChatroomType
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatNotificationDatas = /* GraphQL */ `
  subscription OnCreateChatNotificationDatas(
    $filter: ModelSubscriptionChatNotificationDatasFilterInput
  ) {
    onCreateChatNotificationDatas(filter: $filter) {
      id
      sendFromUserdatasID
      sendFromUserdatasName
      sendToUserdatasID
      sendToUserdatasName
      sendMessage
      sendFromStatus
      sendToStatus
      csStatus
      chatroomName
      Chatrooms {
        id
        chatroomUrl
        roomMasterUserDatasID
        ConectIsChatroomMembers {
          items {
            id
            chatroomID
            chatroomMemberUserDatasID
            createdAt
            updatedAt
          }
          nextToken
        }
        ChatDatas {
          items {
            id
            chatMessage
            chatDateTime
            chatTimestamp
            checkOn
            chatroomID
            SendFromID
            chatOnImage
            chatDataStatus
            traineeCheckStatus
            coachCheckStatus
            csCheckStatus
            createdAt
            updatedAt
          }
          nextToken
        }
        CustomerChatrooms {
          items {
            id
            tag
            roomType
            userdatasID
            chatroomsID
            userdatasType
            roomMasterName
            createdAt
            updatedAt
          }
          nextToken
        }
        lastMessage
        unReadFlg
        roomMasterName
        sendFromTimestamp
        sendToTimestamp
        sendFromDateTime
        sendToDateTime
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      chatNotificationDatasChatroomsId
    }
  }
`;
export const onUpdateChatNotificationDatas = /* GraphQL */ `
  subscription OnUpdateChatNotificationDatas(
    $filter: ModelSubscriptionChatNotificationDatasFilterInput
  ) {
    onUpdateChatNotificationDatas(filter: $filter) {
      id
      sendFromUserdatasID
      sendFromUserdatasName
      sendToUserdatasID
      sendToUserdatasName
      sendMessage
      sendFromStatus
      sendToStatus
      csStatus
      chatroomName
      Chatrooms {
        id
        chatroomUrl
        roomMasterUserDatasID
        ConectIsChatroomMembers {
          items {
            id
            chatroomID
            chatroomMemberUserDatasID
            createdAt
            updatedAt
          }
          nextToken
        }
        ChatDatas {
          items {
            id
            chatMessage
            chatDateTime
            chatTimestamp
            checkOn
            chatroomID
            SendFromID
            chatOnImage
            chatDataStatus
            traineeCheckStatus
            coachCheckStatus
            csCheckStatus
            createdAt
            updatedAt
          }
          nextToken
        }
        CustomerChatrooms {
          items {
            id
            tag
            roomType
            userdatasID
            chatroomsID
            userdatasType
            roomMasterName
            createdAt
            updatedAt
          }
          nextToken
        }
        lastMessage
        unReadFlg
        roomMasterName
        sendFromTimestamp
        sendToTimestamp
        sendFromDateTime
        sendToDateTime
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      chatNotificationDatasChatroomsId
    }
  }
`;
export const onDeleteChatNotificationDatas = /* GraphQL */ `
  subscription OnDeleteChatNotificationDatas(
    $filter: ModelSubscriptionChatNotificationDatasFilterInput
  ) {
    onDeleteChatNotificationDatas(filter: $filter) {
      id
      sendFromUserdatasID
      sendFromUserdatasName
      sendToUserdatasID
      sendToUserdatasName
      sendMessage
      sendFromStatus
      sendToStatus
      csStatus
      chatroomName
      Chatrooms {
        id
        chatroomUrl
        roomMasterUserDatasID
        ConectIsChatroomMembers {
          items {
            id
            chatroomID
            chatroomMemberUserDatasID
            createdAt
            updatedAt
          }
          nextToken
        }
        ChatDatas {
          items {
            id
            chatMessage
            chatDateTime
            chatTimestamp
            checkOn
            chatroomID
            SendFromID
            chatOnImage
            chatDataStatus
            traineeCheckStatus
            coachCheckStatus
            csCheckStatus
            createdAt
            updatedAt
          }
          nextToken
        }
        CustomerChatrooms {
          items {
            id
            tag
            roomType
            userdatasID
            chatroomsID
            userdatasType
            roomMasterName
            createdAt
            updatedAt
          }
          nextToken
        }
        lastMessage
        unReadFlg
        roomMasterName
        sendFromTimestamp
        sendToTimestamp
        sendFromDateTime
        sendToDateTime
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      chatNotificationDatasChatroomsId
    }
  }
`;
export const onCreateInitChatroomDatas = /* GraphQL */ `
  subscription OnCreateInitChatroomDatas(
    $filter: ModelSubscriptionInitChatroomDatasFilterInput
  ) {
    onCreateInitChatroomDatas(filter: $filter) {
      id
      initChatroomType
      initMemberUserdatsID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInitChatroomDatas = /* GraphQL */ `
  subscription OnUpdateInitChatroomDatas(
    $filter: ModelSubscriptionInitChatroomDatasFilterInput
  ) {
    onUpdateInitChatroomDatas(filter: $filter) {
      id
      initChatroomType
      initMemberUserdatsID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInitChatroomDatas = /* GraphQL */ `
  subscription OnDeleteInitChatroomDatas(
    $filter: ModelSubscriptionInitChatroomDatasFilterInput
  ) {
    onDeleteInitChatroomDatas(filter: $filter) {
      id
      initChatroomType
      initMemberUserdatsID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAdditionalServiceUrls = /* GraphQL */ `
  subscription OnCreateAdditionalServiceUrls(
    $filter: ModelSubscriptionAdditionalServiceUrlsFilterInput
  ) {
    onCreateAdditionalServiceUrls(filter: $filter) {
      id
      captionTitle
      url
      urlType
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAdditionalServiceUrls = /* GraphQL */ `
  subscription OnUpdateAdditionalServiceUrls(
    $filter: ModelSubscriptionAdditionalServiceUrlsFilterInput
  ) {
    onUpdateAdditionalServiceUrls(filter: $filter) {
      id
      captionTitle
      url
      urlType
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAdditionalServiceUrls = /* GraphQL */ `
  subscription OnDeleteAdditionalServiceUrls(
    $filter: ModelSubscriptionAdditionalServiceUrlsFilterInput
  ) {
    onDeleteAdditionalServiceUrls(filter: $filter) {
      id
      captionTitle
      url
      urlType
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCustomerChatdatas = /* GraphQL */ `
  subscription OnCreateCustomerChatdatas(
    $filter: ModelSubscriptionCustomerChatdatasFilterInput
  ) {
    onCreateCustomerChatdatas(filter: $filter) {
      id
      chatMessage
      chatDateTime
      chatDateTimestamp
      checkOn
      customerchatroomsID
      sendUserdatasID
      chatOnImage
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCustomerChatdatas = /* GraphQL */ `
  subscription OnUpdateCustomerChatdatas(
    $filter: ModelSubscriptionCustomerChatdatasFilterInput
  ) {
    onUpdateCustomerChatdatas(filter: $filter) {
      id
      chatMessage
      chatDateTime
      chatDateTimestamp
      checkOn
      customerchatroomsID
      sendUserdatasID
      chatOnImage
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCustomerChatdatas = /* GraphQL */ `
  subscription OnDeleteCustomerChatdatas(
    $filter: ModelSubscriptionCustomerChatdatasFilterInput
  ) {
    onDeleteCustomerChatdatas(filter: $filter) {
      id
      chatMessage
      chatDateTime
      chatDateTimestamp
      checkOn
      customerchatroomsID
      sendUserdatasID
      chatOnImage
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCustomerChatrooms = /* GraphQL */ `
  subscription OnCreateCustomerChatrooms(
    $filter: ModelSubscriptionCustomerChatroomsFilterInput
  ) {
    onCreateCustomerChatrooms(filter: $filter) {
      id
      tag
      roomType
      CustomerChatdatas {
        items {
          id
          chatMessage
          chatDateTime
          chatDateTimestamp
          checkOn
          customerchatroomsID
          sendUserdatasID
          chatOnImage
          createdAt
          updatedAt
        }
        nextToken
      }
      userdatasID
      chatroomsID
      userdatasType
      roomMasterName
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCustomerChatrooms = /* GraphQL */ `
  subscription OnUpdateCustomerChatrooms(
    $filter: ModelSubscriptionCustomerChatroomsFilterInput
  ) {
    onUpdateCustomerChatrooms(filter: $filter) {
      id
      tag
      roomType
      CustomerChatdatas {
        items {
          id
          chatMessage
          chatDateTime
          chatDateTimestamp
          checkOn
          customerchatroomsID
          sendUserdatasID
          chatOnImage
          createdAt
          updatedAt
        }
        nextToken
      }
      userdatasID
      chatroomsID
      userdatasType
      roomMasterName
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCustomerChatrooms = /* GraphQL */ `
  subscription OnDeleteCustomerChatrooms(
    $filter: ModelSubscriptionCustomerChatroomsFilterInput
  ) {
    onDeleteCustomerChatrooms(filter: $filter) {
      id
      tag
      roomType
      CustomerChatdatas {
        items {
          id
          chatMessage
          chatDateTime
          chatDateTimestamp
          checkOn
          customerchatroomsID
          sendUserdatasID
          chatOnImage
          createdAt
          updatedAt
        }
        nextToken
      }
      userdatasID
      chatroomsID
      userdatasType
      roomMasterName
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTodayWords = /* GraphQL */ `
  subscription OnCreateTodayWords(
    $filter: ModelSubscriptionTodayWordsFilterInput
  ) {
    onCreateTodayWords(filter: $filter) {
      id
      caption
      contentText
      displayNumber
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTodayWords = /* GraphQL */ `
  subscription OnUpdateTodayWords(
    $filter: ModelSubscriptionTodayWordsFilterInput
  ) {
    onUpdateTodayWords(filter: $filter) {
      id
      caption
      contentText
      displayNumber
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTodayWords = /* GraphQL */ `
  subscription OnDeleteTodayWords(
    $filter: ModelSubscriptionTodayWordsFilterInput
  ) {
    onDeleteTodayWords(filter: $filter) {
      id
      caption
      contentText
      displayNumber
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRefusalOfferDatas = /* GraphQL */ `
  subscription OnCreateRefusalOfferDatas(
    $filter: ModelSubscriptionRefusalOfferDatasFilterInput
  ) {
    onCreateRefusalOfferDatas(filter: $filter) {
      id
      userName
      corporationName
      userdatasID
      corporationsID
      ScheduleEvents {
        id
        eventName
        eventType
        status
        deadlineDateTime
        deadlineTimestamp
        formType
        eventColorcode
        EventDetialDatas {
          items {
            id
            parentScheduleEventsID
            caption
            answer
            createdAt
            updatedAt
          }
          nextToken
        }
        userDatasID
        corporationsID
        createdAt
        updatedAt
      }
      refusalOfferStatus
      createdAt
      updatedAt
      refusalOfferDatasScheduleEventsId
    }
  }
`;
export const onUpdateRefusalOfferDatas = /* GraphQL */ `
  subscription OnUpdateRefusalOfferDatas(
    $filter: ModelSubscriptionRefusalOfferDatasFilterInput
  ) {
    onUpdateRefusalOfferDatas(filter: $filter) {
      id
      userName
      corporationName
      userdatasID
      corporationsID
      ScheduleEvents {
        id
        eventName
        eventType
        status
        deadlineDateTime
        deadlineTimestamp
        formType
        eventColorcode
        EventDetialDatas {
          items {
            id
            parentScheduleEventsID
            caption
            answer
            createdAt
            updatedAt
          }
          nextToken
        }
        userDatasID
        corporationsID
        createdAt
        updatedAt
      }
      refusalOfferStatus
      createdAt
      updatedAt
      refusalOfferDatasScheduleEventsId
    }
  }
`;
export const onDeleteRefusalOfferDatas = /* GraphQL */ `
  subscription OnDeleteRefusalOfferDatas(
    $filter: ModelSubscriptionRefusalOfferDatasFilterInput
  ) {
    onDeleteRefusalOfferDatas(filter: $filter) {
      id
      userName
      corporationName
      userdatasID
      corporationsID
      ScheduleEvents {
        id
        eventName
        eventType
        status
        deadlineDateTime
        deadlineTimestamp
        formType
        eventColorcode
        EventDetialDatas {
          items {
            id
            parentScheduleEventsID
            caption
            answer
            createdAt
            updatedAt
          }
          nextToken
        }
        userDatasID
        corporationsID
        createdAt
        updatedAt
      }
      refusalOfferStatus
      createdAt
      updatedAt
      refusalOfferDatasScheduleEventsId
    }
  }
`;
export const onCreateOfferDatas = /* GraphQL */ `
  subscription OnCreateOfferDatas(
    $filter: ModelSubscriptionOfferDatasFilterInput
  ) {
    onCreateOfferDatas(filter: $filter) {
      id
      userName
      corporationName
      corporationsID
      userdatasID
      ScheduleEvents {
        id
        eventName
        eventType
        status
        deadlineDateTime
        deadlineTimestamp
        formType
        eventColorcode
        EventDetialDatas {
          items {
            id
            parentScheduleEventsID
            caption
            answer
            createdAt
            updatedAt
          }
          nextToken
        }
        userDatasID
        corporationsID
        createdAt
        updatedAt
      }
      offerStatus
      createdAt
      updatedAt
      offerDatasScheduleEventsId
    }
  }
`;
export const onUpdateOfferDatas = /* GraphQL */ `
  subscription OnUpdateOfferDatas(
    $filter: ModelSubscriptionOfferDatasFilterInput
  ) {
    onUpdateOfferDatas(filter: $filter) {
      id
      userName
      corporationName
      corporationsID
      userdatasID
      ScheduleEvents {
        id
        eventName
        eventType
        status
        deadlineDateTime
        deadlineTimestamp
        formType
        eventColorcode
        EventDetialDatas {
          items {
            id
            parentScheduleEventsID
            caption
            answer
            createdAt
            updatedAt
          }
          nextToken
        }
        userDatasID
        corporationsID
        createdAt
        updatedAt
      }
      offerStatus
      createdAt
      updatedAt
      offerDatasScheduleEventsId
    }
  }
`;
export const onDeleteOfferDatas = /* GraphQL */ `
  subscription OnDeleteOfferDatas(
    $filter: ModelSubscriptionOfferDatasFilterInput
  ) {
    onDeleteOfferDatas(filter: $filter) {
      id
      userName
      corporationName
      corporationsID
      userdatasID
      ScheduleEvents {
        id
        eventName
        eventType
        status
        deadlineDateTime
        deadlineTimestamp
        formType
        eventColorcode
        EventDetialDatas {
          items {
            id
            parentScheduleEventsID
            caption
            answer
            createdAt
            updatedAt
          }
          nextToken
        }
        userDatasID
        corporationsID
        createdAt
        updatedAt
      }
      offerStatus
      createdAt
      updatedAt
      offerDatasScheduleEventsId
    }
  }
`;
export const onCreateELearningMovieDatas = /* GraphQL */ `
  subscription OnCreateELearningMovieDatas(
    $filter: ModelSubscriptionELearningMovieDatasFilterInput
  ) {
    onCreateELearningMovieDatas(filter: $filter) {
      id
      captionTitle
      movieUrl
      number
      userdatasID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateELearningMovieDatas = /* GraphQL */ `
  subscription OnUpdateELearningMovieDatas(
    $filter: ModelSubscriptionELearningMovieDatasFilterInput
  ) {
    onUpdateELearningMovieDatas(filter: $filter) {
      id
      captionTitle
      movieUrl
      number
      userdatasID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteELearningMovieDatas = /* GraphQL */ `
  subscription OnDeleteELearningMovieDatas(
    $filter: ModelSubscriptionELearningMovieDatasFilterInput
  ) {
    onDeleteELearningMovieDatas(filter: $filter) {
      id
      captionTitle
      movieUrl
      number
      userdatasID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWeeklySurveyDatas = /* GraphQL */ `
  subscription OnCreateWeeklySurveyDatas(
    $filter: ModelSubscriptionWeeklySurveyDatasFilterInput
  ) {
    onCreateWeeklySurveyDatas(filter: $filter) {
      id
      captionTitle
      surveyUrl
      weeklyNumber
      userdatasID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWeeklySurveyDatas = /* GraphQL */ `
  subscription OnUpdateWeeklySurveyDatas(
    $filter: ModelSubscriptionWeeklySurveyDatasFilterInput
  ) {
    onUpdateWeeklySurveyDatas(filter: $filter) {
      id
      captionTitle
      surveyUrl
      weeklyNumber
      userdatasID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWeeklySurveyDatas = /* GraphQL */ `
  subscription OnDeleteWeeklySurveyDatas(
    $filter: ModelSubscriptionWeeklySurveyDatasFilterInput
  ) {
    onDeleteWeeklySurveyDatas(filter: $filter) {
      id
      captionTitle
      surveyUrl
      weeklyNumber
      userdatasID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAbuildSheetsDatas = /* GraphQL */ `
  subscription OnCreateAbuildSheetsDatas(
    $filter: ModelSubscriptionAbuildSheetsDatasFilterInput
  ) {
    onCreateAbuildSheetsDatas(filter: $filter) {
      id
      abuildSheetsUrl
      captionTitle
      weeklyNumber
      userDatasID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAbuildSheetsDatas = /* GraphQL */ `
  subscription OnUpdateAbuildSheetsDatas(
    $filter: ModelSubscriptionAbuildSheetsDatasFilterInput
  ) {
    onUpdateAbuildSheetsDatas(filter: $filter) {
      id
      abuildSheetsUrl
      captionTitle
      weeklyNumber
      userDatasID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAbuildSheetsDatas = /* GraphQL */ `
  subscription OnDeleteAbuildSheetsDatas(
    $filter: ModelSubscriptionAbuildSheetsDatasFilterInput
  ) {
    onDeleteAbuildSheetsDatas(filter: $filter) {
      id
      abuildSheetsUrl
      captionTitle
      weeklyNumber
      userDatasID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConectIsCoachingToTargetTrainee = /* GraphQL */ `
  subscription OnCreateConectIsCoachingToTargetTrainee(
    $filter: ModelSubscriptionConectIsCoachingToTargetTraineeFilterInput
  ) {
    onCreateConectIsCoachingToTargetTrainee(filter: $filter) {
      id
      coachdatasID
      traineedatasID
      weekoneStatus
      weekoneCurriculumData
      weekoneSurveyData
      weektwoStatus
      weektwoCurriculumData
      weektwoSurveyData
      weekthreeStatus
      weekthreeCurriculumData
      weekthreeSurveyData
      weekfourStatus
      weekfourCurriculumData
      weekfourSurveyData
      weekfiveStatus
      weekfiveCurriculumData
      weekfiveSurveyData
      weeksixStatus
      weeksixCurriculumData
      weeksixSurveyData
      weeksevenStatus
      weeksevenCurriculumData
      weeksevenSurveyData
      weekeightStatus
      weekeightCurriculumData
      weekeightSurveyData
      weeknineStatus
      weeknineCurriculumData
      weeknineSurveyData
      weektenStatus
      weektenCurriculumData
      weektenSurveyData
      weekelevenStatus
      weekelevenCurriculumData
      weekelevenSurveyData
      weektwelveStatus
      weektwelveCurriculumData
      weektwelveSurveyData
      othorStatus
      othorCurriculumData
      othorSurveyData
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConectIsCoachingToTargetTrainee = /* GraphQL */ `
  subscription OnUpdateConectIsCoachingToTargetTrainee(
    $filter: ModelSubscriptionConectIsCoachingToTargetTraineeFilterInput
  ) {
    onUpdateConectIsCoachingToTargetTrainee(filter: $filter) {
      id
      coachdatasID
      traineedatasID
      weekoneStatus
      weekoneCurriculumData
      weekoneSurveyData
      weektwoStatus
      weektwoCurriculumData
      weektwoSurveyData
      weekthreeStatus
      weekthreeCurriculumData
      weekthreeSurveyData
      weekfourStatus
      weekfourCurriculumData
      weekfourSurveyData
      weekfiveStatus
      weekfiveCurriculumData
      weekfiveSurveyData
      weeksixStatus
      weeksixCurriculumData
      weeksixSurveyData
      weeksevenStatus
      weeksevenCurriculumData
      weeksevenSurveyData
      weekeightStatus
      weekeightCurriculumData
      weekeightSurveyData
      weeknineStatus
      weeknineCurriculumData
      weeknineSurveyData
      weektenStatus
      weektenCurriculumData
      weektenSurveyData
      weekelevenStatus
      weekelevenCurriculumData
      weekelevenSurveyData
      weektwelveStatus
      weektwelveCurriculumData
      weektwelveSurveyData
      othorStatus
      othorCurriculumData
      othorSurveyData
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConectIsCoachingToTargetTrainee = /* GraphQL */ `
  subscription OnDeleteConectIsCoachingToTargetTrainee(
    $filter: ModelSubscriptionConectIsCoachingToTargetTraineeFilterInput
  ) {
    onDeleteConectIsCoachingToTargetTrainee(filter: $filter) {
      id
      coachdatasID
      traineedatasID
      weekoneStatus
      weekoneCurriculumData
      weekoneSurveyData
      weektwoStatus
      weektwoCurriculumData
      weektwoSurveyData
      weekthreeStatus
      weekthreeCurriculumData
      weekthreeSurveyData
      weekfourStatus
      weekfourCurriculumData
      weekfourSurveyData
      weekfiveStatus
      weekfiveCurriculumData
      weekfiveSurveyData
      weeksixStatus
      weeksixCurriculumData
      weeksixSurveyData
      weeksevenStatus
      weeksevenCurriculumData
      weeksevenSurveyData
      weekeightStatus
      weekeightCurriculumData
      weekeightSurveyData
      weeknineStatus
      weeknineCurriculumData
      weeknineSurveyData
      weektenStatus
      weektenCurriculumData
      weektenSurveyData
      weekelevenStatus
      weekelevenCurriculumData
      weekelevenSurveyData
      weektwelveStatus
      weektwelveCurriculumData
      weektwelveSurveyData
      othorStatus
      othorCurriculumData
      othorSurveyData
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCoachDatas = /* GraphQL */ `
  subscription OnCreateCoachDatas(
    $filter: ModelSubscriptionCoachDatasFilterInput
  ) {
    onCreateCoachDatas(filter: $filter) {
      id
      userdatasID
      corporationAffiliationID
      registrationDateTime
      registrationTimestamp
      deregistrationDateTime
      deregistrationTimestamp
      picturePublic
      namePublic
      interviewImplementation
      interviewPublic
      ConectIsCoachingToTargetTrainees {
        items {
          id
          coachdatasID
          traineedatasID
          weekoneStatus
          weekoneCurriculumData
          weekoneSurveyData
          weektwoStatus
          weektwoCurriculumData
          weektwoSurveyData
          weekthreeStatus
          weekthreeCurriculumData
          weekthreeSurveyData
          weekfourStatus
          weekfourCurriculumData
          weekfourSurveyData
          weekfiveStatus
          weekfiveCurriculumData
          weekfiveSurveyData
          weeksixStatus
          weeksixCurriculumData
          weeksixSurveyData
          weeksevenStatus
          weeksevenCurriculumData
          weeksevenSurveyData
          weekeightStatus
          weekeightCurriculumData
          weekeightSurveyData
          weeknineStatus
          weeknineCurriculumData
          weeknineSurveyData
          weektenStatus
          weektenCurriculumData
          weektenSurveyData
          weekelevenStatus
          weekelevenCurriculumData
          weekelevenSurveyData
          weektwelveStatus
          weektwelveCurriculumData
          weektwelveSurveyData
          othorStatus
          othorCurriculumData
          othorSurveyData
          createdAt
          updatedAt
        }
        nextToken
      }
      coachManageSheetUrl
      coachReportSheetUrl
      tag
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCoachDatas = /* GraphQL */ `
  subscription OnUpdateCoachDatas(
    $filter: ModelSubscriptionCoachDatasFilterInput
  ) {
    onUpdateCoachDatas(filter: $filter) {
      id
      userdatasID
      corporationAffiliationID
      registrationDateTime
      registrationTimestamp
      deregistrationDateTime
      deregistrationTimestamp
      picturePublic
      namePublic
      interviewImplementation
      interviewPublic
      ConectIsCoachingToTargetTrainees {
        items {
          id
          coachdatasID
          traineedatasID
          weekoneStatus
          weekoneCurriculumData
          weekoneSurveyData
          weektwoStatus
          weektwoCurriculumData
          weektwoSurveyData
          weekthreeStatus
          weekthreeCurriculumData
          weekthreeSurveyData
          weekfourStatus
          weekfourCurriculumData
          weekfourSurveyData
          weekfiveStatus
          weekfiveCurriculumData
          weekfiveSurveyData
          weeksixStatus
          weeksixCurriculumData
          weeksixSurveyData
          weeksevenStatus
          weeksevenCurriculumData
          weeksevenSurveyData
          weekeightStatus
          weekeightCurriculumData
          weekeightSurveyData
          weeknineStatus
          weeknineCurriculumData
          weeknineSurveyData
          weektenStatus
          weektenCurriculumData
          weektenSurveyData
          weekelevenStatus
          weekelevenCurriculumData
          weekelevenSurveyData
          weektwelveStatus
          weektwelveCurriculumData
          weektwelveSurveyData
          othorStatus
          othorCurriculumData
          othorSurveyData
          createdAt
          updatedAt
        }
        nextToken
      }
      coachManageSheetUrl
      coachReportSheetUrl
      tag
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCoachDatas = /* GraphQL */ `
  subscription OnDeleteCoachDatas(
    $filter: ModelSubscriptionCoachDatasFilterInput
  ) {
    onDeleteCoachDatas(filter: $filter) {
      id
      userdatasID
      corporationAffiliationID
      registrationDateTime
      registrationTimestamp
      deregistrationDateTime
      deregistrationTimestamp
      picturePublic
      namePublic
      interviewImplementation
      interviewPublic
      ConectIsCoachingToTargetTrainees {
        items {
          id
          coachdatasID
          traineedatasID
          weekoneStatus
          weekoneCurriculumData
          weekoneSurveyData
          weektwoStatus
          weektwoCurriculumData
          weektwoSurveyData
          weekthreeStatus
          weekthreeCurriculumData
          weekthreeSurveyData
          weekfourStatus
          weekfourCurriculumData
          weekfourSurveyData
          weekfiveStatus
          weekfiveCurriculumData
          weekfiveSurveyData
          weeksixStatus
          weeksixCurriculumData
          weeksixSurveyData
          weeksevenStatus
          weeksevenCurriculumData
          weeksevenSurveyData
          weekeightStatus
          weekeightCurriculumData
          weekeightSurveyData
          weeknineStatus
          weeknineCurriculumData
          weeknineSurveyData
          weektenStatus
          weektenCurriculumData
          weektenSurveyData
          weekelevenStatus
          weekelevenCurriculumData
          weekelevenSurveyData
          weektwelveStatus
          weektwelveCurriculumData
          weektwelveSurveyData
          othorStatus
          othorCurriculumData
          othorSurveyData
          createdAt
          updatedAt
        }
        nextToken
      }
      coachManageSheetUrl
      coachReportSheetUrl
      tag
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTraineeDatas = /* GraphQL */ `
  subscription OnCreateTraineeDatas(
    $filter: ModelSubscriptionTraineeDatasFilterInput
  ) {
    onCreateTraineeDatas(filter: $filter) {
      id
      userdatasID
      corporationOfEmploymentID
      traineeStatus
      graduationDateTime
      graduationTimestamp
      registrationDateTime
      registrationTimstamp
      picturePublic
      namePublic
      interviewImplementation
      intervewPublic
      ConectIsCoachingToTargetTrainees {
        items {
          id
          coachdatasID
          traineedatasID
          weekoneStatus
          weekoneCurriculumData
          weekoneSurveyData
          weektwoStatus
          weektwoCurriculumData
          weektwoSurveyData
          weekthreeStatus
          weekthreeCurriculumData
          weekthreeSurveyData
          weekfourStatus
          weekfourCurriculumData
          weekfourSurveyData
          weekfiveStatus
          weekfiveCurriculumData
          weekfiveSurveyData
          weeksixStatus
          weeksixCurriculumData
          weeksixSurveyData
          weeksevenStatus
          weeksevenCurriculumData
          weeksevenSurveyData
          weekeightStatus
          weekeightCurriculumData
          weekeightSurveyData
          weeknineStatus
          weeknineCurriculumData
          weeknineSurveyData
          weektenStatus
          weektenCurriculumData
          weektenSurveyData
          weekelevenStatus
          weekelevenCurriculumData
          weekelevenSurveyData
          weektwelveStatus
          weektwelveCurriculumData
          weektwelveSurveyData
          othorStatus
          othorCurriculumData
          othorSurveyData
          createdAt
          updatedAt
        }
        nextToken
      }
      curriculumStartDate
      traineeId
      tag
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTraineeDatas = /* GraphQL */ `
  subscription OnUpdateTraineeDatas(
    $filter: ModelSubscriptionTraineeDatasFilterInput
  ) {
    onUpdateTraineeDatas(filter: $filter) {
      id
      userdatasID
      corporationOfEmploymentID
      traineeStatus
      graduationDateTime
      graduationTimestamp
      registrationDateTime
      registrationTimstamp
      picturePublic
      namePublic
      interviewImplementation
      intervewPublic
      ConectIsCoachingToTargetTrainees {
        items {
          id
          coachdatasID
          traineedatasID
          weekoneStatus
          weekoneCurriculumData
          weekoneSurveyData
          weektwoStatus
          weektwoCurriculumData
          weektwoSurveyData
          weekthreeStatus
          weekthreeCurriculumData
          weekthreeSurveyData
          weekfourStatus
          weekfourCurriculumData
          weekfourSurveyData
          weekfiveStatus
          weekfiveCurriculumData
          weekfiveSurveyData
          weeksixStatus
          weeksixCurriculumData
          weeksixSurveyData
          weeksevenStatus
          weeksevenCurriculumData
          weeksevenSurveyData
          weekeightStatus
          weekeightCurriculumData
          weekeightSurveyData
          weeknineStatus
          weeknineCurriculumData
          weeknineSurveyData
          weektenStatus
          weektenCurriculumData
          weektenSurveyData
          weekelevenStatus
          weekelevenCurriculumData
          weekelevenSurveyData
          weektwelveStatus
          weektwelveCurriculumData
          weektwelveSurveyData
          othorStatus
          othorCurriculumData
          othorSurveyData
          createdAt
          updatedAt
        }
        nextToken
      }
      curriculumStartDate
      traineeId
      tag
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTraineeDatas = /* GraphQL */ `
  subscription OnDeleteTraineeDatas(
    $filter: ModelSubscriptionTraineeDatasFilterInput
  ) {
    onDeleteTraineeDatas(filter: $filter) {
      id
      userdatasID
      corporationOfEmploymentID
      traineeStatus
      graduationDateTime
      graduationTimestamp
      registrationDateTime
      registrationTimstamp
      picturePublic
      namePublic
      interviewImplementation
      intervewPublic
      ConectIsCoachingToTargetTrainees {
        items {
          id
          coachdatasID
          traineedatasID
          weekoneStatus
          weekoneCurriculumData
          weekoneSurveyData
          weektwoStatus
          weektwoCurriculumData
          weektwoSurveyData
          weekthreeStatus
          weekthreeCurriculumData
          weekthreeSurveyData
          weekfourStatus
          weekfourCurriculumData
          weekfourSurveyData
          weekfiveStatus
          weekfiveCurriculumData
          weekfiveSurveyData
          weeksixStatus
          weeksixCurriculumData
          weeksixSurveyData
          weeksevenStatus
          weeksevenCurriculumData
          weeksevenSurveyData
          weekeightStatus
          weekeightCurriculumData
          weekeightSurveyData
          weeknineStatus
          weeknineCurriculumData
          weeknineSurveyData
          weektenStatus
          weektenCurriculumData
          weektenSurveyData
          weekelevenStatus
          weekelevenCurriculumData
          weekelevenSurveyData
          weektwelveStatus
          weektwelveCurriculumData
          weektwelveSurveyData
          othorStatus
          othorCurriculumData
          othorSurveyData
          createdAt
          updatedAt
        }
        nextToken
      }
      curriculumStartDate
      traineeId
      tag
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatDatas = /* GraphQL */ `
  subscription OnCreateChatDatas(
    $filter: ModelSubscriptionChatDatasFilterInput
  ) {
    onCreateChatDatas(filter: $filter) {
      id
      chatMessage
      chatDateTime
      chatTimestamp
      checkOn
      chatroomID
      SendFromID
      chatOnImage
      chatDataStatus
      traineeCheckStatus
      coachCheckStatus
      csCheckStatus
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatDatas = /* GraphQL */ `
  subscription OnUpdateChatDatas(
    $filter: ModelSubscriptionChatDatasFilterInput
  ) {
    onUpdateChatDatas(filter: $filter) {
      id
      chatMessage
      chatDateTime
      chatTimestamp
      checkOn
      chatroomID
      SendFromID
      chatOnImage
      chatDataStatus
      traineeCheckStatus
      coachCheckStatus
      csCheckStatus
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatDatas = /* GraphQL */ `
  subscription OnDeleteChatDatas(
    $filter: ModelSubscriptionChatDatasFilterInput
  ) {
    onDeleteChatDatas(filter: $filter) {
      id
      chatMessage
      chatDateTime
      chatTimestamp
      checkOn
      chatroomID
      SendFromID
      chatOnImage
      chatDataStatus
      traineeCheckStatus
      coachCheckStatus
      csCheckStatus
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConectIsChatroomMember = /* GraphQL */ `
  subscription OnCreateConectIsChatroomMember(
    $filter: ModelSubscriptionConectIsChatroomMemberFilterInput
  ) {
    onCreateConectIsChatroomMember(filter: $filter) {
      id
      chatroomID
      chatroomMemberUserDatasID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConectIsChatroomMember = /* GraphQL */ `
  subscription OnUpdateConectIsChatroomMember(
    $filter: ModelSubscriptionConectIsChatroomMemberFilterInput
  ) {
    onUpdateConectIsChatroomMember(filter: $filter) {
      id
      chatroomID
      chatroomMemberUserDatasID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConectIsChatroomMember = /* GraphQL */ `
  subscription OnDeleteConectIsChatroomMember(
    $filter: ModelSubscriptionConectIsChatroomMemberFilterInput
  ) {
    onDeleteConectIsChatroomMember(filter: $filter) {
      id
      chatroomID
      chatroomMemberUserDatasID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatrooms = /* GraphQL */ `
  subscription OnCreateChatrooms(
    $filter: ModelSubscriptionChatroomsFilterInput
  ) {
    onCreateChatrooms(filter: $filter) {
      id
      chatroomUrl
      roomMasterUserDatasID
      ConectIsChatroomMembers {
        items {
          id
          chatroomID
          chatroomMemberUserDatasID
          createdAt
          updatedAt
        }
        nextToken
      }
      ChatDatas {
        items {
          id
          chatMessage
          chatDateTime
          chatTimestamp
          checkOn
          chatroomID
          SendFromID
          chatOnImage
          chatDataStatus
          traineeCheckStatus
          coachCheckStatus
          csCheckStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      CustomerChatrooms {
        items {
          id
          tag
          roomType
          CustomerChatdatas {
            nextToken
          }
          userdatasID
          chatroomsID
          userdatasType
          roomMasterName
          createdAt
          updatedAt
        }
        nextToken
      }
      lastMessage
      unReadFlg
      roomMasterName
      sendFromTimestamp
      sendToTimestamp
      sendFromDateTime
      sendToDateTime
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatrooms = /* GraphQL */ `
  subscription OnUpdateChatrooms(
    $filter: ModelSubscriptionChatroomsFilterInput
  ) {
    onUpdateChatrooms(filter: $filter) {
      id
      chatroomUrl
      roomMasterUserDatasID
      ConectIsChatroomMembers {
        items {
          id
          chatroomID
          chatroomMemberUserDatasID
          createdAt
          updatedAt
        }
        nextToken
      }
      ChatDatas {
        items {
          id
          chatMessage
          chatDateTime
          chatTimestamp
          checkOn
          chatroomID
          SendFromID
          chatOnImage
          chatDataStatus
          traineeCheckStatus
          coachCheckStatus
          csCheckStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      CustomerChatrooms {
        items {
          id
          tag
          roomType
          CustomerChatdatas {
            nextToken
          }
          userdatasID
          chatroomsID
          userdatasType
          roomMasterName
          createdAt
          updatedAt
        }
        nextToken
      }
      lastMessage
      unReadFlg
      roomMasterName
      sendFromTimestamp
      sendToTimestamp
      sendFromDateTime
      sendToDateTime
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatrooms = /* GraphQL */ `
  subscription OnDeleteChatrooms(
    $filter: ModelSubscriptionChatroomsFilterInput
  ) {
    onDeleteChatrooms(filter: $filter) {
      id
      chatroomUrl
      roomMasterUserDatasID
      ConectIsChatroomMembers {
        items {
          id
          chatroomID
          chatroomMemberUserDatasID
          createdAt
          updatedAt
        }
        nextToken
      }
      ChatDatas {
        items {
          id
          chatMessage
          chatDateTime
          chatTimestamp
          checkOn
          chatroomID
          SendFromID
          chatOnImage
          chatDataStatus
          traineeCheckStatus
          coachCheckStatus
          csCheckStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      CustomerChatrooms {
        items {
          id
          tag
          roomType
          CustomerChatdatas {
            nextToken
          }
          userdatasID
          chatroomsID
          userdatasType
          roomMasterName
          createdAt
          updatedAt
        }
        nextToken
      }
      lastMessage
      unReadFlg
      roomMasterName
      sendFromTimestamp
      sendToTimestamp
      sendFromDateTime
      sendToDateTime
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConectIsReadUnreadToNotifications = /* GraphQL */ `
  subscription OnCreateConectIsReadUnreadToNotifications(
    $filter: ModelSubscriptionConectIsReadUnreadToNotificationsFilterInput
  ) {
    onCreateConectIsReadUnreadToNotifications(filter: $filter) {
      id
      userdatasID
      notificationsID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConectIsReadUnreadToNotifications = /* GraphQL */ `
  subscription OnUpdateConectIsReadUnreadToNotifications(
    $filter: ModelSubscriptionConectIsReadUnreadToNotificationsFilterInput
  ) {
    onUpdateConectIsReadUnreadToNotifications(filter: $filter) {
      id
      userdatasID
      notificationsID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConectIsReadUnreadToNotifications = /* GraphQL */ `
  subscription OnDeleteConectIsReadUnreadToNotifications(
    $filter: ModelSubscriptionConectIsReadUnreadToNotificationsFilterInput
  ) {
    onDeleteConectIsReadUnreadToNotifications(filter: $filter) {
      id
      userdatasID
      notificationsID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotifications = /* GraphQL */ `
  subscription OnCreateNotifications(
    $filter: ModelSubscriptionNotificationsFilterInput
  ) {
    onCreateNotifications(filter: $filter) {
      id
      notificationTitle
      notificationDateTime
      notificationTimestamp
      body
      ReadUnreadToNotifications {
        items {
          id
          userdatasID
          notificationsID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotifications = /* GraphQL */ `
  subscription OnUpdateNotifications(
    $filter: ModelSubscriptionNotificationsFilterInput
  ) {
    onUpdateNotifications(filter: $filter) {
      id
      notificationTitle
      notificationDateTime
      notificationTimestamp
      body
      ReadUnreadToNotifications {
        items {
          id
          userdatasID
          notificationsID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotifications = /* GraphQL */ `
  subscription OnDeleteNotifications(
    $filter: ModelSubscriptionNotificationsFilterInput
  ) {
    onDeleteNotifications(filter: $filter) {
      id
      notificationTitle
      notificationDateTime
      notificationTimestamp
      body
      ReadUnreadToNotifications {
        items {
          id
          userdatasID
          notificationsID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTaskDatas = /* GraphQL */ `
  subscription OnCreateTaskDatas(
    $filter: ModelSubscriptionTaskDatasFilterInput
  ) {
    onCreateTaskDatas(filter: $filter) {
      id
      planTaskCreated
      planTaskStartTime
      planTaskEndTime
      planTaskPriority
      planTaskDetial
      resultTaskStartTime
      resultTaskEndTime
      resultTaskPriority
      resultTaskDetial
      tasktimeManagementTimestamp
      taskProgress
      parentTasktimeManagementDatasID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTaskDatas = /* GraphQL */ `
  subscription OnUpdateTaskDatas(
    $filter: ModelSubscriptionTaskDatasFilterInput
  ) {
    onUpdateTaskDatas(filter: $filter) {
      id
      planTaskCreated
      planTaskStartTime
      planTaskEndTime
      planTaskPriority
      planTaskDetial
      resultTaskStartTime
      resultTaskEndTime
      resultTaskPriority
      resultTaskDetial
      tasktimeManagementTimestamp
      taskProgress
      parentTasktimeManagementDatasID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTaskDatas = /* GraphQL */ `
  subscription OnDeleteTaskDatas(
    $filter: ModelSubscriptionTaskDatasFilterInput
  ) {
    onDeleteTaskDatas(filter: $filter) {
      id
      planTaskCreated
      planTaskStartTime
      planTaskEndTime
      planTaskPriority
      planTaskDetial
      resultTaskStartTime
      resultTaskEndTime
      resultTaskPriority
      resultTaskDetial
      tasktimeManagementTimestamp
      taskProgress
      parentTasktimeManagementDatasID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTasktimeManagementDatas = /* GraphQL */ `
  subscription OnCreateTasktimeManagementDatas(
    $filter: ModelSubscriptionTasktimeManagementDatasFilterInput
  ) {
    onCreateTasktimeManagementDatas(filter: $filter) {
      id
      checkIn
      checkOut
      settingDate
      settingTimestamp
      tasktimeManagementOutput
      achievementScore
      userDatasID
      TaskDatas {
        items {
          id
          planTaskCreated
          planTaskStartTime
          planTaskEndTime
          planTaskPriority
          planTaskDetial
          resultTaskStartTime
          resultTaskEndTime
          resultTaskPriority
          resultTaskDetial
          tasktimeManagementTimestamp
          taskProgress
          parentTasktimeManagementDatasID
          createdAt
          updatedAt
        }
        nextToken
      }
      ttmActionToImprove
      ttmEffortTargets
      ttmIssuesToImprove
      ttmReflectionOnDay
      ttmStatus
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTasktimeManagementDatas = /* GraphQL */ `
  subscription OnUpdateTasktimeManagementDatas(
    $filter: ModelSubscriptionTasktimeManagementDatasFilterInput
  ) {
    onUpdateTasktimeManagementDatas(filter: $filter) {
      id
      checkIn
      checkOut
      settingDate
      settingTimestamp
      tasktimeManagementOutput
      achievementScore
      userDatasID
      TaskDatas {
        items {
          id
          planTaskCreated
          planTaskStartTime
          planTaskEndTime
          planTaskPriority
          planTaskDetial
          resultTaskStartTime
          resultTaskEndTime
          resultTaskPriority
          resultTaskDetial
          tasktimeManagementTimestamp
          taskProgress
          parentTasktimeManagementDatasID
          createdAt
          updatedAt
        }
        nextToken
      }
      ttmActionToImprove
      ttmEffortTargets
      ttmIssuesToImprove
      ttmReflectionOnDay
      ttmStatus
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTasktimeManagementDatas = /* GraphQL */ `
  subscription OnDeleteTasktimeManagementDatas(
    $filter: ModelSubscriptionTasktimeManagementDatasFilterInput
  ) {
    onDeleteTasktimeManagementDatas(filter: $filter) {
      id
      checkIn
      checkOut
      settingDate
      settingTimestamp
      tasktimeManagementOutput
      achievementScore
      userDatasID
      TaskDatas {
        items {
          id
          planTaskCreated
          planTaskStartTime
          planTaskEndTime
          planTaskPriority
          planTaskDetial
          resultTaskStartTime
          resultTaskEndTime
          resultTaskPriority
          resultTaskDetial
          tasktimeManagementTimestamp
          taskProgress
          parentTasktimeManagementDatasID
          createdAt
          updatedAt
        }
        nextToken
      }
      ttmActionToImprove
      ttmEffortTargets
      ttmIssuesToImprove
      ttmReflectionOnDay
      ttmStatus
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDetialAnswerHistories = /* GraphQL */ `
  subscription OnCreateDetialAnswerHistories(
    $filter: ModelSubscriptionDetialAnswerHistoriesFilterInput
  ) {
    onCreateDetialAnswerHistories(filter: $filter) {
      id
      answer
      comment
      answerDateTime
      commentDateTime
      answerTimestamp
      commentTimestamp
      parentEventDetialDatasID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDetialAnswerHistories = /* GraphQL */ `
  subscription OnUpdateDetialAnswerHistories(
    $filter: ModelSubscriptionDetialAnswerHistoriesFilterInput
  ) {
    onUpdateDetialAnswerHistories(filter: $filter) {
      id
      answer
      comment
      answerDateTime
      commentDateTime
      answerTimestamp
      commentTimestamp
      parentEventDetialDatasID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDetialAnswerHistories = /* GraphQL */ `
  subscription OnDeleteDetialAnswerHistories(
    $filter: ModelSubscriptionDetialAnswerHistoriesFilterInput
  ) {
    onDeleteDetialAnswerHistories(filter: $filter) {
      id
      answer
      comment
      answerDateTime
      commentDateTime
      answerTimestamp
      commentTimestamp
      parentEventDetialDatasID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEventDetialDatas = /* GraphQL */ `
  subscription OnCreateEventDetialDatas(
    $filter: ModelSubscriptionEventDetialDatasFilterInput
  ) {
    onCreateEventDetialDatas(filter: $filter) {
      id
      parentScheduleEventsID
      caption
      answer
      DetialAnswerHistories {
        items {
          id
          answer
          comment
          answerDateTime
          commentDateTime
          answerTimestamp
          commentTimestamp
          parentEventDetialDatasID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEventDetialDatas = /* GraphQL */ `
  subscription OnUpdateEventDetialDatas(
    $filter: ModelSubscriptionEventDetialDatasFilterInput
  ) {
    onUpdateEventDetialDatas(filter: $filter) {
      id
      parentScheduleEventsID
      caption
      answer
      DetialAnswerHistories {
        items {
          id
          answer
          comment
          answerDateTime
          commentDateTime
          answerTimestamp
          commentTimestamp
          parentEventDetialDatasID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEventDetialDatas = /* GraphQL */ `
  subscription OnDeleteEventDetialDatas(
    $filter: ModelSubscriptionEventDetialDatasFilterInput
  ) {
    onDeleteEventDetialDatas(filter: $filter) {
      id
      parentScheduleEventsID
      caption
      answer
      DetialAnswerHistories {
        items {
          id
          answer
          comment
          answerDateTime
          commentDateTime
          answerTimestamp
          commentTimestamp
          parentEventDetialDatasID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateScheduleEvents = /* GraphQL */ `
  subscription OnCreateScheduleEvents(
    $filter: ModelSubscriptionScheduleEventsFilterInput
  ) {
    onCreateScheduleEvents(filter: $filter) {
      id
      eventName
      eventType
      status
      deadlineDateTime
      deadlineTimestamp
      formType
      eventColorcode
      EventDetialDatas {
        items {
          id
          parentScheduleEventsID
          caption
          answer
          DetialAnswerHistories {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      userDatasID
      corporationsID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateScheduleEvents = /* GraphQL */ `
  subscription OnUpdateScheduleEvents(
    $filter: ModelSubscriptionScheduleEventsFilterInput
  ) {
    onUpdateScheduleEvents(filter: $filter) {
      id
      eventName
      eventType
      status
      deadlineDateTime
      deadlineTimestamp
      formType
      eventColorcode
      EventDetialDatas {
        items {
          id
          parentScheduleEventsID
          caption
          answer
          DetialAnswerHistories {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      userDatasID
      corporationsID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteScheduleEvents = /* GraphQL */ `
  subscription OnDeleteScheduleEvents(
    $filter: ModelSubscriptionScheduleEventsFilterInput
  ) {
    onDeleteScheduleEvents(filter: $filter) {
      id
      eventName
      eventType
      status
      deadlineDateTime
      deadlineTimestamp
      formType
      eventColorcode
      EventDetialDatas {
        items {
          id
          parentScheduleEventsID
          caption
          answer
          DetialAnswerHistories {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      userDatasID
      corporationsID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCorporations = /* GraphQL */ `
  subscription OnCreateCorporations(
    $filter: ModelSubscriptionCorporationsFilterInput
  ) {
    onCreateCorporations(filter: $filter) {
      id
      corporationName
      TraineeDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      CoachDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ScheduleEvents {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      OfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      RafusalOfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCorporations = /* GraphQL */ `
  subscription OnUpdateCorporations(
    $filter: ModelSubscriptionCorporationsFilterInput
  ) {
    onUpdateCorporations(filter: $filter) {
      id
      corporationName
      TraineeDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      CoachDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ScheduleEvents {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      OfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      RafusalOfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCorporations = /* GraphQL */ `
  subscription OnDeleteCorporations(
    $filter: ModelSubscriptionCorporationsFilterInput
  ) {
    onDeleteCorporations(filter: $filter) {
      id
      corporationName
      TraineeDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      CoachDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ScheduleEvents {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      OfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      RafusalOfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRoleDatas = /* GraphQL */ `
  subscription OnCreateRoleDatas(
    $filter: ModelSubscriptionRoleDatasFilterInput
  ) {
    onCreateRoleDatas(filter: $filter) {
      id
      roleNumber
      roleName
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRoleDatas = /* GraphQL */ `
  subscription OnUpdateRoleDatas(
    $filter: ModelSubscriptionRoleDatasFilterInput
  ) {
    onUpdateRoleDatas(filter: $filter) {
      id
      roleNumber
      roleName
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRoleDatas = /* GraphQL */ `
  subscription OnDeleteRoleDatas(
    $filter: ModelSubscriptionRoleDatasFilterInput
  ) {
    onDeleteRoleDatas(filter: $filter) {
      id
      roleNumber
      roleName
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserDatas = /* GraphQL */ `
  subscription OnCreateUserDatas(
    $filter: ModelSubscriptionUserDatasFilterInput
  ) {
    onCreateUserDatas(filter: $filter) {
      id
      userId
      iconImage
      userRole
      TasktimeManagementDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ScheduleEvents {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ReadUnreadToNotifications {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ChatroomMaster {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ChatroomMember {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      TraineeDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      CoachDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      userName
      firstName
      middleName
      lastName
      firstNameKana
      middleNameKana
      lastNameKana
      universityName
      departmentName
      courseName
      AbuildSheetsDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      WeeklySurveyDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ELearningMovieDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ChatDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      OfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      RafusalOfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      graduationYear
      graduateSchoolName
      graduateSchoolDepartmentName
      graduateSchoolCourseName
      graduateSchoolGraduationYear
      highSchoolName
      displayName
      userRemarks
      CustomerChatdatas {
        items {
          id
          chatMessage
          chatDateTime
          chatDateTimestamp
          checkOn
          customerchatroomsID
          sendUserdatasID
          chatOnImage
          createdAt
          updatedAt
        }
        nextToken
      }
      CustomerChatrooms {
        items {
          id
          tag
          roomType
          CustomerChatdatas {
            nextToken
          }
          userdatasID
          chatroomsID
          userdatasType
          roomMasterName
          createdAt
          updatedAt
        }
        nextToken
      }
      CSMemberDatas {
        items {
          id
          userdatasID
          userName
          csChatroomType
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserDatas = /* GraphQL */ `
  subscription OnUpdateUserDatas(
    $filter: ModelSubscriptionUserDatasFilterInput
  ) {
    onUpdateUserDatas(filter: $filter) {
      id
      userId
      iconImage
      userRole
      TasktimeManagementDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ScheduleEvents {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ReadUnreadToNotifications {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ChatroomMaster {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ChatroomMember {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      TraineeDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      CoachDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      userName
      firstName
      middleName
      lastName
      firstNameKana
      middleNameKana
      lastNameKana
      universityName
      departmentName
      courseName
      AbuildSheetsDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      WeeklySurveyDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ELearningMovieDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ChatDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      OfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      RafusalOfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      graduationYear
      graduateSchoolName
      graduateSchoolDepartmentName
      graduateSchoolCourseName
      graduateSchoolGraduationYear
      highSchoolName
      displayName
      userRemarks
      CustomerChatdatas {
        items {
          id
          chatMessage
          chatDateTime
          chatDateTimestamp
          checkOn
          customerchatroomsID
          sendUserdatasID
          chatOnImage
          createdAt
          updatedAt
        }
        nextToken
      }
      CustomerChatrooms {
        items {
          id
          tag
          roomType
          CustomerChatdatas {
            nextToken
          }
          userdatasID
          chatroomsID
          userdatasType
          roomMasterName
          createdAt
          updatedAt
        }
        nextToken
      }
      CSMemberDatas {
        items {
          id
          userdatasID
          userName
          csChatroomType
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserDatas = /* GraphQL */ `
  subscription OnDeleteUserDatas(
    $filter: ModelSubscriptionUserDatasFilterInput
  ) {
    onDeleteUserDatas(filter: $filter) {
      id
      userId
      iconImage
      userRole
      TasktimeManagementDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ScheduleEvents {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ReadUnreadToNotifications {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ChatroomMaster {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ChatroomMember {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      TraineeDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      CoachDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      userName
      firstName
      middleName
      lastName
      firstNameKana
      middleNameKana
      lastNameKana
      universityName
      departmentName
      courseName
      AbuildSheetsDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      WeeklySurveyDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ELearningMovieDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ChatDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      OfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      RafusalOfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      graduationYear
      graduateSchoolName
      graduateSchoolDepartmentName
      graduateSchoolCourseName
      graduateSchoolGraduationYear
      highSchoolName
      displayName
      userRemarks
      CustomerChatdatas {
        items {
          id
          chatMessage
          chatDateTime
          chatDateTimestamp
          checkOn
          customerchatroomsID
          sendUserdatasID
          chatOnImage
          createdAt
          updatedAt
        }
        nextToken
      }
      CustomerChatrooms {
        items {
          id
          tag
          roomType
          CustomerChatdatas {
            nextToken
          }
          userdatasID
          chatroomsID
          userdatasType
          roomMasterName
          createdAt
          updatedAt
        }
        nextToken
      }
      CSMemberDatas {
        items {
          id
          userdatasID
          userName
          csChatroomType
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTraineeRules = /* GraphQL */ `
  subscription OnCreateTraineeRules(
    $filter: ModelSubscriptionTraineeRulesFilterInput
  ) {
    onCreateTraineeRules(filter: $filter) {
      id
      number
      caption
      body
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTraineeRules = /* GraphQL */ `
  subscription OnUpdateTraineeRules(
    $filter: ModelSubscriptionTraineeRulesFilterInput
  ) {
    onUpdateTraineeRules(filter: $filter) {
      id
      number
      caption
      body
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTraineeRules = /* GraphQL */ `
  subscription OnDeleteTraineeRules(
    $filter: ModelSubscriptionTraineeRulesFilterInput
  ) {
    onDeleteTraineeRules(filter: $filter) {
      id
      number
      caption
      body
      createdAt
      updatedAt
    }
  }
`;
