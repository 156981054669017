// AWS
import { API } from "aws-amplify";

// React
import { 
    useEffect, 
    useState,
    useReducer,
    Fragment, 
} from 'react';

// Material UI
import { Box, makeStyles, Typography } from '@material-ui/core';

// GraphQL
import { 
    listChatrooms, 
    listCustomerChatrooms,
} from "../../graphql/queries";

// Self Making Files
import { 
    AMAZON_COGNITO_USER_POOLS, 
    CustomerChatroomTypes, 
    CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_OPERATION, 
    CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_SUPPORT, 
    ERROR_TYPE_ABORTERROR, 
    INITIAL_QUERY, 
    SUBSCRIPTION, 
    SUBSCRIPTION_CHAT, 
    SUBSCRIPTION_UPDATE,
    USER_TYPE, 
} from '../../Commons/Consts';
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { 
    getCurrentUserData, 
    consoleLog, 
    nextTokenAddtionalGrapheql, 
    nextTokenInitialGrapheql, 
    recursiveGrapheql 
} from '../../Commons/CommonFunctions';
import { 
    userReducer, 
    chatroomsReducer,
    chatDatasReducer,
    commonDBDataReducer,
} from "../../Commons/Reducers";
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import SideMenu from "../../Commons/SideMenu";
import ChatMessageSender from "../../components/ChatRoom/ChatMessageSender";
import { onCreateChatDatas, onUpdateChatDatas, onUpdateCustomerChatrooms } from "../../graphql/subscriptions";
import { useParams } from "react-router";
import { CustomerChatRoomList } from "../../components/CustomerSuccess/CustomerChatRoomList";
import { CustomerChatDataList } from "../../components/CustomerSuccess/CustomerChatDataList";
import { ChatroomDetailViewer } from "../../components/CustomerSuccess/ChatroomDetailViewer";
import { ChatroomSearch } from "./SupportChatrooms";
import CSSideMenu from "../../Commons/CSSideMenu";

const localStyles = makeStyles(theme => ({
    detailArea:{
        width: "100%",
        height: "8%",
        position: "absolute",
        top: "0%",
        borderBottom: "solid #666666 2px",
    },
    chatArea:{
        width: "100%",
        height: "76%",
        position: "absolute",
        top: "8%",
        overflow: "auto",
        padding: "10px",
        paddingLeft: "50px",
    },
    messageArea:{
        width: "100%",
        height: "16%",
        position: "absolute",
        top: "84%",
        zIndex: "drawer",
        backgroundColor: theme.palette.background.paper,
    },
    centerOfCenterContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
    },
    searchBoxArea:{
        height: "8%",
        display: "fixed",
    },
    scrollArea:{
        height: "92%",
        overflowY: "auto",
        overflowX: "hidden",
    },    
}));

export default function CoachOperationChatrooms(){
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    const param = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [sendToUserData, setSendToUserData] = useState([]);
    const [sendFromUserData, setSendFromUserData] = useState([]);
    const [userData, dispachUser] = useReducer(userReducer, []);
    const [chatrooms, dispatchChatrooms] = useReducer(commonDBDataReducer, []);
    const [customerChatrooms, dispatchCustomerChatrooms] = useReducer(commonDBDataReducer, []);
    const [chatData, dispatchChatDatas] = useReducer(commonDBDataReducer, []);
    const [selectId, setSelectId] = useState();
    const [searchText, setSearchText] = useState('');
    const [nextToken, setNextToken] = useState('');
    
    const nextGetChatrooms = async () => {
        if(nextToken !== null){
            await nextTokenAddtionalGrapheql(listChatrooms, 'listChatrooms', { limit:1000 , nextToken: nextToken}, dispatchChatrooms, setNextToken);
        }
        else{
            return;
        }
    }
    
    const initCoachOperationChatrooms = async () =>{
        try{
            // ページ閲覧ユーザーデータの取得とState変数への保持
            const currentUserData = await getCurrentUserData();
            dispachUser({ type: INITIAL_QUERY, user:currentUserData},);
            
            const filter = {roomType: {eq: CustomerChatroomTypes.OPERATIONS },
                            userdatasType: {eq: USER_TYPE.COACH }
            };
            await recursiveGrapheql(listCustomerChatrooms, 'listCustomerChatrooms', { filter: filter , limit:100000 , nextToken: null}, dispatchCustomerChatrooms);
            
            if(searchText !== ''){
                const checkfilter = {
                    roomMasterName: {contains: searchText },
                };
                await recursiveGrapheql(listChatrooms, 'listChatrooms', { filter: checkfilter , limit:100000 , nextToken: null}, dispatchChatrooms);
            }
            else{
                await recursiveGrapheql(listChatrooms, 'listChatrooms', { limit:100000 , nextToken: null}, dispatchChatrooms);
            }
            
            setIsLoading(false);
        } catch (e) {
            if (e.name === ERROR_TYPE_ABORTERROR) {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    
    
    }

    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();

        initCoachOperationChatrooms();

        const createSubsc = API.graphql({
            query:onCreateChatDatas,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
        next: async (msg) => {
            if(msg.value.data.onCreateChatDatas.chatroomID === param.chatroomId){
                dispatchChatDatas({ type: SUBSCRIPTION, data:msg.value.data.onCreateChatDatas });
            }else{
                // 暫定的な対応が可能な処理（メッセージが一つもない場合に飲み、他者のメッセージを受信する可能性あり）
                dispatchChatDatas({ type: SUBSCRIPTION_CHAT, data:msg.value.data.onCreateChatDatas });
            }
        }});

        const updateSubsc = API.graphql({
            query:onUpdateChatDatas,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
        next: async (msg) => {
            // 暫定的な対応が可能な処理（メッセージが一つもない場合に飲み、他者のメッセージを受信する可能性あり）
            dispatchChatDatas({ type: SUBSCRIPTION_UPDATE, data:msg.value.data.onUpdateChatDatas });
        }});

        const updateCusCRSubsc = API.graphql({
            query:onUpdateCustomerChatrooms,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
        next: async (msg) => {
            dispatchCustomerChatrooms({ type: SUBSCRIPTION_UPDATE, data:msg.value.data.onUpdateCustomerChatrooms });
        }});

        // アンマウント時処理
        return () => {
            abortCtrl.abort();
            createSubsc.unsubscribe();
            updateCusCRSubsc.unsubscribe();
            updateSubsc.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    return(
        <Box>
            <Header title={CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_OPERATION}/>
            <Box>
                <Box className={commonStyle.mobileModeDisplay}>
                    {isLoading?
                    <LoadingIcon/>
                    :
                    <Box className={commonStyle.pageContent} >
                        <CustomerChatRoomList
                            chatrooms={chatrooms}
                            customerChatrooms={customerChatrooms}
                            user={userData}
                        />
                        <Box className={commonStyle.endBox}/>
                    </Box>}
                </Box>
                <Box className={commonStyle.pcModeDisplay}>
                    <CSSideMenu/>
                    {isLoading?
                    <LoadingIcon/>
                    :
                    <Box>
                        <Box className={commonStyle.secondSideMenu}>
                            <Box className={localStyle.searchBoxArea}>
                                <ChatroomSearch 
                                    searchText={searchText} 
                                    setSearchText={setSearchText}
                                />
                            </Box>
                            <Box className={localStyle.scrollArea}>
                                <CustomerChatRoomList
                                    chatrooms={chatrooms}
                                    chatData={chatData}
                                    user={userData}
                                    customerChatrooms={customerChatrooms}
                                    setSendToUserData={setSendToUserData}
                                    setSendFromUserData={setSendFromUserData}
                                    dispatchChatDatas={dispatchChatDatas}
                                    setSelectId={setSelectId}
                                    selectId={selectId}
                                    csType={CustomerChatroomTypes.OPERATIONS}
                                    userType={USER_TYPE.COACH}
                                />
                                {nextToken !== null ?
                                <Box className={commonStyle.linkButton} onClick={nextGetChatrooms}>
                                    <Typography>更に読み込む</Typography>
                                </Box>
                                :
                                <Fragment></Fragment>
                                }
                            </Box>
                        </Box>
                        {param.chatroomId !== undefined && chatData !== undefined ?
                            chatData.length !== 0 ?
                                isLoading?
                                <LoadingIcon/>
                                :
                                <Box className={commonStyle.secondSideBarOnPageContent}>
                                    <Box className={localStyle.detailArea}>
                                        <ChatroomDetailViewer 
                                            customerChatrooms={customerChatrooms} 
                                            sendToUserData={sendToUserData}
                                            selectId={selectId}
                                        />
                                    </Box>
                                    <Box className={localStyle.chatArea}>
                                        <CustomerChatDataList
                                            chatData={chatData} 
                                            sendFromUserData={userData} 
                                            sendToUserData={sendToUserData}
                                        />
                                    </Box>
                                    <Box className={localStyle.messageArea}>
                                        <ChatMessageSender
                                            chatroomId={param.chatroomId}
                                            sendFromUserData={userData}
                                        />
                                    </Box>
                                </Box>
                            :
                            <Box className={commonStyle.secondSideBarOnPageContent}>
                                <Box className={localStyle.detailArea}>
                                    <ChatroomDetailViewer 
                                        customerChatrooms={customerChatrooms} 
                                        sendToUserData={sendToUserData}
                                        selectId={selectId}
                                    />
                                </Box>
                                <Box className={localStyle.chatArea}>
                                    <Typography variant="h5" className={localStyle.centerOfCenterContents}>メッセージがありません。</Typography>
                                </Box>
                                <Box className={localStyle.messageArea}>
                                    <ChatMessageSender
                                        chatroomId={param.chatroomId}
                                        sendFromUserData={userData}
                                    />
                                </Box>
                            </Box>
                        :
                        <Box className={commonStyle.secondSideBarOnPageContent}>
                            <Box className={localStyle.centerOfCenterContents}>
                                <Typography variant="h4">チャットルームを選択してください。</Typography>
                            </Box>
                        </Box>
                        }
                    </Box>}
                </Box>
            </Box>
            <Footer/>
        </Box>
    );
}
