import { Fragment, useEffect, useReducer } from "react";
import { Box, makeStyles, Typography, } from "@material-ui/core";

import { 
    INITIAL_QUERY, 
    USER_ROLE, 
} from "./Consts";
import { getCurrentUserData } from "./CommonFunctions";
import { userDataReducer } from "./Reducers";

const LoaclStyles = makeStyles(theme => ({
    trainee:{
        padding: "10px",
        width: "100%",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#aaaaff",
    },    
    coach:{
        padding: "10px",
        width: "100%",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ffaaaa",
    },
    customerSuccess:{
        padding: "10px",
        width: "100%",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ffffaa",
    },
    superAdministrator:{
        padding: "10px",
        width: "100%",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#aaffaa",
    },
}));

export default function UserRoleDispaly(){
    const localStyle = LoaclStyles();
    
    const [userData, dispachUser] = useReducer(userDataReducer, []);

    const getUserData = async() =>{
        const currentUserData = await getCurrentUserData();
        dispachUser({ type: INITIAL_QUERY, userData:currentUserData });
    }

    useEffect(() => {
        // マウント時処理
        getUserData();
            
        // アンマウント時処理
        return;
    }, []);

    return (
        <Box className={localStyle.displayNode}>
            {userData.userRole === USER_ROLE.Trainee ? 
                <Box className={localStyle.trainee}>
                    <Typography variant="caption">トレーニー</Typography>
                </Box>
            :
                userData.userRole === USER_ROLE.Coach ?
                    <Box className={localStyle.coach}>
                        <Typography variant="caption">コーチ</Typography>
                    </Box>
                :
                    userData.userRole === USER_ROLE.CustomerSuccess ?
                        <Box className={localStyle.customerSuccess}>
                            <Typography variant="caption">CS</Typography>
                        </Box>
                    :
                        userData.userRole === USER_ROLE.SuperAdministrator ?
                            <Box className={localStyle.superAdministrator}>
                                <Typography variant="caption">全体管理者</Typography>
                            </Box>
                        :
                            <Fragment></Fragment>
            }
        </Box>
    );
}