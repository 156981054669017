// React
import { 
    Fragment, 
} from 'react';
import { useHistory } from 'react-router-dom';

// Material-ui
import { makeStyles } from '@material-ui/core/styles';
import { 
    //Typography, 
    Card, 
    CardContent, 
    TextField, 
    Grid,
} from '@material-ui/core';

// Self Making Files
import { 
    CAPTION_CORPORATION_NAME,
    EVENT_CARD_CAPTION_EVENT_NAME,
    EVENT_CARD_CAPTION_EVENT_START_DATETIME,
    FORMAT_STRING_YYYYMMDDHHMM,
    //STATUS_CAPTION_OFFER, 
    //STATUS_CAPTION_REFUSAL_OFFER,
    URL_PARTS_IEEDITOR,
    URL_PARTS_SLASH,
    URL_PATH_SCHEDULE_DETAIL,
    URL_PATH_SCHEDULE_READ_IEEDITOR,
    URL_PATH_TRAINEE_LIST_DETAIL,
    USER_ROLE, 
} from '../../Commons/Consts'
import { 
    getStringFromDateForFormatString, 
    ReadOnlyContent, 
} from '../../Commons/CommonFunctions';

const localStyles = makeStyles(theme => ({
    clickCard:{
        cursor: "pointer",
    },
    contentTextArea:{
        "& .MuiFormLabel-root":{
            fontSize: "3vmin",
        },
        "& .MuiInputBase-root":{
            fontSize: "3vmin",
        }
    }
}));

function EventCorporationCard({ cardDetailUrl, data, corporationName }){
    const localStyle = localStyles();
    const history = useHistory();
    
    const onClickEventCorporationCard = () =>{
        history.push(cardDetailUrl);
    }

    return(
        <Card 
            variant='outlined'
            className={localStyle.clickCard}
            onClick={onClickEventCorporationCard}
            style={{backgroundColor: data.eventColorcode}}
        >
            <CardContent>
                <Grid container spacing={1}>
                    {/* {data.status === -1? 
                        <Grid item xs={12}>
                            <Typography color="secondary">{STATUS_CAPTION_REFUSAL_OFFER}</Typography>
                        </Grid>
                        :
                        data.status === 101 ?
                            <Grid item xs={12}>
                                <Typography color="primary">{STATUS_CAPTION_OFFER}</Typography>
                            </Grid>
                            :
                            <Fragment></Fragment>
                    } */}
                    <Grid item xs={4} md={4} lg={4} className={localStyle.contentTextArea}>
                        <ReadOnlyContent>
                            <TextField
                                label={CAPTION_CORPORATION_NAME}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                disabled
                                value={corporationName}
                            />
                        </ReadOnlyContent>
                    </Grid>
                    <Grid item xs={4} md={4} lg={4} className={localStyle.contentTextArea}>
                        <ReadOnlyContent>
                            <TextField
                                label={EVENT_CARD_CAPTION_EVENT_NAME}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                disabled
                                value={data.eventName}
                            />
                        </ReadOnlyContent>
                    </Grid>
                    <Grid item xs={4} md={4} lg={4} className={localStyle.contentTextArea}>
                        <ReadOnlyContent>
                            <TextField
                                label={EVENT_CARD_CAPTION_EVENT_START_DATETIME}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                disabled
                                value={getStringFromDateForFormatString(new Date(data.deadlineDateTime), FORMAT_STRING_YYYYMMDDHHMM)}
                            />
                        </ReadOnlyContent>
                    </Grid>
                </Grid>    
            </CardContent>
        </Card>
    );
}

export function EventCorporationCardList({ events=[], refusalOffers=false, offers=false, corporations=[], userRole }){
    //const [duplicationIds, setDuplicationIds] = useState([]);
    const duplicationIds = [];
    return(
    <div>
        {
            events
            .filter(data => { return (-1 <= data.status && data.status <= 101)})
            .sort((a,b) => {return a.deadlineTimestamp < b.deadlineTimestamp ? -1 : 1})
            .map(data => {
                // 隠すべき企業の予定の場合
                const corporationName = corporations.filter(corporation => {return corporation.id === data.corporationsID})[0].corporationName;
                if(offers && refusalOffers){
                    if(data.status === -1){
                        duplicationIds.push(data.corporationsID);
                        return <Fragment></Fragment>;
                    }
                    else if(data.status === 101){
                        duplicationIds.push(data.corporationsID);
                        return <Fragment></Fragment>;
                    }
                    else if(data.status === 100){
                        return <Fragment></Fragment>;
                    }
                    // 最新の予定以外の場合
                    if(duplicationIds.some((id) => (id === data.corporationsID))){
                        return <Fragment></Fragment>;
                    }
                    duplicationIds.push(data.corporationsID);
                    return(
                        userRole === USER_ROLE.Trainee?
                        <EventCorporationCard 
                            cardDetailUrl={URL_PATH_SCHEDULE_DETAIL + URL_PARTS_SLASH + data.corporationsID}
                            data={data}
                            corporationName={corporationName}
                        />
                        :
                        <EventCorporationCard 
                            cardDetailUrl={URL_PATH_TRAINEE_LIST_DETAIL + URL_PARTS_SLASH + data.userDatasID + URL_PARTS_IEEDITOR + URL_PARTS_SLASH + data.id}
                            data={data}
                            corporationName={corporationName}
                        />
                    );
                }
                else if(refusalOffers){
                    if(data.status === -1 && 
                        refusalOffers.find((refOffer) => {return refOffer.refusalOfferDatasScheduleEventsId === data.id}) !== void 0){
                        return(
                            <EventCorporationCard 
                                cardDetailUrl={URL_PATH_SCHEDULE_READ_IEEDITOR + URL_PARTS_SLASH + data.id}
                                data={data}
                                corporationName={corporationName}
                            />
                        );
                    }
                }
                else if(offers){
                    if(data.status === 101 && 
                        offers.find((offer) => {return offer.offerDatasScheduleEventsId === data.id}) !== void 0){
                            return(
                            <EventCorporationCard 
                                cardDetailUrl={URL_PATH_SCHEDULE_READ_IEEDITOR + URL_PARTS_SLASH + data.id}
                                data={data}
                                corporationName={corporationName}
                            />
                        );
                    }
                }
                return <Fragment></Fragment>
            })
        }
    </div>
    );
}