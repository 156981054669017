import * as Consts from './Consts';

export function taskDataReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.task;
        case Consts.UPDATE:
            state[action.updateName] = action.updateValue;
            return {...state};
        default:
            return state;
    }
}

export function ttmReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.ttm;
        case Consts.SUBSCRIPTION_UPDATE:
            return state.map((ttm) => (ttm.id === action.ttm.id ? action.ttm : ttm));
        case Consts.UPDATE:
            state[action.updateName] = action.updateValue;
            return {...state};
        default:
            return state;
    }
}

export function tasksReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.tasks;
        case Consts.SUBSCRIPTION_UPDATE:
            return state.map((task) => (task.id === action.task.id ? action.task : task));
        case Consts.SUBSCRIPTION:
            return [action.task, ...state]
        default:
            return state;
    }
}

export function userDataReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.userData;
        case Consts.UPDATE:
            state[action.updateName] = action.updateValue;
            return {...state};
        default:
            return state;
    }
}

export function corporationsReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.corporations;
        case Consts.ADDITIONAL_QUERY:
            return [...state, ...action.corporations]
        case Consts.SUBSCRIPTION:
            return [action.corporations, ...state]
        default:
            return state;
    }
}

export function abuildSheetsReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.sheets;
        default:
            return state;
    }
}

export function eLearingMovesReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.movies;
        default:
            return state;
    }
}

export function surveyReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.surveies;
        default:
            return state;
    }
}

export function traineeRulesReducer(state, action){
    switch (action.type) {
      case Consts.INITIAL_QUERY:
        return action.traineeRules;
      case Consts.ADDITIONAL_QUERY:
        return [...state, ...action.traineeRules]
      case Consts.SUBSCRIPTION:
        return [action.traineeRules, ...state]
      default:
        return state;
    }
}

export function chatroomsReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.chatrooms;
        case Consts.ADDITIONAL_QUERY:
            return [...state, ...action.chatrooms]
        default:
            return state;
    }
}

export function userReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.user
        default:
            return state;
    }
}

export function chatDatasReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.chatData;
        case Consts.ADDITIONAL_QUERY:
            return [...state, ...action.chatData]
        case Consts.SUBSCRIPTION:
            return [action.chatData, ...state]
        case Consts.SUBSCRIPTION_CHAT:
            if(0 < state.length){
                if(state[0].chatroomID === action.chatData.chatroomID){
                    return [action.chatData, ...state];
                }
                else{
                    return state;
                }
            }else{
                return [action.chatData, ...state];
            }
        case Consts.SUBSCRIPTION_UPDATE:
            return state.map((data) => (data.id === action.chatData.id ? action.chatData : data));
        default:
            return state;
      }
}

export function chatroomMemberReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.chatroomMember;
        default:
            return state;
    }
}

export function eventReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.events;
        case Consts.ADDITIONAL_QUERY:
            return [...state, ...action.events]
        case Consts.UPDATE:
            return action.event;
        case Consts.UPDATE_FORMTYPE:
            return {...state, formType: state.formType = action.updateValue}
        case Consts.SUBSCRIPTION_DELETE:
            if(state.length <=0){
                return state;
            }else if(state.find((event) => (event.userDatasID === action.events.userDatasID))){
                return state.filter((event) => (event.id !== action.events.id));
            }
            else{
                return state;
            }
        case Consts.SUBSCRIPTION_CREATE:
            if(state.length <=0){
                return [action.events, ...state]
            }else if(state.find((event) => (event.userDatasID === action.events.userDatasID))){
                return [action.events, ...state]
            }
            else{
                return state;
            }
        default:
            return state;
    }
}

export function eventDetailsReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.eventDetials
        case Consts.ADDITIONAL_QUERY:
            return state.map((eventDetial) => (eventDetial.id === action.eventDetial.id ? action.eventDetial : eventDetial));
        case Consts.SUBSCRIPTION:
            return [action.eventDetials, ...state]
        case Consts.DELETE:
            return state.filter((eventDetial) => (eventDetial.id !== action.eventDetial.id));
        default:
            return state;
    }
}

export function eventDetailReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.detail;
        case Consts.UPDATE:
            state[action.updateName] = action.updateValue;
            return {...state};
        case Consts.SUBSCRIPTION:
            return [action.detail, ...state]
        default:
            return state;
    }
}

export function answersReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.answers;
        case Consts.SUBSCRIPTION:
            return [action.answers, ...state]
        default:
            return state;
    }
}

export function offerReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.offers;
        case Consts.SUBSCRIPTION:
            return [action.offers, ...state]
        default:
            return state;
    }
}

export function traineeListReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.trainees;
        case Consts.ADDITIONAL_QUERY:
            return [...state, ...action.trainees]
        default:
            return state;
    }
};

export function traineeReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.trainee;
        default:
            return state;
    }
};

export function errorReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.errorCheck;
        case Consts.UPDATE:
            state[action.name] = action.set;
            return {...state};
        default:
            return state;
    }
};

export function commonDBDataReducer(state, action){
    switch (action.type) {
        case Consts.INITIAL_QUERY:
            return action.data;
        case Consts.ADDITIONAL_QUERY:
            return [...state, ...action.data]
        case Consts.UPDATE:
            state[action.updateName] = action.updateValue;
            return {...state};
        case Consts.SUBSCRIPTION:
            return [action.data, ...state]
        case Consts.SUBSCRIPTION_UPDATE:
            return state.map((data) => (data.id === action.data.id ? action.data : data));
        case Consts.SUBSCRIPTION_DELETE:
            return state.filter((data) => (data.id !== action.data.id));
        case Consts.SUBSCRIPTION_CHAT:
            if(0 < state.length){
                if(state[0].chatroomID === action.data.chatroomID){
                    return [action.data, ...state];
                }
                else{
                    return state;
                }
            }else{
                return [action.data, ...state];
            }
        default:
            return state;
    }
}