// React
import { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Material-ui
import { 
    makeStyles, 
} from '@material-ui/core/styles';
import { 
    Container, 
    Box, 
    Grid, 
    Typography,
    IconButton, 
} from '@material-ui/core';
import { ArrowForwardIos } from "@material-ui/icons";

// Self Making Files
import { 
    URL_PARTS_SLASH, 
    FORMAT_STRING_YYYYMMDDHHMM,
    URL_PATH_SCHEDULE_READ,
    CAPTION_CORPORATION_NAME,
    CAPTION_OFFER_REGISTRATION_DATE,
    OFFER_STATUS_COMPLETE, 
} from '../../Commons/Consts';
import { getStringFromDateForFormatString } from "../../Commons/CommonFunctions";
import { commonStyles } from '../../Commons/CommonStyles';

const loclaStyles = makeStyles(theme => ({
    entrysheetCard:{
        borderRadius: "10px",
        padding: "10px",
        marginBottom: "1px",
        backgroundColor: theme.palette.background.paper,
        border: "solid #AAAAAA 1px",
        cursor: "pointer",
    },
    offerStatusCaption:{
        borderRadius: "5px",
        paddingTop: "3px",
        paddingLeft: "5px",
        paddingRight: "5px",
        paddingBottom: "3px",
        marginRight: "5px",
        width: "inherit",
        backgroundColor: "#CDB59B",
        color: "#7f3333",
        fontSize: "3vmin",
        fontWeight: "Bold",
    },
}));

export function OfferDataCard({ offerData }){
    const localStyle = loclaStyles();
    const history = useHistory();
    
    useEffect(() => {
        return;
    }, [])

    return (
        <Grid 
            container 
            spacing={0} 
            className={localStyle.entrysheetCard}
            onClick={() => {
                history.push(URL_PATH_SCHEDULE_READ + URL_PARTS_SLASH + offerData.corporationsID);
            }}
        >
            <Grid item xs={11} spacing={0}>
                <Grid container spacing={0}>
                    <Grid item xs={7}>
                        <Typography>{CAPTION_CORPORATION_NAME}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography>{CAPTION_OFFER_REGISTRATION_DATE}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={7}>
                        <Container>
                            {offerData.offerStatus === 4 ? <span className={localStyle.offerStatusCaption}>{OFFER_STATUS_COMPLETE}</span> : <Fragment></Fragment>}
                            <Typography variant="caption">{offerData.corporationName}</Typography>
                        </Container>
                    </Grid>
                    <Grid item xs={5}>
                        <Container>
                            <Typography variant="caption">{getStringFromDateForFormatString(new Date(offerData.createdAt), FORMAT_STRING_YYYYMMDDHHMM)}</Typography>
                        </Container>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1} className={commonStyles.centerContents}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                >
                    <ArrowForwardIos />
                </IconButton>
            </Grid>
            <Grid item xs={12}>
                <Box pt={2}></Box>
            </Grid>
        </Grid>
    )
}

