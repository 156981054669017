// AWS
import { API } from "aws-amplify";

// GraphQL
import { 
    deleteScheduleEvents, 
    updateScheduleEvents, 
} from '../../graphql/mutations';

// React
import { 
    Fragment, 
    useEffect, 
    useState, 
} from 'react';
import { useHistory } from 'react-router-dom';

// Material-ui
import { 
    makeStyles, 
    styled, 
} from '@material-ui/core/styles';
import { 
    Container, 
    Box, 
    Grid, 
    Button, 
    Slider, 
    Typography,
    IconButton, 
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// Self Making Files
import { 
    EVENT_TYPE, 
    URL_PATH_IEEDITOR, 
    URL_PARTS_SLASH, 
    URL_PATH_ESEDITOR, 
    FORMAT_STRING_YYYYMMDDHHMM,
    URL_PATH_SCHEDULE_READ_IEEDITOR,
    BUTTON_CAPTION_ENTRYSHEET_CREATE,
    BUTTON_CAPTION_INTERN,
    BUTTON_CAPTION_DETAIL_WRITING,
    BUTTON_CAPTION_INTERVIEW_DETAIL_WRITING,
    BUTTON_CAPTION_DETAIL_CHECK,
    BUTTON_CAPTION_ENTRYSHEET_CHECK,
    BUTTON_CAPTION_INTERVIEW_DETAIL_CHECK,
    EVENT_CARD_CAPTION_EVENT_NAME,
    EVENT_CARD_CAPTION_EVENT_START_DATETIME,
    TASK_ATTRIBUTE_TITLE_AHIVEMENT,
    DIALOG_CAPTION_EVENT_DELETE_CONFIRM,
    DIALOG_CONTENT_TEXT_EVENT_DELETE_CONFIRM,
    DIALOG_CANCEL_TEXT_EVENT_DELETE_CONFIRM,
    DIALOG_AGREE_TEXT_EVENT_DELETE_CONFIRM, 
} from '../../Commons/Consts';
import { getStringFromDateForFormatString } from "../../Commons/CommonFunctions";
import ConfirmDialog from "./ConfirmDialog";
import { commonStyles } from "../../Commons/CommonStyles";

// CustomSlider
const CustomSlider = styled(Slider)`
    color: #20b2aa;

    :hover {
        color: #2e8b57;
    }
    & .MuiSlider-rail {
        height: 20px;
    }
`;

const localStyles = makeStyles(theme => ({
    eventCard:{
        borderRadius: "10px",
        padding: "10px",
        marginBottom: "1px",
        backgroundColor: theme.palette.background.paper,
        border: "solid #AAAAAA 1px",
    },
}));

function ActionButton({ eventData }){
    const history = useHistory();
    if(eventData.eventType === EVENT_TYPE.Intern.Value){
        return (
            <Button
                variant='contained'
                onClick={() => {
                    history.push(URL_PATH_IEEDITOR + URL_PARTS_SLASH + String(eventData.id));
                }}
                size="small"
                fullWidth
            >
                {BUTTON_CAPTION_INTERN}
                {BUTTON_CAPTION_DETAIL_WRITING}
            </Button>
        );
    }
    else if(eventData.eventType === EVENT_TYPE.EntrySheet.Value){
        return (
            <Button
                variant='contained'
                onClick={() => {
                    history.push(URL_PATH_ESEDITOR + URL_PARTS_SLASH + String(eventData.id));
                }}
                size="small"
                fullWidth
            >
                {BUTTON_CAPTION_ENTRYSHEET_CREATE}
            </Button>
        );
    }
    else if(eventData.eventType === EVENT_TYPE.Interview.Value){
        return (
            <Button
                variant='contained'
                onClick={() => {
                    history.push(URL_PATH_ESEDITOR + URL_PARTS_SLASH + String(eventData.id));
                }}
                size="small"
                fullWidth
            >
                {BUTTON_CAPTION_INTERVIEW_DETAIL_WRITING}
            </Button>
        );
    }
}

function ReadOnlyButton({ eventData }){
    const history = useHistory();
    if(eventData.eventType === EVENT_TYPE.Intern.Value){
        return (
            <Button
                variant='contained'
                onClick={() => {
                    history.push(URL_PATH_SCHEDULE_READ_IEEDITOR + URL_PARTS_SLASH + String(eventData.id));
                }}
                size="small"
                fullWidth
            >
                {BUTTON_CAPTION_INTERN}
                {BUTTON_CAPTION_DETAIL_CHECK}
            </Button>
        );
    }
    else if(eventData.eventType === EVENT_TYPE.EntrySheet.Value){
        return (
            <Button
                variant='contained'
                onClick={() => {
                    history.push(URL_PATH_SCHEDULE_READ_IEEDITOR + URL_PARTS_SLASH + String(eventData.id));
                }}
                size="small"
                fullWidth
            >
                {BUTTON_CAPTION_ENTRYSHEET_CHECK}
            </Button>
        );
    }
    else if(eventData.eventType === EVENT_TYPE.Interview.Value){
        return (
            <Button
                variant='contained'
                onClick={() => {
                    history.push(URL_PATH_SCHEDULE_READ_IEEDITOR + URL_PARTS_SLASH + String(eventData.id));
                }}
                size="small"
                fullWidth
            >
                {BUTTON_CAPTION_INTERVIEW_DETAIL_CHECK}
            </Button>
        );
    }
}

export function EvnetDataCard({ eventData, readOnly=false}){
    const classes = localStyles();
    const [status, setStatus] = useState(eventData.status);
    const [deleteEventConfirmOpen, setDeleteEventConfirmOpen] = useState(false);
    var marks;
    switch(eventData.eventType){
    case EVENT_TYPE.Intern.Value:
        marks = EVENT_TYPE.Intern.StatusMarks;
        break;
    case EVENT_TYPE.EntrySheet.Value:
        marks = EVENT_TYPE.EntrySheet.StatusMarks;
        break;
    case EVENT_TYPE.Interview.Value:
        marks = EVENT_TYPE.Interview.StatusMarks;
        break;
    default:
        marks =  EVENT_TYPE.Intern.StatusMarks;
        break;
    }
    
    const valuetext = (value) =>{
        return {value};
    };
      
    const valueLabelFormat = (value) => {
        return marks.findIndex((mark) => mark.value === value) + 1;
    };

    const updateScheduleEvent = async (event, newValue) =>{
        await API.graphql({
            query: updateScheduleEvents,
            variables: {
                input: {
                    id: eventData.id,
                    status: newValue,
                }        
            }
        });
        setStatus(newValue);
    }

    const deleteScheduleEvent = async () =>{
        await API.graphql({
            query: deleteScheduleEvents,
            variables: {
                input: { id: eventData.id}
            }
        })
        setDeleteEventConfirmOpen(false);
    }

    const onClickDeleteButton = () =>{
        setDeleteEventConfirmOpen(true);
    }

    useEffect(() => {
        return;
    }, [])

    return (
        <Grid container spacing={0} className={classes.eventCard} style={{backgroundColor: eventData.eventColorcode}}>
            <Grid xs={10} container spacing={0}>
                <Grid item xs={8}>
                    <Grid item xs={12}>
                        <Typography variant="caption">{EVENT_CARD_CAPTION_EVENT_NAME}</Typography>
                        <Container>
                            <Typography>{eventData.eventName}</Typography>
                        </Container>
                    </Grid>    
                    <Grid item xs={12}>
                    <Typography variant="caption">{EVENT_CARD_CAPTION_EVENT_START_DATETIME}</Typography>
                    <Container>
                        <Typography>{getStringFromDateForFormatString(new Date(eventData.deadlineDateTime), FORMAT_STRING_YYYYMMDDHHMM)}</Typography>
                    </Container>
                </Grid>
                </Grid>
                <Grid item xs={4} className={commonStyles.centerContents} >
                    {readOnly?<ReadOnlyButton eventData={eventData}></ReadOnlyButton>:
                        <ActionButton eventData={eventData}/>}
                </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1} className={commonStyles.centerContents} >
                {readOnly?<Fragment></Fragment>:
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={onClickDeleteButton}
                >
                    <DeleteForeverIcon />
                </IconButton>}
            </Grid>
            <Grid item xs={12}>
                <Box pt={2}></Box>
            </Grid>
            <Grid item xs={12}>
                <Container>
                    <Typography variant="caption">{TASK_ATTRIBUTE_TITLE_AHIVEMENT}</Typography>
                    <Container>
                    {readOnly?
                    <CustomSlider
                        value={status}
                        valueLabelFormat={valueLabelFormat}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-restrict"
                        step={null}
                        track={false}
                        valueLabelDisplay="off"
                        marks={marks}
                    />
                    :
                    <CustomSlider
                        value={status}
                        valueLabelFormat={valueLabelFormat}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-restrict"
                        step={null}
                        track={false}
                        valueLabelDisplay="off"
                        marks={marks}
                        onChange={updateScheduleEvent}
                    />}
                    </Container>
                </Container>
            </Grid>
            <ConfirmDialog
                confirmEvent={deleteScheduleEvent}
                open={deleteEventConfirmOpen}
                setOpen={setDeleteEventConfirmOpen}
                caption={DIALOG_CAPTION_EVENT_DELETE_CONFIRM}
                contentText={DIALOG_CONTENT_TEXT_EVENT_DELETE_CONFIRM}
                cancelText={DIALOG_CANCEL_TEXT_EVENT_DELETE_CONFIRM}
                agreeText={DIALOG_AGREE_TEXT_EVENT_DELETE_CONFIRM}
            />                    
        </Grid>
    )
}

