// AWS
import { API } from "aws-amplify";

// React
import { 
    useEffect, 
    useState, 
    useReducer,
} from 'react';
import { useHistory } from 'react-router-dom';

// Material UI
import { 
    Box, 
    Grid, 
    Typography, 
} from '@material-ui/core';

// GraphQL
import { 
    listOfferDatas, 
} from "../../graphql/queries";

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import * as Consts from '../../Commons/Consts';
import { 
    getCurrentUserData, 
    redirectForUserRoleToRedorectPages, 
} from "../../Commons/CommonFunctions";
import { offerReducer } from "../../Commons/Reducers";
import { OfferDataCard } from "../../components/SelectionSituationCalender/OfferDataCard";
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import SideMenu from "../../Commons/SideMenu";

export default function OfferList(){
    const commonStyle = commonStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);

    const [offers, dispachOfferData] = useReducer(offerReducer, []);
    const initRefusalList = async () =>{
        // ユーザーロールに基づき見てはいけないユーザーロールはリダイレクト
        const currentUserData = await getCurrentUserData();
        redirectForUserRoleToRedorectPages(history, currentUserData, Consts.USER_ROLE.Trainee);
    
        // 閲覧ユーザーに関わる選考状況イベントデータリストをクエリにて取得し、State変数へ保持
        const filter = {
            userdatasID: {contains: currentUserData.userId},
        }
        const offersRes = await API.graphql({
            query: listOfferDatas,
            variables: { filter: filter, limit:1000 },
            authMode: Consts.AMAZON_COGNITO_USER_POOLS,
        });
        dispachOfferData({ type: Consts.INITIAL_QUERY, offers:offersRes.data.listOfferDatas.items });
        
        // プログレス表示を停止
        setIsLoading(false);
    }
    
    useEffect(() => {
        // マウント時処理
        initRefusalList();

        // アンマウント時処理
        return;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header 
                title='内定企業一覧'
            />
            <SideMenu/>
            {isLoading ?
            <LoadingIcon/>
            :
            <Box className={commonStyle.pageContent}>
                <Box pt={2}>
                    <Grid container spacing={0}>
                            {0 < offers.length?
                                offers.map(offerData => (
                                    <OfferDataCard offerData={offerData}/>
                                ))
                                :
                                <Typography>内定として登録した企業はありません。</Typography>
                            }
                    </Grid>
                    <Box className={commonStyle.endBox}/>
                </Box>
            </Box>
            }
            <Footer/>
        </Box>
    );
}
