// AWS
import { API } from "aws-amplify";

// React
import { 
    useEffect, 
    useState, 
    useReducer, 
} from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { 
    Box,  
    Button,  
    FormControl,  
    Grid, 
    TextField, 
    Typography, 
} from '@material-ui/core';

// GraphQL
import { createCorporations } from '../../graphql/mutations';
import { listCorporations } from "../../graphql/queries";
import { onCreateCorporations } from '../../graphql/subscriptions';

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { 
    AMAZON_COGNITO_USER_POOLS, 
    BUTTON_CAPTION_SAVE, 
    CAPTION_CORPORATION_NAME, 
    CORPORATION_MANAGEMENT_HEADER_TITLE, 
    HEADER_TITLE_CORPORATION_MASTER_MANAGEMENT, 
    INITIAL_QUERY, 
    SUBSCRIPTION, 
} from '../../Commons/Consts';
import { 
    commonDBDataReducer, 
    userDataReducer, 
} from "../../Commons/Reducers";
import { 
    getCurrentUserData, 
    consoleLog, 
} from "../../Commons/CommonFunctions";
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import CorporationNameInput from "../../components/SelectionSituationCalender/CorporationNameInput";
import SideMenu from "../../Commons/SideMenu";

const localStyles = makeStyles(theme => ({
    messegeTextarea: {
        '& .MuiInputBase-root':{
            paddingTop: "5px",
            paddingBottom: "5px",
            '& .MuiInputBase-input':{
                paddingTop: "5px",
                paddingBottom: "5px",
            },
        },
        '& #message-editor':{
            fontSize: "3vmin",
            color: "#000000"
        },
    },
}));

export default function CorporationMasterManagement(){
    const commonStyle = commonStyles();   
    const localStyle = localStyles();
    const [isLoading, setIsLoading] = useState(true);

    const [userData, dispatchCurrentUserData] = useReducer(userDataReducer, []);
    const [corporationsData, dispatchCorporationsData] = useReducer(commonDBDataReducer, []);
    const [corporationName, setCorporationName] = useState('');
    const [hiddenCorpIds, setHiddenCorpIds] = useState([])
    const [dammy1, setDammy1] = useState();
    const [dammy2, setDammy2] = useState();
    
    const initTodaysWordsManagement = async () =>{
        try{
            // 
            const currentUserData = await getCurrentUserData();
            dispatchCurrentUserData({ type: INITIAL_QUERY, userData: currentUserData });
            
            const corpRes = await API.graphql({
                query: listCorporations,
                variables: { limit: 10000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            const corps = corpRes.data.listCorporations.items;
            dispatchCorporationsData({ type: INITIAL_QUERY, data:corps});
            setHiddenCorpIds(corps.map((item) => {return item.corporationsID}))
            
            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }

    const corporationNameInputChange = (event) =>{
        setCorporationName(event.target.value)
    };

    const corporationCreate = async () =>{
        await API.graphql({
            query: createCorporations,
            variables: {
                input: {corporationName: corporationName}
            }
        });
        
        setCorporationName('');
    }

    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();

        initTodaysWordsManagement();

        const createSubsc = API.graphql({
            query:onCreateCorporations,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
        next: (msg) => {
            const data = msg.value.data.onCreateCorporations;
            dispatchCorporationsData({ type: SUBSCRIPTION, data: data });
        }});

        return () => {
            abortCtrl.abort()
            createSubsc.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header title={HEADER_TITLE_CORPORATION_MASTER_MANAGEMENT}/>
            <SideMenu/>
            {isLoading ?
            <LoadingIcon/>
            :
            <Box className={commonStyle.pageContent}>
                <Box mt={3}>
                    <Grid container>
                        <Grid xs={12} className={commonStyle.marginBottomRow}>
                            <FormControl fullWidth className={localStyle.messegeTextarea}>
                                <TextField
                                    id="message-editor"
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    minRows={1}
                                    maxRows={1}
                                    label={CAPTION_CORPORATION_NAME}
                                    value={corporationName}
                                    onChange={corporationNameInputChange}
                                />
                            </FormControl>                      
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={corporationCreate}>{BUTTON_CAPTION_SAVE}</Button>                     
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={3}>
                    <CorporationNameInput
                        setCorporationId={setDammy1}
                        setInputName={setDammy2}
                        hiddenEventForCorpIds={hiddenCorpIds}
                    />
                </Box>
                <Box mt={3}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} className={localStyle.messegeTextarea}>
                            <Typography variant="caption">{CAPTION_CORPORATION_NAME}</Typography>
                        </Grid>
                    </Grid>
                    {corporationsData
                    .sort((a,b) => {return a.createAt < b.createAt ? 1 : -1})
                    .map(data => {
                        return (
                        <Grid container spacing={0}>
                            <Grid item xs={12} className={localStyle.messegeTextarea}>
                                <TextField
                                    id="message-editor"
                                    fullWidth
                                    multiline
                                    disabled
                                    variant="outlined"
                                    minRows={1}
                                    maxRows={1}
                                    value={data.corporationName}
                                />                        
                            </Grid>
                        </Grid>)
                    })}
                </Box>
            </Box>
            }
            <Footer/>
        </Box>
    );
}
