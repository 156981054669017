// React
import { 
    useEffect, 
    useState, 
} from 'react';
import { useHistory, useLocation} from 'react-router-dom';

// Material UI
import { 
    Box,
    Button,
    Container,
    Grid, 
} from '@material-ui/core';

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import * as Consts from '../../Commons/Consts';
import { 
    getCurrentUserData, 
    consoleLog, 
    redirectForUserRoleToRedorectPages, 
} from '../../Commons/CommonFunctions';
import { commonStyles } from '../../Commons/CommonStyles';
import { LoadingIcon } from '../../Commons/LoadingIcon';
import SideMenu from '../../Commons/SideMenu';
import CommonDialog from '../../Commons/CommonDialog';
import { listCoachDatas } from '../../graphql/queries';
import { API } from 'aws-amplify';

function ManageSheetOpenButton({ manageSheetUrl }){
    const commonStyle = commonStyles();
    const [nonDataWarningOpen, setNonDataWarningOpen] = useState(false);

    const agreeEvent = () => {
        
    }

    return (
        <Grid container>
            { manageSheetUrl !== '' ?
            <Grid xs={12} className={commonStyle.marginBottomRow}>
                <a 
                    rel="noreferrer" 
                    target="_blank" 
                    href={manageSheetUrl} 
                    className={commonStyle.linkButton}
                >
                    {Consts.C_MANAGEMENT_BUTTON_CAPTION_MANAGEMENT_SHEET_OPEN}
                </a>                    
            </Grid>
            :
            <Grid item xs={12} className={commonStyle.marginBottomRow}>
                <Button variant="contained" color='primary' onClick={() => {setNonDataWarningOpen(true)}} className={commonStyle.nonDataButton} fullWidth>{Consts.C_MANAGEMENT_BUTTON_CAPTION_MANAGEMENT_SHEET_OPEN}</Button>
            </Grid>
            }
            <CommonDialog 
                agreeEvent={agreeEvent}
                open={nonDataWarningOpen}
                setOpen={setNonDataWarningOpen}
                contentText={Consts.MESSAGE_ABUILDSHEETS_NON_DATA}
                agreeText={Consts.ABUILDSHEETS_NON_DATA_DIALOG_AGREE_TEXT}
            />
        </Grid>
    )
}

function ReportSheetOpenButton({ reportSheetUrl }){
    const commonStyle = commonStyles();
    const [nonDataWarningOpen, setNonDataWarningOpen] = useState(false);

    const agreeEvent = () => {
        
    }

    return (
        <Grid container>
            { reportSheetUrl !== '' ?
            <Grid xs={12} className={commonStyle.marginBottomRow}>
                <a 
                    rel="noreferrer" 
                    target="_blank" 
                    href={Consts.TEST_SPS_URL} 
                    className={commonStyle.linkButton}
                >
                    {Consts.C_MANAGEMENT_BUTTON_CAPTION_REPORT_OPEN}
                </a>
            </Grid>
            :
            <Grid item xs={12} className={commonStyle.marginBottomRow}>
                <Button variant="contained" color='primary' onClick={() => {setNonDataWarningOpen(true)}} className={commonStyle.nonDataButton} fullWidth>{Consts.C_MANAGEMENT_BUTTON_CAPTION_REPORT_OPEN}</Button>
            </Grid>
            }
            <CommonDialog 
                agreeEvent={agreeEvent}
                open={nonDataWarningOpen}
                setOpen={setNonDataWarningOpen}
                contentText={Consts.MESSAGE_ABUILDSHEETS_NON_DATA}
                agreeText={Consts.ABUILDSHEETS_NON_DATA_DIALOG_AGREE_TEXT}
            />
        </Grid>
    )
}

function MovieUploadPageButton({ movieUploadPageUrl }){
    const commonStyle = commonStyles();
    const [nonDataWarningOpen, setNonDataWarningOpen] = useState(false);

    const agreeEvent = () => {
        
    }

    return (
        <Grid container>
            { movieUploadPageUrl !== '' ?
            <Grid xs={12} className={commonStyle.marginBottomRow}>
                <a 
                    rel="noreferrer" 
                    target="_blank" 
                    href={Consts.TEST_SPS_URL} 
                    className={commonStyle.linkButton}
                >
                    {Consts.C_MANAGEMENT_BUTTON_CAPTION_REPORT_OPEN}
                </a>
            </Grid>
            :
            <Grid item xs={12} className={commonStyle.marginBottomRow}>
                <Button variant="contained" color='primary' onClick={() => {setNonDataWarningOpen(true)}} fullWidth>{Consts.C_MANAGEMENT_BUTTON_CAPTION_REPORT_OPEN}</Button>
            </Grid>
            }
            <CommonDialog 
                agreeEvent={agreeEvent}
                open={nonDataWarningOpen}
                setOpen={setNonDataWarningOpen}
                contentText={Consts.MESSAGE_ABUILDSHEETS_NON_DATA}
                agreeText={Consts.ABUILDSHEETS_NON_DATA_DIALOG_AGREE_TEXT}
            />
        </Grid>
    )
}

export default function CoachingManagement(){
    const history = useHistory();
    const commonStyle = commonStyles();
    const uri = new URL(window.location.href);

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState('');
    const [data2, setData2] = useState('');
    const [userData, setUserData] = useState([]);
    const initCoachingManagement = async () =>{
        try {
            const currentUserData = await getCurrentUserData();
            redirectForUserRoleToRedorectPages(history, currentUserData, Consts.USER_ROLE.Coach);
            setUserData(currentUserData);

            const filter = { userdatasID : {contains: currentUserData.userId} }
            const res = await API.graphql({
                query: listCoachDatas,
                variables: { filter: filter , limit: 100000 },
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            });
            const coachDatas = res.data.listCoachDatas.items;
            if(0 < coachDatas.length){
                setData(coachDatas.at(0).coachManageSheetUrl);
            };

            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        let abortCtrl = new AbortController();

        initCoachingManagement();
        // アンマウント時処理
        return() => {
            abortCtrl.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header title="面談管理"/>
            <SideMenu/>
            {isLoading ?
            <LoadingIcon/>
            :
            <Box className={commonStyle.pageContent} >
                <Container>
                    <Box pt={2}>
                        <ManageSheetOpenButton manageSheetUrl={data}></ManageSheetOpenButton>
                        {/* <MovieUploadPageButton movieUploadPageUrl={data2}></MovieUploadPageButton> */}
                    </Box> 
                </Container>
            </Box>
            }
            <Footer/>
        </Box>
    );
}
