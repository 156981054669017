// AWS
import { API } from "aws-amplify";

// GraphQL
import { createScheduleEvents } from '../../graphql/mutations';

// React
import { 
    useState, 
} from 'react';

// Material-ui
import { makeStyles } from '@material-ui/core/styles';
import { 
    TextField, 
    Modal, 
    FormGroup, 
    FormControl, 
    Button, 
    InputLabel, 
    Select, 
    MenuItem,
} from '@material-ui/core';

// Self Making Files
import { ColorSelector } from "../../Commons/ColoerSelector";
import { BUTTON_CAPTION_CREATE, CAPTION_CORPORATION_NAME, DIALOG_EVENT_CREATE_ATTRIBUTE_EVENT_TYPE, EVENT_CARD_CAPTION_EVENT_NAME, EVENT_CARD_CAPTION_EVENT_START_DATETIME, EVENT_TYPE, MESSAGE_EMPTY_NOT_REGISTRATION } from "../../Commons/Consts";

const localStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    modalFormControl:{
        margin: "5px",
    },
}));

// List all items
function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
}

export function CreateOnlyCorporationEventModal({ userData, corporations, setOpen, open, handleClose, initColor }){
    const localStyle = localStyles();
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().substr(0, 16));
    const [newEventCompanyName] = useState(corporations.corporationName);
    const [newEventType, setNewEventType] = useState(0);
    const [newEventName, setNewEventName] = useState('');
    const [modalStyle] = useState(getModalStyle);
    const [errorIsNewEventNameNone, setErrorIsNewEventNameNone] = useState(false);
    const [eventColor, setEventColor] = useState(initColor); 

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handleEventnameChange = (event) =>{
        setNewEventName(event.target.value);
    }

    const onCloseLocal = () => {
        setErrorIsNewEventNameNone(false);
        handleClose();
    }

    // getModalStyle is not a pure function, we roll the style only on the first render
    const createScheduleEvent = async () =>{
        // 必須チェック
        if(newEventName === null || newEventName === ''){
            setErrorIsNewEventNameNone(true);
            return;
        }
        await API.graphql({
            query: createScheduleEvents,
            variables: {
                input: {
                    eventName: newEventName,
                    eventType: newEventType,
                    status: 33,
                    deadlineDateTime: selectedDate + ':00.000Z',
                    deadlineTimestamp: new Date(selectedDate).valueOf(),
                    formType: "",
                    eventColorcode: eventColor,
                    corporationsID: corporations.id,
                    userDatasID: userData.userId
                }        
            }
        });
        setOpen(false);
    }
    
    return (
      <div>
        <Modal
            open={open}
            onClose={onCloseLocal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={localStyle.paper}>
                <FormGroup>
                    <FormControl className={localStyle.modalFormControl}>
                        <TextField 
                            label={CAPTION_CORPORATION_NAME}
                            defaultValue={newEventCompanyName} 
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </FormControl>
                    <FormControl className={localStyle.modalFormControl}>
                        <TextField 
                            label={EVENT_CARD_CAPTION_EVENT_NAME}
                            required
                            error={errorIsNewEventNameNone}
                            helperText={errorIsNewEventNameNone ? MESSAGE_EMPTY_NOT_REGISTRATION : ''}
                            InputLabelProps={{ shrink: true }}
                            defaultValue={newEventName}
                            onChange={handleEventnameChange}
                        />
                    </FormControl>
                    <FormControl variant="outlined" className={localStyle.modalFormControl}>
                        <InputLabel id="event-type">{DIALOG_EVENT_CREATE_ATTRIBUTE_EVENT_TYPE}</InputLabel>
                        <Select
                            label={DIALOG_EVENT_CREATE_ATTRIBUTE_EVENT_TYPE}
                            labelId="event-type"
                            id='select-event-type'
                            value={newEventType}
                            onChange={(event) => setNewEventType(event.target.value)}
                        >
                            <MenuItem value={0}>{EVENT_TYPE.Intern.Label}</MenuItem>
                            <MenuItem value={1}>{EVENT_TYPE.EntrySheet.Label}</MenuItem>
                            <MenuItem value={2}>{EVENT_TYPE.Interview.Label}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className={localStyle.modalFormControl}>
                        <TextField
                            id="datetime-local"
                            label={EVENT_CARD_CAPTION_EVENT_START_DATETIME}
                            type="datetime-local"
                            defaultValue={selectedDate}
                            className={localStyle.textField}
                            onChange={handleDateChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                    <FormControl className={localStyle.modalFormControl}>
                        <ColorSelector setColor={setEventColor} initColor={eventColor} ></ColorSelector>
                    </FormControl>
                    <FormControl className={localStyle.modalFormControl}>
                        <Button
                            onClick={() => createScheduleEvent()}
                            variant="contained"
                        >{BUTTON_CAPTION_CREATE}</Button>
                    </FormControl>
                </FormGroup>
            </div>
        </Modal>
      </div>
    );
}
