// AWS
import { API } from "aws-amplify";

// GraphQL
import { 
    updateTasktimeManagementDatas, 
} from '../../graphql/mutations';
import { 
    createTaskDatas, 
} from '../../graphql/mutations';
import { 
    onCreateTaskDatas, 
    onUpdateTaskDatas, 
    onUpdateTasktimeManagementDatas, 
} from '../../graphql/subscriptions';

// React
import { 
    useEffect, 
    useState, 
    Fragment,
} from 'react';
import AddIcon from '@material-ui/icons/Add';

// Material-ui
import { makeStyles } from '@material-ui/core/styles';
import { 
    Typography, 
    Container, 
    Box, 
    Grid, 
    Select, 
    MenuItem, 
    TextField, 
    Fab, 
    InputLabel, 
    FormControl, 
    Tabs, 
    Tab,
} from '@material-ui/core';

// Self Making Files
import { 
    AMAZON_COGNITO_USER_POOLS, 
    ERROR_TYPE_ABORTERROR, 
    FORMAT_STRING_MMONTH_DDAY, 
    INITIAL_QUERY, 
    SUBSCRIPTION, 
    SUBSCRIPTION_UPDATE, 
    TAB_MENU_TITLE_ACHIVEMENT, 
    TAB_MENU_TITLE_PLANS, 
    TASK_TITLE_ACTION_TO_IMPROVE, 
    TASK_TITLE_ISSUES_TO_IMPROVE, 
    TASK_TITLE_REFLECTION_ON_DAY, 
    TASK_TITLE_TASK_ACHIEVEMENT, 
    TASK_TITLE_TODAY_EFFORT_TARGETS, 
    TASK_TITLE_TODAY_REFLECTION, 
    TASK_TITLE_TODAY_TARGETS, 
    TASLTIMES_PROMPT_FILL_MESSAGE, 
    TTM_DATA_ACHIEVEMENTSCORE, 
    TTM_DATA_ACTIONTOIMPROVE, 
    TTM_DATA_EFFORTTARGETS, 
    TTM_DATA_ISSUESTOIMPROVE, 
    TTM_DATA_REFLECTIONONDAY, 
    TTM_DATA_TASKTIMEMANAGEMENTOUTPUT, 
    UPDATE, 
    URL_PARTS_SLASH, 
    URL_PATH_CS_TRAINEE_MANAGEMENT, 
    USER_ROLE, 
} from '../../Commons/Consts';
import { 
    formatAWSDateStr, 
    getStringFromDateForFormatString, 
    nonZerosuppresFormatDate, 
    recursiveGrapheql, 
    getCurrentUserData, 
    consoleLog,  
} from '../../Commons/CommonFunctions';
import { PlanTaskCardList } from './PlanTaskCardList';
import { ResultTaskCardList } from './ResultTaskCardList';
import { 
    a11yProps, 
    TabPanel 
} from "../../Commons/TabPanel";
import { commonStyles } from "../../Commons/CommonStyles";
import styled from 'styled-components';
import { common } from "@material-ui/core/colors";
import { useHistory, useParams } from "react-router";
import { ContactSupportOutlined } from "@material-ui/icons";
import { getTasktimeManagementDatas, listTaskDatas, listTasktimeManagementDatas } from "../../graphql/queries";

const localStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 100,
        backgroundColor: theme.palette.background.paper,
        "& .MuiInputBase-input":{
            [theme.breakpoints.down('sm')]: {
                fontSize: "3vmin",
            },
            [theme.breakpoints.up('md')]: {
                fontSize: "2vmin",
            }
        }
    },
    formPCControl: {
        minWidth: 100,
        backgroundColor: theme.palette.background.drawerPaper,
        "& .MuiInputBase-input":{
            [theme.breakpoints.down('sm')]: {
                fontSize: "3vmin",
            },
            [theme.breakpoints.up('md')]: {
                fontSize: "1.5vmin",
            }
        },
        "& .MuiOutlinedInput-multiline":{
            padding: "3px",
        },
    },
    achievementSelect:{
        display: 'flex',
        alignContent: 'stretch',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: "5px",
        "& .MuiSelect-root":{
            padding: "5px 5px 0px 5px",
        }
    },
    settingDateDisplay:{
        padding: "3px",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
    tabMenu:{
        minWidth: "100%",
        maxWidth: "100%",
        width: "100%",
        minHeight: "5%",
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        "& .MuiTab-root":{
            height: "5%",
            minHeight: "5%",
        },
    },
    widthHerf:{
        minWidth: "50%",
        maxWidth: "50%",
        width: "50%",
        backgroundColor: theme.palette.background.paper,
    },
    divider:{
        border: "solid #0A0A0A 1px",
        marginTop: "5px",
        marginBottom: "5px", 
    },
    gridFromControl:{
        padding: "3px",
        borderRadius: "3px",
        marginBottom: "5px",
        backgroundColor: theme.palette.background.paper,
    },
    captionBox:{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.background.paper,
    },
    bcBrown:{
        backgroundColor: "#bcaaa4",
    },
    ttmArea:{
        borderTop: "solid 1px #ffffff",
        position: "fixed",
        right: "1%",
        width: "28%",
        bottom: 5,
    },
    fabbutArea:{
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
    tasksArea:{
        width: "100%",
    },
    planTasksArea:{
        minHeight: "20vh",
        height: "40%",
        maxHeight: "40vh",
        overflowY: "scroll",
    },
    resultTasksArea:{
        height: "15vh",
        overflowY: "scroll",
    },
    fabbut:{
        width: "32px",
        height: "32px",
        zIndex: 1000,
    },
}));

const NoscrollDiv = styled.div`
    /* ~~~ container styles ~~~ */
    .MuiBox-root{
        /*要素の高さを指定*/
        /*上下方向にはみ出した要素ををスクロールさせる*/
        overflow-y: scroll;
        /*スクロールバー非表示（IE・Edge）*/
        -ms-overflow-style: none;
        /*スクロールバー非表示（Firefox）*/
        scrollbar-width: none;
    }
    /*スクロールバー非表示（Chrome・Safari）*/
    .MuiBox-root::-webkit-scrollbar{
        display:none;
    }
`;


export default function CSTTTMChecker({ tasks, ttm, dispatchTtmData, userData, timestamp, ttmId, traineeId }){
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    const param = useParams();
    const history = useHistory();
    const [value, setValue] = useState(0);
    
    const initTaskTimeDetail = async () =>{
        try {
            // コーチが確認した時にCheckOutされる
            checkOutingProcess(tasks);
        } catch (e) {
            if (e.name === ERROR_TYPE_ABORTERROR) {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
        }
    }

    const checkOutingProcess = async (tasks) =>{
        const res = await API.graphql({
            query: getTasktimeManagementDatas,
            variables: { id: ttmId , limit:100000 },
            authMode: AMAZON_COGNITO_USER_POOLS,
        })
        const ttmData = res.data.getTasktimeManagementDatas;
        if(!ttmData.TaskDatas.items.some(checkIsReportingStatus) &&
            checkIsTtmStatus(ttmData)){
            const ttmUpdRes = await API.graphql({
                query: updateTasktimeManagementDatas,
                variables: { 
                    input: {
                        id:ttmId,
                        checkOut: true,
                    },
                },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            dispatchTtmData({ type: INITIAL_QUERY, data:ttmUpdRes.data.updateTasktimeManagementDatas });
        }
    }

    const checkIsReportingStatus = (data, index, array) =>{
        if((array.length - 1) === index) return false; // 最後のタスクについては状況を不問とする
        return data.resultTaskDetial === '' && data.taskProgress === 0;
    }
    
    const checkIsTtmStatus = (data) =>{
        return data.ttmActionToImprove !== '' && data.ttmIssuesToImprove !== '' && data.ttmReflectionOnDay !== ''
    }
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    useEffect(() => {
        // マウント時処理
        // 初期化処理
        let abortCtrl = new AbortController();
        initTaskTimeDetail();

        // APIサブスクリプション登録
        // アンマウント時処理
        return () => {
            abortCtrl.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param.ttmId, param.timestamp]);

    return(
        <Box className={localStyle.tasksArea}>
            <Box className={localStyle.settingDateDisplay}>
                {ttm.settingDate !== undefined ? 
                <Typography variant="h6">
                    {nonZerosuppresFormatDate(new Date(ttm.settingDate), FORMAT_STRING_MMONTH_DDAY)}
                </Typography>
                :
                <Fragment></Fragment>
                }
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} className={localStyle.tabMenu} aria-label="basic tabs example">
                <Tab label={TAB_MENU_TITLE_PLANS} {...a11yProps(0)} className={localStyle.widthHerf} />
                <Tab label={TAB_MENU_TITLE_ACHIVEMENT} {...a11yProps(1)} className={localStyle.widthHerf} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Box pt={1}>
                    <NoscrollDiv>
                        <Box className={localStyle.planTasksArea}>
                            <PlanTaskCardList 
                                tasks={tasks} 
                                userRole={userData.userRole}
                            />
                        </Box>
                    </NoscrollDiv>
                    <Box pt={1} className={localStyle.ttmArea}>
                        <Grid container className={localStyle.gridFromControl}>
                            <Grid item xs={3}>
                                <Box className={localStyle.captionBox}>
                                    <Typography variant="caption">{TASK_TITLE_TODAY_TARGETS}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    minRows={3}
                                    maxRows={3}
                                    disabled={userData.userRole === 17 ? false : true}
                                    InputLabelProps={{ shrink: true }}
                                    className={localStyle.formPCControl}
                                    value={ttm.tasktimeManagementOutput}
                                    placeholder={TASLTIMES_PROMPT_FILL_MESSAGE}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={localStyle.gridFromControl}>
                            <Grid item xs={3}>
                                <Box className={localStyle.captionBox}>
                                    <Typography variant="caption">{TASK_TITLE_TODAY_EFFORT_TARGETS}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    minRows={3}
                                    maxRows={3}
                                    disabled={userData.userRole === 17 ? false : true}
                                    InputLabelProps={{ shrink: true }}
                                    className={localStyle.formPCControl}
                                    value={ttm.ttmEffortTargets}
                                    placeholder={TASLTIMES_PROMPT_FILL_MESSAGE}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box pt={1}>
                    <NoscrollDiv>
                        <Box className={localStyle.resultTasksArea}>
                            <ResultTaskCardList 
                                tasks={tasks} 
                                userRole={userData.userRole}
                            />
                        </Box>
                    </NoscrollDiv>
                    <Box pt={1} className={localStyle.ttmArea}>
                        <Grid container className={localStyle.gridFromControl}>
                            <Grid item xs={3}>
                                <Box className={localStyle.captionBox}>
                                    <Typography variant="caption">{TASK_TITLE_TODAY_TARGETS}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    disabled={userData.userRole === 17 ? false : true}
                                    InputLabelProps={{ shrink: true }}
                                    className={localStyle.formPCControl}
                                    value={ttm.tasktimeManagementOutput}
                                    placeholder={TASLTIMES_PROMPT_FILL_MESSAGE}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={localStyle.gridFromControl}>
                            <Grid item xs={3}>
                                <Box className={localStyle.captionBox}>
                                    <Typography variant="caption">{TASK_TITLE_TODAY_EFFORT_TARGETS}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    disabled={userData.userRole === 17 ? false : true}
                                    InputLabelProps={{ shrink: true }}
                                    className={localStyle.formPCControl}
                                    value={ttm.ttmEffortTargets}
                                    placeholder={TASLTIMES_PROMPT_FILL_MESSAGE}
                                />
                            </Grid>
                        </Grid>
                        <Box className={localStyle.divider} />
                        <Grid container>    
                            <Grid item xs={6} className={commonStyle.rightContents}>
                                <Typography variant='h6'>{TASK_TITLE_TODAY_REFLECTION}</Typography>
                            </Grid>
                            <Grid container xs={6} className={localStyle.achievementSelect}>
                                <FormControl variant="outlined" className={localStyle.formControl}>
                                    <Select
                                        labelId="task-achievement-rate-select-label"
                                        id="task-achievement-rate-select"
                                        disabled={userData.userRole === 17 ? false : true}
                                        value={ttm.length <= 0 ? 
                                            0    
                                            :
                                            ttm.achievementScore
                                            }
                                    >
                                        <MenuItem value={0}>達成率</MenuItem>
                                        <MenuItem value={5}>5%</MenuItem>
                                        <MenuItem value={10}>10%</MenuItem>
                                        <MenuItem value={15}>15%</MenuItem>
                                        <MenuItem value={20}>20%</MenuItem>
                                        <MenuItem value={25}>25%</MenuItem>
                                        <MenuItem value={30}>30%</MenuItem>
                                        <MenuItem value={35}>35%</MenuItem>
                                        <MenuItem value={40}>40%</MenuItem>
                                        <MenuItem value={45}>45%</MenuItem>
                                        <MenuItem value={50}>50%</MenuItem>
                                        <MenuItem value={55}>55%</MenuItem>
                                        <MenuItem value={60}>60%</MenuItem>
                                        <MenuItem value={65}>65%</MenuItem>
                                        <MenuItem value={70}>70%</MenuItem>
                                        <MenuItem value={75}>75%</MenuItem>
                                        <MenuItem value={80}>80%</MenuItem>
                                        <MenuItem value={85}>85%</MenuItem>
                                        <MenuItem value={90}>90%</MenuItem>
                                        <MenuItem value={95}>95%</MenuItem>
                                        <MenuItem value={100}>100%</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid container className={localStyle.gridFromControl}>
                                <Grid item xs={3}>
                                    <Box className={localStyle.captionBox}>
                                        <Typography variant="caption">{TASK_TITLE_REFLECTION_ON_DAY}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        disabled={userData.userRole === 17 ? false : true}
                                        className={localStyle.formPCControl}
                                        value={ttm.ttmReflectionOnDay}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className={localStyle.gridFromControl}>
                                <Grid item xs={3}>
                                    <Box className={localStyle.captionBox}>
                                        <Typography variant="caption">{TASK_TITLE_ISSUES_TO_IMPROVE}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        disabled={userData.userRole === 17 ? false : true}
                                        className={localStyle.formPCControl}
                                        value={ttm.ttmIssuesToImprove}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className={localStyle.gridFromControl}>
                                <Grid item xs={3}>
                                    <Box className={localStyle.captionBox}>
                                        <Typography variant="caption">{TASK_TITLE_ACTION_TO_IMPROVE}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        disabled={userData.userRole === 17 ? false : true}
                                        className={localStyle.formPCControl}
                                        value={ttm.ttmActionToImprove}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </TabPanel>
        </Box>
    );
}