// AWS
import { API } from "aws-amplify";

// GraphQL
import { 
    createTasktimeManagementDatas, 
} from '../../graphql/mutations';
import { 
    createTaskDatas, 
} from '../../graphql/mutations';

// React
import { 
    useEffect, 
    useReducer,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Material-ui
import { 
    Box, 
} from '@material-ui/core';

// Self Making Files
import { 
    AMAZON_COGNITO_USER_POOLS, 
    ERROR_TYPE_ABORTERROR, 
    HEADER_TITLE_TASKTIMES, 
    INITIAL_QUERY, 
    PARAM_PCMODE_TRUE, 
    URL_PARTS_SLASH,
    URL_PATH_TASKTIMES,
    URL_PATH_TASKTIMES_DETAIL, 
} from '../../Commons/Consts';
import { 
    formatAWSDateStr, 
    getCurrentUserData, 
    consoleLog, 
} from '../../Commons/CommonFunctions';
import { LoadingIcon } from "../../Commons/LoadingIcon";
import { commonStyles } from "../../Commons/CommonStyles";
import SideMenu from "../../Commons/SideMenu";
import Header from "../../Commons/Header";
import Footer from "../../Commons/Footer";
import { userDataReducer } from "../../Commons/Reducers";

export default function TaskTimeManageDataCreate(){
    const param = useParams();
    const history = useHistory();
    const commonStyle = commonStyles();
    
    const [userData, dispachCurrentUserData] = useReducer(userDataReducer, [])
    const initTaskTimeDetail = async (type) =>{
        try {
            // URLパラメータtraineeIdがある場合はコーチがトレーニーのデータを見ていることになる
            const currentUserData = await getCurrentUserData();
            dispachCurrentUserData({ type: INITIAL_QUERY, userData: currentUserData })

            // タスクマネジメントデータを作成
            const createRes = await API.graphql({
                query: createTasktimeManagementDatas,
                variables: { 
                    input: {
                        userDatasID: currentUserData.userId,
                        settingDate: formatAWSDateStr(param.timestamp),
                        settingTimestamp: new Date(formatAWSDateStr(param.timestamp)).valueOf(),
                        achievementScore: 0,
                        checkIn: true,
                        checkOut: false,
                        tasktimeManagementOutput: "",
                        ttmActionToImprove: "",
                        ttmEffortTargets: "", 
                        ttmIssuesToImprove: "", 
                        ttmReflectionOnDay: "",
                        ttmStatus: 0,
                    },
                },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            
            // 新規作成のマネジメントデータに合わせて一番最初のタスクデータを作成
            var newStartTime = "08:00";
            var newEndTime = "09:00";
            await API.graphql({
                query: createTaskDatas,
                variables: {
                    input: {
                        parentTasktimeManagementDatasID: createRes.data.createTasktimeManagementDatas.id, 
                        planTaskDetial: "", 
                        planTaskCreated: true, 
                        planTaskStartTime: newStartTime, 
                        planTaskEndTime: newEndTime, 
                        planTaskPriority: 0, 
                        resultTaskDetial: "", 
                        resultTaskStartTime: newStartTime, 
                        resultTaskEndTime: newEndTime, 
                        resultTaskPriority: 0, 
                        taskProgress: 0, 
                        tasktimeManagementTimestamp: new Date(formatAWSDateStr(param.timestamp)).valueOf()
                    }    
                },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });

            // 作成後のタスクタイムマネジメント管理データのIDを付与した状態でタスク詳細画面へ履歴なしでリダイレクト
            if(param.pcMode === PARAM_PCMODE_TRUE){
                history.replace(URL_PATH_TASKTIMES + URL_PARTS_SLASH + param.timestamp + URL_PARTS_SLASH + createRes.data.createTasktimeManagementDatas.id);
            }
            else{
                history.replace(URL_PATH_TASKTIMES_DETAIL + URL_PARTS_SLASH + param.timestamp + URL_PARTS_SLASH + createRes.data.createTasktimeManagementDatas.id);
            }
        } catch (e) {
            if (e.name === ERROR_TYPE_ABORTERROR) {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
        }
    }
    
    
    useEffect(() => {
        // マウント時処理
        // 初期化処理
        let abortCtrl = new AbortController();
        initTaskTimeDetail(INITIAL_QUERY);
        // アンマウント時処理
        return () => {
            abortCtrl.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header title={HEADER_TITLE_TASKTIMES}/>
            <Box className={commonStyle.mobileModeDisplay}>
                <Box className={commonStyle.pageContent} >
                    <LoadingIcon/>
                </Box>
            </Box>
            <SideMenu/>
            <Box className={commonStyle.pcModeDisplay}>
                <Box>
                    <LoadingIcon/>
                </Box>
            </Box>
            <Footer/>
        </Box>
    );
}