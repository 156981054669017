// AWS
import { API } from "aws-amplify";

// React
import { 
    useEffect, 
    useState, 
    useReducer, 
} from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { 
    Box,  
    Button,  
    Container,  
    FormControl,  
    FormGroup,  
    FormHelperText,  
    Grid, 
    InputLabel, 
    MenuItem, 
    Select, 
    TextField, 
    Typography, 
} from '@material-ui/core';
//import MuiPagination from '@material-ui/lab/Pagination';

// GraphQL
import { createTodayWords, updateChatrooms, updateCoachDatas, updateConectIsChatroomMember, updateConectIsCoachingToTargetTrainee, updateCustomerChatdatas, updateCustomerChatrooms, updateUserDatas } from '../../graphql/mutations';
import { listChatrooms, listCoachDatas, listConectIsChatroomMembers, listConectIsCoachingToTargetTrainees, listCustomerChatrooms, listTodayWords, listUserDatas } from "../../graphql/queries";
import { onCreateTodayWords, onUpdateChatrooms, onUpdateCoachDatas, onUpdateConectIsChatroomMember, onUpdateConectIsCoachingToTargetTrainee, onUpdateCustomerChatrooms, onUpdateUserDatas } from '../../graphql/subscriptions';

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { 
    AMAZON_COGNITO_USER_POOLS, 
    BUTTON_CAPTION_SAVE, 
    COACH_MASTER_MANAGEMENT_USER_LIST_NAME, 
    FORM_NAME_COGNITO_USER_ID, 
    HEADER_TITLE_COACH_MASTER_MANAGEMENT, 
    INITIAL_QUERY, 
    PROFILE_EDIT_CAPTION_APARTMENT_NAME, 
    PROFILE_EDIT_CAPTION_BANK_ACCOUNT_NAME, 
    PROFILE_EDIT_CAPTION_BANK_ACCOUNT_NUMBER, 
    PROFILE_EDIT_CAPTION_BANK_BRANCH_NAME, 
    PROFILE_EDIT_CAPTION_BANK_BRANCH_TYPE, 
    PROFILE_EDIT_CAPTION_BANK_NAME, 
    PROFILE_EDIT_CAPTION_BIRTH_DATE, 
    PROFILE_EDIT_CAPTION_COURCE_NAME, 
    PROFILE_EDIT_CAPTION_CWTV_NAME, 
    PROFILE_EDIT_CAPTION_DEPARTMENT_NAME, 
    PROFILE_EDIT_CAPTION_DISPLAY_NAME, 
    PROFILE_EDIT_CAPTION_EMERGENCY_PERSON_ADDRESS, 
    PROFILE_EDIT_CAPTION_EMERGENCY_PERSON_NAME, 
    PROFILE_EDIT_CAPTION_EMERGENCY_PERSON_TYPE, 
    PROFILE_EDIT_CAPTION_EMERGENCY_PHONE_NUMBER, 
    PROFILE_EDIT_CAPTION_FIRST_NAME, 
    PROFILE_EDIT_CAPTION_FIRST_NAME_KANA, 
    PROFILE_EDIT_CAPTION_GRADUATE_SCHOOL_COURSE_NAME, 
    PROFILE_EDIT_CAPTION_GRADUATE_SCHOOL_DEPARTMENT_NAME, 
    PROFILE_EDIT_CAPTION_GRADUATE_SCHOOL_GRADUATION_YEAR, 
    PROFILE_EDIT_CAPTION_GRADUATE_SCHOOL_NAME, 
    PROFILE_EDIT_CAPTION_GRADUATION_YEAR, 
    PROFILE_EDIT_CAPTION_HIGHSCHOOL_NAME, 
    PROFILE_EDIT_CAPTION_LAST_NAME, 
    PROFILE_EDIT_CAPTION_LAST_NAME_KANA, 
    PROFILE_EDIT_CAPTION_PREF_NAME, 
    PROFILE_EDIT_CAPTION_REMARKS, 
    PROFILE_EDIT_CAPTION_STREET_ADDRESS, 
    PROFILE_EDIT_CAPTION_UNIVERSITY_NAME, 
    PROFILE_EDIT_CAPTION_WEEKDAY_PHONE_NUMBER, 
    SUBSCRIPTION, 
    SUBSCRIPTION_UPDATE, 
    TODAYS_WORDS_MANAGEMENT_HEADER_TITLE, 
    TRAINEE_MASTER_MANAGEMENT_USER_LIST_NAME, 
    UPDATE, 
    USER_DATA_APARTMENT_NAME, 
    USER_DATA_BANK_ACCOUNT_NAME, 
    USER_DATA_BANK_ACCOUNT_NUMBER, 
    USER_DATA_BANK_ACCOUNT_TYPE, 
    USER_DATA_BANK_BRANCHE_NAME, 
    USER_DATA_BANK_NAME, 
    USER_DATA_BIRTH_DATE, 
    USER_DATA_COURSE_NAME, 
    USER_DATA_CWTV_NAME, 
    USER_DATA_DEPARTMENT_NAME, 
    USER_DATA_DISPLAY_NAME, 
    USER_DATA_EMERGENCY_PERSON_ADDRESS, 
    USER_DATA_EMERGENCY_PERSON_NAME, 
    USER_DATA_EMERGENCY_PERSON_TYPE, 
    USER_DATA_EMERGENCY_PHONE_NUMBER, 
    USER_DATA_FIRST_NAME, 
    USER_DATA_FIRST_NAME_KANA, 
    USER_DATA_GRADUATE_SCHOOL_COURSE_NAME, 
    USER_DATA_GRADUATE_SCHOOL_DEPARTMENT_NAME, 
    USER_DATA_GRADUATE_SCHOOL_GRADUATION_YEAR, 
    USER_DATA_GRADUATE_SCHOOL_NAME, 
    USER_DATA_GRADUATION_YEAR, 
    USER_DATA_HIGH_SCHOOL_NAME, 
    USER_DATA_LAST_NAME, 
    USER_DATA_LAST_NAME_KANA, 
    USER_DATA_PREF_NAME, 
    USER_DATA_STREET_ADDRESS, 
    USER_DATA_UNIVERSITY_NAME, 
    USER_DATA_USER_REMARKS, 
    USER_DATA_WEEKDAY_PHONE_NUMBER, 
    USER_ROLE 
} from '../../Commons/Consts';
import { 
    commonDBDataReducer, 
    userDataReducer, 
} from "../../Commons/Reducers";
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import SideMenu from "../../Commons/SideMenu";
import { 
    recursiveGrapheql, 
    getCurrentUserData, 
    consoleLog, 
    stringLengthForinVariableSettingValue, 
    validationHerfSizeNumber 
} from "../../Commons/CommonFunctions";
import { BorderBottom } from "@material-ui/icons";
import CommonDialog from "../../Commons/CommonDialog";

const localStyles = makeStyles(theme => ({
    avater:{ 
        width: 128, 
        height: 128
    },
    formFont:{
        '& .MuiTypography-h6':{
            fontSize: "4vmin",
        },
        '& .MuiTypography-h5':{
            fontSize: "5vmin",
        },
        '& .MuiInputBase-input':{
            fontSize: "3vmin",
        },
        '& .MuiOutlinedInput-input':{
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        '& .MuiSelect-select.MuiOutlinedInput-input':{
            paddingBottom: "4px",
        }
    },
    formSpan:{
        paddingTop: "5px",
        paddingBottom: "5px",
    },
    selectSpan:{
        paddingBottom: "10px",
        '& .MuiOutlinedInput-notchedOutline > legend, span':{
            maxWidth: "92px",
            width: "92px",
        },
    },
    controlSpan:{
        paddingBottom: "10px",
        '& .MuiInputBase-multiline':{
            paddingTop: "5px",
            paddingBottom: "5px",
        },
    },
    helperText:{
        paddingLeft: "3px",
        display: "flex",
        alignItems: "center",
    },
    centerContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonHerf:{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
    },
    alertBar: {
        top: "10vh",
    },
    input: {
        display: 'none',
    },
    statusString:{
        marginLeft: "5px",
    },
    selectArea:{
        padding: "10px",
        width: "100%",
        height: "20%",
        position: "absolute",
        overflow: "noscroll",
        borderBottom: "solid #666666 2px",
    },
    detailArea:{
        padding: "10px",
        width: "100%",
        height: "79%",
        position: "absolute",
        overflow: "auto",
        top: "20%",
    },
    avaterIcon:{ 
        width: 128, 
        height: 128,
    },
}));


export default function CoachMasterManagement(){
    const commonStyle = commonStyles();   
    const localStyle = localStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);

    const [userData, dispatchCurrentUserData] = useReducer(userDataReducer, []);
    const [userDatas, dispatchUserDatas] = useReducer(commonDBDataReducer, []);
    const [chatrooms, dispatchChatrooms] = useReducer(commonDBDataReducer, []);
    const [customerChatrooms, dispatchCustomerChatrooms] = useReducer(commonDBDataReducer, []);
    const [coachDatas, dispatchCoachDatas] = useReducer(commonDBDataReducer, []);
    const [traineeChatroomMembers, dispatchTraineeChatroomMembers] = useReducer(commonDBDataReducer, []);
    const [coachingTraineeDatas, dispatchCoachingTraineeDatas] = useReducer(commonDBDataReducer, []);
    const [updateStatus, setUpdateStatus] = useState(false);
    const [updateCognitoId, setUpdateCognitoId] = useState('');
    const [selectCoachCognitoId, setSelectCoachCognitoId] = useState('');
    //const [page, setPage] = useState(1);

    const initCoachMasterManagement = async () =>{
        try{
            const currentUserData = await getCurrentUserData();
            dispatchCurrentUserData({ type: INITIAL_QUERY, userData: currentUserData });
            
            await recursiveGrapheql(listUserDatas, 'listUserDatas', { limit:100000 , nextToken: null}, dispatchUserDatas);
            await recursiveGrapheql(listChatrooms, 'listChatrooms', { limit:100000 , nextToken: null}, dispatchChatrooms);
            await recursiveGrapheql(listCustomerChatrooms, 'listCustomerChatrooms', { limit:100000 , nextToken: null}, dispatchCustomerChatrooms);
            await recursiveGrapheql(listCoachDatas, 'listCoachDatas', { limit:100000 , nextToken: null}, dispatchCoachDatas);
            await recursiveGrapheql(listConectIsChatroomMembers, 'listConectIsChatroomMembers', { limit:100000 , nextToken: null}, dispatchTraineeChatroomMembers);
            await recursiveGrapheql(listConectIsCoachingToTargetTrainees, 'listConectIsCoachingToTargetTrainees', { limit:100000 , nextToken: null}, dispatchCoachingTraineeDatas);
            
            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }
    
    const updateProfile = async () =>{
        const updUser = userDatas.find((data) => data.userId === selectCoachCognitoId);
        const updUserRes = await API.graphql({
            query: updateUserDatas,
            variables: {
                input: {
                    id: updUser.id,
                    userId: updateCognitoId,
                }, 
            },
        });
        
        const updCoach = coachDatas.find((data) => data.userdatasID === selectCoachCognitoId);
        const updCoachRes = await API.graphql({
            query: updateCoachDatas,
            variables: {
                input: {
                    id: updCoach.id,
                    userdatasID: updateCognitoId,
                }, 
            },
        });
        
        const updChatrooms = chatrooms.filter((data) => data.roomMasterUserDatasID === selectCoachCognitoId);
        for(const updChatroom of updChatrooms){
            const updChatroomRes = await API.graphql({
                query: updateChatrooms,
                variables: {
                    input: {
                        id: updChatroom.id,
                        roomMasterUserDatasID: updateCognitoId,
                    }, 
                },
            });
            const csRooms = customerChatrooms.filter((room) => room.chatroomsID === updChatroom.id);
            if(0 < csRooms.length){
                const updCusChatroom = csRooms.at(0);
                const updCusChatroomRes = await API.graphql({
                    query: updateCustomerChatrooms,
                    variables: {
                        input: {
                            id: updCusChatroom.id,
                            userdatasID: updateCognitoId,
                        }, 
                    },
                });
            }
        }

        const updChatroomMembers = traineeChatroomMembers.filter((data) => data.chatroomMemberUserDatasID === selectCoachCognitoId);
        if(0 < updChatroomMembers.length){
            for(const updChatroomMember of updChatroomMembers){
                const updChatroomMemberRes = await API.graphql({
                    query: updateConectIsChatroomMember,
                    variables: {
                        input: {
                            id: updChatroomMember.id,
                            chatroomMemberUserDatasID: updateCognitoId,
                        }, 
                    },
                });
            }
        }else{
            consoleLog(userData.userRole, 'updChatroomMember is not found');
        }

        const updTargetTrainees = coachingTraineeDatas.filter((data) => data.coachdatasID === selectCoachCognitoId);
        if(0 < updTargetTrainees.length){
            for(const updTargetTrainee of updTargetTrainees){
                const updTargetTraineeRes = await API.graphql({
                    query: updateConectIsCoachingToTargetTrainee,
                    variables: {
                        input: {
                            id: updTargetTrainee.id,
                            coachdatasID: updateCognitoId,
                        }, 
                    },
                });
            }
        }else{
            consoleLog(userData.userRole, 'updTargetTrainee is not found');
        }

        setSelectCoachCognitoId('');
        setUpdateCognitoId('');
        setUpdateStatus(false);
    }
    
    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();

        initCoachMasterManagement();

        const updateUserDatasSubsc = API.graphql({
            query:onUpdateUserDatas,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
        next: (msg) => {
            const data = msg.value.data.onUpdateUserDatas;
            dispatchUserDatas({ type: SUBSCRIPTION_UPDATE, data: data });
        }});

        const updateCoachDatasSubsc = API.graphql({
            query:onUpdateCoachDatas,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
        next: (msg) => {
            const data = msg.value.data.onUpdateCoachDatas;
            dispatchCoachDatas({ type: SUBSCRIPTION_UPDATE, data: data });
        }});

        const updateChatroomsSubsc = API.graphql({
            query:onUpdateChatrooms,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
        next: (msg) => {
            const data = msg.value.data.onUpdateChatrooms;
            dispatchChatrooms({ type: SUBSCRIPTION_UPDATE, data: data });
        }});

        const updateCusromterChatroomsSubsc = API.graphql({
            query:onUpdateCustomerChatrooms,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
        next: (msg) => {
            const data = msg.value.data.onUpdateCustomerChatrooms;
            dispatchCustomerChatrooms({ type: SUBSCRIPTION_UPDATE, data: data });
        }});

        const updateChatroomMemberSubsc = API.graphql({
            query:onUpdateConectIsChatroomMember,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
        next: (msg) => {
            const data = msg.value.data.onUpdateConectIsChatroomMember;
            dispatchTraineeChatroomMembers({ type: SUBSCRIPTION_UPDATE, data: data });
        }});

        const updateConnectSubsc = API.graphql({
            query:onUpdateConectIsCoachingToTargetTrainee,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
        next: (msg) => {
            const data = msg.value.data.onUpdateConectIsCoachingToTargetTrainee;
            dispatchCoachingTraineeDatas({ type: SUBSCRIPTION_UPDATE, data: data });
        }});

        return () => {
            abortCtrl.abort()
            updateUserDatasSubsc.unsubscribe();
            updateCoachDatasSubsc.unsubscribe();
            updateChatroomsSubsc.unsubscribe();
            updateCusromterChatroomsSubsc.unsubscribe();
            updateChatroomMemberSubsc.unsubscribe();
            updateConnectSubsc.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header title={HEADER_TITLE_COACH_MASTER_MANAGEMENT}/>
            <SideMenu/>
            {isLoading ?
            <LoadingIcon/>
            :
            <Box className={commonStyle.pageContent}>
                <Box pt={2}/>
                <Container>
                    <Grid container>
                        <Grid item xs={6}>
                            <FormControl 
                                variant="outlined"
                                fullWidth
                            >
                                <InputLabel shrink id="outlined-grauation-year">{COACH_MASTER_MANAGEMENT_USER_LIST_NAME}</InputLabel>
                                <Select
                                    label={COACH_MASTER_MANAGEMENT_USER_LIST_NAME}
                                    labelId="outlined-grauation-year"
                                    id='select-grauationyear'
                                    onChange={(event) => {
                                        const data = coachDatas.filter((data)=> data.userdatasID === event.target.value).at(0);
                                        setSelectCoachCognitoId(data.userdatasID);
                                        setUpdateCognitoId(data.userdatasID);
                                        setUpdateStatus(false);
                                    }}
                                >
                                    {userDatas.filter((data) => data.userRole === USER_ROLE.Coach)
                                    .sort((a,b) => a.lastNameKana < b.lastNameKana ? -1 : 1)
                                    .map((user) => {
                                        return (<MenuItem value={user.userId}>{user.lastName}{user.firstName}({user.displayName})</MenuItem>)})
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={FORM_NAME_COGNITO_USER_ID}
                                labelId="outlined-grauation-year"
                                id='select-grauationyear'
                                variant="outlined" 
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={updateCognitoId}
                                onChange={(event) => {
                                    setUpdateCognitoId(event.target.value);
                                    if(selectCoachCognitoId !== event.target.value){
                                        setUpdateStatus(true);
                                    }
                                    else{
                                        setUpdateStatus(false);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid className={commonStyle.marginTop}>
                            <Button 
                                variant="contained" 
                                color="primary"
                                onClick={() => {setOpen(true);}}
                            >
                                {BUTTON_CAPTION_SAVE}
                            </Button>
                            <span className={localStyle.statusString}>{updateStatus? '属性の変更があります。保存してください。':''}</span>
                        </Grid> 
                    </Grid>
                </Container>
                <Box className={commonStyle.endBox}/>
            </Box>
            }
            <Footer/>
            <CommonDialog
                agreeEvent={updateProfile}
                open={open}
                setOpen={setOpen}
                caption="CognitoID変更"
                contentText="不整合なIDの場合はエラーを引き起こしますが、変更してもよろしいですか？"
                cancelText="実施しない"
                agreeText="実施する"
            />
        </Box>
    );
}
