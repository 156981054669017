import { Amplify, I18n } from 'aws-amplify';
import { Authenticator, translations, withInAppMessaging } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import PageContents from './Commons/PageContents';
import { theme } from './Commons/CommonStyles';

import awsExports from './aws-exports';
import { createContext, useState } from 'react';
Amplify.configure(awsExports);

export const pcChatroomContext = createContext();

const localStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

I18n.putVocabularies(translations);
I18n.setLanguage('ja');

function App() {
    const localStyle = localStyles();
    const [testchatroomId, settestChatroomId] = useState();
    return (
        <Authenticator signUpAttributes={['email']}>
            {({ signOut, user }) => (
                <div className={localStyle.root} >
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <pcChatroomContext.Provider value={{testchatroomId, settestChatroomId}}>
                        <PageContents/>
                    </pcChatroomContext.Provider>
                </ThemeProvider>
                </div>
            )}
        </Authenticator>
    );
}

export default App;
