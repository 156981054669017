// AWS
import { API } from "aws-amplify";

// React
import { 
    useState, 
    Fragment, 
} from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { 
    Avatar, 
    Box,  
    Grid, 
    IconButton, 
    Paper, 
    TextField, 
    Typography, 
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ClearIcon from '@material-ui/icons/Clear';

// GraphQL
import { createChatDatas, createChatNotificationDatas, updateChatrooms } from '../../graphql/mutations';

// Self Making Files
import { AMAZON_COGNITO_USER_POOLS } from '../../Commons/Consts';
import { 
    setStorageFileObject, 
    getCurrentUserData, 
    consoleLog, 
} from "../../Commons/CommonFunctions";
import PreviewImageDialog from "../../Commons/PreviewImageDialog";
import { commonStyles } from "../../Commons/CommonStyles";

const localStyles = makeStyles(theme => ({
    drawer:{
        backgroundColor: theme.palette.background.paper,
        border: "solid #0A0A0A 1px",
        width: "99.5%",
        bottom: "58px",
        position: 'fixed',
        paddingTop: "2vh",
        zIndex: "drawer",
    },
    imageOnDrawer:{
        backgroundColor: theme.palette.background.paper,
        border: "solid #0A0A0A 1px",
        width: "99.5%",
        bottom: "58px",
        position: 'fixed',
        paddingTop: "2vh",
        paddingBottom: "2vh",
        zIndex: "drawer",
    },
    pcDrawer:{
        width: "100%",
        height: "100%",
        paddingTop: "2vh",
        backgroundColor: theme.palette.background.paper,
        zIndex: "drawer",
        '& .MuiOutlinedInput-inputMultiline':{
            minHeight: "8vh",
            maxHeight: "8vh",
        },
        },
    pcImageOnDrawer:{
        width: "100%",
        height: "100%",
        paddingTop: "1vh",
        backgroundColor: theme.palette.background.paper,
        zIndex: "drawer",
        '& .MuiOutlinedInput-inputMultiline':{
            minHeight: "4vh",
            maxHeight: "4vh",
        },
    },
    input:{
        display:"none",
    },
    messegeTextarea: {
        [theme.breakpoints.down('sm')]: {
            '& .MuiInputBase-root':{
                paddingTop: "5px",
                paddingBottom: "5px",
                '& .MuiInputBase-input':{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                },
            },
            '& #message-editor':{
                fontSize: "3vmin",
            },
        },
        [theme.breakpoints.up('md')]: {
            '& .MuiInputBase-root':{
                paddingTop: "5px",
                paddingBottom: "5px",
                '& .MuiInputBase-input':{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                },
            },
            '& #message-editor':{
                fontSize: "2vmin",
            },
            paddingBottom: "5px",
        },
    },
    sendButton:{
        [theme.breakpoints.down('sm')]: {
            paddingRight: "5px",
        },
        [theme.breakpoints.up('md')]: {
            paddingRight: "20px",
        },
    },
    previewImageCardRoot:{
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            flexDirection: "row",
            alignContent: "flex-start",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            alignItems: "center",
            border: "2px dashed #555555",
            padding: "5px",
            borderRadius: "5px",
            marginBottom: "10px",
        },
        [theme.breakpoints.up('md')]: {
            display: "flex",
            flexDirection: "row",
            alignContent: "flex-start",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            alignItems: "center",
            border: "2px dashed #555555",
            padding: "3px",
            borderRadius: "5px",
            marginBottom: "10px",
            "& .MuiIconButton-root":{
                padding: "3px",
                width: "24px",
                height: "24px",
            }
        },
    },
    previewImageCard:{
        display: "flex",
        flexDirection: "row",
        alignContent: "flex-start",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "hidden",
    },
    previewImage:{
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            paddingLeft: "2px",
            paddingBottom: "3px",
            paddingRight: "2px",
            width: "96px",
            height: "96px",
            cursor: "pointer",
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            paddingLeft: "2px",
            paddingBottom: "3px",
            paddingRight: "2px",
            width: "24px",
            height: "24px",
            cursor: "pointer",
        },
    },
    previewImageCaption:{
        [theme.breakpoints.down('sm')]: {
            paddingLeft: "2px",
            paddingRight: "2px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "90%",
            '& .MuiTypography-h6':{
                fontSize: "3vmin",
            },
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: "2px",
            paddingRight: "2px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
            '& .MuiTypography-h6':{
                fontSize: "2vmin",
            },
        },
    },
}));

export default function ChatMessageSender({ chatroomId, sendFromUserData, chatroomName = '' }){
    const commonStyle = commonStyles();   
    const localStyle = localStyles();

    const [messageData, setMessageData] = useState('');
    const [messageSending, setMessageSending] = useState(true);
    
    const [fileObject, setFileObject] = useState(undefined);
    const [previewImage, setPreviewImage] = useState(undefined);
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const sendChatMessage = async () =>{
        try{
            if(messageSending){
                // メッセージとして処理出来るかを確認
                setMessageSending(false);
                var fileKey = null
                if(fileObject !== void 0){
                    const fileUploadRes = await setStorageFileObject(fileObject);
                    fileKey = fileUploadRes.key;
                }
                else{
                    if(!messageData){
                        setMessageSending(true);
                        return;
                    }
                }

                // メッセージデータを作成
                const sendDT = new Date();
                const sendTS = new Date().valueOf();
                await API.graphql({
                    query: createChatDatas,
                    variables: {
                        input: {
                            SendFromID: sendFromUserData.userId,
                            chatDateTime: sendDT,
                            chatTimestamp: sendTS,
                            chatMessage: messageData, 
                            chatOnImage: fileKey,
                            chatroomID: chatroomId,
                            checkOn: false,
                            chatDataStatus: 0,
                        }
                    },
                    authMode: AMAZON_COGNITO_USER_POOLS,
                });

                // チャットルーム単体の送信ステータスを変更
                var updateSendReadStatus = 0;
                var createCsStatus = 0;
                if(sendFromUserData.userRole <= 10){
                    updateSendReadStatus = 48;
                }
                else if(10 < sendFromUserData.userRole && sendFromUserData.userRole <=19){
                    updateSendReadStatus = 3;
                    createCsStatus = 1;
                }
                else{
                    updateSendReadStatus = 12;
                    createCsStatus = 2;
                }
                await API.graphql({
                    query: updateChatrooms,
                    variables: {
                        input: {
                            id: chatroomId,
                            lastMessage: messageData,
                            unReadFlg: updateSendReadStatus,
                            sendFromDateTime: sendDT,
                            sendFromTimestamp: sendTS,
                        }        
                    },
                    authMode: AMAZON_COGNITO_USER_POOLS,
                });

                await API.graphql({
                    query: createChatNotificationDatas,
                    variables: {
                        input: {
                            csStatus: createCsStatus, 
                            sendMessage: messageData,
                            sendFromUserdatasID: sendFromUserData.userId, 
                            sendFromUserdatasName: sendFromUserData.lastName + " " + sendFromUserData.firstName, 
                            sendFromStatus: 0, 
                            sendToStatus: 0, 
                            sendToUserdatasID: "", 
                            sendToUserdatasName: "",
                            chatroomName: chatroomName,
                            chatNotificationDatasChatroomsId: chatroomId,
                        },
                    },
                    authMode: AMAZON_COGNITO_USER_POOLS,
                });

                // メッセージ初期化
                setMessageData('');
                setFileObject(undefined);
                setPreviewImage(undefined);
                setMessageSending(true);
            }
        }
        catch(error){
            consoleLog(sendFromUserData.userRole, error);
            setMessageSending(true);
        }
    }

    const onChangeIconImage = async (event) => {
        const fileObject = event.target.files[0];
        setFileObject(fileObject);
        var file = window.URL.createObjectURL(fileObject);
        setPreviewImage(file);
    }

    const onClickPreviewDelete = async (event) => {
        setFileObject(undefined);
        setPreviewImage(undefined);
    }

    const messageInputChange = (event) =>{
        setMessageData(event.target.value)
    };

    // Safariでのアップロードボタン不具合解決策試し
    const onClickFileAddButton = (event) =>{
        event.target.value = "";
    }

    return(
        <Box>
            <Box className={commonStyle.mobileModeDisplay}>
            <Paper className={fileObject === void 0 ? localStyle.drawer : localStyle.imageOnDrawer}>
                <Grid container spacing={0}>
                    {fileObject === void 0 ? 
                    <Fragment></Fragment>
                    : 
                    <Grid container xs={12}>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={10}>
                            <Box className={localStyle.previewImageCardRoot}>
                                <Box onClick={handleOpen} component="span" className={localStyle.previewImageCard}>
                                    <Avatar 
                                        variant="square" 
                                        src={previewImage}
                                        className={localStyle.previewImage}  
                                    />
                                    <Box className={localStyle.previewImageCaption} >
                                        <Typography variant="h6">
                                            {fileObject.name}
                                        </Typography>
                                    </Box>
                                </Box>
                                <IconButton 
                                    component="span" 
                                    onClick={onClickPreviewDelete}
                                >
                                    <ClearIcon/>
                                </IconButton>
                                <PreviewImageDialog
                                    open={open} 
                                    setOpen={setOpen} 
                                    handleClose={handleClose} 
                                    caption={fileObject.name}
                                    src={previewImage}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                    </Grid>
                    }   
                    <Grid container xs={12}>
                        <Grid item xs={1} className={commonStyle.flexStartCenterContents}>
                            <input 
                                accept="image/*, application/pdf, application/msword" 
                                className={localStyle.input} 
                                id="icon-button-file" 
                                type="file" 
                                value="" 
                                onChange={onChangeIconImage}
                                onClick={onClickFileAddButton}/>
                            <label htmlFor="icon-button-file">
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                    <AddBoxIcon/>
                                </IconButton>
                            </label>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                id="message-editor"
                                fullWidth
                                multiline
                                className={localStyle.messegeTextarea}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                minRows={1}
                                maxRows={4}
                                label="メッセージ内容"
                                value={messageData}
                                onChange={messageInputChange}
                            />                        
                        </Grid>
                        <Grid item xs={1} className={commonStyle.rightContents}>
                            <IconButton
                                edge="start"
                                color="primary"
                                aria-label="menu"
                                className={localStyle.sendButton}
                                onClick={sendChatMessage}
                            >
                                <SendIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            </Box>
            <Box className={commonStyle.pcModeDisplay}>
                <Box className={fileObject === void 0 ? localStyle.pcDrawer : localStyle.pcImageOnDrawer}>
                    <Grid container spacing={0}>
                        {fileObject === void 0 ? 
                        <Fragment></Fragment>
                        : 
                        <Grid container xs={12}>
                            <Grid item xs={1}>
                            </Grid>
                            <Grid item xs={10}>
                                <Box className={localStyle.previewImageCardRoot}>
                                    <Box onClick={handleOpen} component="span" className={localStyle.previewImageCard}>
                                        <Avatar 
                                            variant="square" 
                                            src={previewImage}
                                            className={localStyle.previewImage}  
                                        />
                                        <Box className={localStyle.previewImageCaption} >
                                            <Typography variant="h6">
                                                {fileObject.name}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <IconButton 
                                        component="span" 
                                        onClick={onClickPreviewDelete}
                                    >
                                        <ClearIcon fontSize="large" />
                                    </IconButton>
                                    <PreviewImageDialog
                                        open={open} 
                                        setOpen={setOpen} 
                                        handleClose={handleClose} 
                                        caption={fileObject.name}
                                        src={previewImage}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={1}>
                            </Grid>
                        </Grid>
                        }   
                        <Grid container xs={12}>
                            <Grid item xs={1} className={commonStyle.flexStartCenterContents}>
                                <input 
                                    accept="image/*, application/pdf, application/msword" 
                                    className={localStyle.input} 
                                    id="icon-button-file" 
                                    type="file" 
                                    value="" 
                                    onChange={onChangeIconImage}
                                    onClick={onClickFileAddButton}/>
                                <label htmlFor="icon-button-file">
                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                        <AddBoxIcon fontSize="large" />
                                    </IconButton>
                                </label>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    id="message-editor"
                                    fullWidth
                                    multiline
                                    className={localStyle.messegeTextarea}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    label="メッセージ内容"
                                    value={messageData}
                                    onChange={messageInputChange}
                                />                        
                            </Grid>
                            <Grid item xs={1} className={commonStyle.rightContents}>
                                <IconButton
                                    edge="start"
                                    color="primary"
                                    aria-label="menu"
                                    className={localStyle.sendButton}
                                    onClick={sendChatMessage}
                                >
                                    <SendIcon fontSize="large" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}
