// AWS
import { API } from "aws-amplify";

// React
import { 
    useEffect, 
    useState, 
    useReducer,
    Fragment, 
} from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { 
    Box,  
    Button,  
    Container,  
    FormControl,  
    Grid, 
    InputLabel, 
    MenuItem, 
    Select, 
    TextField, 
    Typography, 
} from '@material-ui/core';
//import MuiPagination from '@material-ui/lab/Pagination';

// GraphQL
import { 
    createAbuildSheetsDatas, 
    createChatrooms, 
    createConectIsChatroomMember, 
    createCustomerChatrooms, 
    updateAbuildSheetsDatas, 
    updateCoachDatas, 
    updateConectIsCoachingToTargetTrainee
} from '../../graphql/mutations';
import { 
    listAbuildSheetsDatas, 
    listCoachDatas, 
    listConectIsCoachingToTargetTrainees, 
    listCustomerChatrooms, 
    listInitChatroomDatas, 
    listTraineeDatas, 
    listUserDatas 
} from "../../graphql/queries";
import { onCreateAbuildSheetsDatas, onUpdateAbuildSheetsDatas, onUpdateCoachDatas, onUpdateConectIsCoachingToTargetTrainee, onUpdateUserDatas } from '../../graphql/subscriptions';

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { 
    AMAZON_COGNITO_USER_POOLS, 
    CustomerChatroomTypes, 
    HEADER_TITLE_COACH_ASSGIN_MANAGEMENT, 
    INITIAL_QUERY, 
    SUBSCRIPTION, 
    SUBSCRIPTION_UPDATE, 
    USER_ROLE, 
    USER_TYPE 
} from '../../Commons/Consts';
import { 
    commonDBDataReducer, 
    userDataReducer, 
} from "../../Commons/Reducers";
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import SideMenu from "../../Commons/SideMenu";
import { 
    getCurrentUserData, 
    recursiveGrapheql,
    consoleLog, 
} from "../../Commons/CommonFunctions";
import CommonDialog from "../../Commons/CommonDialog";

const localStyles = makeStyles(theme => ({
    avater:{ 
        width: 128, 
        height: 128
    },
    formFont:{
        '& .MuiTypography-h6':{
            fontSize: "4vmin",
        },
        '& .MuiTypography-h5':{
            fontSize: "5vmin",
        },
        '& .MuiInputBase-input':{
            fontSize: "3vmin",
        },
        '& .MuiOutlinedInput-input':{
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        '& .MuiSelect-select.MuiOutlinedInput-input':{
            paddingBottom: "4px",
        }
    },
    formSpan:{
        paddingTop: "5px",
        paddingBottom: "5px",
    },
    selectSpan:{
        paddingBottom: "10px",
        '& .MuiOutlinedInput-notchedOutline > legend, span':{
            maxWidth: "92px",
            width: "92px",
        },
    },
    controlSpan:{
        paddingBottom: "10px",
        '& .MuiInputBase-multiline':{
            paddingTop: "5px",
            paddingBottom: "5px",
        },
    },
    helperText:{
        paddingLeft: "3px",
        display: "flex",
        alignItems: "center",
    },
    centerContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonHerf:{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
    },
    alertBar: {
        top: "10vh",
    },
    input: {
        display: 'none',
    },
    statusString:{
        marginLeft: "5px",
    },
    selectArea:{
        padding: "10px",
        width: "100%",
        height: "20%",
        position: "absolute",
        overflow: "noscroll",
        borderBottom: "solid #666666 2px",
    },
    detailArea:{
        padding: "10px",
        width: "100%",
        height: "79%",
        position: "absolute",
        overflow: "auto",
        top: "20%",
    },
    avaterIcon:{ 
        width: 128, 
        height: 128,
    },
}));


export default function ConnectManagement(){
    const commonStyle = commonStyles();   
    const localStyle = localStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [coachAssignOpen, setCoachAssignOpen] = useState(false);
    const [abuildSheetsOpen, setAbuildSheetsOpen] = useState(false);
    const [abuildSheetsUpdateStatus, setAbuildSheetsUpdateStatus] = useState(false);
    const [managementSheetsOpen, setManagementSheetsOpen] = useState(false);
    const [managementSheetsUpdateStatus, setManagementSheetsUpdateStatus] = useState(false);
    const [currentUserData, setCurrentUserData] = useState([]);
    
    const [userData, dispatchCurrentUserData] = useReducer(userDataReducer, []);
    const [userDatas, dispatchUserDatas] = useReducer(commonDBDataReducer, []);
    const [traineeDatas, dispatchTraineeDatas] = useReducer(commonDBDataReducer, []);
    const [coachDatas, dispatchCoachDatas] = useReducer(commonDBDataReducer, []);
    const [customerChatrooms, dispatchCustomerChatrooms] = useReducer(commonDBDataReducer, []);
    const [coachingTraineeDatas, dispatchCoachingTraineeDatas] = useReducer(commonDBDataReducer, []);
    const [abuildSheetsURLs, dispatchAbuildSheetsURLs] = useReducer(commonDBDataReducer, []);
    const [selectConnectData, setSelectConnectData] = useState([]);
    const [selectTraineeUserData, setSelectTraineeUserData] = useState([]);
    const [selectCoachUserData, setSelectCoachUserData] = useState([]);
    const [selectCoachData, setSelectCoachData] = useState([]);
    const [beforeCoachName, setBeforeCoachName] = useState('');
    const [afterrCoachName, setAfterCoachName] = useState('');
    const [selectUserCoachingChatroom, setSelectUserCoachingChatroom] = useState([]);
    const [selectUserAbuildSheetsURLs, setSelectUserAbuildSheetsURLs] = useState([]);
    const [selectUserEditAbuildSheetsURL, setSelectUserEditAbuildSheetsURL] = useState('');
    const [selectCoachCoachingManagementSheetsURL, setSelectCoachCoachingManagementSheetsURL] = useState('');
    const [initCSUserId, setInitCSUserId] = useState('');
    const [coachEMailAddress, setCoachEMailAddress] = useState('');
    //const [page, setPage] = useState(1);

    const initConnectManagement = async () =>{
        try{
            const user = await getCurrentUserData();
            setCurrentUserData(user);
            dispatchCurrentUserData({ type: INITIAL_QUERY, userData: user });
            await recursiveGrapheql(listUserDatas, 'listUserDatas', { limit:100000 , nextToken: null}, dispatchUserDatas);
            await recursiveGrapheql(listTraineeDatas, 'listTraineeDatas', { limit:100000 , nextToken: null}, dispatchTraineeDatas);
            await recursiveGrapheql(listCoachDatas, 'listCoachDatas', { limit:100000 , nextToken: null}, dispatchCoachDatas);
            await recursiveGrapheql(listCustomerChatrooms, 'listCustomerChatrooms', { limit:100000 , nextToken: null}, dispatchCustomerChatrooms);
            await recursiveGrapheql(listConectIsCoachingToTargetTrainees, 'listConectIsCoachingToTargetTrainees', { limit:100000 , nextToken: null}, dispatchCoachingTraineeDatas);
            await recursiveGrapheql(listAbuildSheetsDatas, 'listAbuildSheetsDatas', { limit:100000 , nextToken: null}, dispatchAbuildSheetsURLs);
            const opRoomInitFilter = {
                initChatroomType: {eq: CustomerChatroomTypes.CUSTOMER_SUCCESS}
            }
            const opRoomInitDataRes = await API.graphql({
                query: listInitChatroomDatas,
                variables: { filter: opRoomInitFilter,limit: 100000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            setInitCSUserId(opRoomInitDataRes.data.listInitChatroomDatas.items.at(0).initMemberUserdatsID);
            
            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }
    
    const updateCoachingConnect = async () =>{
        const res = await API.graphql({
            query: updateConectIsCoachingToTargetTrainee,
            variables: {
                input: {
                    id: selectConnectData.id,
                    coachdatasID: selectCoachUserData.userId,
                }, 
            },
        });
        consoleLog(userData.userRole, res);
            
        const coachingRoomCreRes = await API.graphql({
            query: createChatrooms,
            variables: {
                input: {
                    roomMasterUserDatasID : selectTraineeUserData.userId,
                    lastMessage: "",
                    roomMasterName: selectTraineeUserData.lastName + " " + selectTraineeUserData.firstName,
                    unReadFlg: 0,
                    sendFromTimestamp: 0,
                    sendToTimestamp: 0,
                }
            }
        });
        const coachingRoomId = coachingRoomCreRes.data.createChatrooms.id;
        await API.graphql({
            query: createConectIsChatroomMember,
            variables: {
                input: {
                    chatroomMemberUserDatasID : selectCoachUserData.userId,
                    chatroomID : coachingRoomId,
                }
            }
        });
        await API.graphql({
            query: createCustomerChatrooms,
            variables: {
                input: {
                    chatroomsID: coachingRoomId, 
                    roomType: CustomerChatroomTypes.COCHING_CHAT,
                    userdatasID: selectTraineeUserData.userId, 
                    tag: "",
                    userdatasType: USER_TYPE.TRAINEE,
                    roomMasterName: selectTraineeUserData.lastName + " " + selectTraineeUserData.firstName,
                }
            }
        });

        setSelectTraineeUserData([]);
        setSelectConnectData([]);
        setBeforeCoachName(selectCoachUserData.lastName + ' ' + selectCoachUserData.firstName + '(' + selectCoachUserData.displayName + ')');
        setSelectUserCoachingChatroom([]);
        setSelectUserAbuildSheetsURLs([]);
    }

    const updateOrCreateAbuildSheetsURLAgreeEvent = async () =>{
        try{
            if(0 < selectUserAbuildSheetsURLs.length){
                const res = await API.graphql({
                    query: updateAbuildSheetsDatas,
                    variables: {
                        input: {
                            id: selectUserAbuildSheetsURLs.at(0).id,
                            abuildSheetsUrl: selectUserEditAbuildSheetsURL,
                        }, 
                    },
                });
                consoleLog(userData.userRole, res);
            }
            else{
                const res = await API.graphql({
                    query: createAbuildSheetsDatas,
                    variables: {
                        input: {
                            abuildSheetsUrl: selectUserEditAbuildSheetsURL,
                            captionTitle: selectTraineeUserData.lastName + selectTraineeUserData.firstName + 'のAbuild戦略特訓シート',
                            userDatasID: selectTraineeUserData.userId,
                            weeklyNumber: 1,
                        }, 
                    },
                });
                consoleLog(userData.userRole, res);
            }
            setAbuildSheetsUpdateStatus(false);
        }
        catch(e){
            consoleLog(userData.userRole, e);
            alert("保存できない形式のデータです。");
        }
    }

    const updateCoachDataToManagementSheetsURL = async () =>{
        try{
            const res = await API.graphql({
                query: updateCoachDatas,
                variables: {
                    input: {
                        id: selectCoachData.id,
                        coachManageSheetUrl: selectCoachCoachingManagementSheetsURL,
                    }, 
                },
            });
            consoleLog(userData.userRole, res);
            setManagementSheetsUpdateStatus(false);
        }catch(e){
            consoleLog(userData.userRole, e);
            alert("保存できない形式のデータです。");
        }
    }

    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();

        initConnectManagement();

        const updateConnectSubsc = API.graphql({
            query:onUpdateConectIsCoachingToTargetTrainee,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
        next: (msg) => {
            const data = msg.value.data.onUpdateConectIsCoachingToTargetTrainee;
            dispatchCoachingTraineeDatas({ type: SUBSCRIPTION_UPDATE, data: data });
        }});

        const createAbuildSheetsURLSubsc = API.graphql({
            query:onCreateAbuildSheetsDatas,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
        next: (msg) => {
            const data = msg.value.data.onCreateAbuildSheetsDatas;
            dispatchAbuildSheetsURLs({ type: SUBSCRIPTION, data: data });
        }});

        const updateAbuildSheetsURLSubsc = API.graphql({
            query:onUpdateAbuildSheetsDatas,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
        next: (msg) => {
            const data = msg.value.data.onUpdateAbuildSheetsDatas;
            dispatchAbuildSheetsURLs({ type: SUBSCRIPTION_UPDATE, data: data });
        }});

        const updateCoachDatasSubsc = API.graphql({
            query:onUpdateCoachDatas,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
        next: (msg) => {
            const data = msg.value.data.onUpdateCoachDatas;
            dispatchCoachDatas({ type: SUBSCRIPTION_UPDATE, data: data });
        }});

        return () => {
            abortCtrl.abort()
            updateConnectSubsc.unsubscribe();
            createAbuildSheetsURLSubsc.unsubscribe();
            updateAbuildSheetsURLSubsc.unsubscribe();
            updateCoachDatasSubsc.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header title={HEADER_TITLE_COACH_ASSGIN_MANAGEMENT} />
            <SideMenu/>
            {isLoading ?
            <LoadingIcon/>
            :
            <Box className={commonStyle.pageContent}>
                <Box pt={2}/>
                <Container>
                    <Grid container className={commonStyle.marginBottom}>
                        <Grid item xs={6}>
                            <FormControl 
                                variant="outlined"
                                fullWidth
                            >
                                <InputLabel shrink id="outlined-grauation-year">対象トレーニー選択</InputLabel>
                                <Select
                                    label="対象トレーニー選択"
                                    labelId="outlined-grauation-year"
                                    id='select-grauationyear'
                                    onChange={(event) => {
                                        const selectUserData = userDatas.filter((data)=> data.userId === event.target.value).at(0);
                                        const selectConnectData = coachingTraineeDatas.filter((data) => data.traineedatasID === event.target.value).at(0);
                                        const selectConnectCoachdata = userDatas.filter((data)=> data.userId === selectConnectData.coachdatasID).at(0);
                                        setSelectTraineeUserData(selectUserData);
                                        setSelectConnectData(selectConnectData);
                                        if(selectConnectCoachdata.userId === initCSUserId){
                                            setBeforeCoachName('');
                                        }
                                        else{
                                            setBeforeCoachName(selectConnectCoachdata.lastName + ' ' + selectConnectCoachdata.firstName + '(' + selectConnectCoachdata.displayName + ')');
                                        }

                                        const coachingChatroom = customerChatrooms.filter((data) => data.userdatasID === selectUserData.userId).filter((data) => data.roomType === CustomerChatroomTypes.COCHING_CHAT);
                                        if(0 < coachingChatroom.length){
                                            setSelectUserCoachingChatroom(coachingChatroom);
                                        }
                                        else{
                                            setSelectUserCoachingChatroom([]);
                                        }

                                        const selectUserabuildSheetsURL = abuildSheetsURLs.filter((data)=> data.userDatasID === selectUserData.userId);
                                        if(0 < selectUserabuildSheetsURL.length){
                                            setSelectUserAbuildSheetsURLs(selectUserabuildSheetsURL);
                                            setSelectUserEditAbuildSheetsURL(selectUserabuildSheetsURL.at(0).abuildSheetsUrl);            
                                        }
                                        else{
                                            setSelectUserAbuildSheetsURLs([]);
                                            setSelectUserEditAbuildSheetsURL('');
                                        }
                                        setAbuildSheetsUpdateStatus(false);
                                    }}
                                >
                                    {userDatas.filter((data) => data.userRole === USER_ROLE.Trainee)
                                    .sort((a,b) => a.lastNameKana < b.lastNameKana ? -1 : 1)
                                    .map((user) => {
                                        return (<MenuItem value={user.userId}>{user.lastName}{user.firstName}</MenuItem>)})}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl 
                                variant="outlined"
                                fullWidth
                            >
                                <InputLabel shrink id="outlined-grauation-year">対象コーチ選択</InputLabel>
                                <Select
                                    label="対象コーチ選択"
                                    labelId="outlined-grauation-year"
                                    id='select-grauationyear'
                                    onChange={(event) => {
                                        const userData = userDatas.filter((data)=> data.userId === event.target.value).at(0);
                                        setSelectCoachUserData(userData);
                                        setCoachEMailAddress(userData.eMailAddress);
                                        setAfterCoachName(userData.lastName + ' ' + userData.firstName + '(' + userData.displayName + ')');
                                        const coachData = coachDatas.filter((data)=> data.userdatasID === event.target.value).at(0);
                                        setSelectCoachData(coachData);
                                        setSelectCoachCoachingManagementSheetsURL(coachData.coachManageSheetUrl);
                                        setManagementSheetsUpdateStatus(false);
                                    }}
                                >
                                    {userDatas.filter((data) => data.userRole === USER_ROLE.Coach || data.userRole === USER_ROLE.SuperAdministrator)
                                    .sort((a,b) => a.lastNameKana < b.lastNameKana ? -1 : 1)
                                    .map((user) => {
                                        return (<MenuItem value={user.userId}>{user.lastName}{user.firstName}({user.displayName})</MenuItem>)})
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container className={commonStyle.marginBottom}>
                        <Grid item xs={6}>
                            <FormControl 
                                fullWidth
                            >
                                <InputLabel shrink id="outlined-grauation-year">現在のコーチ</InputLabel>
                                <TextField 
                                    disabled 
                                    label={beforeCoachName === '' ? 'アサインなし' : beforeCoachName}
                                />   
                            </FormControl>
                            <FormControl 
                                fullWidth
                            >
                                <InputLabel shrink id="outlined-grauation-year">アサイン後のコーチ</InputLabel>
                                <TextField 
                                    disabled 
                                    label={afterrCoachName === '' ? '選択なし' : afterrCoachName}
                                />   
                            </FormControl>
                            <FormControl 
                                fullWidth
                            >
                                <InputLabel shrink id="outlined-grauation-year">アサイン後のコーチのメールアドレス</InputLabel>
                                <TextField 
                                    disabled 
                                    label={coachEMailAddress}
                                />   
                            </FormControl>
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={5}>
                            <Grid className={commonStyle.leftOfCenterContents}>
                                <Button 
                                    variant="contained" 
                                    color="primary"
                                    onClick={() => {setCoachAssignOpen(true);}}
                                >
                                    コーチアサイン
                                </Button>
                                <span className={localStyle.statusString}>{0 < selectUserCoachingChatroom.length ? 'コーチングチャットルームが存在します。コーチ変更なのかどうか確認の上で作業するよう注意してください。':''}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className={commonStyle.marginBottom}>
                        {currentUserData.userRole === USER_ROLE.SuperAdministrator ?
                            0 < selectUserCoachingChatroom.length ?
                            <Typography>{selectUserCoachingChatroom.at(0).chatroomsID}</Typography>
                            :
                            <Fragment></Fragment>
                        :
                        <Fragment></Fragment>
                        }
                    </div>
                    <Grid container className={commonStyle.marginBottom}>
                        <Grid item xs={6}>
                            <FormControl 
                                variant="outlined"
                                fullWidth
                            >
                                <InputLabel shrink id="outlined-grauation-year">選択トレーニーAbuild戦略特訓シートURL</InputLabel>
                                <TextField
                                    label="選択トレーニーAbuild戦略特訓シートURL"
                                    variant="outlined"
                                    className={localStyle.controlSpan}
                                    InputLabelProps={{ shrink: true }}
                                    value={selectUserEditAbuildSheetsURL}
                                    helperText="URLの形式（https://～）でなければ保存できません。"
                                    onChange={(event) => {
                                        setSelectUserEditAbuildSheetsURL(event.target.value);
                                        setAbuildSheetsUpdateStatus(true);
                                    }}
                                >
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={5}>
                            <Grid className={commonStyle.leftOfCenterContents}>
                                <Button 
                                    variant="contained" 
                                    color="primary"
                                    onClick={() => {setAbuildSheetsOpen(true);}}
                                >
                                    Abuild戦略特訓シートURL保存
                                </Button>
                                <span className={localStyle.statusString}>{abuildSheetsUpdateStatus? 'URLの変更があります。保存してください。':''}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={commonStyle.marginBottom}>
                        <Grid item xs={6}>
                            <FormControl 
                                variant="outlined"
                                fullWidth
                            >
                                <InputLabel shrink id="outlined-grauation-year">選択コーチ面談管理シートURL</InputLabel>
                                <TextField
                                    label="選択コーチ面談管理シートURL"
                                    variant="outlined"
                                    className={localStyle.controlSpan}
                                    InputLabelProps={{ shrink: true }}
                                    value={selectCoachCoachingManagementSheetsURL}
                                    helperText="URLの形式（https://～）でなければ保存できません。"
                                    onChange={(event) => {
                                        setSelectCoachCoachingManagementSheetsURL(event.target.value);
                                        setManagementSheetsUpdateStatus(true);
                                    }}
                                >
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={5}>
                            <Grid className={commonStyle.leftOfCenterContents}>
                                <Button 
                                    variant="contained" 
                                    color="primary"
                                    onClick={() => {setManagementSheetsOpen(true);}}
                                >
                                    面談管理シートURL保存
                                </Button>
                                <span className={localStyle.statusString}>{managementSheetsUpdateStatus? 'URLの変更があります。保存してください。':''}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <Box className={commonStyle.endBox}/>
            </Box>
            }
            <Footer/>
            <CommonDialog
                agreeEvent={updateCoachingConnect}
                open={coachAssignOpen}
                setOpen={setCoachAssignOpen}
                caption="コーチアサイン登録"
                contentText="コーチアサインしてもよろしいですか？"
                cancelText="実施しない"
                agreeText="実施する"
            />
            <CommonDialog
                agreeEvent={updateOrCreateAbuildSheetsURLAgreeEvent}
                open={abuildSheetsOpen}
                setOpen={setAbuildSheetsOpen}
                caption="Abuild戦略特訓シート登録"
                contentText="Abuild戦略特訓シート登録してもよろしいですか？"
                cancelText="実施しない"
                agreeText="実施する"
            />
            <CommonDialog
                agreeEvent={updateCoachDataToManagementSheetsURL}
                open={managementSheetsOpen}
                setOpen={setManagementSheetsOpen}
                caption="面談管理シート登録"
                contentText="面談管理シート登録してもよろしいですか？"
                cancelText="実施しない"
                agreeText="実施する"
            />
        </Box>
    );
}
