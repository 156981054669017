// AWS
import { API } from "aws-amplify";

// React
import { 
    useEffect, 
    useState, 
    useReducer, 
} from 'react';
import { 
    useHistory, 
    useParams, 
} from 'react-router-dom';

// Material UI
import { 
    Box, 
} from '@material-ui/core';

// GraphQL
import { 
    listScheduleEvents, 
    listCorporations,
    listOfferDatas,
    listRefusalOfferDatas,
} from "../../graphql/queries";

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';

import * as Consts from '../../Commons/Consts';
import { 
    redirectForUserRoleToRedorectPages, 
    getCurrentUserData, 
    getUserDataById,
    getUserIconImage, 
    consoleLog, 
} from "../../Commons/CommonFunctions";
import { 
    userDataReducer, 
    corporationsReducer, 
    eventReducer, 
} from "../../Commons/Reducers";
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import SideMenu from "../../Commons/SideMenu";
import CoachingTraineeDetail from "../../components/CoachingManagement/CoachingTraineeDetail";

export default function TraineeDetail(){
    const history = useHistory();
    const param = useParams();
    const commonStyle = commonStyles();
    const [isLoading, setIsLoading] = useState(true);
    
    // タブ用の設定値
    const [traineeData, dispatchTraineeUserData] = useReducer(userDataReducer, []);
    const [corporations, dispatchCorporations] = useReducer(corporationsReducer, []);
    const [events, dispachEventData] = useReducer(eventReducer, []);
    const [previewImage, setPreviewImage] = useState();
    const [offers, setOffers] = useState([]);
    const [refOffers, setRefOffers] = useState([]);
    const [userData, setUserData] = useState([]);
    
    const setInitData = async () =>{
        try {
            const currentUserData = await getCurrentUserData();
            redirectForUserRoleToRedorectPages(history, currentUserData, Consts.USER_ROLE.Coach);
            setUserData(currentUserData);

            // Get a specific item
            const traineeUserData = await getUserDataById(param.traineeId);
            dispatchTraineeUserData({ type: Consts.INITIAL_QUERY, userData: traineeUserData });
            if(traineeUserData.iconImage !== null){
                const file = await getUserIconImage(traineeUserData);
                setPreviewImage(file);
            }
            
            const corpRes = await API.graphql({
                query: listCorporations,
                variables: { limit:100000 },
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            });
            dispatchCorporations({ type: Consts.INITIAL_QUERY, corporations:corpRes.data.listCorporations.items });
            
            const eventFilter = {
                userDatasID: {contains: traineeUserData.userId}
            }
            const eventRes = await API.graphql({
                query: listScheduleEvents,
                variables: { filter: eventFilter, limit:100000 },
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            });
            dispachEventData({ type: Consts.INITIAL_QUERY, events:eventRes.data.listScheduleEvents.items });

            // 閲覧対象のトレーニーユーザーに紐づく内定情報と辞退情報を取得し、State関数に保持
            const offerFilter = {
                userdatasID: {contains: traineeUserData.userId}
            }
            const offerRes = await API.graphql({
                query: listOfferDatas,
                variables: { filter: offerFilter, limit:100000 },
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            });
            setOffers(offerRes.data.listOfferDatas.items);

            const refusalOfferRes = await API.graphql({
                query: listRefusalOfferDatas,
                variables: { filter: offerFilter, limit:100000 },
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            });
            setRefOffers(refusalOfferRes.data.listRefusalOfferDatas.items);

            // 内定、辞退した予定は過去分も全て表示しないため、確認用の企業データIDを配列にし、State変数へ保持
            var localIds = [];
            localIds.push(offerRes.data.listOfferDatas.items.map((item) => {return item.corporationsID}));
            localIds.push(refusalOfferRes.data.listRefusalOfferDatas.items.map((item) => {return item.corporationsID}));
            
            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
        }
    }
    
    useEffect(() => {
        // アンマウント時処理
        let abortCtrl = new AbortController();

        setInitData();
        return() => {
            abortCtrl.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header title={Consts.HEADER_TITLE_TRAINEE_DETAIL}/>
            <SideMenu/>
            {isLoading ?
            <LoadingIcon/>
            :
            <Box className={commonStyle.pageContent} >
                <CoachingTraineeDetail
                    traineeId={param.traineeId}
                    traineeData={traineeData}
                    corporations={corporations}
                    events={events}
                    previewImage={previewImage}
                    offers={offers}
                    refOffers={refOffers}
                />
            </Box>
            }
            <Footer/>
        </Box>
    );
}
