// React
import { 
    Fragment,
    useEffect, 
    useReducer, 
    useState,  
} from 'react';
import { 
    useHistory, useParams,  
} from 'react-router-dom';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { 
    Typography, 
    Container, 
    Box, 
    Grid, 
    Avatar, 
    Button, 
    Tabs, 
    Tab, 
} from '@material-ui/core';

// Self Making Files
import * as Consts from '../../Commons/Consts';
import { 
    getCurrentUserData, 
    recursiveGrapheql, 
    consoleLog, 
} from "../../Commons/CommonFunctions";
import { 
    a11yProps, 
    TabPanel, 
} from "../../Commons/TabPanel";
import { EventCorporationCardList } from "../../components/SelectionSituationCalender/EventCorporationDataList";
import { commonStyles } from "../../Commons/CommonStyles";

import TasktimeCalendar from '../TaskTimeManegement/TasktimeCalendar';
import { listAbuildSheetsDatas, listTaskDatas, listTasktimeManagementDatas } from '../../graphql/queries';
import { API } from 'aws-amplify';
import CommonDialog from '../../Commons/CommonDialog';
import { commonDBDataReducer, ttmReducer } from '../../Commons/Reducers';
import PCTaskTimeManagementEditor from '../TaskTimeManegement/PCTaskTimeManagementEditor';
import CSTTTMChecker from '../TaskTimeManegement/CSTTTMChecker';
import { onUpdateTasktimeManagementDatas } from '../../graphql/subscriptions';

const localStyles = makeStyles(theme => ({
    avater:{ 
        [theme.breakpoints.down('sm')]: {
            width: 64,
            height: 64,
        },
        [theme.breakpoints.up('md')]: {
            width: 128,
            height: 128,
        },
    },
    profileButton:{
        height: 50,
        marginBottom:10,
    },
    linkButton:{
        padding: "6px 6px 6px 6px",
        marginBottom: "10px",
        display: "inline-block",
        minWidth: "100%",
        width: "100%",
        color: "white",
        cursor: "pointer",
        backgroundColor: theme.palette.primary.main,
        textDecoration:"none",
        border: "none",
        borderRadius: "5px",
        textAlign: "center",
    },
    tabMenu:{
        minWidth: "100%",
        maxWidth: "100%",
        width: "100%",
        height: '5vh',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
    },
    widthThirdPart:{
        minWidth: "33.3%",
        maxWidth: "33.3%",
        width: "33.3%",
    },
    tasksAreaBackground:{
        backgroundColor: theme.palette.background.boxPaper,
    },
    fullHeight:{
        height: "100%",
    },
    userName:{
        [theme.breakpoints.down('sm')]: {
            fontSize: "4vmin",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "3vmin",
        },
    },
    universityName:{
        [theme.breakpoints.down('sm')]: {
            fontSize: "4vmin",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "2vmin",
        },
    },
}));

export default function CoachingTraineeDetail({ traineeId, traineeData, previewImage, events, corporations, offers, refOffers, csChatrooms, pcMode }) {
    const history = useHistory();
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    const param = useParams();
    const [userData , setUserData] = useState([]);
    //const [ttm, setTtm] = useState([]);
    const [supChatroomId, setSupChatroomId] = useState('');
    const [abuildSheetsData, setAbuildSheetsData] = useState(null);
    const [nonDataWarningOpen, setNonDataWarningOpen] = useState(false);
    const [ttmData, dispatchTtmData] = useReducer(commonDBDataReducer, []);
    const [ttms, dispatchTtms] = useReducer(commonDBDataReducer, []);
    const [tasks, dispatchTasks] = useReducer(commonDBDataReducer, []);
    
    const agreeEvent = () => {
        
    }

    const setInitData = async () =>{
        try {
            const currentUserData = await getCurrentUserData();
            setUserData(currentUserData);
            
            const filter = { userDatasID: {contains: traineeId} }
            const sheetsRes = await API.graphql({
                query: listAbuildSheetsDatas,
                variables: { filter: filter , limit:100000 },
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            })
            const sheets = sheetsRes.data.listAbuildSheetsDatas.items;
            if(0 < sheets.length){
                setAbuildSheetsData(sheets.at(0));
            };
            
            if(pcMode){
                // 閲覧トレーニーユーザー、または閲覧コーチユーザーの担当トレーニーのタスクタイムマネジメント管理データを検索し、State変数へ保持
                await recursiveGrapheql(listTasktimeManagementDatas, 'listTasktimeManagementDatas', { filter: filter , limit:100000 }, dispatchTtms);
                if(param.ttmid !== undefined){
                    dispatchTtmData({ type: Consts.INITIAL_QUERY, data:ttms.find((t) => t.id === param.ttmid) })
                    const tassFilter = {
                        parentTasktimeManagementDatasID: {contains:param.ttmid},
                    }
                    await recursiveGrapheql(listTaskDatas, 'listTaskDatas', { filter: tassFilter , limit:100000 , nextToken: null}, dispatchTasks);
                }
                
                setSupChatroomId(csChatrooms.find((room) => room.roomType === 'CUSTOMER_SUCCESS').chatroomsID);
            }
        } catch (e) {
            if (e.name === "AbortError") {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
        }
    }
    
    useEffect(() => {
        // アンマウント時処理
        let abortCtrl = new AbortController();

        setInitData();
        // サブスクリプション登録
        const updateTtmSubscription = API.graphql({
            query:onUpdateTasktimeManagementDatas,
            authMode: Consts.AMAZON_COGNITO_USER_POOLS// 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
            next: (msg) => {
                const ttm = msg.value.data.onUpdateTasktimeManagementDatas;
                dispatchTtms({ type: Consts.SUBSCRIPTION_UPDATE, data:ttm });
            }
        });

        // アンマウント時処理
        return() => {
            abortCtrl.abort();
            updateTtmSubscription.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param.ttmId, param.timestamp]);
    
    // タブ用の設定値
    // const [tabNumber, setTabNumber] = useState(0);
    // const handleChange = (event, newValue) => {
    //     setTabNumber(newValue);
    // };

    return(
        <Box>
            <Box pt={2}>
                <Box className={commonStyle.mobileModeDisplay}>
                    <Container>
                        <Grid container>
                            <Grid item xs={3}>
                                {previewImage !== null ?
                                <Avatar
                                    src={previewImage}
                                    className={localStyle.avater}
                                />
                                :
                                <Avatar
                                    className={localStyle.avater}
                                />
                                }
                            </Grid>
                            <Grid container xs={9}>
                                <Grid item xs={12}>
                                <Typography className={localStyle.userName} >{traineeData.lastName}{traineeData.firstName}</Typography>                                    </Grid>
                                <Grid item xs={12}>
                                <Typography className={localStyle.universityName} >{traineeData.universityName}/{traineeData.courseName}/{traineeData.departmentName}</Typography>        
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                    <Box mt={2}>
                        <Container>
                            { abuildSheetsData !== null ?
                            <Grid xs={12} className={commonStyle.marginBottomRow}>
                                <a 
                                    rel="noreferrer" 
                                    target="_blank" 
                                    href={abuildSheetsData.abuildSheetsUrl} 
                                    className={localStyle.linkButton}
                                >
                                    Abuild Sheets（別タブで開きます）
                                </a>
                            </Grid>
                            :
                            <Grid item xs={12} className={commonStyle.marginBottomRow}>
                                <Button variant="contained" color='primary' onClick={() => {setNonDataWarningOpen(true)}} className={commonStyle.profileButton} fullWidth>Abuild Sheets（別タブで開きます）</Button>
                            </Grid>
                            }
                            <Button 
                                variant="contained"
                                color="primary"
                                fullWidth
                                className={localStyle.profileButton}
                                onClick={() => {
                                    history.push(Consts.URL_PATH_TASKTIMES + Consts.URL_PARTS_SLASH + traineeId);
                                }}
                            >
                                タスクマネジメント
                            </Button>
                        </Container>
                    </Box>
                </Box>
                <Box className={commonStyle.pcModeDisplay}>
                    <Box className={commonStyle.secondSideBarOnPageContent} >
                        <Grid container className={localStyle.fullHeight} >
                            <Grid container xs={6}>
                                <Container>
                                    <Grid container xs={12}>
                                        <Grid item xs={6} className={commonStyle.centerContents} >
                                            <Avatar
                                                src={previewImage}
                                                className={localStyle.avater}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={commonStyle.centerContents} >
                                            <Typography className={localStyle.userName} >
                                                {traineeData.lastName}{traineeData.firstName}
                                            </Typography>
                                        </Grid>
                                    <Grid item xs={12}>
                                            <Typography className={localStyle.universityName} >
                                                {traineeData.universityName}/{traineeData.courseName}/{traineeData.departmentName}
                                            </Typography>        
                                        </Grid>
                                        <Grid item xs={6} className={commonStyle.centerContents} >
                                            { abuildSheetsData !== null ?
                                            <a 
                                                rel="noreferrer" 
                                                target="_blank" 
                                                href={abuildSheetsData.abuildSheetsUrl} 
                                                className={localStyle.linkButton}
                                            >
                                                Abuild Sheets（別タブで開きます）
                                            </a>
                                            :
                                            <Button variant="contained" color='primary' onClick={() => {setNonDataWarningOpen(true)}} className={commonStyle.nonDataButton} fullWidth>Abuild Sheets（別タブで開きます）</Button>
                                            }
                                        </Grid>
                                        <Grid item xs={1}/>
                                        {userData.userRole === Consts.USER_ROLE.CustomerSuccess ||
                                        userData.userRole === Consts.USER_ROLE.SuperAdministrator ?
                                        <Grid item xs={5} className={commonStyle.centerContents} >
                                            <a 
                                                rel="noreferrer" 
                                                target="_blank" 
                                                href={'#' + Consts.URL_PATH_CS_CHAT_SUPPORT + Consts.URL_PARTS_SLASH + supChatroomId} 
                                                className={localStyle.linkButton}
                                            >
                                                サポートチャット（別タブで開きます）
                                            </a>
                                        </Grid>
                                        :
                                        <Fragment></Fragment>
                                        }
                                        <TasktimeCalendar ttm={ttms} userData={userData} traineeId={traineeId} pcMode />
                                    </Grid>
                                </Container>
                            </Grid>
                            <Grid container xs={6} className={localStyle.tasksAreaBackground}>
                                <Container>
                                {param.ttmid !== undefined ?
                                    <CSTTTMChecker
                                        tasks={tasks}
                                        ttm={ttmData}
                                        dispatchTtmData={dispatchTtmData}
                                        userData={userData}
                                        timestamp={param.timestamp}
                                        ttmId={param.ttmid}
                                        traineeId={traineeId}
                                    />
                                    :
                                    <Fragment></Fragment>
                                }
                                </Container>
                            </Grid>
                        </Grid>
                    </Box>
                    {/* <Box mt={2}>
                        <Box sx={{ borderBottom: 1, borderColor: 'primary' }}  >
                            <Tabs value={tabNumber} onChange={handleChange} className={localStyle.tabMenu} aria-label="basic tabs example">
                                <Tab fullWidth label="選考中" {...a11yProps(0)} className={localStyle.widthThirdPart} />
                                <Tab fullWidth label="選考外" {...a11yProps(1)} className={localStyle.widthThirdPart} />
                                <Tab fullWidth label="内定済" {...a11yProps(2)} className={localStyle.widthThirdPart} />
                            </Tabs>
                        </Box>
                        <TabPanel value={tabNumber} index={0}>
                            <EventCorporationCardList
                                events={events}
                                refusalOffers={refOffers}
                                offers={offers}
                                corporations={corporations}
                                userRole={userData.userRole}
                            />
                            <Box className={commonStyle.endBox}/>
                        </TabPanel>
                        <TabPanel value={tabNumber} index={1}>
                            <EventCorporationCardList
                                events={events}
                                refusalOffers={refOffers}
                                corporations={corporations}
                                userRole={userData.userRole}
                            />
                            <Box className={commonStyle.endBox}/>
                        </TabPanel>
                        <TabPanel value={tabNumber} index={2}>
                            <EventCorporationCardList
                                events={events}
                                offers={offers}
                                corporations={corporations}
                                userRole={userData.userRole}
                            />
                            <Box className={commonStyle.endBox}/>
                        </TabPanel>
                    </Box> */}
                </Box>
                <CommonDialog 
                    agreeEvent={agreeEvent}
                    open={nonDataWarningOpen}
                    setOpen={setNonDataWarningOpen}
                    contentText={Consts.MESSAGE_ABUILDSHEETS_NON_DATA}
                    agreeText={Consts.ABUILDSHEETS_NON_DATA_DIALOG_AGREE_TEXT}
                />
            </Box>
        </Box>
    );
}
