import { 
    Box, 
    Checkbox, 
    Chip, 
    Grid, 
    Input, 
    ListItemText, 
    makeStyles, 
    MenuItem, 
    Select, 
    Typography 
} from "@material-ui/core";
import { API } from "aws-amplify";
import { Fragment, useEffect, useState } from "react";
import { commonStyles } from "../../Commons/CommonStyles";
import { 
    AMAZON_COGNITO_USER_POOLS, 
    CS_CHATROOM_TAGS, 
    USER_ROLE
} from "../../Commons/Consts";
import { updateCustomerChatrooms, updateTraineeDatas } from "../../graphql/mutations";
import { listCoachDatas, listTraineeDatas } from "../../graphql/queries";

const localStyles = makeStyles(theme => ({
    area:{
        paddingTop: "2px",
        paddingLeft: "20px",
        width: "100%",
    },
    nameArea:{
        paddingTop: "8px",
        paddingLeft: "20px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-start",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    tagsContents:{
        marginLeft: "10px",
        minHeight: "45px",
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        "& #select-multiple-tag":{
            borderBottom: "none",
        },
    },
    tag:{
        marginRight: "5px",
        paddingTop: "3px",
        paddingLeft: "5px",
        paddingRight: "5px",
        paddingBottom: "1px",
        color: "#3333ff",
        backgroundColor: "#ffffff",
        border: "solid #3333ff 1px"
    },
}));


export function ChatroomDetailViewer({ customerChatrooms, selectId, sendToUserData, sendFromUserData}){
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    const [detailData, setDetailData] = useState(null);

    const init = async () =>{
        const filter = {
            userdatasID: {contains: sendToUserData.userId },
        };
        const res = await API.graphql({
            query: listTraineeDatas,
            variables: { filter: filter, limit: 10000 },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
        if(0 === res.data.listTraineeDatas.items.length){
            const coachRes = await API.graphql({
                query: listCoachDatas,
                variables: { filter: filter, limit: 10000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            setDetailData(coachRes.data.listCoachDatas.items.at(0));
        }
        else{
            setDetailData(res.data.listTraineeDatas.items.at(0));
        }
    }
    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box className={localStyle.area}>
            {customerChatrooms.filter((chatroom) => chatroom.chatroomsID === selectId)
            .map((chatroom) => {
                return (
                    <Grid container>
                        {sendFromUserData?
                        <Grid item sx={2} className={commonStyle.leftContents}>
                            <Typography variant="caption">トレーニー：{sendToUserData.lastName}{sendToUserData.firstName}</Typography>
                            <Typography variant="caption">コーチ：{sendFromUserData.lastName}{sendFromUserData.firstName}({sendFromUserData.displayName})</Typography>
                        </Grid>
                        :
                        <Grid item sx={2} className={commonStyle.leftContents}>
                            <Typography variant="h6">{sendToUserData.lastName}{sendToUserData.firstName}</Typography>
                        </Grid>
                        }
                        <Grid tiem sx={10} className={localStyle.tagsContents}>
                            <TagSelect SelectedTags={detailData !== null ? detailData.tag !== null ? detailData.tag.split(',') : [] : []} chatroom={chatroom} userRole={sendToUserData.userRole} />
                        </Grid>
                    </Grid>
                )
            })
            }
        </Box>
    )
}
export function ChatroomNameViewer({ chatrooms, selectId, sendToUserData}){
    const commonStyle = commonStyles();
    const localStyle = localStyles();

    return (
        <Box className={localStyle.nameArea}>
            {chatrooms.filter((chatroom) => chatroom.id === selectId)
            .map((chatroom) => {
                return (
                    <Grid container>
                        <Grid item sx={2} className={commonStyle.centerContents}>
                            <Typography variant="h6">
                                {sendToUserData.displayName === null ?
                                sendToUserData.lastName + ' ' + sendToUserData.firstName
                                :
                                sendToUserData.displayName
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                )
            })
            }
        </Box>
    )
}

export function TagsViewer({ tags }){
    const localStyle = localStyles();
    return (
        <Box className={localStyle.tagsContents}>
            {tags.split(',').map((t) => {
                if(t !== ''){
                    return(<Box className={localStyle.tag}>{t}</Box>)
                }
                else{
                    return(<Fragment></Fragment>)
                }
                })}
        </Box>
    )
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    getContentAnchorEl: null,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
        }
    }
};

function TagSelect({ SelectedTags, chatroom, userRole }){
    const localStyle = localStyles();
    
    const handleChange = async (event) => {
        await API.graphql({
            query: updateCustomerChatrooms,
            variables: { 
                input: {id: chatroom.id, tag: event.target.value.join()} },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
        if(userRole === USER_ROLE.Trainee){
            const filter = {
                userdatasID: {contains: chatroom.userdatasID },
            };
            const res = await API.graphql({
                query: listTraineeDatas,
                variables: { filter: filter, limit: 10000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            await API.graphql({
                query: updateTraineeDatas,
                variables: { 
                    input: {id: res.data.listTraineeDatas.items.at(0).id, tag: event.target.value.join()} },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
        }
        else if(userRole === USER_ROLE.Coach){
            const filter = {
                userdatasID: {contains: chatroom.userdatasID },
            };
            const res = await API.graphql({
                query: listCoachDatas,
                variables: { filter: filter, limit: 10000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            await API.graphql({
                query: listCoachDatas,
                variables: { 
                    input: {id: res.data.listCoachDatas.items.at(0).id, tag: event.target.value.join()} },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
        }
        
    };
    
    return(
        <Select
            id="mutiple-tag"
            multiple
            value={SelectedTags}
            onChange={handleChange}
            input={<Input id="select-multiple-tag" />}
            renderValue={(selected) => (
                <div>
                {selected.map((value) => {
                    if(value !== ''){
                        return (<Chip key={value} label={value} className={localStyle.tag} />);
                    }
                    else if(1 < selected.length){
                        return (<Fragment></Fragment>);
                    }
                    else{
                        return (<Fragment>タグを追加する場合はここをクリック</Fragment>);
                    }
                })}
                </div>
            )}
            MenuProps={MenuProps}
        >
            {CS_CHATROOM_TAGS.map((name) => (
                <MenuItem key={name} value={name}>
                    <Checkbox checked={SelectedTags.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                </MenuItem>
            ))}
        </Select>
    )
}