// AWS
import { API } from "aws-amplify";

// GraphQL
import { 
    updateTasktimeManagementDatas, 
} from '../../graphql/mutations';
import { 
    createTaskDatas, 
} from '../../graphql/mutations';
import { 
    onCreateTaskDatas, 
    onUpdateTaskDatas, 
    onUpdateTasktimeManagementDatas, 
} from '../../graphql/subscriptions';

// React
import { 
    useEffect, 
    useState, 
    Fragment,
} from 'react';
import AddIcon from '@material-ui/icons/Add';

// Material-ui
import { makeStyles } from '@material-ui/core/styles';
import { 
    Typography, 
    Container, 
    Box, 
    Grid, 
    Select, 
    MenuItem, 
    TextField, 
    Fab, 
    InputLabel, 
    FormControl, 
    Tabs, 
    Tab,
} from '@material-ui/core';

// Self Making Files
import { 
    AMAZON_COGNITO_USER_POOLS, 
    ERROR_TYPE_ABORTERROR, 
    INITIAL_QUERY, 
    SUBSCRIPTION, 
    SUBSCRIPTION_UPDATE, 
    TAB_MENU_TITLE_ACHIVEMENT, 
    TAB_MENU_TITLE_PLANS, 
    TASK_TITLE_ACTION_TO_IMPROVE, 
    TASK_TITLE_ISSUES_TO_IMPROVE, 
    TASK_TITLE_REFLECTION_ON_DAY, 
    TASK_TITLE_TASK_ACHIEVEMENT, 
    TASK_TITLE_TODAY_EFFORT_TARGETS, 
    TASK_TITLE_TODAY_REFLECTION, 
    TTM_DATA_ACHIEVEMENTSCORE, 
    TTM_DATA_ACTIONTOIMPROVE, 
    TTM_DATA_EFFORTTARGETS, 
    TTM_DATA_ISSUESTOIMPROVE, 
    TTM_DATA_REFLECTIONONDAY, 
    UPDATE, 
    USER_ROLE, 
} from '../../Commons/Consts';
import { 
    formatAWSDateStr, 
    consoleLog, 
} from '../../Commons/CommonFunctions';

import { PlanTaskCardList } from '../../components/TaskTimeManegement/PlanTaskCardList';
import { ResultTaskCardList } from '../../components/TaskTimeManegement/ResultTaskCardList';
import { 
    a11yProps, 
    TabPanel 
} from "../../Commons/TabPanel";
import { commonStyles } from "../../Commons/CommonStyles";

const localStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 100,
        backgroundColor: theme.palette.background.paper,
        "& .MuiInputBase-input":{
            [theme.breakpoints.down('sm')]: {
                fontSize: "3vmin",
            },
            [theme.breakpoints.up('md')]: {
                fontSize: "2vmin",
            }
        }
    },
    achievementSelect:{
        display: 'flex',
        alignContent: 'stretch',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: "12px",
    },
    tabMenu:{
        minWidth: "100%",
        maxWidth: "100%",
        width: "100%",
        height: '5vh',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
    },
    widthHerf:{
        minWidth: "50%",
        maxWidth: "50%",
        width: "50%",
    },
    divider:{
        border: "solid #0A0A0A 1px",
        marginTop: "5px",
        marginBottom: "5px", 
    },
    bcBrown:{
        backgroundColor: "#bcaaa4",
    }
}));

export default function TaskTimeManagementEditor({ tasks, dispatchTasks, ttm, dispatchTtm, userData, timestamp, ttmId }){
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    const [value, setValue] = useState(0);
    
    const initTaskTimeDetail = async (type) =>{
        try {
            // コーチが確認した時にCheckOutされる
            if(userData.userRole === USER_ROLE.Coach ||
                userData.userRole === USER_ROLE.CustomerSuccess){
                checkOutingProcess(tasks, ttm, type);
            }
        } catch (e) {
            if (e.name === ERROR_TYPE_ABORTERROR) {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
        }
    }

    const checkOutingProcess = async (tasks, ttmDatas, type) =>{
        if(!tasks.some(checkIsReportingStatus) &&
            checkIsTtmStatus(ttmDatas)){
            const ttmUpdRes = await API.graphql({
                query: updateTasktimeManagementDatas,
                variables: { 
                    input: {
                        id:ttmDatas.id,
                        checkOut: true,
                    },
                },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            dispatchTtm({ type: type, data:ttmUpdRes.data.updateTasktimeManagementDatas });
        }
    }

    const checkIsReportingStatus = (data) =>{
        return data.resultTaskDetial === '' && data.taskProgress === 0
    }
    
    const checkIsTtmStatus = (data) =>{
        return data.ttmActionToImprove !== '' && data.ttmIssuesToImprove !== '' && data.ttmReflectionOnDay !== ''
    }
    
    const createPlaninTask = async (id) =>{
        var ttmId = null;
        if(id !== null){
            ttmId = id;
        }
        else{
            ttmId = ttm.id;
        }
        
        var newStartTime = "08:00";
        var newEndTime = "09:00";
        if(0 < tasks.length){
            const baseData = tasks.filter(data => { return data.planTaskCreated === true}).sort((a, b) => {return a.createdAt < b.createdAt ? -1 : 1}).at(-1);
            const endTimeValues = baseData.planTaskEndTime.split(':');
            if(23 <= endTimeValues[0]){
                endTimeValues[0] = '00'
            }
            else{
                endTimeValues[0] = ('0' + (Number(endTimeValues[0]) + 1).toString()).slice(-2);
            }
            newStartTime = baseData.planTaskEndTime;
            newEndTime = endTimeValues.join(':');
        }
        
        await API.graphql({
            query: createTaskDatas,
            variables: {
                input: {
                    parentTasktimeManagementDatasID: ttmId, 
                    planTaskDetial: "", 
                    planTaskCreated: true, 
                    planTaskStartTime: newStartTime, 
                    planTaskEndTime: newEndTime, 
                    planTaskPriority: 0, 
                    resultTaskDetial: "", 
                    resultTaskStartTime: newStartTime, 
                    resultTaskEndTime: newEndTime, 
                    resultTaskPriority: 0, 
                    taskProgress: 0, 
                    tasktimeManagementTimestamp: new Date(formatAWSDateStr(timestamp)).valueOf()
                }    
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
        await API.graphql({
            query: updateTasktimeManagementDatas,
            variables: { 
                input: {
                    id:ttmId,
                    checkIn: true,
                    checkOut: false,
                },
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
    };
    
    const createNoPlanTask = async (id) =>{
        var ttmId = null;
        if(id !== null){
            ttmId = id;
        }
        else{
            ttmId = ttm.id;
        }
        
        var newStartTime = "08:00";
        var newEndTime = "09:00";
        if(0 < tasks.length){
            const baseData = tasks.sort((a, b) => {return a.createdAt < b.createdAt ? -1 : 1}).at(-1);
            const endTimeValues = baseData.planTaskEndTime.split(':');
            if(23 <= endTimeValues[0]){
                endTimeValues[0] = '00'
            }
            else{
                endTimeValues[0] = ('0' + (Number(endTimeValues[0]) + 1).toString()).slice(-2);
            }
            newStartTime = baseData.planTaskEndTime;
            newEndTime = endTimeValues.join(':');
        }
        
        await API.graphql({
            query: createTaskDatas,
            variables: {
                input: {
                    parentTasktimeManagementDatasID: ttmId, 
                    planTaskDetial: "", 
                    planTaskCreated: false, 
                    planTaskStartTime: newStartTime, 
                    planTaskEndTime: newEndTime, 
                    planTaskPriority: 0, 
                    resultTaskDetial: "", 
                    resultTaskStartTime: newStartTime, 
                    resultTaskEndTime: newEndTime, 
                    resultTaskPriority: 0, 
                    taskProgress: 0, 
                    tasktimeManagementTimestamp: new Date(formatAWSDateStr(timestamp)).valueOf()
                }    
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
        await API.graphql({
            query: updateTasktimeManagementDatas,
            variables: { 
                input: {
                    id:ttmId,
                    checkIn: true,
                    checkOut: false,
                },
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
    };
    
    const updateTtmDatas = async (type) => {
        const res = await API.graphql({
            query: updateTasktimeManagementDatas,
            variables: { 
                input: {
                    id:ttm.id,
                    achievementScore: ttm.achievementScore,
                    checkIn: true,
                    checkOut: false,
                    tasktimeManagementOutput: "",
                    ttmActionToImprove: ttm.ttmActionToImprove,
                    ttmEffortTargets: ttm.ttmEffortTargets, 
                    ttmIssuesToImprove: ttm.ttmIssuesToImprove, 
                    ttmReflectionOnDay: ttm.ttmReflectionOnDay,
                },
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
        dispatchTtm({ type: INITIAL_QUERY, data:res.data.updateTasktimeManagementDatas });
    }

    const bulrTtmUpdate = () =>{
        updateTtmDatas();
    }
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    useEffect(() => {
        // マウント時処理
        // 初期化処理
        let abortCtrl = new AbortController();
        initTaskTimeDetail(INITIAL_QUERY);

        // APIサブスクリプション登録
        const subscription = API.graphql({
            query:onUpdateTaskDatas,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
            next: (msg) => {
                const task = msg.value.data.onUpdateTaskDatas;
                dispatchTasks({ type: SUBSCRIPTION_UPDATE, data: task });
            }
        });
        const createSubscription = API.graphql({
            query:onCreateTaskDatas,
            authMode: AMAZON_COGNITO_USER_POOLS// 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
            next: (msg) => {
                const task = msg.value.data.onCreateTaskDatas;
                if(ttmId === task.parentTasktimeManagementDatasID){
                    dispatchTasks({ type: SUBSCRIPTION, data: task });
                }
            }
        });
        const updateTtmSubscription = API.graphql({
            query:onUpdateTasktimeManagementDatas,
            authMode: AMAZON_COGNITO_USER_POOLS// 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
            next: (msg) => {
                const updateTtm = msg.value.data.onUpdateTasktimeManagementDatas;
                if(ttmId === updateTtm.id){
                    dispatchTtm({ type: INITIAL_QUERY, data: updateTtm });
                }
            }
        });
        
        // アンマウント時処理
        return () => {
            abortCtrl.abort();
            createSubscription.unsubscribe();
            subscription.unsubscribe();
            updateTtmSubscription.unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} className={localStyle.tabMenu} aria-label="basic tabs example">
                <Tab label={TAB_MENU_TITLE_PLANS} {...a11yProps(0)} className={localStyle.widthHerf} />
                <Tab label={TAB_MENU_TITLE_ACHIVEMENT} {...a11yProps(1)} className={localStyle.widthHerf} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Box pt={1}>
                    <PlanTaskCardList 
                        tasks={tasks} 
                        userRole={userData.userRole}
                    />
                    {userData.userRole === USER_ROLE.Trainee ?
                    <Fab 
                        color="primary" 
                        aria-label="add" 
                        className={commonStyle.fabbut} 
                        onClick={(event) => {createPlaninTask(ttm.id)}}
                    >
                        <AddIcon />
                    </Fab>
                    :
                    <Fragment></Fragment>
                    }
                    <Box pt={1} className={commonStyle.marginBottomRow} />
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                label={TASK_TITLE_TODAY_EFFORT_TARGETS}
                                fullWidth
                                multiline
                                variant="outlined"
                                minRows={5}
                                maxRows={10}
                                disabled={userData.userRole === 17 ? false : true}
                                InputLabelProps={{ shrink: true }}
                                className={localStyle.formControl}
                                value={ttm.ttmEffortTargets}
                                onChange={(event) => {dispatchTtm({type: UPDATE, updateName:TTM_DATA_EFFORTTARGETS, updateValue:event.target.value })}}
                                onBlur={bulrTtmUpdate}
                            />
                        </Grid>
                    </Grid>
                    <Box className={commonStyle.endBox}/>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box pt={1}>
                    <ResultTaskCardList 
                        tasks={tasks} 
                        userRole={userData.userRole}
                    />
                    {userData.userRole === USER_ROLE.Trainee ?
                    <Fab 
                        color="primary" 
                        aria-label="add" 
                        className={commonStyle.fabbut} 
                        onClick={(event) => {createNoPlanTask(ttm.id)}}
                    >
                        <AddIcon />
                    </Fab>
                    :
                    <Fragment></Fragment>
                    }
                    <Box pt={1} className={commonStyle.marginBottomRow} />
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                label={TASK_TITLE_TODAY_EFFORT_TARGETS}
                                fullWidth
                                multiline
                                variant="outlined"
                                minRows={5}
                                maxRows={10}
                                disabled={userData.userRole === 17 ? false : true}
                                InputLabelProps={{ shrink: true }}
                                className={localStyle.formControl}
                                value={ttm.ttmEffortTargets}
                                onChange={(event) => {dispatchTtm({type: UPDATE, updateName:TTM_DATA_EFFORTTARGETS, updateValue:event.target.value })}}
                                onBlur={bulrTtmUpdate}
                            />
                        </Grid>
                    </Grid>
                    <Box className={localStyle.divider} />
                    <Container>
                        <Grid container>    
                            <Grid item xs={12} className={commonStyle.marginBottomRow}>
                                <Typography variant='h6'>{TASK_TITLE_TODAY_REFLECTION}</Typography>
                            </Grid>
                            <Grid container xs={12} className={localStyle.achievementSelect}>
                                <FormControl variant="outlined" className={localStyle.formControl}>
                                    <InputLabel id="task-achievement-rate-select-label">{TASK_TITLE_TASK_ACHIEVEMENT}</InputLabel>
                                    <Select
                                        labelId="task-achievement-rate-select-label"
                                        id="task-achievement-rate-select"
                                        label={TASK_TITLE_TASK_ACHIEVEMENT}
                                        disabled={userData.userRole === 17 ? false : true}
                                        value={ttm.length <= 0 ? 
                                            0    
                                            :
                                            ttm.achievementScore
                                            }
                                        onChange={(event) => {dispatchTtm({type: UPDATE, updateName:TTM_DATA_ACHIEVEMENTSCORE, updateValue:event.target.value })}}
                                        onBlur={bulrTtmUpdate}
                                    >
                                        <MenuItem value={0}>未着手</MenuItem>
                                        <MenuItem value={5}>5%</MenuItem>
                                        <MenuItem value={10}>10%</MenuItem>
                                        <MenuItem value={15}>15%</MenuItem>
                                        <MenuItem value={20}>20%</MenuItem>
                                        <MenuItem value={25}>25%</MenuItem>
                                        <MenuItem value={30}>30%</MenuItem>
                                        <MenuItem value={35}>35%</MenuItem>
                                        <MenuItem value={40}>40%</MenuItem>
                                        <MenuItem value={45}>45%</MenuItem>
                                        <MenuItem value={50}>50%</MenuItem>
                                        <MenuItem value={55}>55%</MenuItem>
                                        <MenuItem value={60}>60%</MenuItem>
                                        <MenuItem value={65}>65%</MenuItem>
                                        <MenuItem value={70}>70%</MenuItem>
                                        <MenuItem value={75}>75%</MenuItem>
                                        <MenuItem value={80}>80%</MenuItem>
                                        <MenuItem value={85}>85%</MenuItem>
                                        <MenuItem value={90}>90%</MenuItem>
                                        <MenuItem value={95}>95%</MenuItem>
                                        <MenuItem value={100}>100%</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className={commonStyle.marginBottomRow}>
                                <TextField
                                    label={TASK_TITLE_REFLECTION_ON_DAY}
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    minRows={5}
                                    maxRows={10}
                                    disabled={userData.userRole === 17 ? false : true}
                                    InputLabelProps={{ shrink: true }}
                                    className={localStyle.formControl}
                                    value={ttm.ttmReflectionOnDay}
                                    onChange={(event) => {dispatchTtm({type: UPDATE, updateName:TTM_DATA_REFLECTIONONDAY, updateValue:event.target.value })}}
                                    onBlur={bulrTtmUpdate}
                                />
                            </Grid>
                            <Grid item xs={12} className={commonStyle.marginBottomRow}>
                                <TextField
                                    label={TASK_TITLE_ISSUES_TO_IMPROVE}
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    minRows={5}
                                    maxRows={10}
                                    disabled={userData.userRole === 17 ? false : true}
                                    InputLabelProps={{ shrink: true }}
                                    className={localStyle.formControl}
                                    value={ttm.ttmIssuesToImprove}
                                    onChange={(event) => {dispatchTtm({type: UPDATE, updateName:TTM_DATA_ISSUESTOIMPROVE,updateValue:event.target.value })}}
                                    onBlur={bulrTtmUpdate}
                                />
                            </Grid>
                            <Grid item xs={12} className={commonStyle.marginBottomRow}>
                                <TextField
                                    label={TASK_TITLE_ACTION_TO_IMPROVE}
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    minRows={5}
                                    maxRows={10}
                                    disabled={userData.userRole === 17 ? false : true}
                                    InputLabelProps={{ shrink: true }}
                                    className={localStyle.formControl}
                                    value={ttm.ttmActionToImprove}
                                    onChange={(event) => {dispatchTtm({type: UPDATE, updateName:TTM_DATA_ACTIONTOIMPROVE, updateValue:event.target.value })}}
                                    onBlur={bulrTtmUpdate}
                                />
                            </Grid>
                        </Grid>
                        <Box className={commonStyle.endBox}/>
                    </Container>
                </Box>
            </TabPanel>
        </Box>
    );
}