// AWS
import { API } from "aws-amplify";

// React
import { 
    useEffect, 
    useState, 
    useReducer, 
} from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { 
    Box,  
    Button,  
    FormControl,  
    Grid, 
    TextField, 
    Typography, 
} from '@material-ui/core';
//import MuiPagination from '@material-ui/lab/Pagination';

// GraphQL
import { createTodayWords } from '../../graphql/mutations';
import { listTodayWords } from "../../graphql/queries";
import { onCreateTodayWords } from '../../graphql/subscriptions';

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { 
    AMAZON_COGNITO_USER_POOLS, 
    BUTTON_CAPTION_SAVE, 
    HEADER_TITLE_TODAY_WORDS_MASTER_MANAGEMENT, 
    INITIAL_QUERY, 
    SUBSCRIPTION, 
    TODAYS_WORDS_MANAGEMENT_HEADER_TITLE, 
} from '../../Commons/Consts';
import { 
    commonDBDataReducer, userDataReducer, 
} from "../../Commons/Reducers";
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import SideMenu from "../../Commons/SideMenu";
import { 
    getCurrentUserData, 
    consoleLog, 
} from "../../Commons/CommonFunctions";

const localStyles = makeStyles(theme => ({
    messegeTextarea: {
        '& .MuiInputBase-root':{
            paddingTop: "5px",
            paddingBottom: "5px",
            '& .MuiInputBase-input':{
                paddingTop: "5px",
                paddingBottom: "5px",
            },
        },
        '& #message-editor':{
            fontSize: "3vmin",
            color: "#000000"
        },
    },
}));

export default function TodaysWordsMasterManagement(){
    const commonStyle = commonStyles();   
    const localStyle = localStyles();
    const [isLoading, setIsLoading] = useState(true);

    const [userData, dispatchCurrentUserData] = useReducer(userDataReducer, []);
    const [todaysWordsData, dispatchTodaysWordsData] = useReducer(commonDBDataReducer, []);
    const [captionData, setCaptionData] = useState('');
    const [messageData, setMessageData] = useState('');
    const [displayNumber, setDisplayNumber] = useState(0);
    
    //const [page, setPage] = useState(1);

    const initTodaysWordsManagement = async () =>{
        try{
            const currentUserData = await getCurrentUserData();
            dispatchCurrentUserData({ type: INITIAL_QUERY, userData: currentUserData });
            
            // 確認用に新井翔太の今日の一言を一覧で取得 
            const eventRes = await API.graphql({
                query: listTodayWords,
                variables: { limit: 1000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            const words = eventRes.data.listTodayWords.items;
            dispatchTodaysWordsData({ type: INITIAL_QUERY, data:words});
            setDisplayNumber(words.length + 1);

            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }

    const captionInputChange = (event) =>{
        setCaptionData(event.target.value)
    };

    const messageInputChange = (event) =>{
        setMessageData(event.target.value)
    };

    const displayNumberInputChange = (event) =>{
        setDisplayNumber(event.target.value)
    };

    const todaysWordsCreate = async () =>{
        await API.graphql({
            query: createTodayWords,
            variables: {
                input: { 
                    caption: captionData, 
                    contentText: messageData, 
                    displayNumber: displayNumber,
                }
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        })
        setCaptionData('');
        setMessageData('');
        setDisplayNumber(displayNumber + 1);
    }

    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();

        initTodaysWordsManagement();

        const createSubsc = API.graphql({
            query:onCreateTodayWords,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
        next: (msg) => {
            const data = msg.value.data.onCreateTodayWords;
            dispatchTodaysWordsData({ type: SUBSCRIPTION, data: data });
        }});

        return () => {
            abortCtrl.abort()
            createSubsc.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header title={HEADER_TITLE_TODAY_WORDS_MASTER_MANAGEMENT}/>
            <SideMenu/>
            {isLoading ?
            <LoadingIcon/>
            :
            <Box className={commonStyle.pageContent}>
                <Box mt={3}>
                    <Grid container>
                        <Grid xs={12} className={commonStyle.marginBottomRow}>
                            <FormControl fullWidth className={localStyle.messegeTextarea}>
                                <TextField
                                    id="message-editor"
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    minRows={1}
                                    maxRows={1}
                                    label="キャプション"
                                    value={captionData}
                                    onChange={captionInputChange}
                                />
                            </FormControl>                      
                        </Grid>
                        <Grid xs={12} className={commonStyle.marginBottomRow}>
                            <FormControl fullWidth className={localStyle.messegeTextarea}>
                                <TextField
                                    id="message-editor"
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    minRows={1}
                                    maxRows={4}
                                    label="メッセージ内容"
                                    value={messageData}
                                    onChange={messageInputChange}
                                />
                            </FormControl>                         
                        </Grid>
                        <Grid xs={12} className={commonStyle.marginBottomRow}>
                            <FormControl fullWidth className={localStyle.messegeTextarea}>
                                <TextField
                                    id="display-number-editor"
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    minRows={1}
                                    maxRows={1}
                                    label="表示番号"
                                    value={displayNumber}
                                    onChange={displayNumberInputChange}
                                />
                            </FormControl>                   
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={todaysWordsCreate}>{BUTTON_CAPTION_SAVE}</Button>                     
                        </Grid>
                    </Grid>
                </Box>
                {/* <Box>
                    <MuiPagination
                        count={10}          //総ページ数
                        color="primary"     //ページネーションの色
                        onChange={(e, page) =>setPage(page)}  //変更されたときに走る関数。第2引数にページ番号が入る
                        page={page}         //現在のページ番号
                    />
                </Box> */}
                <Box mt={3}>
                    <Grid container spacing={0}>
                        <Grid item xs={2} className={localStyle.messegeTextarea}>
                            <Typography variant="caption">表示番号</Typography>
                        </Grid>
                        <Grid item xs={5} className={localStyle.messegeTextarea}>
                            <Typography variant="caption">キャプション</Typography>
                        </Grid>
                        <Grid item xs={5} className={localStyle.messegeTextarea}>
                            <Typography variant="caption">メッセージ内容</Typography>
                        </Grid>
                    </Grid>
                    {todaysWordsData
                    .sort((a,b) => {return a.displayNumber < b.displayNumber ? 1 : -1})
                    .map(data => {
                        return (
                        <Grid container spacing={0}>
                            <Grid item xs={2} className={localStyle.messegeTextarea}>
                                <TextField
                                    id="message-editor"
                                    fullWidth
                                    multiline
                                    disabled
                                    variant="outlined"
                                    minRows={1}
                                    maxRows={1}
                                    value={data.displayNumber}
                                />                        
                            </Grid>
                            <Grid item xs={5} className={localStyle.messegeTextarea}>
                                <TextField
                                    id="message-editor"
                                    fullWidth
                                    multiline
                                    disabled
                                    variant="outlined"
                                    minRows={1}
                                    maxRows={2}
                                    value={data.caption}
                                />                        
                            </Grid>
                            <Grid item xs={5} className={localStyle.messegeTextarea}>
                                <TextField
                                    id="message-editor"
                                    fullWidth
                                    multiline
                                    disabled
                                    variant="outlined"
                                    minRows={1}
                                    maxRows={2}
                                    value={data.contentText}
                                />                        
                            </Grid>
                        </Grid>)
                    })}
                </Box>
                <Box className={commonStyle.endBox}/>
            </Box>
            }
            <Footer/>
        </Box>
    );
}
