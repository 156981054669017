import { API } from "aws-amplify";
import { useEffect, useState, useReducer, Fragment } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import { listUserDatas } from "../graphql/queries";

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MenuBookIcon from '@material-ui/icons/MenuBook';

import * as Consts from './Consts';
import { FitContent, getCurrentUserData } from "./CommonFunctions";
import { userDataReducer } from "./Reducers";
import { commonStyles } from "./CommonStyles";

const localStyles = makeStyles(theme => ({
    root:{
        [theme.breakpoints.down('sm')]: {
            display: "block",
        },
        [theme.breakpoints.up('md')]: {
            display: "none",
        },
    },
}));

function TraineeNavigation({valueSetValue}){
    const commonStyle = commonStyles();
    const history = useHistory();
    const [value, setValue] = useState(valueSetValue);

    return (<BottomNavigation
        value={value}
        onChange={(event, newValue) => {
            setValue(newValue);
            history.push(Consts.URL_PARTS_SLASH + newValue);
        }}
        showLabels
        className={commonStyle.footer}
    >
            {/* <BottomNavigationAction 
                label={Consts.FOOTER_TITLE_SCHEDULE}
                value={Consts.URL_PATH_SCHEDULE.split(Consts.URL_PARTS_SLASH)[1]}
                icon={<TrendingUpIcon/>} 
            /> */}
            <BottomNavigationAction 
                label={Consts.FOOTER_TITLE_TASKTAIMES}
                value={Consts.URL_PATH_TASKTIMES.split(Consts.URL_PARTS_SLASH)[1]}
                icon={<BorderColorIcon/>} 
            />
            <BottomNavigationAction 
                label={Consts.FOOTER_TITLE_CHATROOMS}
                value={Consts.URL_PATH_CHATROOMS.split(Consts.URL_PARTS_SLASH)[1]}
                icon={<QuestionAnswerIcon/>} 
            />
            {/* <BottomNavigationAction 
                label={Consts.FOOTER_TITLE_SUBJECTS}
                value={Consts.URL_PATH_SUBJECTS.split(Consts.URL_PARTS_SLASH)[1]}
                icon={<FitnessCenterIcon/>} 
            /> */}
            <BottomNavigationAction 
                label={Consts.FOOTER_TITLE_MYPAGE}
                value={Consts.URL_PATH_MYPAGE.split(Consts.URL_PARTS_SLASH)[1]}
                icon={<PersonIcon/>}
            />
    </BottomNavigation>);
}

function CoachNavigation({valueSetValue}){
    const commonStyle = commonStyles();
    const history = useHistory();
    const [value, setValue] = useState(valueSetValue);

    return (
    <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
            setValue(newValue);
            history.push(Consts.URL_PARTS_SLASH + newValue);
        }}
        showLabels
        className={commonStyle.footer}
    >
            {/* <BottomNavigationAction 
                label={Consts.FOOTER_TITLE_COACHING_MANAGEMENT}
                value={Consts.URL_PATH_COACHING_MANAGEMENT.split(Consts.URL_PARTS_SLASH)[1]}
                icon={<AssignmentIcon/>} 
            /> */}
            <BottomNavigationAction 
                label={Consts.FOOTER_TITLE_TRAINEE_LIST} 
                value={Consts.URL_PATH_TRAINEE_LIST.split(Consts.URL_PARTS_SLASH)[1]}
                icon={<PeopleIcon/>} 
            />
            <BottomNavigationAction 
                label={Consts.FOOTER_TITLE_CHATROOMS}
                value={Consts.URL_PATH_CHATROOMS.split(Consts.URL_PARTS_SLASH)[1]}
                icon={<QuestionAnswerIcon/>} 
            />
            {/* <BottomNavigationAction 
                label={Consts.FOOTER_TITLE_MANUAL}
                value={Consts.URL_PATH_USERS_MANUAL.split(Consts.URL_PARTS_SLASH)[1]}
                icon={<MenuBookIcon/>} 
            /> */}
            <BottomNavigationAction 
                label={Consts.FOOTER_TITLE_MYPAGE}
                value={Consts.URL_PATH_MYPAGE.split(Consts.URL_PARTS_SLASH)[1]}
                icon={<PersonIcon/>}
            />

    </BottomNavigation>);
}

export default function Footer() {
    const localStyle = localStyles();
    const history = useHistory();
    const valueSetValue = history.location.pathname.split(Consts.URL_PARTS_SLASH)[1];
    
    const [userData, dispachUser] = useReducer(userDataReducer, []);
    const getUserData = async() =>{
        const currentUserData = await getCurrentUserData();
        const userFilter = {userId: {contains: currentUserData.userId}};
        const userRes = await API.graphql({
                query: listUserDatas,
                variables: { filter: userFilter, limit:10000 },
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            });
        dispachUser({ type: Consts.INITIAL_QUERY, userData:userRes.data.listUserDatas.items[0] });
    }
    
    useEffect(() => {
        // マウント時処理
        getUserData();
            
        // アンマウント時処理
        return;
    }, []);

    return (
        <Box className={localStyle.root}>
            <FitContent>
                {userData.userRole === Consts.USER_ROLE.Trainee ? 
                <TraineeNavigation valueSetValue={valueSetValue} />
                :
                userData.userRole === Consts.USER_ROLE.Coach ? 
                <CoachNavigation valueSetValue={valueSetValue} />
                :
                <Fragment></Fragment>
                }
            </FitContent>
        </Box>
    );
}
  