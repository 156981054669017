// AWS
import { API } from "aws-amplify";

// GraphQL
import { 
    updateTasktimeManagementDatas, 
    updateTaskDatas,
    deleteTaskDatas, 
} from '../../graphql/mutations';

// React
import { Fragment, useState } from 'react';

// Material-ui
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { 
    Box,
    Grid, 
    IconButton, 
    // Select, 
    // MenuItem, 
    TextField, 
    // InputLabel, 
    // FormControl, 
    Typography,
} from '@material-ui/core';

// Self Making Files
import { 
    AMAZON_COGNITO_USER_POOLS, 
    TASK_ATTRIBUTE_TITLE_END_TIME, 
    TASK_ATTRIBUTE_TITLE_START_TIME, 
    TASK_ATTRIBUTE_TITLE_TASK_CONTENTS,
    TASLTIMES_TASK_DETAIL_CAREFULL_MESSAGE,
    USER_ROLE,  
} from '../../Commons/Consts';
import { LoadingIcon } from "../../Commons/LoadingIcon";
import { commonStyles } from "../../Commons/CommonStyles";

const localStyles = makeStyles(theme => ({
    taskCard:{
        [theme.breakpoints.down('sm')]: {
            borderRadius: "10px",
            paddingTop: "13px",
            paddingLeft: "5px",
            marginBottom: "1px",
            backgroundColor: theme.palette.background.paper,
            border: "solid #AAAAAA 1px",
            '& .MuiInputBase-root':{
                paddingTop: "5px",
                paddingBottom: "5px",
                fontSize: "2vmin",
                '& .MuiInputBase-input':{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    fontSize: "2vmin",
                },
            },
            '& .MuiInputBase-root>fieldset>legend>span,.MuiFormLabel-root':{
                [theme.breakpoints.down('sm')]: {
                    fontSize: "4vmin",
                },
                [theme.breakpoints.up('md')]: {
                    fontSize: "2vmin",
                },
            },
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: "10px",
            paddingTop: "5px",
            paddingLeft: "5px",
            marginBottom: "1px",
            backgroundColor: theme.palette.background.paper,
            border: "solid #AAAAAA 1px",
            '& .MuiInputBase-root':{
                paddingTop: "5px",
                paddingBottom: "5px",
                fontSize: "1.5vmin",
                '& .MuiInputBase-input':{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    fontSize: "1.5vmin",
                },
            },
        },
    },
    formControl: {
        backgroundColor: theme.palette.background.paper,
    },
    timeField:{
        '& .MuiInputBase-input':{
            paddingLeft:"1px",
            paddingRight:"0px",
            fontSize: "2vmin",
        },
    },
    taskDetailField:{
        backgroundColor: theme.palette.background.drawerPaper,
    },
    taskNumber:{
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignContent: 'stretch',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'flex-start',
            paddingLeft:"7px",
            paddingTop:"2px",
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            paddingRight: "3px",
            paddingBottom:"5px",
        },
    },
    prioritySelect:{
        display: 'flex',
        alignContent: 'stretch',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    marginBottomRow:{
        alignItems: 'center',
        marginBottom: "6px",
        paddingRight:"5px",
    },
    loader: {
        display: 'flex',
        alignContent: 'stretch',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        paddingBottom: "12px",
    },
}));

export function PlanTaskCardList({ tasks, userRole }){
    return (
        <div>
            <Grid container spacing={0}>
                {tasks.length <= 0 ? 
                    <LoadingIcon/>
                    :  
                    tasks
                    .filter(data => { return data.planTaskCreated})
                    .sort((a, b) => {return a.createdAt < b.createdAt ? -1 : 1})
                    .map((taskData, index) => (
                        <PlanTaskCard 
                            taskData={taskData} 
                            userRole={userRole}
                            index={index}
                        />
                    ))
                }
            </Grid>
        </div>
    ); 
}

function PlanTaskCard({ taskData, userRole, index }){
    const localStyle = localStyles();
    const commonStyle = commonStyles();
    const [startTime, setStartTime] = useState(taskData.planTaskStartTime)
    const [endTime, setEndTime] = useState(taskData.planTaskEndTime)
    const [detail, setDetail] = useState(taskData.planTaskDetial)
    
    const updateTask = async () =>{
        // タブメニュー移動時のスピードについていくための即時反映
        taskData.resultTaskDetial = detail;
        taskData.resultTaskEndTime = endTime;
        taskData.resultTaskStartTime = startTime;
        
        await API.graphql({
            query: updateTaskDatas,
            variables: {
                input: {
                    id: taskData.id, 
                    planTaskDetial: detail,
                    planTaskEndTime: endTime,
                    planTaskStartTime: startTime,
                    resultTaskDetial: detail, 
                    resultTaskEndTime: endTime,
                    resultTaskStartTime: startTime, 
                }
            }
        });
        await API.graphql({
            query: updateTasktimeManagementDatas,
            variables: { 
                input: {
                    id: taskData.parentTasktimeManagementDatasID,
                    checkIn: true,
                    checkOut: false,
                },
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
    }

    const deleteTask = async() => {
        await API.graphql({
            query: deleteTaskDatas,
            variables: {
                input: {
                    id: taskData.id, 
                }
            }
        });
        
    }

    const clickDeleteIcon = async (event) => {
        await deleteTask();
    }

    const handleStartTimeChange = (event) => {
        setStartTime(event.target.value);
    };
    
    const handleEndTimeChange = (event) => {
        setEndTime(event.target.value);
    };
    
    // const handleTaskPriorityChange = (event) => {
    //     dispatchTask({ type:UPDATE, updateName:TASK_DATA_PLANTASKPRIORITY, updateValue:event.target.value });
    //     dispatchTask({ type:UPDATE, updateName:TASK_DATA_RESULTTASKPRIORITY, updateValue:event.target.value });
    // };
    
    const handleTaskDetialChange = (event) => {
        setDetail(event.target.value);
    };

    return(
        <Grid container xs={12} className={localStyle.taskCard}>
            <Grid container xs={12}>
                <Grid item xs={1} className={localStyle.taskNumber}>
                    <Typography>{index+1}</Typography>
                </Grid>
                <Grid container xs={userRole === USER_ROLE.SuperAdministrator ? 10 : 11}>
                    <Grid container className={localStyle.marginBottomRow}>
                        <Grid container xs={4}>
                            <Grid item xs={6}>
                                <Box className={commonStyle.mobileModeDisplay}>
                                    <TextField
                                        id="startTime"
                                        label={TASK_ATTRIBUTE_TITLE_START_TIME}
                                        type="time"
                                        variant="outlined"
                                        fullWidth
                                        disabled={userRole === USER_ROLE.Trainee ? false : true}
                                        value={startTime}
                                        className={localStyle.timeField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        onChange={handleStartTimeChange}
                                        onBlur={updateTask}
                                    />
                                </Box>
                                <Box className={commonStyle.pcModeDisplay}>
                                    <TextField
                                        id="startTime"
                                        type="time"
                                        variant="outlined"
                                        fullWidth
                                        disabled={userRole === USER_ROLE.Trainee ? false : true}
                                        value={startTime}
                                        className={localStyle.timeField}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        onChange={handleStartTimeChange}
                                        onBlur={updateTask}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box className={commonStyle.mobileModeDisplay}>
                                    <TextField
                                        id="endTime"
                                        label={TASK_ATTRIBUTE_TITLE_END_TIME}
                                        type="time"
                                        variant="outlined"
                                        fullWidth
                                        disabled={userRole === USER_ROLE.Trainee ? false : true}
                                        value={endTime}
                                        className={localStyle.timeField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        onChange={handleEndTimeChange}
                                        onBlur={updateTask}
                                    />
                                </Box>
                                <Box className={commonStyle.pcModeDisplay}>
                                    <TextField
                                        id="endTime"
                                        type="time"
                                        variant="outlined"
                                        fullWidth
                                        disabled={userRole === USER_ROLE.Trainee ? false : true}
                                        value={endTime}
                                        className={localStyle.timeField}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        onChange={handleEndTimeChange}
                                        onBlur={updateTask}
                                    />
                                </Box>                                
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <Box className={commonStyle.mobileModeDisplay}>
                                <TextField
                                    label={TASK_ATTRIBUTE_TITLE_TASK_CONTENTS}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    disabled={userRole === USER_ROLE.Trainee ? false : true}
                                    maxRows={3}
                                    value={detail}
                                    onChange={handleTaskDetialChange}
                                    onBlur={updateTask}
                                >
                                </TextField>
                            </Box>
                            <Box className={commonStyle.pcModeDisplay}>
                                <TextField
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    disabled={userRole === USER_ROLE.Trainee ? false : true}
                                    maxRows={3}
                                    className={localStyle.taskDetailField}
                                    value={detail === '' ? '' : detail}
                                    placeholder={TASLTIMES_TASK_DETAIL_CAREFULL_MESSAGE}
                                    onChange={handleTaskDetialChange}
                                    onBlur={updateTask}
                                >
                                </TextField>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {userRole === USER_ROLE.SuperAdministrator ?
                <Grid item xs={1}>
                    <Box className={commonStyle.centerOfCenterContents}>
                        <IconButton 
                            color="primary" 
                            aria-label="タスク削除" 
                            size="small"
                            onClick={clickDeleteIcon}
                        >
                            <DeleteForeverIcon/>
                        </IconButton>
                    </Box>
                </Grid>
                :
                <Fragment></Fragment>
                }
            </Grid>
        </Grid>
    );
}

