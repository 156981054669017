// React
import React from 'react';

// Material-ui
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmDialog({ confirmEvent, open, setOpen, caption, contentText, cancelText, agreeText }) {
    const handleClose = () => {
        setOpen(false);
    };
    const handleAgree = () => {
        confirmEvent();
        setOpen(false);
    };

    return (
        <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{caption}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {contentText}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                {cancelText}
            </Button>
            <Button onClick={handleAgree} color="primary" autoFocus>
                {agreeText}
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}