
import { Box, CircularProgress } from '@material-ui/core';
import { commonStyles } from "./CommonStyles";
export function LoadingIcon({ className }){
    if(!className){
        className = commonStyles().loader;
    }
    return (
        <Box className={className}>
            <CircularProgress size={100}/>
        </Box>);
}