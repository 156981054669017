// AWS
import { API } from "aws-amplify";

// React
import { 
    useEffect, 
    useState, 
} from 'react';

// Material UI
import { 
    Typography, 
    Box, 
} from '@material-ui/core';

// GraphQL
import { listConectIsCoachingToTargetTrainees, listCorporations, listOfferDatas, listRefusalOfferDatas, listScheduleEvents } from "../../graphql/queries";

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import * as Consts from '../../Commons/Consts';
import { 
    getCurrentUserData, 
    consoleLog, 
    getUserDataById, 
    getUserIconImage, 
} from "../../Commons/CommonFunctions";
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import SideMenu from "../../Commons/SideMenu";
import CoachingTraineeList from "../../components/CoachingManagement/CoachingTraineeList";
import CoachingTraineeDetail from "../../components/CoachingManagement/CoachingTraineeDetail";
import CSSideMenu from "../../Commons/CSSideMenu";
import { userDataReducer, userReducer } from "../../Commons/Reducers";

export default function CoachManagement(){
    const commonStyle = commonStyles();
    
    const [isLoading, setIsLoading] = useState(true);
    const [traineeList, setTraineeList] = useState([]);
    const [traineeId, setTraineeId] = useState();
    const [traineeData, setTraineeUserData] = useState([]);
    const [previewImage, setPreviewImage] = useState();
    const [events, setEventData] = useState([]);
    const [corporations, setCorporations] = useState([]);
    const [offers, setOffers] = useState([]);
    const [refOffers, setRefOffers] = useState([]);
    const [userData, dispatchCurrentUserData] = userReducer(userDataReducer, []);
    
    const setInitData = async () =>{
        try {
            setIsLoading(true);
            const currentUserData = await getCurrentUserData();
            dispatchCurrentUserData({ type: Consts.INITIAL_QUERY, userData: currentUserData });
            const targetTraineeDataRes = await API.graphql({
                query: listConectIsCoachingToTargetTrainees,
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            });
            
            setTraineeList(targetTraineeDataRes.data.listConectIsCoachingToTargetTrainees.items);
            // Get a specific item
            const traineeUserData = await getUserDataById(traineeId);
            setTraineeUserData(traineeUserData);
            if(traineeUserData.iconImage !== null){
                const file = await getUserIconImage(traineeUserData);
                setPreviewImage(file);
            }
            
            const corpRes = await API.graphql({
                query: listCorporations,
                variables: { limit:10000 },
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            });
            setCorporations(corpRes.data.listCorporations.items);
            
            const eventFilter = {
                userDatasID: {contains: traineeUserData.userId}
            }
            const eventRes = await API.graphql({
                query: listScheduleEvents,
                variables: { filter: eventFilter, limit:10000 },
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            });
            setEventData(eventRes.data.listScheduleEvents.items);
        
            // 閲覧対象のトレーニーユーザーに紐づく内定情報と辞退情報を取得し、State関数に保持
            const offerFilter = {
                userdatasID: {contains: traineeUserData.userId}
            }
            const offerRes = await API.graphql({
                query: listOfferDatas,
                variables: { filter: offerFilter, limit:1000 },
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            });
            setOffers(offerRes.data.listOfferDatas.items);

            const refusalOfferRes = await API.graphql({
                query: listRefusalOfferDatas,
                variables: { filter: offerFilter, limit:1000 },
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            });
            setRefOffers(refusalOfferRes.data.listRefusalOfferDatas.items);  
            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
        }
    }
    
    useEffect(() => {
        let abortCtrl = new AbortController();

        setInitData();
        
        // アンマウント時処理
        return() => {
            abortCtrl.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [traineeId]);

    return(
        <Box>
            <Header title={Consts.HEADER_TITLE_COACH_MANAGEMENT}/>
            <Box>
                <Box className={commonStyle.mobileModeDisplay}>
                    <Box className={commonStyle.pageContent} >
                        <CoachingTraineeList
                            trainees={traineeList}
                        />
                    </Box>
                </Box>
                <Box className={commonStyle.pcModeDisplay}>
                    <CSSideMenu/>
                    <Box className={commonStyle.secondSideMenu}>
                        <Box className={commonStyle.scrollArea}>
                            <CoachingTraineeList
                                trainees={traineeList}
                                setTraineeId={setTraineeId}
                            />
                        </Box>
                    </Box>
                    <Box className={commonStyle.secondSideBarOnPageContent} >
                        {traineeId !== undefined?
                            isLoading ?
                            <LoadingIcon/>
                            :
                            <CoachingTraineeDetail
                                traineeId={traineeId}
                                traineeData={traineeData}
                                previewImage={previewImage}
                                events={events}
                                corporations={corporations}
                                offers={offers}
                                refOffers={refOffers}
                            />
                        :
                        <Typography variant="h4" className={commonStyle.centerContents}>コーチを選択してください</Typography>
                        }
                    </Box>
                </Box>
            </Box>
            <Footer/>
        </Box>
    );
}
