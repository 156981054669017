// AWS React
import { API } from "aws-amplify";

// GraphQL
import { 
    createEventDetialDatas, 
} from '../../graphql/mutations';
import { 
    listEventDetialDatas, 
    getScheduleEvents, 
    getCorporations, 
} from "../../graphql/queries";
import { onCreateEventDetialDatas } from '../../graphql/subscriptions';

// React
import { 
    useEffect, 
    useState,
    useReducer, 
} from 'react';
import { useParams } from 'react-router-dom';

// Material-ui
import { makeStyles } from '@material-ui/core/styles';
import { 
    Typography, 
    Container, 
    Box, 
    Grid, 
    IconButton, 
    TextField, 
    Fab, 
    Paper,
} from '@material-ui/core';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import AddIcon from '@material-ui/icons/Add';

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { 
    corporationsReducer, 
    eventDetailsReducer, 
    eventReducer, 
    userReducer, 
} from "../../Commons/Reducers";
import { 
    AMAZON_COGNITO_USER_POOLS, 
    DETAIL_TYPE_HELPER_TEXT, 
    DETAIL_TYPE_LABEL, 
    ERROR_TYPE_ABORTERROR, 
    EVENT_TYPE, 
    FORMAT_STRING_YYYYMMDDHHMM, 
    INITIAL_QUERY, 
    INTERVIEW_DATE_HEADER_TITLE, 
    MEMO_EDITOR_CAPTION, 
    SUBSCRIPTION, 
} from "../../Commons/Consts";
import { 
    getCurrentUserData, 
    getStringFromDateForFormatString, 
    consoleLog, 
} from "../../Commons/CommonFunctions";
import { QuestionCardList } from "../../components/SelectionSituationCalender/QuestionCardList";
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import SideMenu from "../../Commons/SideMenu";

const localStyles = makeStyles(theme => ({
    titleHeader:{
        backgroundColor: "#AFAFAF",
    },
    typography: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        fontSize: "4vmin",
    },
    fabbut:{
        position: 'fixed',
        right: "2vh",
        bottom: "28vh",  
    },
    drawer:{
        border: "solid #0A0A0A 1px",
        position: 'fixed',
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        bottom: "11vh",
        zIndex: "drawer",
    },
}));

export default function CoachingEventDetailEditor(){
    const param = useParams();
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    
    const [userData, dispatchCurrentUserData] = useReducer(userReducer, []);
    const [corporations, dispatchCorporations] = useReducer(corporationsReducer, []);
    const [events, dispachEventData] = useReducer(eventReducer, []);
    const [eventDetails, dispachEventDetials] = useReducer(eventDetailsReducer, []);
    const [isLoading, setIsLoading] = useState(true);
    
    const initEventDetailEditor = async () =>{
        try {
            const currentUserData = await getCurrentUserData();
            dispatchCurrentUserData({ type: INITIAL_QUERY, user: currentUserData });
            
            const eventRes = await API.graphql({
                query: getScheduleEvents,
                variables: { id: param.eventId },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            consoleLog(currentUserData.userRole, eventRes);
            dispachEventData({ type: INITIAL_QUERY, events:eventRes.data.getScheduleEvents });
            
            const corpRes = await API.graphql({
                query: getCorporations,
                variables: { id: eventRes.data.getScheduleEvents.corporationsID },
                authMode: AMAZON_COGNITO_USER_POOLS,
            }); 
            consoleLog(currentUserData.userRole, corpRes);
            dispatchCorporations({ type: INITIAL_QUERY, corporations:corpRes.data.getCorporations });
        
            const filter = {parentScheduleEventsID: {contains: param.eventId}};
            const res = await API.graphql({
                query: listEventDetialDatas,
                variables: { filter: filter, limit:10000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            })
            consoleLog(currentUserData.userRole, res);
            dispachEventDetials({ type: INITIAL_QUERY, eventDetials:res.data.listEventDetialDatas.items });

            setIsLoading(false);
        } catch (e) {
            if (e.name === ERROR_TYPE_ABORTERROR) {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }

    const copyToClipboard = async () => {
        var resultText = '';
        const filter = {parentScheduleEventsID: {contains: param.eventId}};
        const res = await API.graphql({
            query: listEventDetialDatas,
            variables: { filter: filter, limit:10000 },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
        consoleLog(userData.userRole, res);
        const eventDetialDataList = res.data.listEventDetialDatas.items;
        for(const idx in eventDetialDataList){
            const num = Number(idx)+1;
            resultText += ('【設問' + num + '】\r\n');
            resultText += ('【' + eventDetialDataList[idx].caption + '】\r\n');
            resultText += (eventDetialDataList[idx].answer + '\r\n');
            resultText += '\r\n';
        }
        await global.navigator.clipboard.writeText(resultText);
    };
    
    const createEventDetialData = async () =>{
        const res = await API.graphql({
            query: createEventDetialDatas,
            variables: {
                input: {
                    answer: "", 
                    caption: "", 
                    parentScheduleEventsID: param.eventId
                }        
            }
        });
        consoleLog(userData.userRole, res);
    };

    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();
        initEventDetailEditor();
        
        const creSubscription = API.graphql({
            query:onCreateEventDetialDatas,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
          next: (msg) => {
            const eventDetials = msg.value.data.onCreateEventDetialDatas;
            dispachEventDetials({ type: SUBSCRIPTION, eventDetials: eventDetials });
          }
        });

        return () => {
            abortCtrl.abort()
            creSubscription.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header title={corporations.corporationName != null && events.eventName ? events.eventName + ':' + corporations.corporationName : ''}/>
            <SideMenu/>
            {isLoading ?
            <LoadingIcon/>
            :
            <Box className={commonStyle.pageContent} >
                <Grid 
                    container
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={localStyle.titleHeader}
                    spacing={0}
                >
                    <Grid
                        item                         
                        xs={2}
                    >
                    </Grid>
                    <Grid
                        item                         
                        xs={8}
                    >
                        <Typography
                            gutterBottom 
                            variant="h5"
                            align="center"
                            component="div"
                            className={localStyle.typography}
                        >
                            {events.deadlineDateTime != null ? INTERVIEW_DATE_HEADER_TITLE + getStringFromDateForFormatString(new Date(events.deadlineDateTime), FORMAT_STRING_YYYYMMDDHHMM) : '面接日'}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton aria-label="linkcopy" onClick={copyToClipboard}>
                            <InsertLinkIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                {events.eventType === EVENT_TYPE.EntrySheet.Value ?
                    <Box pt={1}></Box>
                    :
                    <Container 
                            className={commonStyle.borders}
                            justifyContent="center"
                        >
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <TextField 
                                    fullWidth 
                                    label={DETAIL_TYPE_LABEL}
                                    helperText={DETAIL_TYPE_HELPER_TEXT}
                                    id="inputFormType" 
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                    value={events.formType}
                                />
                            </Grid>
                        </Grid>
                        <Box pt={1}></Box>
                    </Container>
                }
                <Container>
                    <QuestionCardList
                        eventDetails={eventDetails}
                        userRole={userData.userRole}
                        readOnly
                    />
                </Container>
                <Box className={commonStyle.endBox}/>
                <Fab color="primary" aria-label="add" className={localStyle.fabbut} onClick={createEventDetialData}>
                    <AddIcon />
                </Fab>
            </Box>
            }
            <Paper className={localStyle.drawer}>
                <TextField
                    id="memoEditor"
                    fullWidth
                    multiline
                    minRows={5}
                    maxRows={20}
                    InputLabelProps={{ shrink: true }}
                    label={MEMO_EDITOR_CAPTION}
                />
            </Paper>
            <Footer/>
        </Box>
    );
}
