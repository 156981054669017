// AWS
import { Auth } from "aws-amplify";

// React
import { 
    useEffect, 
    useState, 
    useReducer, 
    Fragment 
} from 'react';
import { useHistory } from 'react-router-dom';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { 
    Typography, 
    Container, 
    Box, 
    Grid, 
    Avatar, 
    Button, 
} from '@material-ui/core';

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { 
    CORPORATION_MANAGEMENT_HEADER_TITLE,
    HEADER_TITLE_MYPAGE,
    INITIAL_QUERY,
    TODAYS_SHOTA_ARAI_HEADER_TITLE,
    URL_PATH_CORPORATION_MASTER_MANAGEMENT,
    URL_PATH_MODULE_TEST_2,
    URL_PATH_MYPAGE_ABUILD_BIBLE, 
    URL_PATH_MYPAGE_MY_ES_LIST, 
    URL_PATH_MYPAGE_OFFER_LIST, 
    URL_PATH_MYPAGE_PROFILE_EDIT, 
    URL_PATH_MYPAGE_REFUSAL_LIST, 
    URL_PATH_MYPAGE_TRAINEE_RULES,
    URL_PATH_REGISTRATION,
    URL_PATH_TODAYS_WORDS_MASTER_MANAGEMENT,
    USER_ROLE, 
} from '../../Commons/Consts';
import { 
    getCurrentUserData, 
    getCurrentUserIconImage, 
    consoleLog, 
} from "../../Commons/CommonFunctions";
import ConfirmDialog from "../../components/SelectionSituationCalender/ConfirmDialog";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import { userDataReducer } from "../../Commons/Reducers";
import { commonStyles } from "../../Commons/CommonStyles";
import SideMenu from "../../Commons/SideMenu";

const localStyles = makeStyles(theme => ({
    avater:{ 
        [theme.breakpoints.down('sm')]: {
            width: 64,
            height: 64,
        },
        [theme.breakpoints.up('md')]: {
            width: 128,
            height: 128,
        },
    },
    profileButton:{
        height: 50,
        marginBottom:10,
    },
    userName:{
        fontSize: "5vmin",
    },
    universityName:{
        fontSize: "4vmin",
    },
    buttonArea:{
        padding: "10px",
    },
    lastButtonArea:{
        width: "76.5%",
        position: "fixed",
        bottom: 0,
        padding: "10px",
    },
}));

export default function Mypage(){
    const history = useHistory();
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [signoutConfirmOpen, setSignoutConfirmOpen] = useState(false);
    
    const [userData, dispatchUserData] = useReducer(userDataReducer, []);
    const [previewImage, setPreviewImage] = useState();
    const setInitData = async () =>{
        try {
            // Get a specific item
            const currentUserData = await getCurrentUserData();
            dispatchUserData({ type: INITIAL_QUERY, userData: currentUserData });
            const previewImage = await getCurrentUserIconImage(currentUserData);
            setPreviewImage(previewImage);
            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
        }
    }

    const currentUserSignOut = async () => {
        try {
            await Auth.signOut();
        } catch (error) {
            consoleLog(userData.userRole, 'error signing out: ' + error);
        }
    }

    const onClickSignout = () =>{
        setSignoutConfirmOpen(true);
    }
    
    useEffect(() => {
        // アンマウント時処理
        let abortCtrl = new AbortController();

        setInitData();
        return() => {
            abortCtrl.abort()
        }
    }, []);

    return(
        <Box>
            <Header title={HEADER_TITLE_MYPAGE}/>
            <SideMenu/>
            {isLoading?
            <LoadingIcon/>
            :
            <Box>
                <Box className={commonStyle.pageContent} >
                    <Box pt={2}>
                        <Container>
                            <Box className={commonStyle.mobileModeDisplay}>
                                <Grid container>
                                    <Grid item xs={3} className={commonStyle.centerContents}>
                                        <Avatar
                                            src={previewImage}
                                            className={localStyle.avater}
                                        />
                                    </Grid>
                                    <Grid container xs={9}>
                                        <Grid item xs={12}>
                                            <Typography className={localStyle.userName} >{userData.lastName}{userData.firstName}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className={localStyle.universityName} >{userData.universityName}/{userData.departmentName}/{userData.courseName}</Typography>        
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={commonStyle.pcModeDisplay}>
                                <Grid container>
                                    <Grid item xs={3} />
                                    <Grid container xs={6}>
                                        <Grid item xs={2} />
                                        <Grid item xs={3}>
                                            <Avatar
                                                src={previewImage}
                                                className={localStyle.avater}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={commonStyle.centerContents}>
                                            <Typography className={localStyle.userName} >{userData.lastName}{userData.firstName}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3} />
                                </Grid>
                            </Box>
                        </Container>
                        <Box mt={2}>
                            <Box className={commonStyle.mobileModeDisplay}>
                                <Container>
                                    <Button 
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        className={localStyle.profileButton}
                                        onClick={() => {
                                            history.push(URL_PATH_MYPAGE_PROFILE_EDIT);
                                        }}>プロフィール編集</Button>
                                    <Button 
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        className={localStyle.profileButton}
                                        onClick={() => {
                                            history.push(URL_PATH_MYPAGE_TRAINEE_RULES);
                                        }}>トレーニー十戒</Button>
                                    {/* <Button 
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        className={localStyle.profileButton}
                                        onClick={() => {
                                            history.push(URL_PATH_MYPAGE_ABUILD_BIBLE);
                                        }}>Abuild教典</Button> */}
                                    {userData.userRole === USER_ROLE.Trainee?
                                    <div>
                                        {/* <Button 
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            className={localStyle.profileButton}
                                            onClick={() => {
                                                history.push(URL_PATH_MYPAGE_MY_ES_LIST);
                                            }}>ES一覧</Button>
                                        <Button 
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            className={localStyle.profileButton}
                                            onClick={() => {
                                                history.push(URL_PATH_MYPAGE_OFFER_LIST);
                                            }}>内定企業一覧</Button>
                                        <Button 
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            className={localStyle.profileButton}
                                            onClick={() => {
                                                history.push(URL_PATH_MYPAGE_REFUSAL_LIST);
                                            }}>辞退企業一覧</Button> */}
                                    </div>
                                    :
                                    <Fragment></Fragment>
                                    }
                                    <Button 
                                    variant="contained"
                                    color="text.secondary"
                                    fullWidth
                                    className={localStyle.profileButton}
                                    onClick={onClickSignout}>サインアウト</Button>
                                    {userData.userRole === USER_ROLE.SuperAdministrator ?
                                    <div>
                                    <Button 
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        className={localStyle.profileButton}
                                        onClick={()=>{history.push(URL_PATH_REGISTRATION);}}>
                                        テスト用初期登録画面
                                    </Button>
                                    <Button 
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        className={localStyle.profileButton}
                                        onClick={()=>{history.push(URL_PATH_TODAYS_WORDS_MASTER_MANAGEMENT);}}>
                                        {TODAYS_SHOTA_ARAI_HEADER_TITLE}
                                    </Button>
                                    <Button 
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        className={localStyle.profileButton}
                                        onClick={()=>{history.push(URL_PATH_CORPORATION_MASTER_MANAGEMENT);}}>
                                        {CORPORATION_MANAGEMENT_HEADER_TITLE}
                                    </Button>
                                    <Button 
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        className={localStyle.profileButton}
                                        onClick={()=>{history.push(URL_PATH_MODULE_TEST_2);}}>
                                        通知テスト
                                    </Button>
                                    </div>
                                    :
                                    <Fragment></Fragment>
                                    }
                                    
                                </Container>
                            </Box>
                            <Box className={commonStyle.pcModeDisplay}>
                                <Container>
                                    <Grid container>
                                        <Grid item xs={6} className={localStyle.buttonArea} >
                                            <Button 
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={localStyle.profileButton}
                                                onClick={() => {
                                                    history.push(URL_PATH_MYPAGE_PROFILE_EDIT);
                                                }}>プロフィール編集</Button>
                                        </Grid>
                                        <Grid item xs={6}  className={localStyle.buttonArea} >
                                            <Button 
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={localStyle.profileButton}
                                                onClick={() => {
                                                    history.push(URL_PATH_MYPAGE_TRAINEE_RULES);
                                                }}>トレーニー十戒</Button>
                                        </Grid>
                                        {/*<Grid item xs={6} className={localStyle.buttonArea} >
                                             <Button 
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={localStyle.profileButton}
                                                onClick={() => {
                                                    history.push(URL_PATH_MYPAGE_ABUILD_BIBLE);
                                                }}>Abuild教典</Button> 
                                        </Grid>*/}
                                        {userData.userRole === USER_ROLE.Trainee?
                                        <div>
                                            {/* <Grid item xs={6}  className={localStyle.buttonArea} >
                                                    <Button 
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    className={localStyle.profileButton}
                                                    onClick={() => {
                                                        history.push(URL_PATH_MYPAGE_MY_ES_LIST);
                                                    }}>ES一覧</Button>
                                                    </Grid>
                                                <Grid item xs={6}  className={localStyle.buttonArea} >
                                                    <Button 
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    className={localStyle.profileButton}
                                                    onClick={() => {
                                                        history.push(URL_PATH_MYPAGE_OFFER_LIST);
                                                    }}>内定企業一覧</Button>
                                                </Grid>
                                                <Grid item xs={6}  className={localStyle.buttonArea} >
                                                    <Button 
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    className={localStyle.profileButton}
                                                    onClick={() => {
                                                        history.push(URL_PATH_MYPAGE_REFUSAL_LIST);
                                                    }}>辞退企業一覧</Button>
                                                </Grid>*/}
                                        </div>
                                        :
                                        <Fragment></Fragment>
                                        }
                                    </Grid>
                                    <Grid container className={localStyle.lastButtonArea}>
                                        <Grid item xs={12} >
                                            <Button 
                                            variant="contained"
                                            color="text.secondary"
                                            fullWidth
                                            className={localStyle.profileButton}
                                            onClick={onClickSignout}>サインアウト</Button>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Box>
                            <Box className={commonStyle.endBox}/>
                        </Box>
                    </Box>
                    <ConfirmDialog 
                        confirmEvent={currentUserSignOut}
                        open={signoutConfirmOpen}
                        setOpen={setSignoutConfirmOpen}
                        caption='サインアウト確認'
                        contentText='サインアウトします。よろしいですか？'
                        cancelText='サインアウトしない'
                        agreeText='サインアウトする'
                    />
                </Box>
            </Box>
            }
            <Footer/>
        </Box>
    );
}
