// AWS React
import { 
    useEffect, 
    useState, 
    useReducer,
    Fragment, 
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
    Typography, 
    Box, 
    Grid, 
    Avatar, 
    Badge, 
    IconButton, 
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

// React-Icons
import { ArrowForwardIos } from '@material-ui/icons';

// Self Making Files
import * as Consts from '../../Commons/Consts';
import { 
    getUserDataById, 
    getUserIconImage,
    recursiveGrapheql, 
    consoleLog, 
} from '../../Commons/CommonFunctions';
import { 
    chatDatasReducer, 
    chatroomMemberReducer,
    commonDBDataReducer, 
} from "../../Commons/Reducers";
import { API } from 'aws-amplify';
import { listChatDatas, listTodayWords, listTraineeDatas } from '../../graphql/queries';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const localStyles = makeStyles(theme => ({
    roomCard:{
        padding: "5px",
        marginTop: "10px",
        marginBottom: "10px",
        cursor: "pointer",
        minHeight: "11vh",
        backgroundColor: theme.palette.background.drawerPaper,
        borderRadius: "5px",
        "&:hover": {
            background: theme.palette.background.selectMenu,
        },
    },
    selectRoomCard:{
        padding: "5px",
        marginTop: "10px",
        marginBottom: "10px",
        cursor: "pointer",
        minHeight: "11vh",
        backgroundColor: theme.palette.background.selectMenu,
        borderRadius: "5px",
    },
    roomStatusTextArea:{
        [theme.breakpoints.down('sm')]: {
            '& .MuiTypography-h5':{
                fontSize: "4vmin",
                fontWeight: "500",
            },
            '& .MuiTypography-h6':{
                fontSize: "4vmin",
            },
        },
        [theme.breakpoints.up('md')]: {
            '& .MuiTypography-h5':{
                fontSize: "2vmin",
                fontWeight: "500",
            },
            '& .MuiTypography-h6':{
                fontSize: "2vmin",
            },
        }
    },
    centerContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
    loader: {
        display: 'flex',
        alignContent: 'stretch',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        paddingBottom: "12px",
    },
    massgeIcon:{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper,
    },
}));

export function ChatRoomList({ chatrooms, user, setSendToUserData, dispatchChatDatas, setSelectId, selectId }){
    const localStyle = localStyles();
    return (
        <div>
            {user.userRole === Consts.USER_ROLE.Trainee ?
            <TodayWordsListItem user={user} setSendToUserData={setSendToUserData} selectId={selectId} />
            :
            <Fragment></Fragment>
            }
            {chatrooms.length <= 0 ? 
            <Box className={localStyle.loader}>
                
            </Box>
            :  
            chatrooms.map((chatroom) => {
                if(setSendToUserData){
                    return (<PCChatRoomListItem
                        chatroom={chatroom}
                        user={user}
                        setSendToUserData={setSendToUserData}
                        dispatchChatDatas={dispatchChatDatas}
                        setSelectId={setSelectId}
                        selectId={selectId}
                    />);
                }
                else{
                    return (<ChatRoomListItem
                        chatroom={chatroom}
                        user={user}
                    />);
                }
            })
            }
        </div>
    );
}

function TodayWordsListItem({ user, setSendToUserData, selectId }){
    const history = useHistory();
    const localStyle = localStyles();
    const [todaysWordsData, setTodayWordsData] = useState([]);
    const initTodayWordsListItem = async () =>{
        const traineeFilter = { userdatasID: {contains: user.userId} }
        const traineeRes = await API.graphql({
            query: listTraineeDatas,
            variables: { filter: traineeFilter , limit:10000 },
            authMode: Consts.AMAZON_COGNITO_USER_POOLS,
        });
        const traineeData = traineeRes.data.listTraineeDatas.items;
        
        const wordsRes = await API.graphql({
            query: listTodayWords,
            variables: { limit: 100 },
            authMode: Consts.AMAZON_COGNITO_USER_POOLS,
        });
        if(0 < wordsRes.data.listTodayWords.items.length){
            var word = wordsRes.data.listTodayWords.items.find((word) => word.displayNumber === 1);
            const curriculumStartDate = new Date(traineeData.at(0).curriculumStartDate);
            const nowDate = new Date();
            const diffDays = parseInt((nowDate - curriculumStartDate) / 1000 / 60 / 60 / 24);
            if(diffDays <= 1){
                word = wordsRes.data.listTodayWords.items.find((word) => word.displayNumber === 1);
            }else if(diffDays <= 90){
                word = wordsRes.data.listTodayWords.items.find((word) => word.displayNumber === diffDays);
            }else{
                word = wordsRes.data.listTodayWords.items.find((word) => word.displayNumber === 90);
            }
            setTodayWordsData(word);
        }
    }

    const onClickChatroomGird = async () => {
        if(setSendToUserData){
            history.push(Consts.URL_PATH_CHATROOMS + Consts.URL_PARTS_SLASH + 'twh');
        }
        else{
            history.push(Consts.URL_PATH_CS_EVENT_TODAY_WORDS_HISTORY);
        }
    }
    
    useEffect(() => {
        initTodayWordsListItem();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={selectId === 'twh' ? localStyle.selectRoomCard : localStyle.roomCard}
            rowSacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            onClick={onClickChatroomGird}
        >
            <Grid container>
                <Grid item xs={2} className={localStyle.centerContents}>
                    <Avatar className={localStyle.massgeIcon}>
                        <MailOutlineIcon/>
                    </Avatar>                
                </Grid>
                <Grid container xs={9} className={localStyle.roomStatusTextArea}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={11}>
                        <Typography variant='h5' >
                            {Consts.TODAYS_SHOTA_ARAI_HEADER_TITLE}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item zeroMinWidth xs={11}>
                        <Typography variant='h6' noWrap>{todaysWordsData.caption}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={1} className={localStyle.centerContents}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                    >
                        <ArrowForwardIos />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
}

function ChatRoomListItem({ chatroom, user }){
    const history = useHistory();
    const localStyle = localStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [imageLoading] = useState(false);
    const [chatData, dispatchChatDatas] = useReducer(commonDBDataReducer, []);
    const [chatroomMember, dispatchChatroomMember] = useReducer(chatroomMemberReducer, []);
    const [unreadCount] = useState(chatroom.ChatDatas.items.filter(item => {return item.checkOn === false && item.SendFromID !== user.userId}).length);
    const [previewImage, setPreviewImage] = useState();
    const initChatroomDetail = async () =>{
        var memberUserData = null
        if(user.userRole === Consts.USER_ROLE.Trainee){
            // トレーニーの場合は、チェットルームのデータからメンバーデータを抜き出し、その情報を基づいてメンバーのユーザー情報を取得し、State変数へ保持
            memberUserData  = await getUserDataById(chatroom.ConectIsChatroomMembers.items[0].chatroomMemberUserDatasID);
            dispatchChatroomMember({ type: Consts.INITIAL_QUERY, chatroomMember:memberUserData });
        }else{
            // トレーニー以外の場合は、チャットルームIDからチャットルームメンバー中間テーブルからメンバーのユーザー情報を取得し、State変数へ保持
            memberUserData = await getUserDataById(chatroom.roomMasterUserDatasID);
            dispatchChatroomMember({ type: Consts.INITIAL_QUERY, chatroomMember:memberUserData });
        }
        // チャットルームデータからチャットデータを取得し、State変数へ保持
        const chatdataFilter = { chatroomID: {contains: chatroom.id} }
        await recursiveGrapheql(listChatDatas, 'listChatDatas', { filter:chatdataFilter, limit:100000 , nextToken: null}, dispatchChatDatas);
        
        // アイコンデータの取得
        const previewImage = await getUserIconImage(memberUserData);
        setPreviewImage(previewImage);
        setIsLoading(false);
    }

    const onClickChatroomGird = async () => {
        history.push(Consts.URL_PATH_CHATROOMS_DETAIL + Consts.URL_PARTS_SLASH + chatroom.id);
    }
    
    useEffect(() => {
        initChatroomDetail();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={localStyle.roomCard}
            rowSacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            onClick={onClickChatroomGird}
        >
            {isLoading?
            <Box className={localStyle.loader}>   
            </Box>
            :
            <Grid container>
                <Grid item xs={2} className={localStyle.centerContents}>
                    {/* <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                    >                                  
                    </StyledBadge> */}

                    {user.userRole === Consts.USER_ROLE.Coach ?
                        unreadCount <= 0 ?
                            imageLoading? 
                                <Box className={localStyle.loader}>   
                                </Box>
                            :
                                <Avatar
                                    src={previewImage}
                                />
                        :
                        <Badge 
                            badgeContent={unreadCount}
                            color="secondary"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            {imageLoading? 
                            <Box className={localStyle.loader}>   
                            </Box>
                            :
                            <Avatar
                                src={previewImage}
                            />
                            }
                        </Badge>
                    :
                        imageLoading? 
                                <Box className={localStyle.loader}>   
                                </Box>
                            :
                                <Avatar
                                    src={previewImage}
                                />
                    }                  
                </Grid>
                <Grid container xs={9} className={localStyle.roomStatusTextArea}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={11}>
                        <Typography variant='h5' >
                            {chatroomMember.displayName === null ?
                            chatroomMember.lastName + ' ' + chatroomMember.firstName
                            :
                            chatroomMember.displayName
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item zeroMinWidth xs={11}>
                        <Typography variant='h6' noWrap>
                            {chatData.length <= 0 ?
                            'メッセージはありません。'
                            :
                            chatData.sort((a,b) => {return a.chatTimestamp < b.chatTimestamp ? 1 : -1})[0].chatMessage
                            }
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={1} className={localStyle.centerContents}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                    >
                        <ArrowForwardIos />
                    </IconButton>
                </Grid>
            </Grid>
            }
        </Grid>
    );
}
function PCChatRoomListItem({ chatroom, user, dispatchChatDatas, setSendToUserData, setSelectId, selectId }){
    const localStyle = localStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [imageLoading] = useState(false);
    const [chatData, setChatData] = useState([]);
    const [chatroomMember, dispatchChatroomMember] = useReducer(chatroomMemberReducer, []);
    const [unreadCount] = useState(chatroom.ChatDatas.items
        .filter(item => {return item.checkOn === false && item.SendFromID !== user.userId})
        .length);
    const [previewImage, setPreviewImage] = useState();
    const initChatroomDetail = async () =>{
        try{
            var memberUserData = null
            if(user.userRole === Consts.USER_ROLE.Trainee){
                // トレーニーの場合は、チェットルームのデータからメンバーデータを抜き出し、その情報を基づいてメンバーのユーザー情報を取得し、State変数へ保持
                memberUserData  = await getUserDataById(chatroom.ConectIsChatroomMembers.items[0].chatroomMemberUserDatasID);
                dispatchChatroomMember({ type: Consts.INITIAL_QUERY, chatroomMember:memberUserData });
            }else{
                // トレーニー以外の場合は、チャットルームIDからチャットルームメンバー中間テーブルからメンバーのユーザー情報を取得し、State変数へ保持
                memberUserData = await getUserDataById(chatroom.roomMasterUserDatasID);
                dispatchChatroomMember({ type: Consts.INITIAL_QUERY, chatroomMember:memberUserData });
            }
            // チャットルームデータからチャットデータを取得し、State変数へ保持
            setChatData(chatroom.ChatDatas.items);
            setSendToUserData(chatroomMember);

            // アイコンデータの取得
            const previewImage = await getUserIconImage(memberUserData);
            setPreviewImage(previewImage);
            setIsLoading(false);
        } catch (e) {
            if (e.name === Consts.ERROR_TYPE_ABORTERROR) {
                // something to do when error occur
                consoleLog(user.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }

    const onClickChatroomGird = async () => {
        const chatdataFilter = { chatroomID: {contains: chatroom.id} }
        await recursiveGrapheql(listChatDatas, 'listChatDatas', { filter:chatdataFilter, limit:1000 , nextToken: null}, dispatchChatDatas);
        setSelectId(chatroom.id);
        setSendToUserData(chatroomMember);
        history.push(Consts.URL_PATH_CHATROOMS + Consts.URL_PARTS_SLASH + chatroom.id);
    }
    
    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();

        initChatroomDetail();
        // アンマウント時処理
        // アンマウント時処理
        return () => {
            abortCtrl.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={selectId === 'twh' ? localStyle.roomCard : chatroom.id === selectId ? localStyle.selectRoomCard : localStyle.roomCard}
            rowSacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            onClick={onClickChatroomGird}
        >
            {isLoading?
            <Box className={localStyle.loader}>   
            </Box>
            :
            <Grid container>
                <Grid item xs={2} className={localStyle.centerContents}>
                    {/* <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                    >                                  
                    </StyledBadge> */}

                    {user.userRole === Consts.USER_ROLE.Coach ?
                        unreadCount <= 0 ?
                            imageLoading? 
                                <Box className={localStyle.loader}>   
                                </Box>
                            :
                                <Avatar
                                    src={previewImage}
                                />
                        :
                        <Badge 
                            badgeContent={unreadCount}
                            color="secondary"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            {imageLoading? 
                            <Box className={localStyle.loader}>   
                            </Box>
                            :
                            <Avatar
                                src={previewImage}
                            />
                            }
                        </Badge>
                    :
                        imageLoading? 
                                <Box className={localStyle.loader}>   
                                </Box>
                            :
                                <Avatar
                                    src={previewImage}
                                />
                    }                  
                </Grid>
                <Grid container xs={9} className={localStyle.roomStatusTextArea}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={11}>
                        <Typography variant='h5' >
                            {chatroomMember.displayName === null ?
                            chatroomMember.lastName + ' ' + chatroomMember.firstName
                            :
                            chatroomMember.displayName
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item zeroMinWidth xs={11}>
                        <Typography variant='h6' noWrap>
                            {chatData.length <= 0 ?
                            'メッセージはありません。'
                            :
                            chatData.sort((a,b) => {return a.chatTimestamp < b.chatTimestamp ? 1 : -1})[0].chatMessage
                            }
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={1} className={localStyle.centerContents}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                    >
                        <ArrowForwardIos />
                    </IconButton>
                </Grid>
            </Grid>
            }
        </Grid>
    );
}
  
