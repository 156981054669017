import { 
    useHistory, 
} from 'react-router-dom';

// React-Calendar
import Calendar from 'react-calendar';
import styled from 'styled-components';
import 'react-calendar/dist/Calendar.css';

// Material=ui
import { makeStyles } from '@material-ui/core/styles';
import { 
    Box, Grid, IconButton, Typography, 
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

// Self Making Files
import { 
    CALENDAR_VIEW_MONTH,
    ERROR_TYPE_ABORTERROR,
    PARAM_PCMODE_TRUE,
    URL_PARTS_SLASH,
    URL_PATH_CS_TRAINEE_MANAGEMENT,
    URL_PATH_TASKTIMES,
    URL_PATH_TASKTIMES_CREATE,
    URL_PATH_TASKTIMES_DETAIL,
    USER_ROLE, 
} from '../../Commons/Consts';
import { 
    formatDateStrForYYYYMMDD, 
    getStringFromDateForFormatString, 
    consoleLog, 
    nonZerosuppresFormatDate,
} from '../../Commons/CommonFunctions';

import { commonStyles } from '../../Commons/CommonStyles';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Fragment, useEffect, useState } from 'react';
import { Palette } from '@material-ui/icons';
import { LoadingIcon } from '../../Commons/LoadingIcon';

const CalendarContainer = styled.div`
    /* ~~~ container styles ~~~ */
    width: 100%;
    max-height: 100%;
    .react-calendar{
        width: 100%;
        border-radius: 10px;
        
        /* ~~~ navigation styles ~~~ */
        .react-calendar__navigation {
            display: flex;
            max-height: 4vh;
            margin-bottom: 0px;

            .react-calendar__navigation__label {
                font-weight: bold;
                color:#000000;
            }

            .react-calendar__navigation__arrow {
                flex-grow: 0.333;
            }
            .react-calendar__navigation__arrow react-calendar__navigation__prev-button {
                width: 200px;
            }
            .react-calendar__navigation__label__labelText react-calendar__navigation__label__labelText--from{
                width: 200px;
            }
            .react-calendar__navigation__arrow react-calendar__navigation__next-button {
                width: 200px;
            }    
        }
        
        /* ~~~ calendar tile styles ~~~ */
        .react-calendar__tile{
            min-height: 9vh;
            padding-top: 5px;
            padding-right: 0px;
            padding-bottom: 0px;
            padding-left: 0px;
            width: 98%;
        }
        .react-calendar__tile:nth-child(7n) {
            color: #00F;
        }
        .react-calendar__month-view__weekdays{
            border: solid #aaaaaa;
            border-top-width: 0.1vmin;
            border-bottom-width: 0.1vmin;
            border-left-width: 0;
            border-right-width: 0;
        }
        
        .react-calendar__month-view__days__day--neighboringMonth {
            color: #757575 !important;
          }
        /* ~~~ button styles ~~~ */
        .react-calendar__viewContainer {
            button {
                border: none;
            }
        }
        .taskIconRoot{
            width: 45%;
            height: 65%;
            border-radius: 50%;
            box-shadow:  0 3px 5px 0 rgba(0, 0, 0, .5);
        }
        .taskSttingIcon{
            border: solid #AAAAAA 2px;
            background-color: #FFFFFF;
        }
        .taskCheckingIcon{
            background-color: #f8bbd0;
        }
        .taskCheckOutingIcon{
            background-color: #64b5f6;
            svg{
                width: 85%;
                height: 85%;
                color: #FFFFFF;
            }
        }
    }
`;

const localStyles = makeStyles(theme => ({
    calenderBody:{
        width: "100%",
    },
    navigationBar:{
        minHeight: "3vh",
        height: "5vh",
        "& .MuiIconButton-root":{
            padding: 0,
        },
    },
    dayBar:{
        display:"flex",
    },
    dayBox:{
        width: "14.285714%",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
    calenderTile:{
        minHeight: "5vh",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    dateTileBar:{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    dateTile:{
        width: "13.285714%",
        [theme.breakpoints.down('sm')]: {
            minHeight: "50px",
            marginLeft: "0.5%",
            marginRight: "0.5%",
            marginBottom: "1%",
            boxShadow: "1.09631px 1.09631px 2.19262px rgba(0, 0, 0, 0.25)",
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "6vh",
            height: "7.5vh",
            marginLeft: "0.5%",
            marginRight: "0.5%",
            marginBottom: "1%",
            boxShadow: "1.09631px 1.09631px 2.19262px rgba(0, 0, 0, 0.25)",
        },
        backgroundColor: theme.palette.background.paper,
        cursor: "pointer",
        "& #click-tile":{
            height: "100%",
        }
    },
    todayDateTile:{
        width: "13.285714%",
        [theme.breakpoints.down('sm')]: {
            minHeight: "50px",
            marginLeft: "0.5%",
            marginRight: "0.5%",
            marginBottom: "1%",
            boxShadow: "1.09631px 1.09631px 2.19262px rgba(0, 0, 0, 0.25)",
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "6vh",
            height: "7.5vh",
            marginLeft: "0.5%",
            marginRight: "0.5%",
            marginBottom: "1%",
            boxShadow: "1.09631px 1.09631px 2.19262px rgba(0, 0, 0, 0.25)",
        },
        backgroundColor: theme.palette.background.menuHover,
        cursor: "pointer",
        "& #click-tile":{
            height: "100%",
        }
    },
    selectDateTile:{
        width: "13.285714%",
        [theme.breakpoints.down('sm')]: {
            minHeight: "50px",
            marginLeft: "0.5%",
            marginRight: "0.5%",
            marginBottom: "1%",
            boxShadow: "1.09631px 1.09631px 2.19262px rgba(0, 0, 0, 0.25)",
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "6vh",
            height: "7.5vh",
            marginLeft: "0.5%",
            marginRight: "0.5%",
            marginBottom: "1%",
            boxShadow: "1.09631px 1.09631px 2.19262px rgba(0, 0, 0, 0.25)",
        },
        backgroundColor: theme.palette.background.boxPaper,
        cursor: "pointer",
        "& #click-tile":{
            height: "100%",
        }
    },
    displayDateBox:{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
    iconArea:{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
    taskCheckingIcon:{
        [theme.breakpoints.down('sm')]: {
            width: "20px",
            height: "20px",
        },
        [theme.breakpoints.up('md')]: {
            width: "3vh",
            height: "3vh",
        },
        backgroundColor: theme.palette.secondary.main,
    },
    taskCheckOutIcon:{
        [theme.breakpoints.down('sm')]: {
            width: "20px",
            height: "20px",
        },
        [theme.breakpoints.up('md')]: {
            width: "3vh",
            height: "3vh",
        },
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        color: "#ffffff",
    },
    todayWordsCard:{
        backgroundColor: "#ffffff",
        border: "solid #aaaaaa 0.1vmin",
        borderRadius: "5px",
        paddingTop: "5px",
        paddingRight: "5px",
        paddingLeft: "4px",
        paddingBottom: "5px",
        margin: "3px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        '& .MuiTypography-h4':{
            fontSize: "4vmin",
            fontWeight: "bold",
        },
        '& .MuiTypography-h5':{
            fontSize: "4vmin",
        },
        '& .MuiTypography-h6':{
            fontSize: "3vmin",
            wordBreak: "break-word",
            maxHeight: "15vh",
            overflow: "scroll",
        }
    },
}));

function getFirstDate (date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

function getLastDate (date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

function DateTileContent({calenderData, ttm, traineeId, initDate, userData, pcMode, selectDate}){
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    const history = useHistory();
    const now = getStringFromDateForFormatString(new Date(), 'YYYY-MM-DD');
    
    const day = new Date(new Date(new Date(initDate).setDate(calenderData)).setHours(9));
    return (
        <Box className={selectDate === getStringFromDateForFormatString(day, 'YYYYMMDD') ? localStyle.selectDateTile :
            now !== getStringFromDateForFormatString(day, 'YYYY-MM-DD') ? localStyle.dateTile : localStyle.todayDateTile}>
            {ttm[calenderData] !== undefined ?
                <Box
                    id='click-tile'
                    onClick={(event) => {
                        if(userData.userRole === USER_ROLE.Trainee)
                        {
                            if(pcMode){
                                history.push(URL_PATH_TASKTIMES + URL_PARTS_SLASH + formatDateStrForYYYYMMDD(new Date(ttm[calenderData].settingDate)) + URL_PARTS_SLASH + ttm[calenderData].id);
                            }
                            else{
                                history.push(URL_PATH_TASKTIMES_DETAIL + URL_PARTS_SLASH + formatDateStrForYYYYMMDD(new Date(ttm[calenderData].settingDate)) + URL_PARTS_SLASH + ttm[calenderData].id);
                            }
                        }else if(userData.userRole === USER_ROLE.Coach){
                            if(pcMode){
                                history.push(URL_PATH_TASKTIMES_DETAIL + URL_PARTS_SLASH + formatDateStrForYYYYMMDD(new Date(ttm[calenderData].settingDate)) + URL_PARTS_SLASH + ttm[calenderData].id + URL_PARTS_SLASH + traineeId);
                            }
                            else{
                                history.push(URL_PATH_TASKTIMES_DETAIL + URL_PARTS_SLASH + formatDateStrForYYYYMMDD(new Date(ttm[calenderData].settingDate)) + URL_PARTS_SLASH + ttm[calenderData].id + URL_PARTS_SLASH + traineeId);
                            }
                        }else if(userData.userRole === USER_ROLE.CustomerSuccess || userData.userRole === USER_ROLE.SuperAdministrator){
                            if(pcMode){
                                history.push(URL_PATH_CS_TRAINEE_MANAGEMENT + URL_PARTS_SLASH + traineeId + URL_PARTS_SLASH + ttm[calenderData].id + URL_PARTS_SLASH + formatDateStrForYYYYMMDD(new Date(ttm[calenderData].settingDate)));
                            }
                            else{
                                history.push(URL_PATH_CS_TRAINEE_MANAGEMENT + URL_PARTS_SLASH + traineeId + URL_PARTS_SLASH + ttm[calenderData].id + URL_PARTS_SLASH + formatDateStrForYYYYMMDD(new Date(ttm[calenderData].settingDate)));
                            }
                        }
                    }}
                >
                    <Box className={localStyle.displayDateBox}>
                        {day.getMonth() === initDate.getMonth() ?
                        <Typography variant='caption'>{nonZerosuppresFormatDate(day, 'd')}</Typography>
                        :
                        <Typography variant='caption'>{nonZerosuppresFormatDate(day, 'd')}</Typography>
                        }
                    </Box>
                    {ttm[calenderData].checkOut === false ?
                    <Box className={localStyle.iconArea}>
                        <Box className={localStyle.taskCheckingIcon}>
                        </Box>
                    </Box>
                    :
                    <Box className={localStyle.iconArea}>
                        <Box className={localStyle.taskCheckOutIcon}>
                            <CheckIcon/>
                        </Box>
                    </Box>
                    }
                </Box>
            :
            userData.userRole === USER_ROLE.Trainee ?
                <Box id='click-tile'
                    onClick={() => {
                        if(pcMode){
                            history.push(URL_PATH_TASKTIMES_CREATE + URL_PARTS_SLASH + formatDateStrForYYYYMMDD(day) + URL_PARTS_SLASH + PARAM_PCMODE_TRUE);
                        }
                        else{
                            history.push(URL_PATH_TASKTIMES_CREATE + URL_PARTS_SLASH + formatDateStrForYYYYMMDD(day));
                        }
                            
                    }}
                >
                    <Box className={localStyle.displayDateBox}>
                        {day.getMonth() === initDate.getMonth() ?
                        <Typography variant='caption'>{nonZerosuppresFormatDate(day, 'd')}</Typography>
                        :
                        <Typography variant='caption'>{nonZerosuppresFormatDate(day, 'd')}</Typography>
                        }
                    </Box>
                </Box>
                :
                <Box>
                    <Box className={localStyle.displayDateBox}>
                        {day.getMonth() === initDate.getMonth() ?
                        <Typography variant='caption'>{nonZerosuppresFormatDate(day, 'd')}</Typography>
                        :
                        <Typography variant='caption'>{nonZerosuppresFormatDate(day, 'd')}</Typography>
                        }
                    </Box>
                </Box>
            }
        </Box>
    );
}

export default function TasktimeCalendar({ userData, ttm, traineeId, pcMode, selectDate }){
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    
    const [isLoading, setIsLoading] = useState(true);
    const [initDate, setInitDate] = useState(getFirstDate(new Date()));
    const [dates, setDates] = useState([]);
    const [ttms, setTtms] = useState([]);
    
    const onClickLeftArrow = () =>{
        setInitDate(new Date(initDate.setMonth(initDate.getMonth()-1)));
    }

    const onClickRightArrow = () =>{
        setInitDate(new Date(initDate.setMonth(initDate.getMonth()+1)));
    }

    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();
        try{
            setIsLoading(true);
            // マウント時処理
            const start =  initDate.getDate();
            const last =  getLastDate(initDate).getDate();
            let dateArray = [];
            let ttms = {};
            for(let day = start + (0 - initDate.getDay()); day <= last + (6 - getLastDate(initDate).getDay()); day++){
                const dayDate = new Date(new Date(new Date(initDate).setDate(day)).setHours(9));
                let ttmObj = undefined;
                if(ttm.find(data => { return data.settingDate === getStringFromDateForFormatString(dayDate, 'YYYY-MM-DD')})){
                    ttmObj = ttm[ttm.findIndex(data => { return data.settingDate === getStringFromDateForFormatString(dayDate, 'YYYY-MM-DD')})];
                }
                ttms[day] = ttmObj;
                dateArray.push(day);
            }
            setDates(dateArray);
            setTtms(ttms);
            // {ttm.find(data => { return data.settingDate === getStringFromDateForFormatString(day, 'YYYY-MM-DD')}) ?
            //     ttm.filter(data => { return data.settingDate === getStringFromDateForFormatString(day, 'YYYY-MM-DD')}).map(data =>{
            setIsLoading(false);
        } catch (e) {
            if (e.name === ERROR_TYPE_ABORTERROR) {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }

        return() => {
            abortCtrl.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initDate,ttm]);
    
    return(
        <Box id='tasktime-management-calendar' className={localStyle.calenderBody}>
            <Box>
                <Grid container className={localStyle.navigationBar}>
                    <Grid item xs={5} className={commonStyle.rightContents}>
                        <IconButton onClick={onClickLeftArrow}>
                            <ArrowLeftIcon></ArrowLeftIcon>
                        </IconButton>
                    </Grid>
                    <Grid item xs={2} className={commonStyle.centerContents}>
                        <Typography>{nonZerosuppresFormatDate(initDate, 'yyyy/M')}</Typography>
                    </Grid>
                    <Grid item xs={5} className={commonStyle.leftContents}>
                        <IconButton onClick={onClickRightArrow}>
                            <ArrowRightIcon></ArrowRightIcon>
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
                <Box className={localStyle.dayBar}>
                    <Box className={localStyle.dayBox}><Typography variant='caption'>日</Typography></Box>
                    <Box className={localStyle.dayBox}><Typography variant='caption'>月</Typography></Box>
                    <Box className={localStyle.dayBox}><Typography variant='caption'>火</Typography></Box>
                    <Box className={localStyle.dayBox}><Typography variant='caption'>水</Typography></Box>
                    <Box className={localStyle.dayBox}><Typography variant='caption'>木</Typography></Box>
                    <Box className={localStyle.dayBox}><Typography variant='caption'>金</Typography></Box>
                    <Box className={localStyle.dayBox}><Typography variant='caption'>土</Typography></Box>
                </Box>
            <Box>
            </Box>
            {isLoading ?
            <LoadingIcon/>
            :
            <Box className={localStyle.dateTileBar}>
                {dates.map((date)=> {
                    return (<DateTileContent 
                                calenderData={date}
                                ttm={ttms}
                                traineeId={traineeId}
                                initDate={initDate}
                                userData={userData}
                                pcMode={pcMode}
                                selectDate={selectDate}
                            />)})}
            </Box>
            }
        </Box>
    );
}
