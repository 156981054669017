import { API, Auth, Storage } from "aws-amplify";
import { Fragment } from "react";
import { listChatNotificationDatas, listUserDatas } from "../graphql/queries";
import * as Consts from './Consts';

import styled from 'styled-components';
import { updateChatNotificationDatas } from "../graphql/mutations";
export const ReadOnlyContent = styled.div`
    .MuiInputBase-root.Mui-disabled{
        color: #000000;
    }
`;
export const FitContent = styled.div`
    .MuiBottomNavigation-root{
        background-color: #0B0332;
    }
    .MuiBottomNavigationAction-root{
        min-width:0px;
        background-color: #0B0332;
        color: #EDEDED;
    }
    .Mui-selected{
        color:#CDB59B;
    }
`;

export function nonZerosuppresFormatDate (date, format) {
    format = format.replace(/yyyy/g, date.getFullYear());
    format = format.replace(/M/, (date.getMonth() + 1));
    format = format.replace(/d/, date.getDate());
    format = format.replace(/H/, date.getHours());
    format = format.replace(/m/, date.getMinutes());
    format = format.replace(/s/, date.getSeconds());
    format = format.replace(/S/, date.getMilliseconds());
    return format;
};

export function getStringFromDateForFormatString(date, formatString) {
    var year_str = date.getUTCFullYear();
    var month_str = 1 + date.getUTCMonth(); //月だけ+1すること
    var day_str = date.getUTCDate();
    var hour_str = date.getUTCHours();
    var minute_str = date.getUTCMinutes();
    var second_str = date.getUTCSeconds();
    
    month_str = ('0' + month_str).slice(-2);
    day_str = ('0' + day_str).slice(-2);
    hour_str = ('0' + hour_str).slice(-2);
    minute_str = ('0' + minute_str).slice(-2);
    second_str = ('0' + second_str).slice(-2);
    
    // フォーマット
    var format_str = formatString;
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    format_str = format_str.replace(/hh/g, hour_str);
    format_str = format_str.replace(/mm/g, minute_str);
    format_str = format_str.replace(/ss/g, second_str);
    
    return format_str;
}
export async function consoleLog(checkUserRole, logData){
    switch (checkUserRole) {
        case Consts.USER_ROLE.Trainee:
            break
        case Consts.USER_ROLE.Coach:
            break
        case Consts.USER_ROLE.CustomerSuccess:
            break
        case Consts.USER_ROLE.SuperAdministrator:
            console.log(logData);
            break
        default:
            break
    }
}
export async function redirectForUserRoleToRedorectPages(history, currentUserData, checkUserRole){
    switch (checkUserRole) {
        case Consts.USER_ROLE.Trainee:
            if(currentUserData.userRole === Consts.USER_ROLE.Coach){
                history.replace(Consts.URL_PATH_COACHING_MANAGEMENT);
            }else if(currentUserData.userRole === Consts.USER_ROLE.CustomerSuccess){
                history.replace(Consts.URL_PATH_MYPAGE);
            }
            break
        case Consts.USER_ROLE.Coach:
            if(currentUserData.userRole === Consts.USER_ROLE.Trainee){
                history.replace(Consts.URL_PATH_SCHEDULE);
            }else if(currentUserData.userRole === Consts.USER_ROLE.CustomerSuccess){
                history.replace(Consts.URL_PATH_MYPAGE);
            }
            break
        case Consts.USER_ROLE.CustomerSuccess:
            if(currentUserData.userRole === Consts.USER_ROLE.Trainee){
                history.replace(Consts.URL_PATH_SCHEDULE);
            }else if(currentUserData.userRole === Consts.USER_ROLE.Coach){
                history.replace(Consts.URL_PATH_COACHING_MANAGEMENT);
            }
            break
        case Consts.USER_ROLE.SuperAdministrator:
            if(currentUserData.userRole === Consts.USER_ROLE.Trainee){
                history.replace(Consts.URL_PATH_SCHEDULE);
            }else if(currentUserData.userRole === Consts.USER_ROLE.Coach){
                history.replace(Consts.URL_PATH_COACHING_MANAGEMENT);
            }else if(currentUserData.userRole === Consts.USER_ROLE.CustomerSuccess){
                history.replace(Consts.URL_PATH_MYPAGE);
            }break
        default:
            break
    }
}
export function LineTextComponent({ msg }) {
    const texts = msg.split(/(\n)/).map((item, index) => {
        return (
            <Fragment key={index}>
            { item.match(/\n/) ? <br /> : item }
            </Fragment>
        );
    });
    return <div>{texts}</div>;
}

export function formatDisplayDateStr(dateStr){
    return dateStr.slice(0,4) + '年' + dateStr.slice(4,6) + '月' + dateStr.slice(6,8) + '日';
}

export function formatAWSDateStr(dateStr){
    return dateStr.slice(0,4) + '-' + dateStr.slice(4,6) + '-' + dateStr.slice(6,8);
}

export function formatDateStrForYYYYMMDD(date){
    return `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}${('0' + date.getDate()).slice(-2)}`;
}

export function getFormatDate(date){
    return `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}${('0' + date.getDate()).slice(-2)}`;
} 


export async function getUserDataById(id){
    const filter = {userId: {contains: id}};
    const userDataRes = await API.graphql({
        query: listUserDatas,
        variables: { filter: filter, limit:100000 },
        authMode: Consts.AMAZON_COGNITO_USER_POOLS,
    });
    return userDataRes.data.listUserDatas.items[0];
}

export async function getCurrentUserData(){
    const user = await Auth.currentAuthenticatedUser();
    const filter = {userId: {contains: user.username}};
    const userDataRes = await API.graphql({
        query: listUserDatas,
        variables: { filter: filter, limit:100000 },
        authMode: Consts.AMAZON_COGNITO_USER_POOLS,
    });
    return userDataRes.data.listUserDatas.items[0];
}

export async function getCurrentUserInfo(){
    const user = await Auth.currentUserInfo();
    return user;
}

export async function getCurrentUserCredentials(){
    try {
        const credentials = await Auth.currentCredentials();
        return credentials;
        // set the identityId in state
    } catch(e) {}
}

export async function getUserIconImage( userData ){
    var file = null;
    if(userData.iconImage !== null){
        file = await Storage.get(userData.iconImage,
            { 
                level: 'protected',
                identityId: userData.userName,
        });
    }
    return file;
}

export function getContentType(objectKey){
    const contentType = objectKey.split('.').pop();
    return contentType;
}

const imageFileExt = [
    'BMP',
    'JPG',
    'JPEG',
    'GIF',
    'PNG',
]

export async function checkChatNotifications(userData){
    const csSendNotifications = async (notif) => {
        new Notification(notif.sendFromUserdatasName + "がメッセージを送信しました。",{body: notif.sendMessage});
        const res = await API.graphql({
            query: updateChatNotificationDatas,
            variables: {
                input: {
                    id: notif.id,
                    csStatus: 0,
                }        
            }, 
            authMode: Consts.AMAZON_COGNITO_USER_POOLS,
        });
    }
    
    switch (userData.userRole) {
        case Consts.USER_ROLE.Trainee:
            break
        case Consts.USER_ROLE.Coach:
            break
        case Consts.USER_ROLE.CustomerSuccess:
        case Consts.USER_ROLE.SuperAdministrator:
            const filter = { csStatus: {ne: 0}}
            const res = await API.graphql({
                query: listChatNotificationDatas,
                variables: {filter: filter, limit: 100000},
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            });
            const chatNotifications = res.data.listChatNotificationDatas.items;
            if(0 < chatNotifications.length){
                for(const notif of chatNotifications){
                    if ("Notification" in window) {
                        // 通知が許可されていたら早期リターン
                        const permission = Notification.permission;
                        if (permission === "granted") {
                            // 通知の許可を求める
                            csSendNotifications(notif);
                        }else{
                            // 通知の許可を求める
                            Notification.requestPermission().then(() => {
                                new Notification("通知が許可されました。")
                                csSendNotifications(notif);
                            });
                        }                       
                    }
                }
            }
            break
        default:
            break
    }
}
export function checkContentTypeIsImagefile(checkStringe){
    if(imageFileExt.find(ext => checkStringe.toUpperCase() === ext)){
        return true;
    }
    return false;
}

export async function getStorageObject( userData, imageKey ){
    var file = null;
    const contentType = getContentType(imageKey);
    if(imageKey !== null){
        file = await Storage.get(imageKey,
            { 
                level: 'protected',
                identityId: userData.userName,
                contentType: contentType,
        });
    }
    return file;
}

export async function getStorageDownloadObject( userData, imageKey ){
    var file = null;
    const contentType = getContentType(imageKey);
    if(imageKey !== null){
        file = await Storage.get(imageKey,
            { 
                level: 'protected',
                identityId: userData.userName,
                contentType: contentType,
                validateObjectExistence: true,
                download: true,
        });
        const retObj = downloadBlob(file.Body, imageKey);
        return retObj;
    }
    return file;
}

export function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const retObject = {
        href: url,
        download: filename || 'download',
    }
    return retObject;
}

export async function setStorageFileObject( fileObject ){
    const storagePutRes = await Storage.put(
        fileObject.name, 
        fileObject, 
        {
            level: 'protected',
            contentType: fileObject.type,
        }
    );
    return storagePutRes;
}

export async function getCurrentUserIconImage( userData ){
    var file = null;
    if(userData.iconImage !== null){
        file = await Storage.get(userData.iconImage,
            { 
                level: 'protected',
        });
    }
    return file;
}

export function isTraineeIdReturnToCurrentUserIdOrTraineeUserId(taraineeUserId, currentUserId='' ){
    var checkUserId = currentUserId;
    if(taraineeUserId !== void 0){
        checkUserId = taraineeUserId;
    }
    return checkUserId;
}

export function validationHerfSizeNumber(input){
    var validated = '';
    for(const c of input){
        if (c.match(/[0-9]/)){
            validated += c;
        }
    }
    return validated;
}

export function validationKana(input){
    var validated = '';
    for(const c of input){
        if (c.match(/[\u30a0-\u30ff\u3040-\u309f]/)) {
            validated += c;
        }
    }
    return validated;
}

export function checkIsKana(input){
    for(const c of input){
        if (!c.match(/[\u30a0-\u30ff]/) && !c.match(/[ |　]/)){
            return true;
        }
    }
    return false;
}

export function stringLengthForinVariableSettingValue(string, maxLength=0){
    if(maxLength === 0) maxLength = string.length;
    return string.slice(0, maxLength);
}

export async function recursiveGrapheql(query, queryName, variables, dispatch){
    const res = await API.graphql({
        query: query,
        variables: variables,
        authMode: Consts.AMAZON_COGNITO_USER_POOLS,
    });
    if(res.data[queryName].nextToken){
        variables.nextToken = res.data[queryName].nextToken;
        await recursiveGrapheql(query, queryName, variables, dispatch);
        dispatch({ type: Consts.ADDITIONAL_QUERY, data:res.data[queryName].items });
    }
    else{
        dispatch({ type: Consts.INITIAL_QUERY, data:res.data[queryName].items });
    }
}
export async function testrecursiveGrapheql(query, queryName, variables, dispatch){
    const res = await API.graphql({
        query: query,
        variables: variables,
        authMode: Consts.AMAZON_COGNITO_USER_POOLS,
    });
    if(res.data[queryName].nextToken){
        variables.nextToken = res.data[queryName].nextToken;
        const retObj = await testrecursiveGrapheql(query, queryName, variables, dispatch);
        dispatch({ type: Consts.ADDITIONAL_QUERY, data:res.data[queryName].items });
        return [...retObj, ...res.data[queryName].items]
    }
    else{
        dispatch({ type: Consts.INITIAL_QUERY, data:res.data[queryName].items });
        return [...res.data[queryName].items]
    }
}

export async function testrecursiveGrapheqlForNonDispatch(query, queryName, variables){
    const res = await API.graphql({
        query: query,
        variables: variables,
        authMode: Consts.AMAZON_COGNITO_USER_POOLS,
    });
    if(res.data[queryName].nextToken){
        variables.nextToken = res.data[queryName].nextToken;
        const retObj = await testrecursiveGrapheqlForNonDispatch(query, queryName, variables);
        return [...retObj, ...res.data[queryName].items]
    }
    else{
        return [...res.data[queryName].items]
    }
}

export async function nextTokenInitialGrapheql(query, queryName, variables, dispatch, setNextToken){
    const res = await API.graphql({
        query: query,
        variables: variables,
        authMode: Consts.AMAZON_COGNITO_USER_POOLS,
    });
    if(res.data[queryName].nextToken){
        setNextToken(res.data[queryName].nextToken);
        dispatch({ type: Consts.INITIAL_QUERY, data:res.data[queryName].items });
    }
    else{
        setNextToken(null);
        dispatch({ type: Consts.INITIAL_QUERY, data:res.data[queryName].items });
    }
}

export async function nextTokenAddtionalGrapheql(query, queryName, variables, dispatch, setNextToken){
    const res = await API.graphql({
        query: query,
        variables: variables,
        authMode: Consts.AMAZON_COGNITO_USER_POOLS,
    });
    if(res.data[queryName].nextToken){
        setNextToken(res.data[queryName].nextToken);
        dispatch({ type: Consts.ADDITIONAL_QUERY, data:res.data[queryName].items });
    }
    else{
        setNextToken(null);
        dispatch({ type: Consts.ADDITIONAL_QUERY, data:res.data[queryName].items });
    }
}