import { makeStyles, createTheme } from '@material-ui/core/styles';

// フォントを設定
const fontFamily = [
    'Noto Sans JP',
    'メイリオ',
    'ＭＳ Ｐゴシック',
    'sans-serif',
    // 使用したいフォントを以降に羅列してください
].join(',');
export const theme = createTheme({
    palette: {
        primary: {
            main: '#0B0332',
            contrastText: '#EDEDED',
        },
        secondary: {
            main: '#600001',
        },
        action:{
            main: '#CDB59B'
        },
        background: {
            paper: '#FFFFFF',
            boxPaper: '#CDB59B',
            drawerPaper: '#E0E0E0',
            selectMenu: '#B8B8B8',
            menuHover: '#EDEDED',
        },
        type: 'light',
    },
    divider: '#00FFFF',
    overrides: {
        MuiButton: {
        color: 'black',
        },
    },
    typography: {
        button: {
            textTransform: "none"
        },
        fontFamily:fontFamily,
    },
    status: {
        danger: 'orange',
    },
});

// 名前をcommonStylesに変更し、これに共通したいスタイルを突っ込み利用するようにする
const PC_SIDE_BAR_WIDTH_PERCENTAGE_VALUE = 20;
const PC_SECOND_SIDE_BAR_WIDTH_PERCENTAGE_VALUE = 20;
const PC_BASE_DISPLAY_WIDTH_PERCENTAGE_VALUE = 100 - PC_SIDE_BAR_WIDTH_PERCENTAGE_VALUE;
const PC_BASE_DISPLAY_WIDTH_WIDTH_SECOND_BAR_PERCENTAGE_VALUE = 100 - (PC_SECOND_SIDE_BAR_WIDTH_PERCENTAGE_VALUE + PC_SIDE_BAR_WIDTH_PERCENTAGE_VALUE);
const PC_HEADER_PIXEL_SIZE_PERCENTAGE_VALUE = 9;  
const PC_BASE_DISPLAY_HEIGHT_PERCENTAGE_VALUE = 100 - PC_HEADER_PIXEL_SIZE_PERCENTAGE_VALUE;
export const commonStyles = makeStyles(theme => ({
    pageContent:{
        backgroundColor: theme.palette.background.drawerPaper,
        [theme.breakpoints.down('sm')]: {
            top: "50px",
            width: "100%",
            height: "100%",
        },
        [theme.breakpoints.up('md')]: {
            width: PC_BASE_DISPLAY_WIDTH_PERCENTAGE_VALUE + "%",
            height: PC_BASE_DISPLAY_HEIGHT_PERCENTAGE_VALUE + "%",
            top: "9%",
            left: PC_SIDE_BAR_WIDTH_PERCENTAGE_VALUE + "%",
        },
        position: 'fixed',
        overflow: "auto",
    },
    noscrollPageContent:{
        backgroundColor: theme.palette.background.drawerPaper,
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            top: "50px",
            height: "100%",
        },
        [theme.breakpoints.up('md')]: {
            width: PC_BASE_DISPLAY_WIDTH_PERCENTAGE_VALUE + "%",
            height: PC_BASE_DISPLAY_HEIGHT_PERCENTAGE_VALUE + "%",
            left: PC_SIDE_BAR_WIDTH_PERCENTAGE_VALUE + "%",
            top: "80px",
        },
        position: 'fixed',
        overflow: "noscroll",
    },
    secondSideBarOnPageContent:{
        backgroundColor: theme.palette.background.drawerPaper,
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            height: "100%",
            top: "50px",
        },
        [theme.breakpoints.up('md')]: {
            width: PC_BASE_DISPLAY_WIDTH_WIDTH_SECOND_BAR_PERCENTAGE_VALUE + "%",
            height: PC_BASE_DISPLAY_HEIGHT_PERCENTAGE_VALUE + "%",
            top: "9%",
            left: (PC_SIDE_BAR_WIDTH_PERCENTAGE_VALUE + PC_SECOND_SIDE_BAR_WIDTH_PERCENTAGE_VALUE) + "%",
        },
        position: 'fixed',
        overflow: "auto",
    },
    secondSideMenu:{
        [theme.breakpoints.down('sm')]: {
            display: "none",
            height: "100%",
        },
        [theme.breakpoints.up('md')]: {
            width: PC_SECOND_SIDE_BAR_WIDTH_PERCENTAGE_VALUE + "%",
            height: PC_BASE_DISPLAY_HEIGHT_PERCENTAGE_VALUE + "%",
            display: "block",
            top: PC_HEADER_PIXEL_SIZE_PERCENTAGE_VALUE + "%",
            left: PC_SIDE_BAR_WIDTH_PERCENTAGE_VALUE + "%",
            padding: "1px",
            paddingTop: "10px",
        },
        position: 'fixed',
        backgroundColor: theme.palette.background.drawerPaper,
        borderRight: "double #a0a0a0 3px",
    },
    sideMenu:{
        [theme.breakpoints.down('sm')]: {
            display: "none",
            height: "100%",
        },
        [theme.breakpoints.up('md')]: {
            width: PC_SIDE_BAR_WIDTH_PERCENTAGE_VALUE + "%",
            height: PC_BASE_DISPLAY_HEIGHT_PERCENTAGE_VALUE + "%",
            display: "block", 
            top: PC_HEADER_PIXEL_SIZE_PERCENTAGE_VALUE + "%", 
            padding: "1px",
        },
        paddingBottom: '150px',
        backgroundColor: theme.palette.background.paper,
        position: 'fixed',
        borderRight: "double #a0a0a0 3px",
    },
    searchBoxArea:{
        height: "8%",
        display: "fixed",
    },
    searchBoxOnScrollArea:{
        height: "92%",
        overflowY: "auto",
        overflowX: "hidden",
    },
    scrollArea:{
        height: "100%",
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
    },
    mobileModeDisplay:{
        [theme.breakpoints.down('sm')]: {
            display: "block",
        },
        [theme.breakpoints.up('md')]: {
            display: "none",
        },
    },
    pcModeDisplay:{
        [theme.breakpoints.down('sm')]: {
            display: "none",
        },
        [theme.breakpoints.up('md')]: {
            display: "block",
        },
    },

    fixedContent:{
        position: "fixed",
    },
    header: {
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            height: "50px",
        },
        [theme.breakpoints.up('md')]: {
            height: "9%",
        },
        position: 'fixed',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        alignItems: 'center',
        top: 0,
    },
    headerItemLeft: {
        display: 'flex',
        justifyContent: 'center',
    },
    headerItemCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    headerItemRight: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    title: {
        color: theme.palette.secondary.contrastText,
        fontSize: "4vmin"
    },
    footer: {
        width: "100%",
        height: "60px",
        position: 'fixed',
        left: 0,
        bottom: 0,
        zIndex: 1000,
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        '& .MuiBottomNavigationAction-label':{
            fontSize:'2vmin',
        },
        '& .MuiBottomNavigationAction-label.Mui-selected':{
            fontSize:'2vmin',
        },
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    loader: {
        display: 'flex',
        alignContent: 'stretch',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        height:"100vh",
        width:"100%",
    },
    borders:{
        border: "solid #FAFAFA 0.5px",
    },
    bordersBlack:{
        border: "solid #0A0A0A 0.5px",
    },
    prefixedText:{
        '& .MuiTypography-h5':{
            fontSize: "6vmin",
            fontWeight: "bold",
        },
        '& .MuiTypography-h6':{
            fontSize: "5vmin",
        }
    },
    fabbut:{
        position: 'fixed',
        right: "2vh",
        bottom: "12vh",  
        zIndex: 1000,
    },
    marginTop:{
        marginTop: "3px",
    },
    marginBottom:{
        marginBottom: "20px",
    },
    marginBottomRow:{
        marginBottom: "12px",
    },
    rightContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-start",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "flex-end",
    },
    leftContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-start",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    flexStartCenterContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-start",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
    centerContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
    centerOfCenterContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    leftOfCenterContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "100%",
    },
    linkButton:{
        padding: "16px 6px 16px 6px",
        display: "inline-block",
        minWidth: "100%",
        width: "100%",
        color: "white",
        cursor: "pointer",
        backgroundColor: theme.palette.primary.main,
        textDecoration:"none",
        border: "none",
        borderRadius: "5px",
        textAlign: "center",
    },
    nonDataButton:{
        height: 50,
        padding: "16px 6px 16px 6px",
        marginBottom: "10px",
    },
    endBox:{
        height: "130px"
    },
}));