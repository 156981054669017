// AWS
import { API } from "aws-amplify";

// GraphQL
import { 
    updateTasktimeManagementDatas, 
    updateTaskDatas,
    deleteTaskDatas, 
} from '../../graphql/mutations';

// React
import { Fragment, useState } from 'react';

// Material-ui
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { 
    Grid, 
    Select, 
    MenuItem, 
    TextField, 
    InputLabel, 
    FormControl, 
    Typography,
    Box,
    IconButton,
} from '@material-ui/core';

// Self Making Files
import { 
    AMAZON_COGNITO_USER_POOLS, 
    TASK_ATTRIBUTE_TITLE_AHIVEMENT, 
    TASK_ATTRIBUTE_TITLE_END_TIME, 
    TASK_ATTRIBUTE_TITLE_START_TIME, 
    TASK_ATTRIBUTE_TITLE_TASK_CONTENTS,
    USER_ROLE, 
} from '../../Commons/Consts';
import { LoadingIcon } from "../../Commons/LoadingIcon";
import { commonStyles } from "../../Commons/CommonStyles";

const localStyles = makeStyles(theme => ({
    taskCard:{
        [theme.breakpoints.down('sm')]: {
            borderRadius: "10px",
            paddingTop: "13px",
            paddingLeft: "5px",
            marginBottom: "1px",
            backgroundColor: theme.palette.background.paper,
            border: "solid #AAAAAA 1px",
            '& .MuiInputBase-root':{
                paddingTop: "5px",
                paddingBottom: "5px",
                fontSize: "2vmin",
                '& .MuiInputBase-input':{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    fontSize: "2vmin",
                },
            },
            '& .MuiInputBase-root>fieldset>legend>span,.MuiFormLabel-root':{
                [theme.breakpoints.down('sm')]: {
                    fontSize: "4vmin",
                },
                [theme.breakpoints.up('md')]: {
                    fontSize: "2vmin",
                },
            },
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: "10px",
            paddingTop: "5px",
            paddingLeft: "5px",
            marginBottom: "1px",
            backgroundColor: theme.palette.background.paper,
            border: "solid #AAAAAA 1px",
            '& .MuiInputBase-root':{
                paddingTop: "5px",
                paddingBottom: "5px",
                fontSize: "1.5vmin",
                '& .MuiInputBase-input':{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    fontSize: "1.5vmin",
                },
            },
        },
    },
    formControl: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
    },
    timeField:{
        '& .MuiInputBase-input':{
            paddingLeft:"1px",
            paddingRight:"0px",
            fontSize: "2vmin",
        },
    },
    taskDetailField:{
        backgroundColor: theme.palette.background.drawerPaper,
    },
    taskNumber:{
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignContent: 'stretch',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'flex-start',
            paddingLeft:"7px",
            paddingTop:"2px",
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            paddingRight: "3px",
            paddingBottom:"5px",
        },
    },
    prioritySelect:{
        display: 'flex',
        alignContent: 'stretch',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    progressSelect:{
        display: 'flex',
        alignContent: 'stretch',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        '& .MuiSelect-root':{
            paddingLeft:"3px",
            paddingRight:"0px",
        },
        '& .MuiSelect-icon':{
            right: '-3px',
        },
    },
    taskContentsRow:{
        alignItems: 'center',
        marginBottom: "6px",
        paddingRight: "5px",
    },
}));

export function ResultTaskCardList({ tasks, userRole }){
    return (
        <div>
            <Grid container spacing={0}>
                {tasks.length <= 0 ? 
                    <LoadingIcon/>
                    :
                    tasks
                    .sort((a, b) => {return a.createdAt < b.createdAt ? -1 : 1})
                    .map((taskData, index) => (
                        <ResultTaskCard 
                            taskData={taskData} 
                            userRole={userRole}
                            index={index}
                        />
                    ))
                }
            </Grid>
        </div>
    ); 
}

function ResultTaskCard({ taskData, userRole, index }){
    const localStyle = localStyles();
    const commonStyle = commonStyles();

    const [startTime, setStartTime] = useState(taskData.resultTaskStartTime);
    const [endTime, setEndTime] = useState(taskData.resultTaskEndTime);
    const [detail, setDetail] = useState(taskData.resultTaskDetial);
    const [progress, setProgress] = useState(taskData.taskProgress);
    
    const updateTask = async () =>{
        await API.graphql({
            query: updateTaskDatas,
            variables: {
                input: {
                    id: taskData.id, 
                    resultTaskDetial: detail, 
                    resultTaskEndTime: endTime, 
                    resultTaskStartTime: startTime, 
                    taskProgress: progress,
                }
            }
        });
        await API.graphql({
            query: updateTasktimeManagementDatas,
            variables: { 
                input: {
                    id: taskData.parentTasktimeManagementDatasID,
                    checkIn: true,
                    checkOut: false,
                },
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
    };

    const deleteTask = async() => {
        await API.graphql({
            query: deleteTaskDatas,
            variables: {
                input: {
                    id: taskData.id, 
                }
            }
        });
        
    }

    const clickDeleteIcon = async (event) => {
        await deleteTask();
    }

    const handleStartTimeChange = (event) => {
        setStartTime(event.target.value);
    };
    
    const handleEndTimeChange = (event) => {
        setEndTime(event.target.value);
    };
    
    // const handleTaskPriorityChange = (event) => {
    //     dispatchTask({ type:UPDATE, updateName:TASK_DATA_RESULTTASKPRIORITY, updateValue:event.target.value });
    // };
    
    const handleTaskDetialChange = (event) => {
        setDetail(event.target.value);
    };
    
    const handleTaskProgressChange = (event) => {
        setProgress(event.target.value);
    };
    
    return(
        <Grid container xs={12} className={localStyle.taskCard}>
            <Grid container xs={12}>
                <Grid container xs={1} className={localStyle.taskNumber}>
                    <Typography>{index+1}</Typography>
                </Grid>
                <Grid container xs={userRole === USER_ROLE.SuperAdministrator ? 10 : 11}>
                    <Grid container className={localStyle.taskContentsRow}>
                        <Grid container xs={4}>
                            <Grid item xs={6}>
                                <Box className={commonStyle.mobileModeDisplay}>
                                    <TextField
                                        id="startTime"
                                        label={TASK_ATTRIBUTE_TITLE_START_TIME}
                                        type="time"
                                        variant="outlined"
                                        fullWidth
                                        disabled={userRole === USER_ROLE.Trainee ? false : true}
                                        value={startTime}
                                        className={localStyle.timeField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        onChange={handleStartTimeChange}
                                        onBlur={updateTask}
                                    />
                                </Box>
                                <Box className={commonStyle.pcModeDisplay}>
                                    <TextField
                                        id="startTime"
                                        type="time"
                                        variant="outlined"
                                        fullWidth
                                        disabled={userRole === USER_ROLE.Trainee ? false : true}
                                        value={startTime}
                                        className={localStyle.timeField}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        onChange={handleStartTimeChange}
                                        onBlur={updateTask}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box className={commonStyle.mobileModeDisplay}>
                                    <TextField
                                        id="endTime"
                                        label={TASK_ATTRIBUTE_TITLE_END_TIME}
                                        type="time"
                                        variant="outlined"
                                        fullWidth
                                        disabled={userRole === USER_ROLE.Trainee ? false : true}
                                        value={endTime}
                                        className={localStyle.timeField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        onChange={handleEndTimeChange}
                                        onBlur={updateTask}
                                    />
                                </Box>
                                <Box className={commonStyle.pcModeDisplay}>
                                    <TextField
                                        id="endTime"
                                        type="time"
                                        variant="outlined"
                                        fullWidth
                                        disabled={userRole === USER_ROLE.Trainee ? false : true}
                                        value={endTime}
                                        className={localStyle.timeField}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        onChange={handleEndTimeChange}
                                        onBlur={updateTask}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} className={localStyle.progressSelect}>
                            <FormControl fullWidth variant="outlined" className={localStyle.formControl}>
                                <Box className={commonStyle.mobileModeDisplay}>
                                    <InputLabel id="task-progress-select-label">{TASK_ATTRIBUTE_TITLE_AHIVEMENT}</InputLabel>
                                    <Select
                                        labelId="task-progress-select-label"
                                        id="task-progress-select"
                                        label={TASK_ATTRIBUTE_TITLE_AHIVEMENT}
                                        disabled={userRole === 17 ? false : true}
                                        defaultValue={progress}
                                        value={progress}
                                        fullWidth
                                        onChange={handleTaskProgressChange}
                                        onBlur={updateTask}
                                    >
                                        <MenuItem value={0}>0%</MenuItem>
                                        <MenuItem value={5}>5%</MenuItem>
                                        <MenuItem value={10}>10%</MenuItem>
                                        <MenuItem value={15}>15%</MenuItem>
                                        <MenuItem value={20}>20%</MenuItem>
                                        <MenuItem value={25}>25%</MenuItem>
                                        <MenuItem value={30}>30%</MenuItem>
                                        <MenuItem value={35}>35%</MenuItem>
                                        <MenuItem value={40}>40%</MenuItem>
                                        <MenuItem value={45}>45%</MenuItem>
                                        <MenuItem value={50}>50%</MenuItem>
                                        <MenuItem value={55}>55%</MenuItem>
                                        <MenuItem value={60}>60%</MenuItem>
                                        <MenuItem value={65}>65%</MenuItem>
                                        <MenuItem value={70}>70%</MenuItem>
                                        <MenuItem value={75}>75%</MenuItem>
                                        <MenuItem value={80}>80%</MenuItem>
                                        <MenuItem value={85}>85%</MenuItem>
                                        <MenuItem value={90}>90%</MenuItem>
                                        <MenuItem value={95}>95%</MenuItem>
                                        <MenuItem value={100}>100%</MenuItem>
                                    </Select>
                                </Box>
                            </FormControl>
                            <FormControl fullWidth variant="outlined" className={localStyle.formControl}>
                                <Box className={commonStyle.pcModeDisplay}>
                                    <Select
                                        fullWidth
                                        lid="task-progress-select"
                                        disabled={userRole === 17 ? false : true}
                                        defaultValue={progress}
                                        value={progress}
                                        onChange={handleTaskProgressChange}
                                        onBlur={updateTask}
                                    >
                                        <MenuItem value={0}>0%</MenuItem>
                                        <MenuItem value={5}>5%</MenuItem>
                                        <MenuItem value={10}>10%</MenuItem>
                                        <MenuItem value={15}>15%</MenuItem>
                                        <MenuItem value={20}>20%</MenuItem>
                                        <MenuItem value={25}>25%</MenuItem>
                                        <MenuItem value={30}>30%</MenuItem>
                                        <MenuItem value={35}>35%</MenuItem>
                                        <MenuItem value={40}>40%</MenuItem>
                                        <MenuItem value={45}>45%</MenuItem>
                                        <MenuItem value={50}>50%</MenuItem>
                                        <MenuItem value={55}>55%</MenuItem>
                                        <MenuItem value={60}>60%</MenuItem>
                                        <MenuItem value={65}>65%</MenuItem>
                                        <MenuItem value={70}>70%</MenuItem>
                                        <MenuItem value={75}>75%</MenuItem>
                                        <MenuItem value={80}>80%</MenuItem>
                                        <MenuItem value={85}>85%</MenuItem>
                                        <MenuItem value={90}>90%</MenuItem>
                                        <MenuItem value={95}>95%</MenuItem>
                                        <MenuItem value={100}>100%</MenuItem>
                                    </Select>
                                </Box>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Box className={commonStyle.mobileModeDisplay}>
                                <TextField
                                    label={TASK_ATTRIBUTE_TITLE_TASK_CONTENTS}
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    disabled={userRole === USER_ROLE.Trainee ? false : true}
                                    InputLabelProps={{ shrink: true }}
                                    maxRows={3}
                                    value={detail}
                                    onChange={handleTaskDetialChange}
                                    onBlur={updateTask}
                                >
                                </TextField>
                            
                            </Box>
                            <Box className={commonStyle.pcModeDisplay}>
                                <TextField
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    disabled={userRole === USER_ROLE.Trainee ? false : true}
                                    maxRows={3}
                                    value={detail === '' ? '' : detail}
                                    placeholder={'タスク内容を入力してください。'}
                                    onChange={handleTaskDetialChange}
                                    onBlur={updateTask}
                                >
                                </TextField>
                            </Box>
                        </Grid>          
                    </Grid>
                </Grid>
                {userRole === USER_ROLE.SuperAdministrator ?
                <Grid item xs={1}>
                    <Box className={commonStyle.centerOfCenterContents}>
                        <IconButton 
                            color="primary" 
                            aria-label="タスク削除" 
                            size="small"
                            onClick={clickDeleteIcon}
                        >
                            <DeleteForeverIcon/>
                        </IconButton>
                    </Box>
                </Grid>
                :
                <Fragment></Fragment>
                }
            </Grid>
        </Grid>
    );
}