// AWS React
import { API } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Grid, Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

// Self Making Files
import { 
    checkContentTypeIsImagefile, 
    getContentType, 
    getStorageDownloadObject, 
    getStorageObject, 
    getStringFromDateForFormatString, 
    getUserIconImage, 
    LineTextComponent, 
    getCurrentUserData, 
    consoleLog, 
} from "../../Commons/CommonFunctions";

import { createRef, Fragment, useCallback, useEffect, useState } from 'react';
import { AMAZON_COGNITO_USER_POOLS, CHAT_STATUS_ALREADY_READ, CHAT_STATUS_READED, ERROR_TYPE_ABORTERROR, FORMAT_STRING_YYYYMMDDHHMM, USER_ROLE } from '../../Commons/Consts';
import PreviewImageDialog from '../../Commons/PreviewImageDialog';
import { updateChatDatas } from '../../graphql/mutations';
import { commonStyles } from '../../Commons/CommonStyles';

const localStyles = makeStyles(theme => ({
    listRoot:{
        padding: "10px",
    },
    messsageCardRoot:{
        marginTop: "10px",
    },
    todayWordsCard:{
        borderRadius: "10px",
        padding: "10px",
        backgroundColor: "#CDB59B",
        [theme.breakpoints.down('sm')]: {
            '& .MuiTypography-h4':{
                fontSize: "4vmin",
                fontWeight: "bold",
            },
            '& .MuiTypography-h5':{
                fontSize: "4vmin",
            },
            '& .MuiTypography-h6':{
                fontSize: "3vmin",
            }
        },
        [theme.breakpoints.up('md')]: {
            '& .MuiTypography-h4':{
                fontSize: "3vmin",
                fontWeight: "bold",
            },
            '& .MuiTypography-h5':{
                fontSize: "3vmin",
            },
            '& .MuiTypography-h6':{
                fontSize: "2vmin",
            }
        },
    },
}));

export function TodayWordsList({ todayWordsData }){
    const localStyle = localStyles();
    const commonStyle = commonStyles();
    const [userData , setUserData] = useState([]);
    // ref を作成しスクロールさせたい場所にある Element にセット
    const ref = createRef();
    // このコールバックを呼び出して ref.current.scrollIntoView() を呼び出してスクロール
    const scrollToBottomOfList = useCallback(() => {
    ref.current.scrollIntoView({
        behavior: 'auto',
        block: 'end',
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ ref ]);

    const initTodayWordsList = async () =>{
        try{
            const currentUserData = await getCurrentUserData();
            setUserData(currentUserData);
            scrollToBottomOfList();
        } catch (e) {
            if (e.name === ERROR_TYPE_ABORTERROR) {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
        }
    }

    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();

        initTodayWordsList();
        // アンマウント時処理
        return () => {
            abortCtrl.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box className={localStyle.listRoot}>
            {todayWordsData !== undefined ?
            todayWordsData.sort((a,b) => {return a.displayNumber < b.displayNumber ? -1 : 1}).map((data) => {
                return(
                <div>
                    <Box className={commonStyle.mobileModeDisplay}>
                        <Grid container className={localStyle.messsageCardRoot} >
                            <Grid item xs={1}/>
                            <Grid item xs={10}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    className={localStyle.todayWordsCard}
                                    rowSacing={1}
                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                    <Grid item xs={12} className={commonStyle.centerContents}>
                                        <Typography variant="h5">{data.displayNumber}日目</Typography>
                                        <Typography variant="h5"><LineTextComponent msg={data.caption}></LineTextComponent></Typography>
                                    </Grid>
                                    <Box p={1}/>
                                    <Grid item xs={12}>
                                        <Typography variant="h6"><LineTextComponent msg={data.contentText}></LineTextComponent></Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}/>
                        </Grid>
                    </Box>
                    <Box className={commonStyle.pcModeDisplay}>
                        <Grid container className={localStyle.messsageCardRoot} >
                            <Grid item xs={1}/>
                            <Grid item xs={10}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    className={localStyle.todayWordsCard}
                                    rowSacing={1}
                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                    <Grid item xs={12} className={commonStyle.centerContents}>
                                        <Typography variant="h5">{data.displayNumber}日目</Typography>
                                        <Typography variant="h5"><LineTextComponent msg={data.caption}></LineTextComponent></Typography>
                                    </Grid>
                                    <Box p={1}/>
                                    <Grid item xs={12}>
                                        <Typography variant="h6"><LineTextComponent msg={data.contentText}></LineTextComponent></Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}/>
                        </Grid>
                    </Box>
                </div>
            )})
            :
            <Fragment></Fragment>
            }
            <Box className={commonStyle.endBox}/>
            <div id="endBox" ref={ref}></div>
        </Box>
    )
}

