// React
import { 
    useEffect, 
    useState, 
} from 'react';

// Material UI
import { 
    Container, 
    Box, 
} from '@material-ui/core';

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { 
    getCurrentUserData, 
    getCurrentUserIconImage, 
    consoleLog, 
} from '../../Commons/CommonFunctions';
import { ProfileEditForm } from '../../components/Mypage/ProfileEditForm';
import { commonStyles } from '../../Commons/CommonStyles';
import { LoadingIcon } from '../../Commons/LoadingIcon';
import SideMenu from '../../Commons/SideMenu';

export default function ProfileEdit(){  
    const commonStyle = commonStyles();
    const [isLoading, setIsLoading] = useState(true);
     
    const [userData, setUserData] = useState();
    const [previewImage, setPreviewImage] = useState();
    const setInitData = async () =>{
        try {
            const currentUserData = await getCurrentUserData();
            setUserData(currentUserData);
            if(currentUserData.iconImage !== null){
                const file = await getCurrentUserIconImage(currentUserData);
                setPreviewImage(file);
            }
            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        let abortCtrl = new AbortController();

        setInitData();
        // アンマウント時処理
        return() => {
            abortCtrl.abort();
        }
    }, []);

    return(
        <Box>
            <Header title="プロフィール編集"/>
            <SideMenu/>
            {isLoading ?
            <LoadingIcon/>
            :
            <Box>
                <Box className={commonStyle.pageContent} >
                    <Box pt={2}>
                        <ProfileEditForm 
                            initUserData={userData} 
                            initPreviewImage={previewImage} 
                        />
                        <Box className={commonStyle.endBox}/>
                    </Box>
                </Box>
                <Box className={commonStyle.pcModeDisplay}>
                    <Box className={commonStyle.pageContent} >
                        <Container pt={2}>
                            <ProfileEditForm 
                                initUserData={userData} 
                                initPreviewImage={previewImage} 
                            />
                            <Box className={commonStyle.endBox}/>
                        </Container>
                    </Box>
                </Box>
            </Box>
            }
            <Footer/>
        </Box>
    );
}
