import React, { useEffect } from "react";
import { Box, Grid, makeStyles, } from "@material-ui/core";
import { IconButton, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { URL_PATH_CHATROOMS, URL_PATH_COACHING_MANAGEMENT, URL_PATH_MYPAGE, URL_PATH_NOTIFICATIONS, URL_PATH_REGISTRATION, URL_PATH_SCHEDULE, URL_PATH_SUBJECTS, URL_PATH_TASKTIMES, URL_PATH_TRAINEE_LIST, URL_PATH_USERS_MANUAL } from "./Consts";
import { commonStyles } from "./CommonStyles";
import { checkChatNotifications, getCurrentUserData } from "./CommonFunctions";

const LoaclStyles = makeStyles(theme => ({
    smartphoneHeader:{
        [theme.breakpoints.down('sm')]: {
            display: "block",
        },
        [theme.breakpoints.up('md')]: {
            display: "none",
        },
    },
    pcHeader:{
        [theme.breakpoints.down('sm')]: {
            display: "none",
        },
        [theme.breakpoints.up('md')]: {
            display: "block",
        },
    },
    nowNotification:{
        color: theme.palette.action.main,
    },
}));

const Header = (props) => {
    const commonStyle = commonStyles();
    const localStyle = LoaclStyles();
    const history = useHistory();

    const initPageContents = async() =>{
        const currentUserData = await getCurrentUserData();
        await checkChatNotifications(currentUserData);
    }

    useEffect(() => {
        // マウント時処理
        initPageContents();
            
        // アンマウント時処理
        return;
    }, []);

    return (
        <Box>
            <Box className={localStyle.smartphoneHeader}>
                <Grid container className={commonStyle.header} >
                    <Grid item xs={2} className={commonStyle.headerItemLeft} >
                        { 
                            history.location.pathname !== URL_PATH_SCHEDULE &&
                            history.location.pathname !== URL_PATH_TASKTIMES &&
                            history.location.pathname !== URL_PATH_CHATROOMS &&
                            history.location.pathname !== URL_PATH_SUBJECTS &&
                            history.location.pathname !== URL_PATH_MYPAGE &&
                            history.location.pathname !== URL_PATH_COACHING_MANAGEMENT &&
                            history.location.pathname !== URL_PATH_TRAINEE_LIST &&
                            history.location.pathname !== URL_PATH_USERS_MANUAL &&
                            history.location.pathname !== URL_PATH_NOTIFICATIONS &&
                            history.location.pathname !== URL_PATH_REGISTRATION ?
                            <IconButton
                                edge="start"
                                className={commonStyle.menuButton}
                                color="inherit"
                                aria-label="menu"
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                <ArrowBackIosIcon />
                            </IconButton>
                            :
                            <Box></Box>
                        }
                    </Grid>
                    <Grid item xs={8} className={commonStyle.headerItemCenter}>
                        <Typography
                            className={commonStyle.title}
                        >
                            {props.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} className={commonStyle.headerItemRight}>
                        { 
                            history.location.pathname !== URL_PATH_REGISTRATION ?
                            <Box>
                                {history.location.pathname === URL_PATH_NOTIFICATIONS ? 
                                <IconButton
                                    edge="start"
                                    className={localStyle.nowNotification}
                                    aria-label="notifications"
                                >
                                    <NotificationsIcon />
                                </IconButton>
                                :
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="notifications"
                                    onClick={() => {
                                        history.push(URL_PATH_NOTIFICATIONS);
                                    }}
                                >
                                    <NotificationsIcon />
                                </IconButton>
                                }
                            </Box>
                            :
                            <Box></Box>
                        }
                    </Grid>
                </Grid>
            </Box>
            <Box className={localStyle.pcHeader}>
                <Grid container className={commonStyle.header} >
                    <Grid item xs={1} className={commonStyle.headerItemLeft} >
                        { 
                            history.location.pathname !== URL_PATH_SCHEDULE &&
                            history.location.pathname !== URL_PATH_TASKTIMES &&
                            history.location.pathname !== URL_PATH_CHATROOMS &&
                            history.location.pathname !== URL_PATH_SUBJECTS &&
                            history.location.pathname !== URL_PATH_MYPAGE &&
                            history.location.pathname !== URL_PATH_COACHING_MANAGEMENT &&
                            history.location.pathname !== URL_PATH_TRAINEE_LIST &&
                            history.location.pathname !== URL_PATH_USERS_MANUAL &&
                            history.location.pathname !== URL_PATH_NOTIFICATIONS &&
                            history.location.pathname !== URL_PATH_REGISTRATION ?
                            <IconButton
                                edge="start"
                                className={commonStyle.menuButton}
                                color="inherit"
                                aria-label="menu"
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                <ArrowBackIosIcon />
                            </IconButton>
                            :
                            <Box></Box>
                        }
                    </Grid>
                    <Grid item xs={10} className={commonStyle.headerItemCenter}>
                        <Typography
                            className={commonStyle.title}
                        >
                            {props.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} className={commonStyle.headerItemRight}>
                        { 
                            history.location.pathname !== URL_PATH_REGISTRATION ?
                            <Box>
                                {history.location.pathname === URL_PATH_NOTIFICATIONS ? 
                                <IconButton
                                    edge="start"
                                    className={localStyle.nowNotification}
                                    aria-label="notifications"
                                >
                                    <NotificationsIcon />
                                </IconButton>
                                :
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="notifications"
                                    onClick={() => {
                                        history.push(URL_PATH_NOTIFICATIONS);
                                    }}
                                >
                                    <NotificationsIcon />
                                </IconButton>
                                }
                            </Box>
                            :
                            <Box></Box>
                        }
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Header;