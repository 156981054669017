// AWS
import { API } from "aws-amplify";

// React
import { 
    useEffect, 
    useReducer, 
    useState, 
} from 'react';

// Material UI
import { 
    Typography, 
    Box,
    makeStyles, 
} from '@material-ui/core';

// GraphQL
import { 
    listCorporations, 
    listCustomerChatrooms, 
    listOfferDatas, 
    listRefusalOfferDatas, 
    listScheduleEvents, 
    listTraineeDatas, 
    listUserDatas
} from "../../graphql/queries";

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { 
    ADDITIONAL_QUERY, 
    AMAZON_COGNITO_USER_POOLS, 
    HEADER_TITLE_TRAINEE_MANAGEMENT, 
    INITIAL_QUERY 
} from '../../Commons/Consts';
import { 
    getCurrentUserData,
    consoleLog, 
    getUserDataById, 
    getUserIconImage, 
    testrecursiveGrapheql, 
    testrecursiveGrapheqlForNonDispatch, 
} from "../../Commons/CommonFunctions";
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import SideMenu from "../../Commons/SideMenu";
import CoachingTraineeList from "../../components/CoachingManagement/CoachingTraineeList";
import CoachingTraineeDetail from "../../components/CoachingManagement/CoachingTraineeDetail";
import { useParams } from "react-router";
import CustomerTraineeList from "../../components/CustomerSuccess/CustomerTraineeList";
import CSSideMenu from "../../Commons/CSSideMenu";
import { SearchBox } from "../../Commons/SearchBox";
import { commonDBDataReducer, userDataReducer } from "../../Commons/Reducers";

const localStyles = makeStyles(theme => ({
    centerOfCenterContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
    },
    searchBoxArea:{
        height: "8%",
        display: "fixed",
    },
    scrollArea:{
        height: "92%",
        overflowY: "auto",
        overflowX: "hidden",
    },    
}));

export default function TranieeManagement(){
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    const param = useParams();
    const [userData, dispatchCurrentUserData] = useReducer(userDataReducer, []);
    const [isLoading, setIsLoading] = useState(true);
    const [traineeList, dispatchTraineeList] = useReducer(commonDBDataReducer, []);
    const [traineeData, setTraineeUserData] = useState([]);
    const [previewImage, setPreviewImage] = useState();
    const [events, setEventData] = useState([]);
    const [corporations, setCorporations] = useState([]);
    const [offers, setOffers] = useState([]);
    const [refOffers, setRefOffers] = useState([]);
    const [csChatrooms, setcsChatrooms] = useState([]);
    const [searchText, setSearchText] = useState('');
    
    const initTranieeManagement = async () =>{
        try {
            setIsLoading(true);

            if(searchText !== ''){
                const checkfilter = {
                    lastName: { contains: searchText },
                    // or:{
                    //     firstName: { contains: searchText },
                    // },
                };
                const searchUsers = await testrecursiveGrapheqlForNonDispatch(listUserDatas, 'listUserDatas', { filter:checkfilter, limit:100000 , nextToken: null});
                const trainees = await testrecursiveGrapheqlForNonDispatch(listTraineeDatas, 'listTraineeDatas', { limit:100000 , nextToken: null});
                const setObj = [];
                for(const user of searchUsers){
                    const searchTrainee = trainees.find((t) => t.userdatasID === user.userId);
                    if(searchTrainee !== undefined){
                        setObj.push(searchTrainee);
                    }
                }
                dispatchTraineeList({type: INITIAL_QUERY, data:setObj});
            }
            else{
                const trainees = await testrecursiveGrapheql(listTraineeDatas, 'listTraineeDatas', { limit:100000 , nextToken: null}, dispatchTraineeList);
            }

            const currentUserData = await getCurrentUserData();
            dispatchCurrentUserData({ type: INITIAL_QUERY, userData: currentUserData });
            
            // Get a specific item
            if(param.traineeId){
                const traineeUserData = await getUserDataById(param.traineeId);
                setTraineeUserData(traineeUserData);
                if(traineeUserData.iconImage !== null){
                    const file = await getUserIconImage(traineeUserData);
                    setPreviewImage(file);
                }
                else{
                    setPreviewImage(null);
                }
                
                const eventFilter = {
                    userDatasID: {contains: traineeUserData.userId}
                }
                const eventRes = await API.graphql({
                    query: listScheduleEvents,
                    variables: { filter: eventFilter, limit:10000 },
                    authMode: AMAZON_COGNITO_USER_POOLS,
                });
                setEventData(eventRes.data.listScheduleEvents.items);
            
                const corpRes = await API.graphql({
                    query: listCorporations,
                    variables: { limit:10000 },
                    authMode: AMAZON_COGNITO_USER_POOLS,
                });
                setCorporations(corpRes.data.listCorporations.items);

                // CS向けのチャットルーム遷移情報の取得
                const cusfilter = {userdatasID: {contains: param.traineeId}};
                const cusChatroomsRes = await API.graphql({
                    query: listCustomerChatrooms,
                    variables: { filter: cusfilter, limit: 10000 },
                    authMode: AMAZON_COGNITO_USER_POOLS,
                });
                setcsChatrooms(cusChatroomsRes.data.listCustomerChatrooms.items);
                
                // 閲覧対象のトレーニーユーザーに紐づく内定情報と辞退情報を取得し、State関数に保持
                const offerFilter = {
                    userdatasID: {contains: traineeUserData.userId}
                }
                const offerRes = await API.graphql({
                    query: listOfferDatas,
                    variables: { filter: offerFilter, limit:1000 },
                    authMode: AMAZON_COGNITO_USER_POOLS,
                });
                setOffers(offerRes.data.listOfferDatas.items);
    
                const refusalOfferRes = await API.graphql({
                    query: listRefusalOfferDatas,
                    variables: { filter: offerFilter, limit:1000 },
                    authMode: AMAZON_COGNITO_USER_POOLS,
                });
                setRefOffers(refusalOfferRes.data.listRefusalOfferDatas.items);  
            }
            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
        }
    }
    
    useEffect(() => {
        let abortCtrl = new AbortController();

        initTranieeManagement();
        
        // アンマウント時処理
        return() => {
            abortCtrl.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param.traineeId, searchText]);

    return(
        <Box>
            <Header title={HEADER_TITLE_TRAINEE_MANAGEMENT}/>
            <Box>
                <Box className={commonStyle.mobileModeDisplay}>
                    <Box className={commonStyle.pageContent} >
                        <CustomerTraineeList
                            trainees={traineeList}
                        />
                    </Box>
                </Box>
                <Box className={commonStyle.pcModeDisplay}>
                    <CSSideMenu/>
                    <Box className={commonStyle.secondSideMenu}>
                        <Box className={commonStyle.searchBoxArea}>
                            <SearchBox
                                searchText={searchText} 
                                setSearchText={setSearchText}
                            />
                        </Box>
                        <Box className={commonStyle.searchBoxOnScrollArea}>
                            <CustomerTraineeList
                                trainees={traineeList}
                                pcMode
                            />
                        </Box>
                    </Box>
                    <Box className={commonStyle.secondSideBarOnPageContent} >
                    {param.traineeId !== undefined?
                        isLoading ?
                        <LoadingIcon/>
                        :
                        <CoachingTraineeDetail
                            traineeId={param.traineeId}
                            traineeData={traineeData}
                            previewImage={previewImage}
                            events={events}
                            corporations={corporations}
                            offers={offers}
                            refOffers={refOffers}
                            csChatrooms={csChatrooms}
                            pcMode
                        />
                    :
                    <Box className={localStyle.centerOfCenterContents}>
                        <Typography variant="h4">トレーニーを選択してください</Typography>
                    </Box>
                    }
                    </Box>
                </Box>
            </Box>
            <Footer/>
        </Box>
    );
}
