// AWS React
import { API } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

// Self Making Files
import { 
    checkContentTypeIsImagefile, 
    getContentType, 
    getCurrentUserData, 
    getStorageDownloadObject, 
    getStorageObject, 
    getStringFromDateForFormatString, 
    getUserIconImage, 
    LineTextComponent, 
    consoleLog, 
} from "../../Commons/CommonFunctions";
import { createRef, Fragment, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { AMAZON_COGNITO_USER_POOLS, CHAT_STATUS_ALREADY_READ, CHAT_STATUS_READED, FORMAT_STRING_YYYYMMDDHHMM, INITIAL_QUERY, USER_ROLE } from '../../Commons/Consts';
import PreviewImageDialog from '../../Commons/PreviewImageDialog';
import { updateChatDatas, updateChatrooms, updateCustomerChatdatas } from '../../graphql/mutations';
import { commonStyles } from '../../Commons/CommonStyles';
import { pcChatroomContext } from '../../App';
import { listUserDatas } from '../../graphql/queries';

const localStyles = makeStyles(theme => ({
    listRoot:{
        paddingRight: "40px",
    },
    messsageCardRoot:{
        marginTop: "10px",
    },
    fromMessageCardGrid:{
        position: "relative",
        '&::before':{
            content: '""',
            position: "absolute",
            width: 0,
            height: 0,
            right: "-10px",
            top: "-10px",
            borderBottom: "20px solid #CDB59B",
            borderRight: "10px solid transparent",
            borderLeft: "10px solid transparent",
            transform: "rotate(45deg)",
        },
    },
    fromMessageCard:{
        borderRadius: "10px",
        padding: "10px",
        backgroundColor: "#CDB59B",
        [theme.breakpoints.down('sm')]: {
            '& .MuiTypography-root':{
                fontSize: "4vmin",
            },
        },
        [theme.breakpoints.up('md')]: {
            '& .MuiTypography-root':{
                fontSize: "2vmin",
            },
        },
    },
    fromMessageDeleteCardGrid:{
        position: "relative",
        '&::before':{
            content: '""',
            position: "absolute",
            width: 0,
            height: 0,
            right: "-10px",
            top: "-10px",
            borderBottom: "20px solid #B8B8B8",
            borderRight: "10px solid transparent",
            borderLeft: "10px solid transparent",
            transform: "rotate(45deg)",
        },
    },
    fromMessageDeleteCard:{
        borderRadius: "10px",
        padding: "10px",
        backgroundColor: "#B8B8B8",
        [theme.breakpoints.down('sm')]: {
            '& .MuiTypography-root':{
                fontSize: "4vmin",
            },
        },
        [theme.breakpoints.up('md')]: {
            '& .MuiTypography-root':{
                fontSize: "2vmin",
            },
        },
    },
    toMessageCardGrid:{
        position: "relative",
        '&::before':{
            content: '""',
            position: "absolute",
            width: 0,
            height: 0,
            left: "-10px",
            top: "-10px",
            borderBottom: "20px solid #FFFFFF",
            borderRight: "10px solid transparent",
            borderLeft: "10px solid transparent",
            transform: "rotate(-45deg)",
        },
    },
    toMessageCard:{
        borderRadius: "10px",
        padding: "10px",
        backgroundColor: "#FFFFFF",
        [theme.breakpoints.down('sm')]: {
            '& .MuiTypography-root':{
                fontSize: "4vmin",
            },
        },
        [theme.breakpoints.up('md')]: {
            '& .MuiTypography-root':{
                fontSize: "2vmin",
            },
        },
    },
    centerContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-start",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    rightContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-start",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "flex-end",
    },
    csNameContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-start",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        marginRight: "15px",
    },
    contentCard:{
        margin: "2px",
        padding: "5px",
        borderRadius: "10px",
        backgroundColor: "#555555",
        color: "#EEEEEE",
        width: "98%",
        display: "flex",
        flexDirection: "row",
        alignContent: "flex-start",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "center",
        '& .MuiAvatar-square':{
            marginLeft: "4px",
            marginRight: "4px",
        },
        '& .MuiTypography-root':{
            fontSize: "2vmin",
        },
        '& a':{
            display: "flex",
            flexDirection: "row",
            alignContent: "flex-start",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            alignItems: "center",
            color: "white",
            cursor: "pointer",
            textDecoration:"none",
            '& .MuiSvgIcon-root':{
                width: "40px",
                height: "40px",
            },
        },
    },
    endBox:{
        [theme.breakpoints.down('sm')]: {
            height: "40vh",
        },
        [theme.breakpoints.up('md')]: {
            height: "26vh",
        },
    },
}));

function PreviewImageBox({ imageKey, userData }){
    const localStyle = localStyles();
    const [contentType] = useState(getContentType(imageKey));
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState();
    const [downloadContent, setDownloadContent] = useState({});
    
    const initPreviewImageBox = async () => {
        if(checkContentTypeIsImagefile(contentType)){
            const res = await getStorageObject(userData, imageKey);
            setImage(res);
        }else{
            const res = await getStorageDownloadObject(userData, imageKey);
            setDownloadContent(res);
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        initPreviewImageBox();
        // アンマウント時処理
        return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <div>
            {checkContentTypeIsImagefile(contentType)?
            <Box className={localStyle.contentCard}>
                <Avatar variant="square" src={image} onClick={handleOpen}></Avatar>
                <Typography>{imageKey}</Typography>
                <PreviewImageDialog
                    open={open} 
                    setOpen={setOpen} 
                    handleClose={handleClose} 
                    caption={imageKey}
                    src={image}
                />
            </Box>
            :
            <Box className={localStyle.contentCard}>
                <a 
                    rel="noreferrer" 
                    target="_blank" 
                    href={downloadContent.href} 
                    download={downloadContent.download}
                >
                    <GetAppIcon/>
                    <Typography>{imageKey}</Typography>
                </a>
            </Box>
            }

        </div>);
}

const initialState = {
    mouseX: null,
    mouseY: null,
};
  
export function CustomerChatDataList({chatroom, chatData, sendFromUserData, sendToUserData}){
    const localStyle = localStyles();
    const commonStyle = commonStyles();
    const [sendToUserIcon, setSendToUserIcon] = useState();
    const [csUserDatas, setCsUserDatas] = useState([]);
    
    const [anchorEl, setAnchorEl] = useState(initialState);
  
    const handleClick = (event) => {
        event.preventDefault();
        setAnchorEl({
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        });
    };
  
    const handleClose = () => {
        setAnchorEl(initialState);
    };
  
    // ref を作成しスクロールさせたい場所にある Element にセット
    const ref = createRef();
    // このコールバックを呼び出して ref.current.scrollIntoView() を呼び出してスクロール
    const scrollToBottomOfList = useCallback(() => {
    ref.current.scrollIntoView({
        behavior: 'auto',
        block: 'end',
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ ref ]);

    const initCsUsers = async () =>{
        // 名前表示用のユーザーデータを取得
        const userFilter = {userRole: {eq: USER_ROLE.CustomerSuccess}}
        const csUsersRes = await API.graphql({
            query: listUserDatas,
            variables: { filter: userFilter, limit: 10000 },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
        setCsUserDatas(csUsersRes.data.listUserDatas.items);
    }

    const initChatIcons = async () => {
        setSendToUserIcon(await getUserIconImage(sendToUserData));
    }

    const readChatMessage = async (chat) =>{
        await API.graphql({
            query: updateChatDatas,
            variables: {
                input: {
                    id: chat.id,
                    checkOn: true,
                }        
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
        const updateUnReadFlg = chatroom.unReadFlg | 16; 
        await API.graphql({
            query: updateChatrooms,
            variables: {
                input: {
                    id: chatroom.id,
                }        
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        })
    }
    const updateUnReadFlg = async () => {
        const currentUserData = await getCurrentUserData();
        var updateUnReadFlg = chatroom.unReadFlg;
        if(currentUserData.userRole === USER_ROLE.Trainee){
            updateUnReadFlg = chatroom.unReadFlg | 1
        }
        else if(currentUserData.userRole === USER_ROLE.Coach){
            updateUnReadFlg = chatroom.unReadFlg | 4
        }
        else if(currentUserData.userRole === USER_ROLE.CustomerSuccess || 
            currentUserData.userRole === USER_ROLE.SuperAdministrator){
            updateUnReadFlg = chatroom.unReadFlg | 16
        }
        const res = await API.graphql({
            query: updateChatrooms,
            variables: {
                input: {
                    id: chatroom.id,
                    unReadFlg: updateUnReadFlg,
                }        
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
    }
    
    useEffect(() => {
        initChatIcons();
        initCsUsers();
        scrollToBottomOfList();
        for(const chat of chatData){
            if(chat.checkOn === false && chat.SendFromID !== sendFromUserData.userId){
                readChatMessage(chat);
            } 
        }
        if(0 < chatData.length){
            updateUnReadFlg();
        } 
        
        // アンマウント時処理
        return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatData]);

    return(
        <Box className={localStyle.listRoot}>
            {chatData
                .sort((a,b) => a.chatTimestamp < b.chatTimestamp ? -1 : 1)
                .map((data) => {
                const checkDate = new Date(data.chatDateTime);
                const gmtDate = new Date(checkDate.setHours(checkDate.getHours() + 9));
                const csUsers = csUserDatas.find((csUser) => (data.SendFromID === csUser.userId));
                return(
                data.SendFromID !== sendToUserData.userId ?
                <div>
                    <Box className={commonStyle.mobileModeDisplay}>
                        <Grid container className={localStyle.messsageCardRoot} >
                            <Grid item xs={4}/>
                            <Grid item xs={8} className={localStyle.fromMessageCardGrid}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    className={localStyle.fromMessageCard}
                                    rowSacing={1}
                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                    {data.chatOnImage === null ?
                                    <Fragment>{data.chatOnImage}</Fragment>
                                    :
                                    <PreviewImageBox imageKey={data.chatOnImage} userData={sendFromUserData} />
                                    }
                                    <Grid item xs={12}>
                                        <Typography><LineTextComponent msg={data.chatMessage}></LineTextComponent></Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={localStyle.rightContents}>
                                    <Typography variant='caption'>{getStringFromDateForFormatString(gmtDate, FORMAT_STRING_YYYYMMDDHHMM)}{sendFromUserData.userRole === USER_ROLE.Coach && data.checkOn === true ? CHAT_STATUS_READED : ''}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className={commonStyle.pcModeDisplay}>
                        <Grid container className={localStyle.messsageCardRoot} onClick={handleClick}>
                            <Grid item xs={12} className={localStyle.csNameContents}>
                                {csUsers !== undefined ?
                                    <Typography variant='caption'>{csUsers.lastName}{csUsers.firstName}</Typography>
                                :
                                    <Fragment></Fragment>
                                }
                            </Grid>
                            <Grid item xs={1}>{}</Grid>
                            <Grid item xs={11} className={data.chatDataStatus === 1 ? localStyle.fromMessageDeleteCardGrid : localStyle.fromMessageCardGrid}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    className={data.chatDataStatus === 1 ? localStyle.fromMessageDeleteCard : localStyle.fromMessageCard}
                                    rowSacing={1}
                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                    {data.chatOnImage === null ?
                                    <Fragment>{data.chatOnImage}</Fragment>
                                    :
                                    <PreviewImageBox imageKey={data.chatOnImage} userData={sendFromUserData} />
                                    }
                                    <Grid item xs={12}>
                                        <Typography><LineTextComponent msg={data.chatMessage}></LineTextComponent>{data.chatDataStatus === 1 ? '(非表示)' : '' }</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={localStyle.rightContents}>
                                    <Typography variant='caption'>{getStringFromDateForFormatString(gmtDate, FORMAT_STRING_YYYYMMDDHHMM)}{sendFromUserData.userRole === USER_ROLE.Coach && data.checkOn === true ? CHAT_STATUS_READED : ''}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Menu
                            id="simple-menu"
                            keepMounted
                            open={Boolean(anchorEl.mouseY)}
                            onClose={handleClose}
                            anchorReference="anchorPosition"
                            anchorPosition={
                                anchorEl.mouseY !== null && anchorEl.mouseX !== null
                                ? { top: anchorEl.mouseY, left: anchorEl.mouseX }
                                : undefined
                            }
                        >
                            {sendFromUserData.userRole === USER_ROLE.Trainee ? 
                            <MenuItem onClick={handleClose}>削除</MenuItem>
                            :
                            <MenuItem onClick={async () => {
                                    await API.graphql({
                                        query: updateChatDatas,
                                        variables: {
                                            input: {
                                                id: data.id,
                                                chatDataStatus: 1,
                                            }        
                                        },
                                        authMode: AMAZON_COGNITO_USER_POOLS,
                                    });
                                    handleClose();
                                }}
                            >
                                非表示化
                            </MenuItem>
                            }
                        </Menu>
                    </Box>
                </div>
                :
                <div>
                    <Box className={commonStyle.mobileModeDisplay}>
                        <Grid container className={localStyle.messsageCardRoot} >
                            <Grid item xs={1} className={localStyle.centerContents}>
                                <Avatar
                                    src={sendToUserIcon}
                                />
                            </Grid>
                            <Grid item xs={1}/>
                            <Grid item xs={8} className={localStyle.toMessageCardGrid} >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    className={localStyle.toMessageCard}
                                    rowSacing={1}
                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                    {data.chatOnImage === null ?
                                    <Fragment>{data.chatOnImage}</Fragment>
                                    :
                                    <PreviewImageBox imageKey={data.chatOnImage} userData={sendToUserData} />
                                    }
                                    <Grid item xs={12}>
                                        <Typography><LineTextComponent msg={data.chatMessage}></LineTextComponent></Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='caption'>{getStringFromDateForFormatString(gmtDate, FORMAT_STRING_YYYYMMDDHHMM)}{sendFromUserData.userRole === USER_ROLE.Coach && data.checkOn === true ? CHAT_STATUS_ALREADY_READ : ''}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}/>
                        </Grid>
                    </Box>
                    <Box className={commonStyle.pcModeDisplay}>
                        <Grid container className={localStyle.messsageCardRoot} >
                            <Grid item xs={10} className={localStyle.toMessageCardGrid} >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    className={localStyle.toMessageCard}
                                    rowSacing={1}
                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                    {data.chatOnImage === null ?
                                    <Fragment>{data.chatOnImage}</Fragment>
                                    :
                                    <PreviewImageBox imageKey={data.chatOnImage} userData={sendToUserData} />
                                    }
                                    <Grid item xs={12}>
                                        <Typography><LineTextComponent msg={data.chatMessage}></LineTextComponent></Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='caption'>{getStringFromDateForFormatString(gmtDate, FORMAT_STRING_YYYYMMDDHHMM)}{sendFromUserData.userRole === USER_ROLE.Coach && data.checkOn === true ? CHAT_STATUS_ALREADY_READ : ''}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}/>
                        </Grid>
                    </Box>
                </div>
            )})}
            <div id="endBox" ref={ref}></div>
        </Box>
    )
}

