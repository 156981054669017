// AWS
import { API } from "aws-amplify";

// React
import { 
    useEffect, 
    useState,
    useReducer,
    Fragment, 
} from 'react';

// Material UI
import { Box, makeStyles, Typography } from '@material-ui/core';

// GraphQL
import { 
    listChatDatas,
    listChatrooms, 
    listConectIsChatroomMembers,
    listCustomerChatrooms,
    listTodayWords,
    listTraineeDatas,
} from "../../graphql/queries";

// Self Making Files
import { 
    ADDITIONAL_QUERY, 
    AMAZON_COGNITO_USER_POOLS, 
    ERROR_TYPE_ABORTERROR, 
    HEADER_TITLE_CHATROOMS, 
    INITIAL_QUERY, 
    SUBSCRIPTION, 
    SUBSCRIPTION_CHAT, 
    USER_ROLE, 
} from '../../Commons/Consts';
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { 
    getCurrentUserData, 
    getCurrentUserInfo, 
    getUserDataById, 
    consoleLog, 
    recursiveGrapheql 
} from '../../Commons/CommonFunctions';
import { 
    userReducer, 
    chatroomsReducer,
    chatDatasReducer,
    commonDBDataReducer,
} from "../../Commons/Reducers";
import { ChatRoomList } from "../../components/ChatRoom/ChatRoomList";
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import SideMenu from "../../Commons/SideMenu";
import { ChatDataList } from "../../components/ChatRoom/ChatDataList";
import ChatMessageSender from "../../components/ChatRoom/ChatMessageSender";
import { onCreateChatDatas } from "../../graphql/subscriptions";
import { useParams } from "react-router";
import { ChatroomDetailViewer, ChatroomNameViewer } from "../../components/CustomerSuccess/ChatroomDetailViewer";
import TodayWordsHistory from "./TodayWordsHistory";
import { TodayWordsList } from "../../components/ChatRoom/TodayWordsList";
import { updateUserDatas } from "../../graphql/mutations";

const localStyles = makeStyles(theme => ({
    detailArea:{
        width: "100%",
        height: "6%",
        position: "absolute",
        top: "0%",
        borderBottom: "solid #666666 2px",
    },
    chatArea:{
        width: "100%",
        height: "78%",
        position: "absolute",
        top: "6%",
        overflow: "auto",
        padding: "10px",
        paddingLeft: "50px",
    },
    noSendMesasgechatArea:{
        width: "100%",
        height: "94%",
        position: "absolute",
        top: "6%",
        overflow: "auto",
        padding: "10px",
        paddingLeft: "50px",
    },
    messageArea:{
        width: "100%",
        height: "16%",
        position: "absolute",
        top: "84%",
        zIndex: "drawer",
        backgroundColor: theme.palette.background.paper,
    },
    centerOfCenterContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
    },
}));

export default function TestChatRooms(){
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    const param = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [sendToUserData, setSendToUserData] = useState([]);
    const [userData, dispachUser] = useReducer(userReducer, []);
    const [chatrooms, dispatchChatrooms] = useReducer(commonDBDataReducer, []);
    const [chatData, dispatchChatDatas] = useReducer(commonDBDataReducer, []);
    const [selectId, setSelectId] = useState();
    const [todaysWordsData, setTodayWordsData] = useState([]);
    const [noSendMesasgeFlag, setNoSendMesasgeFlag] = useState(false);
    
    const initChatrooms = async () =>{
        try{
            // ページ閲覧ユーザーデータの取得とState変数への保持
            setNoSendMesasgeFlag(false);
            var currentUserData = await getCurrentUserData();
            if(param.tgduserid !== undefined){
                currentUserData = await getUserDataById(param.tgduserid);
            }
            if(currentUserData.userName === undefined || currentUserData.userName === ''){
                const userInfo = await getCurrentUserInfo();
                const res = await API.graphql({
                    query: updateUserDatas,
                    variables: {
                        input: {
                            id: currentUserData.id,
                            userName: userInfo.id,
                        }        
                    },
                    authMode: AMAZON_COGNITO_USER_POOLS,
                });
                dispachUser({ type: INITIAL_QUERY, user:res.data.updateUserDatas});
            }else{
                dispachUser({ type: INITIAL_QUERY, user:currentUserData});
            }

            // ユーザー権限がトレーニーの場合は、トレーニーがマスターのチャットルームを取得し、State変数へ保持
            if(currentUserData.userRole === USER_ROLE.Trainee){
                const chatroomsfilter = { roomMasterUserDatasID: {contains: currentUserData.userId} }
                await recursiveGrapheql(listChatrooms, 'listChatrooms', { filter:chatroomsfilter, limit:100000 , nextToken: null}, dispatchChatrooms);
                if(param.chatroomId !== 'twh'){
                    const filter = {chatroomsID: {contains: param.chatroomId}};
                    const cusChatroomsRes = await API.graphql({
                        query: listCustomerChatrooms,
                        variables: { filter: filter, limit: 100000 },
                        authMode: AMAZON_COGNITO_USER_POOLS,
                    });
                    if(0 < cusChatroomsRes.data.listCustomerChatrooms.items.length){
                        if(cusChatroomsRes.data.listCustomerChatrooms.items.at(0).roomType === 'OPERATIONS'){
                            setNoSendMesasgeFlag(true);
                        }
                    }
                }
            }else if(currentUserData.userRole === USER_ROLE.Coach){
                // ユーザー権限がコーチの場合は、コーチが担当しているトレーニーのチャットルームを全て取得し、State変数へ保持
                const roomMemberChatroomFilter = { chatroomMemberUserDatasID: {contains: currentUserData.userId}};
                const memberChatroomRes = await API.graphql({
                    query: listConectIsChatroomMembers,
                    variables: { filter : roomMemberChatroomFilter, limit: 100000 },
                    authMode: AMAZON_COGNITO_USER_POOLS,
                });
                var chatrooms = [];
                for(const memberChatroom of memberChatroomRes.data.listConectIsChatroomMembers.items){
                    const masterChatroomfilter = { id: {contains: memberChatroom.chatroomID}};
                    const masterChatroomRes = await API.graphql({
                        query: listChatrooms,
                        variables: { filter: masterChatroomfilter, limit: 100000 },
                        authMode: AMAZON_COGNITO_USER_POOLS,
                    });
                    chatrooms = [...chatrooms, ...masterChatroomRes.data.listChatrooms.items];
                }
                dispatchChatrooms({ type: INITIAL_QUERY, data: chatrooms});
            }
            
            if(param.chatroomId === 'twh'){
                // 新井翔太の今日の一言を取得
                const traineeFilter = { userdatasID: {contains: currentUserData.userId} }
                const traineeRes = await API.graphql({
                    query: listTraineeDatas,
                    variables: { filter: traineeFilter , limit:100000 },
                    authMode: AMAZON_COGNITO_USER_POOLS,
                });
                const traineeData = traineeRes.data.listTraineeDatas.items;
                
                const wordsRes = await API.graphql({
                    query: listTodayWords,
                    variables: { limit: 100000 },
                    authMode: AMAZON_COGNITO_USER_POOLS,
                });
                var word = wordsRes.data.listTodayWords.items.filter((word) => word.displayNumber === 1);
                if(0 < traineeData.length){
                    setTodayWordsData(word);
                    const curriculumStartDate = new Date(traineeData.at(0).curriculumStartDate);
                    const nowDate = new Date();
                    const diffDays = parseInt((nowDate - curriculumStartDate) / 1000 / 60 / 60 / 24);
                    if(diffDays <= 1){
                        word = wordsRes.data.listTodayWords.items.filter((word) => word.displayNumber === 1);
                    }else{
                        word = wordsRes.data.listTodayWords.items.filter((word) => word.displayNumber <= diffDays);
                    }
                };
                setTodayWordsData(word);
            }
            
            setIsLoading(false);
        } catch (e) {
            if (e.name === ERROR_TYPE_ABORTERROR) {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    
    
    }

    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();

        initChatrooms();

        const createSubsc = API.graphql({
            query:onCreateChatDatas,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
        next: async (msg) => {
            if(msg.value.data.onCreateChatDatas.chatroomID === param.chatroomId){
                dispatchChatDatas({ type: SUBSCRIPTION, data:msg.value.data.onCreateChatDatas });
            }else{
                // 暫定的な対応が可能な処理（メッセージが一つもない場合に飲み、他者のメッセージを受信する可能性あり）
                dispatchChatDatas({ type: SUBSCRIPTION_CHAT, data:msg.value.data.onCreateChatDatas });
            }    
        }});

        // アンマウント時処理
        return () => {
            abortCtrl.abort();
            createSubsc.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param.chatroomId]);

    return(
        <Box>
            <Header title={HEADER_TITLE_CHATROOMS}/>
            <Box>
                <Box className={commonStyle.mobileModeDisplay}>
                    {isLoading?
                    <LoadingIcon/>
                    :
                    <Box className={commonStyle.pageContent} >
                        <ChatRoomList
                            chatrooms={chatrooms}
                            user={userData}
                        />
                        <Box className={commonStyle.endBox}/>
                    </Box>}
                </Box>
                <Box className={commonStyle.pcModeDisplay}>
                    <SideMenu/>
                    {isLoading?
                    <LoadingIcon/>
                    :
                    <Box>
                        <Box className={commonStyle.secondSideMenu}>
                            <Box pt={2} pb={2} className={commonStyle.leftContents}>
                                <Typography variant="h5">チャット</Typography>
                            </Box>
                            <Box className={commonStyle.scrollArea}>
                                <ChatRoomList
                                    chatrooms={chatrooms}
                                    user={userData}
                                    setSendToUserData={setSendToUserData}
                                    dispatchChatDatas={dispatchChatDatas}
                                    setSelectId={setSelectId}
                                    selectId={param.chatroomId}
                                />
                            </Box>
                        </Box>
                        {param.chatroomId !== 'twh'?
                            chatData.length !== 0 ?
                                isLoading?
                                <LoadingIcon/>
                                :
                                <Box className={commonStyle.secondSideBarOnPageContent}>
                                    <Box className={localStyle.detailArea}>
                                        <ChatroomNameViewer 
                                            chatrooms={chatrooms} 
                                            selectId={param.chatroomId}
                                            sendToUserData={sendToUserData}
                                        />
                                    </Box>
                                    {noSendMesasgeFlag?
                                    <Box className={localStyle.noSendMesasgechatArea}>
                                        <ChatDataList
                                            chatData={chatData} 
                                            sendFromUserData={userData} 
                                            sendToUserData={sendToUserData}
                                            chatroom={chatrooms.find((c) => c.id === param.chatroomId)}
                                        />
                                    </Box>
                                    :
                                    <Box className={localStyle.chatArea}>
                                        <ChatDataList
                                            chatData={chatData} 
                                            sendFromUserData={userData} 
                                            sendToUserData={sendToUserData}
                                            chatroom={chatrooms.find((c) => c.id === param.chatroomId)}
                                        />
                                    </Box>
                                    }
                                    {!noSendMesasgeFlag?
                                    <Box className={localStyle.messageArea}>
                                        <ChatMessageSender
                                            chatroomId={param.chatroomId}
                                            sendFromUserData={userData}
                                        />
                                    </Box>
                                    :
                                    <Fragment></Fragment>
                                    }
                                </Box>
                            :
                            <Box className={commonStyle.secondSideBarOnPageContent}>
                                <Box className={localStyle.chatArea}>
                                    <Typography variant="h5" className={localStyle.centerOfCenterContents}>メッセージがありません。</Typography>
                                </Box>
                                {!noSendMesasgeFlag?
                                <Box className={localStyle.messageArea}>
                                    <ChatMessageSender
                                        chatroomId={param.chatroomId}
                                        sendFromUserData={userData}
                                    />
                                </Box>
                                :
                                <Fragment></Fragment>
                                }
                            </Box>
                        :
                        param.chatroomId === 'twh'?
                        <Box className={commonStyle.secondSideBarOnPageContent}>
                            <TodayWordsList todayWordsData={todaysWordsData}></TodayWordsList>
                        </Box>
                        :
                        <Box className={commonStyle.secondSideBarOnPageContent}>
                            <Box className={localStyle.centerOfCenterContents}>
                                <Typography variant="h4">チャットルームを選択してください。</Typography>
                            </Box>
                        </Box>
                        }
                    </Box>}
                </Box>
            </Box>
            <Footer/>
        </Box>
    );
}
