import React, { Fragment, useEffect, useReducer, useState } from "react";
import { Avatar, Badge, Box, Card, Grid, makeStyles, withStyles, } from "@material-ui/core";
import { ArrowForwardIos } from '@material-ui/icons';
import CreateIcon from '@material-ui/icons/Create';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { 
    CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_OPERATION,
    CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_PAYMENT,
    CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_SUPPORT,
    CUSTOMER_SUCCESS_MENU_COACH_MANAGEMENT,
    CUSTOMER_SUCCESS_MENU_EVENT_BUKKING_STATUS,
    CUSTOMER_SUCCESS_MENU_TRAINEE_MANAGEMENT,
    FOOTER_TITLE_CHATROOMS, 
    FOOTER_TITLE_COACHING_MANAGEMENT, 
    FOOTER_TITLE_MANUAL, 
    FOOTER_TITLE_MYPAGE, 
    FOOTER_TITLE_SCHEDULE, 
    FOOTER_TITLE_SUBJECTS, 
    FOOTER_TITLE_TASKTAIMES, 
    FOOTER_TITLE_TRAINEE_LIST, 
    HEADER_TITLE_MASTER_MANAGEMENT, 
    INITIAL_QUERY, 
    URL_PATH_CHATROOMS, 
    URL_PATH_COACHING_MANAGEMENT, 
    URL_PATH_CS_CHAT_OPERATION, 
    URL_PATH_CS_CHAT_PAYMENT, 
    URL_PATH_CS_CHAT_SUPPORT, 
    URL_PATH_CS_COACH_MANAGEMENT, 
    URL_PATH_CS_EVENT_BOOKING_STATUS, 
    URL_PATH_CS_TRAINEE_MANAGEMENT, 
    URL_PATH_TRAINEE_MASTER_MANAGEMENT, 
    URL_PATH_MYPAGE, 
    URL_PATH_SCHEDULE, 
    URL_PATH_SUBJECTS, 
    URL_PATH_TASKTIMES, 
    URL_PATH_TRAINEE_LIST, 
    URL_PATH_USERS_MANUAL, 
    USER_ROLE, 
    URL_PATH_MASTER_MANAGEMENT,
    URL_PATH_CHATROOMS_DETAIL_CS,
    URL_PATH_CS_CHAT_APP_SUPPORT,
    CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_APP_SUPPORT,
    URL_PATH_CS_CHAT_SUPPORT_COACH,
    URL_PATH_CS_CHAT_OPERATION_COACH,
    URL_PATH_CS_CHAT_APP_SUPPORT_COACH,
    URL_PATH_CS_CHAT_COACHING,
    CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_COACHING,
    CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_SUPPORT_COACH,
    CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_OPERATION_COACH,
    CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_APP_SUPPORT_COACH,
    ERROR_TYPE_ABORTERROR,
    CustomerChatroomTypes,
    USER_TYPE
} from "./Consts";
import { commonStyles } from "./CommonStyles";
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import { 
    getCurrentUserData, 
    recursiveGrapheql, 
    consoleLog, 
} from "./CommonFunctions";
import { commonDBDataReducer, userDataReducer } from "./Reducers";
import UserRoleDispaly from "./UserRoleDispaly";
import { listChatrooms } from "../graphql/queries";

const localStyles = makeStyles(theme => ({
    menuButton:{
        padding: "10px",
        marginTop: "10px",
        marginBottom: "10px",
        width: "100%",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.palette.background.menuHover,
        },
    },
    menuActiveButton:{
        padding: "10px",
        marginTop: "10px",
        marginBottom: "10px",
        width: "100%",
        cursor: "pointer",
        backgroundColor: theme.palette.background.menuHover,
    },
    menuIcon:{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper,
        marginRight: "10px",
    },
    nowNotification:{
        color: theme.palette.action.main,
    },
    menuTitleArea:{
        height: "9%",
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-start",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    scrollArea:{
        height: "91%",
        width: "100%",
        overflow: "auto",
    },
    
}));

const NonActionCountBadge = withStyles((theme) => ({
    badge: {
        right: 23,
        top: -12,
        border: `2px solid ${theme.palette.background.paper}`,
        backgroundColor: "#AA0000",
        color: "#FFFFFF",
    },
}))(Badge);

function TraineeSideMenu(){
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    const history = useHistory();

    return (
    <Box>
        <Card 
            variant='outlined'
            className={localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_SCHEDULE)}}
            hidden
        >
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <TrendingUpIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{FOOTER_TITLE_SCHEDULE}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_TASKTIMES) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_TASKTIMES)}}
        >
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <CreateIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{FOOTER_TITLE_TASKTAIMES}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CHATROOMS) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CHATROOMS)}}
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <QuestionAnswerIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{FOOTER_TITLE_CHATROOMS}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_SUBJECTS) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_SUBJECTS)}}
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <FitnessCenterIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{FOOTER_TITLE_SUBJECTS}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_MYPAGE) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_MYPAGE)}}
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <PersonIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{FOOTER_TITLE_MYPAGE}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
    </Box>
    );
}

function CoachSideMenu(){
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    const history = useHistory();

    return (
    <Box>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_COACHING_MANAGEMENT) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_COACHING_MANAGEMENT)}}
        >
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <AssignmentIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{FOOTER_TITLE_COACHING_MANAGEMENT}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_TRAINEE_LIST) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_TRAINEE_LIST)}}
        >
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <PeopleIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{FOOTER_TITLE_TRAINEE_LIST}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CHATROOMS) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CHATROOMS)}}
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <QuestionAnswerIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{FOOTER_TITLE_CHATROOMS}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_USERS_MANUAL) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_USERS_MANUAL)}}
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <MenuBookIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{FOOTER_TITLE_MANUAL}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_MYPAGE) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_MYPAGE)}}
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <PersonIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{FOOTER_TITLE_MYPAGE}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
    </Box>
    );
}

function CustomerSuccessSideMenu(){
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    const history = useHistory();

    return (<Box>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CS_CHAT_COACHING) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CS_CHAT_COACHING)}}
        >
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <CompareArrowsIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_COACHING}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CS_CHAT_SUPPORT) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CS_CHAT_SUPPORT)}}
        >
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <LiveHelpIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_SUPPORT}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CS_CHAT_SUPPORT_COACH) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CS_CHAT_SUPPORT_COACH)}}
        >
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <LiveHelpIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_SUPPORT_COACH}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CS_CHAT_OPERATION) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CS_CHAT_OPERATION)}}
        >
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <DeveloperBoardIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_OPERATION}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CS_CHAT_OPERATION_COACH) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CS_CHAT_OPERATION_COACH)}}
        >
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <DeveloperBoardIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_OPERATION_COACH}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CS_CHAT_APP_SUPPORT) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CS_CHAT_APP_SUPPORT)}}
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <SettingsApplicationsIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_APP_SUPPORT}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CS_CHAT_APP_SUPPORT_COACH) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CS_CHAT_APP_SUPPORT_COACH)}}
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <SettingsApplicationsIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_APP_SUPPORT_COACH}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CS_TRAINEE_MANAGEMENT) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CS_TRAINEE_MANAGEMENT)}}
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <PeopleIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_TRAINEE_MANAGEMENT}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CS_COACH_MANAGEMENT) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CS_COACH_MANAGEMENT)}}
            hidden
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <PeopleOutlineIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_COACH_MANAGEMENT}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_TRAINEE_MASTER_MANAGEMENT) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_TRAINEE_MASTER_MANAGEMENT)}}
            hidden
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <ScheduleIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_EVENT_BUKKING_STATUS}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_MASTER_MANAGEMENT) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_MASTER_MANAGEMENT)}}
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <PersonIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{HEADER_TITLE_MASTER_MANAGEMENT}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_MYPAGE) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_MYPAGE)}}
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <PersonIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{FOOTER_TITLE_MYPAGE}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
    </Box>);
}

function SuperAdministratorSideMenu(chatrooms){
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    const history = useHistory();
    const [supNonActionCount, setSupNonActionCount] = useState(0);
    const [supUnReadCount, setSupUnReadCount] = useState(0);
    const [coSupNonActionCount, setCoSupNonActionCount] = useState(0);
    const [coSupUnReadCount, setCoSupUnReadCount] = useState(0);
    const [opNonActionCount, setOpNonActionCount] = useState(0);
    const [opUnReadCount, setOpUnReadCount] = useState(0);
    const [coOpNonActionCount, setCoOpNonActionCount] = useState(0);
    const [coOpUnReadCount, setCoOpUnReadCount] = useState(0);
    const [apSupNonActionCount, setApSupNonActionCount] = useState(0);
    const [apSupUnReadCount, setApSupUnReadCount] = useState(0);
    const [coApSupNonActionCount, setCoApSupNonActionCount] = useState(0);
    const [coApSupUnReadCount, setCoApSupUnreadCount] = useState(0);
    
    const initSuperAdministratorSideMenu = async () =>{
        try{
            // ページ閲覧ユーザーデータの取得とState変数への保持
            if(0 < chatrooms.chatrooms.length){
                const supChatrooms = chatrooms.chatrooms.filter((room) => {
                    if(0 < room.CustomerChatrooms.items.length){
                        return room.CustomerChatrooms.items.at(0).roomType === CustomerChatroomTypes.CUSTOMER_SUCCESS &&
                        room.CustomerChatrooms.items.at(0).userdatasType === USER_TYPE.TRAINEE;
                    }
                    else{
                        return false;
                    }
                }).filter((room) => room.unReadFlg !== 0 && room.unReadFlg !== 16);
                
                const coSupChatrooms = chatrooms.chatrooms.filter((room) => {
                    if(0 < room.CustomerChatrooms.items.length){
                        return room.CustomerChatrooms.items.at(0).roomType === CustomerChatroomTypes.CUSTOMER_SUCCESS &&
                        room.CustomerChatrooms.items.at(0).userdatasType === USER_TYPE.COACH;
                    }
                    else{
                        return false;
                    }
                }).filter((room) => room.unReadFlg !== 0 && room.unReadFlg !== 16);
                
                const opChatrooms = chatrooms.chatrooms.filter((room) => {
                    if(0 < room.CustomerChatrooms.items.length){
                        return room.CustomerChatrooms.items.at(0).roomType === CustomerChatroomTypes.OPERATIONS &&
                        room.CustomerChatrooms.items.at(0).userdatasType === USER_TYPE.TRAINEE;
                    }
                    else{
                        return false;
                    }
                }).filter((room) => room.unReadFlg !== 0 && room.unReadFlg !== 16);
                
                const coOpChatrooms = chatrooms.chatrooms.filter((room) => {
                    if(0 < room.CustomerChatrooms.items.length){
                        return room.CustomerChatrooms.items.at(0).roomType === CustomerChatroomTypes.OPERATIONS &&
                        room.CustomerChatrooms.items.at(0).userdatasType === USER_TYPE.COACH;
                    }
                    else{
                        return false;
                    }
                }).filter((room) => room.unReadFlg !== 0 && room.unReadFlg !== 16);
                
                const apSupChatrooms = chatrooms.chatrooms.filter((room) => {
                    if(0 < room.CustomerChatrooms.items.length){
                        return room.CustomerChatrooms.items.at(0).roomType === CustomerChatroomTypes.APP_SUPPORT &&
                        room.CustomerChatrooms.items.at(0).userdatasType === USER_TYPE.TRAINEE;
                    }
                    else{
                        return false;
                    }
                }).filter((room) => room.unReadFlg !== 0 && room.unReadFlg !== 16);
                const coApSupChatrooms = chatrooms.chatrooms.filter((room) => {
                    if(0 < room.CustomerChatrooms.items.length){
                        return room.CustomerChatrooms.items.at(0).roomType === CustomerChatroomTypes.APP_SUPPORT &&
                        room.CustomerChatrooms.items.at(0).userdatasType === USER_TYPE.COACH;
                    }
                    else{
                        return false;
                    }
                }).filter((room) => room.unReadFlg !== 0 && room.unReadFlg !== 16);
                    
                setSupNonActionCount(supChatrooms
                    .filter((room) => ((room.unReadFlg >> 1) & 1) === 1)
                    .filter((room) => ((room.unReadFlg >> 4) & 1) === 1).length);
                setSupUnReadCount(supChatrooms
                    .filter((room) => ((room.unReadFlg >> 1) & 1) === 1)
                    .filter((room) => ((room.unReadFlg >> 4) & 1) === 0).length);
                setOpNonActionCount(opChatrooms
                    .filter((room) => ((room.unReadFlg >> 1) & 1) === 1)
                    .filter((room) => ((room.unReadFlg >> 4) & 1) === 1).length);
                setOpUnReadCount(opChatrooms
                    .filter((room) => ((room.unReadFlg >> 1) & 1) === 1)
                    .filter((room) => ((room.unReadFlg >> 4) & 1) === 0).length);
                setApSupNonActionCount(apSupChatrooms
                    .filter((room) => ((room.unReadFlg >> 1) & 1) === 1)
                    .filter((room) => ((room.unReadFlg >> 4) & 1) === 1).length);
                setApSupUnReadCount(apSupChatrooms
                    .filter((room) => ((room.unReadFlg >> 1) & 1) === 1)
                    .filter((room) => ((room.unReadFlg >> 4) & 1) === 0).length);
                setCoSupNonActionCount(coSupChatrooms
                    .filter((room) => ((room.unReadFlg >> 3) & 1) === 1)
                    .filter((room) => ((room.unReadFlg >> 4) & 1) === 1).length);
                setCoSupUnReadCount(coSupChatrooms
                    .filter((room) => ((room.unReadFlg >> 3) & 1) === 1)
                    .filter((room) => ((room.unReadFlg >> 4) & 1) === 0).length);
                setCoOpNonActionCount(coOpChatrooms
                    .filter((room) => ((room.unReadFlg >> 3) & 1) === 1)
                    .filter((room) => ((room.unReadFlg >> 4) & 1) === 1).length);
                setCoOpUnReadCount(coOpChatrooms
                    .filter((room) => ((room.unReadFlg >> 3) & 1) === 1)
                    .filter((room) => ((room.unReadFlg >> 4) & 1) === 0).length);
                setCoApSupNonActionCount(coApSupChatrooms
                    .filter((room) => ((room.unReadFlg >> 3) & 1) === 1)
                    .filter((room) => ((room.unReadFlg >> 4) & 1) === 1).length);
                setCoApSupUnreadCount(coApSupChatrooms
                    .filter((room) => ((room.unReadFlg >> 3) & 1) === 1)
                    .filter((room) => ((room.unReadFlg >> 4) & 1) === 0).length);
            }
        } catch (e) {
            if (e.name === ERROR_TYPE_ABORTERROR) {
                // something to do when error occur
            }
        }
    }

    useEffect(() => {
        let abortCtrl = new AbortController();
        initSuperAdministratorSideMenu();
        
        // アンマウント時処理
        return () => {
            abortCtrl.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatrooms]);
    
    return (<Box>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CS_CHAT_COACHING) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CS_CHAT_COACHING)}}
        >
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <CompareArrowsIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_COACHING}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CS_CHAT_SUPPORT) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CS_CHAT_SUPPORT)}}
        >
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <LiveHelpIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="caption">未返信：{supNonActionCount} 未読：{supUnReadCount}</Typography>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_SUPPORT}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CS_CHAT_SUPPORT_COACH) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CS_CHAT_SUPPORT_COACH)}}
        >
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <LiveHelpIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="caption">未返信：{coSupNonActionCount} 未読：{coSupUnReadCount}</Typography>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_SUPPORT_COACH}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CS_CHAT_OPERATION) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CS_CHAT_OPERATION)}}
        >
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <DeveloperBoardIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="caption">未返信：{opNonActionCount} 未読：{opUnReadCount}</Typography>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_OPERATION}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CS_CHAT_OPERATION_COACH) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CS_CHAT_OPERATION_COACH)}}
        >
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <DeveloperBoardIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="caption">未返信：{coOpNonActionCount} 未読：{coOpUnReadCount}</Typography>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_OPERATION_COACH}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CS_CHAT_APP_SUPPORT) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CS_CHAT_APP_SUPPORT)}}
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <SettingsApplicationsIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="caption">未返信：{apSupNonActionCount} 未読：{apSupUnReadCount}</Typography>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_APP_SUPPORT}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CS_CHAT_APP_SUPPORT_COACH) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CS_CHAT_APP_SUPPORT_COACH)}}
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <SettingsApplicationsIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="caption">未返信：{coApSupNonActionCount} 未読：{coApSupUnReadCount}</Typography>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_APP_SUPPORT_COACH}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CS_COACH_MANAGEMENT) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CS_COACH_MANAGEMENT)}}
            hidden
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <PeopleOutlineIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_COACH_MANAGEMENT}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_TRAINEE_MASTER_MANAGEMENT) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_TRAINEE_MASTER_MANAGEMENT)}}
            hidden
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <ScheduleIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_EVENT_BUKKING_STATUS}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CS_TRAINEE_MANAGEMENT) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CS_TRAINEE_MANAGEMENT)}}
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <PeopleIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_TRAINEE_MANAGEMENT}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_CS_COACH_MANAGEMENT) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_CS_COACH_MANAGEMENT)}}
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <PeopleOutlineIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_COACH_MANAGEMENT}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_TRAINEE_MASTER_MANAGEMENT) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => history.push(URL_PATH_TRAINEE_MASTER_MANAGEMENT)}
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <ScheduleIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{CUSTOMER_SUCCESS_MENU_EVENT_BUKKING_STATUS}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_MASTER_MANAGEMENT) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_MASTER_MANAGEMENT)}}
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <PersonIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{HEADER_TITLE_MASTER_MANAGEMENT}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
        <Card 
            variant='outlined'
            className={history.location.pathname.startsWith(URL_PATH_MYPAGE) ? localStyle.menuActiveButton : localStyle.menuButton}
            onClick={() => {history.push(URL_PATH_MYPAGE)}}
        >    
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar className={localStyle.menuIcon}>
                        <PersonIcon/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} className={commonStyle.leftContents}>
                    <Typography variant="h6">{FOOTER_TITLE_MYPAGE}</Typography>
                </Grid>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <ArrowForwardIos/>
                </Grid>
            </Grid>
        </Card>
    </Box>);
}

export default function CSSideMenu(){
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    const [userData, dispachUser] = useReducer(userDataReducer, []);
    const [chatrooms, dispatchChatrooms] = useReducer(commonDBDataReducer, []);
    
    const getUserData = async() =>{
        const currentUserData = await getCurrentUserData();
        dispachUser({ type: INITIAL_QUERY, userData:currentUserData });
        await recursiveGrapheql(listChatrooms, 'listChatrooms', { limit:100000 , nextToken: null}, dispatchChatrooms);
    }

    useEffect(() => {
        // マウント時処理
        getUserData();
            
        // アンマウント時処理
        return;
    }, []);

    return (
        <Box className={commonStyle.sideMenu}>
            <Box className={localStyle.menuTitleArea}>
                <Typography variant="h5">メインメニュー</Typography>
            </Box>
            <Box className={localStyle.scrollArea}>
                {userData.userRole === USER_ROLE.Trainee ? 
                // トレーニー
                <TraineeSideMenu></TraineeSideMenu>
                :
                userData.userRole === USER_ROLE.Coach?
                // コーチ
                <CoachSideMenu></CoachSideMenu>
                :
                userData.userRole === USER_ROLE.CustomerSuccess ?
                // CS
                <CustomerSuccessSideMenu></CustomerSuccessSideMenu>
                :
                userData.userRole === USER_ROLE.SuperAdministrator ?
                // 全体管理者
                <SuperAdministratorSideMenu chatrooms={chatrooms}></SuperAdministratorSideMenu>
                :
                <Fragment></Fragment>
                }
            </Box>
        </Box>
    );
}