import React, { useEffect, useState } from 'react';
import { Analytics, Notifications } from 'aws-amplify';
import { Button, View, withInAppMessaging } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';

const { InAppMessaging } = Notifications;

// To display your in-app message, make sure this event name matches one you created
// in an In-App Messaging campaign!
const myFirstEvent = { name: 'ChatroomMessaging' };
const event = {
    name: 'ChatroomMessaging',
    attributes: { color: 'red' },
    metrics: { quantity: 10 }
  };

export function TestMessagingPage(){
    const [hasSound, setHasSound] = useState(true);
    useEffect(() => {
        if ("Notification" in window) {
            // 通知が許可されていたら早期リターン
            const permission = Notification.permission;
            if (permission === "denied" || permission === "granted") {
            return;
            }
            // 通知の許可を求める
            Notification.requestPermission().then(() => new Notification("通知が許可されました。"));
        }
        InAppMessaging.syncMessages();
    }, []);
  
    const handlePushNotif = () => {
      if ("Notification" in window) {
        const notif = new Notification("こんにちは！");
        // プッシュ通知が表示された時に起きるイベント
        notif.addEventListener("show", () => {
          // 状態によって音の有無を変える
          if (hasSound) {
            // 音再生
            new Audio("./push.wav").play();
          }
        });
      }
    };

    return (
        <View>
            <Button title="60歳以上" onClick={() => {
                Analytics.updateEndpoint({
                optOut: 'NONE',
                address: 'hogehoge.address',
                userAttributes: {
                    over60: [true]
                }
                }).then(() => {
                });
            }}>
                Create Endpoint
            </Button>
            {/* This button has an example of an analytics event triggering the in-app message. */}
            <Button
                onClick={() => {
                Analytics.record(myFirstEvent);
                }}
            >
                Record Analytics Event
            </Button>

            {/* This button has an example of an In-app Messaging event triggering the in-app message.*/}
            <Button
                onClick={() => {
                InAppMessaging.dispatchEvent(event);
                }}
            >
                Send In-App Messaging Event
            </Button>
            <Button onClick={handlePushNotif}>PUSH</Button>
            <Button onClick={() => setHasSound((prev) => !prev)}>
                {hasSound ? "音なしにする" : "音ありにする"}
            </Button>
        </View>
    );
};
