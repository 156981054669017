// AWS
import { API } from "aws-amplify";

// GraphQL
import { listCorporations } from "../../graphql/queries";

// React
import { 
    useState, 
    useEffect, 
} from "react";

// Material-ui
import { 
    FormHelperText, 
    TextField, 
} from "@material-ui/core"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { 
    AMAZON_COGNITO_USER_POOLS, 
    CAPTION_CORPORATION_NAME, 
    MESSAGE_EMPTY_NOT_REGISTRATION,
} from "../../Commons/Consts";

export default function CorporationNameInput({ setCorporationId, setInputName, error=false, required, hiddenEventForCorpIds }){
    const [companyList, setCompanyList] = useState();
    const [rootCompanyList, setRootCompanyList] = useState();
    const getCompanyData = async () => {
        const corpRes = await API.graphql({
            query: listCorporations,
            variables: { limit:10000 },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
        if(0 < corpRes.data.listCorporations.items.length){
            const corpList = corpRes.data.listCorporations.items.filter((corp) => {
                if(hiddenEventForCorpIds.find((id) => id === corp.id)){
                    return false;
                }
                else{
                    return true;
                }
            });
            setCompanyList(corpList);
            setRootCompanyList(corpList);
        }
        
    };
    
    useEffect(() => {
        getCompanyData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [value, setValue] = useState(null);
    
    return (
        <div>
            <Autocomplete
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="corporation-name-select"
                options={companyList}
                renderOption={(option) => option.corporationName}
                value={value}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        //setCheck("typeof newValue === 'string'");
                        setValue({
                            corporationName: newValue,
                        });
                        setCorporationId('');
                    } else if (newValue === null) {
                        //setCheck("newValue === null");
                        setCorporationId('');
                    } else {
                        //setCheck("typeof newValue !== 'string' || newValue !== null");
                        setValue(newValue);
                        setInputName(newValue.corporationName);
                        setCorporationId(newValue.id);
                    }
                }}
                onInputChange={(event, newValue) => {
                    setInputName(newValue);
                    setValue({
                        corporationName: newValue,
                    });
                    setCompanyList(rootCompanyList.filter((corp) => (corp.corporationName.indexOf(newValue) > -1)));
                }}
                getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.corporationName;
                    }}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} label={CAPTION_CORPORATION_NAME + (required ? '*' : '')} />}
            />
            <FormHelperText error={error} id="component-error-text">{error ? MESSAGE_EMPTY_NOT_REGISTRATION : ''}</FormHelperText>
        </div>
    );
}