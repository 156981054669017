// AWS
import { API } from "aws-amplify";

// React
import { 
    useEffect, 
    useState, 
    useReducer,
    Fragment, 
} from 'react';
import { useParams } from 'react-router-dom';

// Material UI
import { 
    Box,  
} from '@material-ui/core';

// GraphQL
import { listChatDatas, listChatrooms, listCustomerChatrooms } from "../../graphql/queries";
import { onCreateChatDatas } from '../../graphql/subscriptions';

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import * as Consts from '../../Commons/Consts';
import { 
    getCurrentUserData, 
    getUserDataById,
    recursiveGrapheql, 
    consoleLog, 
} from "../../Commons/CommonFunctions";
import { 
    userReducer, 
    chatDatasReducer,
    commonDBDataReducer, 
} from "../../Commons/Reducers";
import { ChatDataList } from "../../components/ChatRoom/ChatDataList";
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import SideMenu from "../../Commons/SideMenu";
import ChatMessageSender from "../../components/ChatRoom/ChatMessageSender";

export default function ChatRoomDetial(){
    const param = useParams();
    const commonStyle = commonStyles();   
    const [isLoading, setIsLoading] = useState(true);

    const [sendToUserName, setSendToUserName] = useState('');

    const [sendFromUserData, dispachSendFromUserData] = useReducer(userReducer, []);
    const [chatData, dispachChatData] = useReducer(commonDBDataReducer, []);
    const [chatroom, dispachChatroom] = useReducer(commonDBDataReducer, []);
    const [sendToUserData, dispachSendToUserData] = useReducer(userReducer, []);
    const [noSendMesasgeFlag, setNoSendMesasgeFlag] = useState(false);
    
    const initChatroomDetail = async () =>{
        try{
            // 閲覧ユーザーの情報を取得し、State変数へ保持
            const currentUserData = await getCurrentUserData();
            dispachSendFromUserData({type: Consts.INITIAL_QUERY, user:currentUserData});
            
            // URLパラメータのチャットルームIDに基づきチャットルームのデータを取得し、その中のチャットデータリストを取得し、State変数へ保持
            const filter = {
                id: {contains: param.chatroomId}
            }
            const chatRoomRes = await API.graphql({
                query: listChatrooms,
                variables: { filter: filter, limit:100000 },
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            });
            const chatroomData = chatRoomRes.data.listChatrooms.items.at(0);
            dispachChatroom({ type: Consts.INITIAL_QUERY, data:chatroomData });
            const chatdataFllter = {
                chatroomID: {contains: param.chatroomId}
            }
            await recursiveGrapheql(listChatDatas, 'listChatDatas', { filter:chatdataFllter, limit:100000 , nextToken: null}, dispachChatData);
            
            const cusfilter = {chatroomsID: {contains: param.chatroomId}};
            const cusChatroomsRes = await API.graphql({
                query: listCustomerChatrooms,
                variables: { filter: cusfilter, limit: 100000 },
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            });
            if(0 < cusChatroomsRes.data.listCustomerChatrooms.items.length){
                if(cusChatroomsRes.data.listCustomerChatrooms.items.at(0).roomType === 'OPERATIONS'){
                    setNoSendMesasgeFlag(true);
                }
            }

            // URLパラメータのチャットルームIDに従い、チャットルームの送信相手情報を取得し、State変数へ保持
            const chatroomMemberUserDatasID =  chatroomData.ConectIsChatroomMembers.items[0].chatroomMemberUserDatasID;
            if(chatroomMemberUserDatasID === currentUserData.userId){
                // チャットルームのメンバー側が閲覧ユーザーだった場合、チャットルームマスターを送信相手として情報を取得し、State変数へ保持
                const roomMemberUserData = await getUserDataById(chatroomData.roomMasterUserDatasID);
                dispachSendToUserData({ type: Consts.INITIAL_QUERY, user:roomMemberUserData });
                if(roomMemberUserData.displayName === null){
                    setSendToUserName(roomMemberUserData.lastName + roomMemberUserData.firstName);
                }
                else{
                    setSendToUserName(roomMemberUserData.displayName);
                }
            }else{
                // チャットルームのマスター側が閲覧ユーザーだった場合、チャットルームのメンバーを送信相手として情報を取得し、State変数へ保持
                const roomMemberUserData = await getUserDataById(chatroomMemberUserDatasID);
                dispachSendToUserData({ type: Consts.INITIAL_QUERY, user:roomMemberUserData });
                if(roomMemberUserData.displayName === null){
                    setSendToUserName(roomMemberUserData.lastName + roomMemberUserData.firstName);
                }
                else{
                    setSendToUserName(roomMemberUserData.displayName);
                }
            }
            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                // something to do when error occur
                consoleLog(sendFromUserData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();

        initChatroomDetail();

        const createSubsc = API.graphql({
            query:onCreateChatDatas,
            authMode: Consts.AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
        next: (msg) => {
            const chatData = msg.value.data.onCreateChatDatas;
            if(msg.value.data.onCreateChatDatas.chatroomID === param.chatroomId){
                dispachChatData({ type: Consts.SUBSCRIPTION, data: chatData });
            }
        }});

        return () => {
            abortCtrl.abort()
            createSubsc.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header title={sendToUserName === '' ? "" : "チャット:"+sendToUserName}/>
            <SideMenu/>
            {isLoading ?
            <LoadingIcon/>
            :
            <Box className={commonStyle.pageContent} >
                <Box mt={3}>
                    <ChatDataList
                        chatData={chatData} 
                        sendFromUserData={sendFromUserData} 
                        sendToUserData={sendToUserData}
                        chatroom={chatroom}
                    />
                    {noSendMesasgeFlag?
                    <Fragment></Fragment>
                    :
                    <ChatMessageSender
                        chatroomId={param.chatroomId}
                        sendFromUserData={sendFromUserData}
                    />
                    }
                </Box>
            </Box>
            }
            <Footer/>
        </Box>
    );
}
