
// AWS
import { 
    API, 
    Storage, 
} from "aws-amplify";

// React
import { Fragment } from 'react';
import { useEffect, useState, useReducer } from 'react';
import { useHistory } from 'react-router';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { 
    Typography, 
    Box, 
    Grid, 
    FormGroup, 
    FormControl, 
    Button, 
    FormHelperText, 
    TextField, 
    InputLabel, 
    Select, 
    MenuItem,
    IconButton,
    Avatar,
    Snackbar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// GraphQL
import { updateUserDatas } from '../../graphql/mutations';

// Self Making Files
import { 
    ERR_NAME_BANKACCOUNTNAMEUNMATCH,
    ERR_NAME_FIRSTNAMEKANAUNMATCH,
    ERR_NAME_LASTNAMEKANAUNMATCH,
    ERR_NAME_MATCHNUMBER,
    MESSAGE_ICON_SAVED,
    MESSAGE_SAVED,
    MESSAGE_SAVE_FAILED,
    UPDATE,
    USER_DATA_APARTMENT_NAME,
    USER_DATA_BANK_ACCOUNT_NAME,
    USER_DATA_BANK_ACCOUNT_NUMBER,
    USER_DATA_BANK_ACCOUNT_TYPE,
    USER_DATA_BANK_BRANCHE_NAME,
    USER_DATA_BANK_NAME,
    USER_DATA_BIRTH_DATE,
    USER_DATA_COURSE_NAME,
    USER_DATA_CWTV_NAME,
    USER_DATA_DEPARTMENT_NAME,
    USER_DATA_DISPLAY_NAME,
    USER_DATA_EMAIL_ADDRESS,
    USER_DATA_EMERGENCY_PERSON_ADDRESS,
    USER_DATA_EMERGENCY_PERSON_NAME,
    USER_DATA_EMERGENCY_PERSON_TYPE,
    USER_DATA_EMERGENCY_PHONE_NUMBER,
    USER_DATA_FIRST_NAME,
    USER_DATA_FIRST_NAME_KANA,
    USER_DATA_GRADUATE_SCHOOL_COURSE_NAME,
    USER_DATA_GRADUATE_SCHOOL_DEPARTMENT_NAME,
    USER_DATA_GRADUATE_SCHOOL_GRADUATION_YEAR,
    USER_DATA_GRADUATE_SCHOOL_NAME,
    USER_DATA_GRADUATION_YEAR,
    USER_DATA_HIGH_SCHOOL_NAME,
    USER_DATA_ICON_IMAGE, 
    USER_DATA_LAST_NAME, 
    USER_DATA_LAST_NAME_KANA, 
    USER_DATA_MIDDLE_NAME, 
    USER_DATA_MIDDLE_NAME_KANA, 
    USER_DATA_PREF_NAME, 
    USER_DATA_STREET_ADDRESS, 
    USER_DATA_UNIVERSITY_NAME, 
    USER_DATA_USER_REMARKS, 
    USER_DATA_USER_NAME,
    USER_DATA_WEEKDAY_PHONE_NUMBER,
    USER_ROLE,
    USER_DATA_PREF_CODE,
    PROFILE_EDIT_CAPTION_LAST_NAME,
    PROFILE_EDIT_CAPTION_FIRST_NAME,
    PROFILE_EDIT_CAPTION_LAST_NAME_KANA,
    PROFILE_EDIT_CAPTION_FIRST_NAME_KANA,
    PROFILE_EDIT_CAPTION_DISPLAY_NAME,
    PROFILE_EDIT_CAPTION_BIRTH_DATE,
    PROFILE_EDIT_CAPTION_WEEKDAY_PHONE_NUMBER,
    PROFILE_EDIT_CAPTION_PREF_NAME,
    PROFILE_EDIT_CAPTION_CWTV_NAME,
    PROFILE_EDIT_CAPTION_STREET_ADDRESS,
    PROFILE_EDIT_CAPTION_APARTMENT_NAME,
    PROFILE_EDIT_CAPTION_HIGHSCHOOL_NAME,
    PROFILE_EDIT_CAPTION_UNIVERSITY_NAME,
    PROFILE_EDIT_CAPTION_DEPARTMENT_NAME,
    PROFILE_EDIT_CAPTION_COURCE_NAME,
    PROFILE_EDIT_CAPTION_GRADUATION_YEAR,
    PROFILE_EDIT_CAPTION_GRADUATE_SCHOOL_NAME,
    PROFILE_EDIT_CAPTION_GRADUATE_SCHOOL_DEPARTMENT_NAME,
    PROFILE_EDIT_CAPTION_GRADUATE_SCHOOL_COURSE_NAME,
    PROFILE_EDIT_CAPTION_GRADUATE_SCHOOL_GRADUATION_YEAR,
    PROFILE_EDIT_CAPTION_EMERGENCY_PHONE_NUMBER,
    PROFILE_EDIT_CAPTION_EMERGENCY_PERSON_NAME,
    PROFILE_EDIT_CAPTION_EMERGENCY_PERSON_TYPE,
    PROFILE_EDIT_CAPTION_EMERGENCY_PERSON_ADDRESS,
    PROFILE_EDIT_CAPTION_BANK_NAME,
    PROFILE_EDIT_CAPTION_BANK_BRANCH_NAME,
    PROFILE_EDIT_CAPTION_BANK_BRANCH_TYPE,
    PROFILE_EDIT_CAPTION_BANK_ACCOUNT_NUMBER,
    PROFILE_EDIT_CAPTION_BANK_ACCOUNT_NAME,
    PROFILE_EDIT_CAPTION_REMARKS, 
} from '../../Commons/Consts';
import { 
    errorReducer, 
    userDataReducer, 
} from '../../Commons/Reducers';
import { 
    checkIsKana, 
    consoleLog, 
    getCurrentUserInfo, 
    stringLengthForinVariableSettingValue, 
    validationHerfSizeNumber, 
} from '../../Commons/CommonFunctions';

const useStyles = makeStyles(theme => ({
    avater:{ 
        width: 128, 
        height: 128
    },
    formFont:{
        [theme.breakpoints.down('sm')]: {
            '& .MuiTypography-h6':{
                fontSize: "4vmin",
            },
            '& .MuiTypography-h5':{
                fontSize: "5vmin",
            },
            '& .MuiInputBase-input':{
                fontSize: "3vmin",
            },
            '& .MuiOutlinedInput-input':{
                paddingTop: "10px",
                paddingBottom: "10px",
            },
            '& .MuiSelect-select.MuiOutlinedInput-input':{
                paddingBottom: "4px",
            },
        },
        [theme.breakpoints.up('md')]: {
            '& .MuiTypography-h6':{
                fontSize: "3vmin",
            },
            '& .MuiTypography-h5':{
                fontSize: "4vmin",
            },
            '& .MuiInputBase-input':{
                fontSize: "2vmin",
            },
            '& .MuiOutlinedInput-input':{
                paddingTop: "10px",
                paddingBottom: "10px",
            },
            '& .MuiSelect-select.MuiOutlinedInput-input':{
                paddingBottom: "4px",
            }
        },
        
    },
    formSpan:{
        paddingTop: "5px",
        paddingBottom: "5px",
    },
    selectSpan:{
        paddingBottom: "10px",
        '& .MuiOutlinedInput-notchedOutline > legend, span':{
            maxWidth: "92px",
            width: "92px",
        },
    },
    controlSpan:{
        paddingBottom: "10px",
        '& .MuiInputBase-multiline':{
            paddingTop: "5px",
            paddingBottom: "5px",
        },
    },
    helperText:{
        paddingLeft: "3px",
        display: "flex",
        alignItems: "center",
    },
    centerContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonHerf:{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
    },
    alertBar: {
        top: "10vh",
    },
    input: {
        display: 'none',
    },
    avaterIcon:{ 
        width: 128, 
        height: 128,
    },
}));

function RegistButtonGrid({ noCancel, onClickSaveButton }){
    const history = useHistory();
    const localStyle = useStyles();
    
    return(     
    <div>       
        {noCancel?
        <Grid container >
            <Grid item xs={12} className={localStyle.buttonHerf}>
                <Button 
                    fullWidth
                    variant="contained" 
                    color="primary"
                    onClick={onClickSaveButton}
                >保存</Button>
            </Grid>
        </Grid>
        :
        <Grid container >
            <Grid item xs={6} className={localStyle.buttonHerf}>
                <Button 
                    fullWidth
                    variant="contained" 
                    color="secondary"
                    onClick={() => history.goBack()}
                >キャンセル</Button>
            </Grid>
            <Grid item xs={6} className={localStyle.buttonHerf}>
                <Button 
                    fullWidth
                    variant="contained" 
                    color="primary"
                    onClick={onClickSaveButton}
                >保存</Button>
            </Grid>            
        </Grid>
        }
    </div>
);
}

export function ProfileEditForm({ initUserData, initPreviewImage, noCancel, updatedProcess, setUserData }){
    const localStyle = useStyles();
    const [userData, dispatchUserData] = useReducer(userDataReducer, initUserData);
    const [fileObject, setFileObject] = useState(null);
    const [previewImage, setPreviewImage] = useState(initPreviewImage);
    const [error, dispachError] = useReducer(errorReducer, {id:true});
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpen(false);
    };

    const updateProfile = async () =>{
        var input = {};
        for(var obj in userData){
            if(typeof(userData[obj]) !== 'object' && obj !== 'createdAt' && obj !== 'updatedAt'){
                input[obj] = userData[obj];
            }
        }
        if(fileObject){
            const storagePutRes = await Storage.put(
                fileObject.name, 
                fileObject, 
                {
                    level: 'protected',
                    contentType: fileObject.type,
                    progressCallback(progress) {
                        consoleLog(userData.userRole, `Uploaded: ${progress.loaded}/${progress.total}`);
            },
                }
            );
            const userInfo = await getCurrentUserInfo();
            input[USER_DATA_ICON_IMAGE] = storagePutRes.key;
            input[USER_DATA_USER_NAME] = userInfo.id;
        }
        const res = await API.graphql({
            query: updateUserDatas,
            variables: {
                input: input, 
                condition: {userId: {contains: userData.userId}}
            },
        });
        return res;
    }

    const onChangeIconImage = (event) => {
        const fileObject = event.target.files[0];
        setFileObject(fileObject);
        var file = window.URL.createObjectURL(fileObject);
        setPreviewImage(file);
        storageUploadIconImage(fileObject);
    }

    const storageUploadIconImage = async (fileObject) => {
        const storagePutRes = await Storage.put(
            fileObject.name, 
            fileObject, 
            {
                level: 'protected',
                contentType: fileObject.type,
                progressCallback(progress) {
                    consoleLog(userData.userRole, `Uploaded: ${progress.loaded}/${progress.total}`);
                },
            }
        );
        dispatchUserData({
            type: UPDATE, 
            updateName: USER_DATA_ICON_IMAGE,
            updateValue: storagePutRes.key,
        });
        const userInfo = await getCurrentUserInfo();
        const res = await API.graphql({
            query: updateUserDatas,
            variables: {
                input: {
                    id: userData.id,
                    userName: userInfo.id,
                    iconImage: userData.iconImage,
                }, 
                condition: {userId: {contains: userData.userId}}
            },
        });
        if(res.data !== null){
            setMessage(MESSAGE_ICON_SAVED);
            setOpen(true);
        }
    }

    const onClickSaveButton = async () =>{
        var check = true;
        
        // 必須チェック
        for(const key in userData){
            dispachError({type:UPDATE, name:key, set:false });
            if(userData[key] === null || userData[key] === ''){
                if(userData.userRole === USER_ROLE.Trainee || userData.userRole === USER_ROLE.RegistingUser){
                    if (key !== USER_DATA_ICON_IMAGE &&
                        key !== USER_DATA_USER_NAME && 
                        key !== USER_DATA_MIDDLE_NAME && 
                        key !== USER_DATA_MIDDLE_NAME_KANA &&
                        key !== USER_DATA_EMAIL_ADDRESS &&
                        key !== USER_DATA_BIRTH_DATE &&
                        key !== USER_DATA_WEEKDAY_PHONE_NUMBER &&
                        key !== USER_DATA_APARTMENT_NAME &&
                        key !== USER_DATA_DISPLAY_NAME &&
                        key !== USER_DATA_BANK_ACCOUNT_TYPE &&
                        key !== USER_DATA_PREF_CODE &&
                        key !== USER_DATA_PREF_NAME &&
                        key !== USER_DATA_STREET_ADDRESS &&
                        key !== USER_DATA_CWTV_NAME &&
                        key !== USER_DATA_USER_REMARKS &&
                        key !== USER_DATA_EMERGENCY_PERSON_ADDRESS &&
                        key !== USER_DATA_EMERGENCY_PERSON_NAME &&
                        key !== USER_DATA_EMERGENCY_PHONE_NUMBER &&
                        key !== USER_DATA_EMERGENCY_PERSON_TYPE &&
                        key !== USER_DATA_GRADUATE_SCHOOL_NAME &&
                        key !== USER_DATA_GRADUATE_SCHOOL_DEPARTMENT_NAME &&
                        key !== USER_DATA_GRADUATE_SCHOOL_COURSE_NAME &&
                        key !== USER_DATA_GRADUATE_SCHOOL_GRADUATION_YEAR &&
                        key !== USER_DATA_BANK_ACCOUNT_NAME &&
                        key !== USER_DATA_BANK_ACCOUNT_NUMBER &&
                        key !== USER_DATA_BANK_ACCOUNT_TYPE &&
                        key !== USER_DATA_BANK_BRANCHE_NAME &&
                        key !== USER_DATA_BANK_NAME){
                            dispachError({type:UPDATE, name:key, set:true });
                            check = false;
                    }
                }else{
                    if (key !== USER_DATA_ICON_IMAGE &&
                        key !== USER_DATA_USER_NAME && 
                        key !== USER_DATA_MIDDLE_NAME && 
                        key !== USER_DATA_MIDDLE_NAME_KANA &&
                        key !== USER_DATA_EMAIL_ADDRESS &&
                        key !== USER_DATA_BIRTH_DATE &&
                        key !== USER_DATA_WEEKDAY_PHONE_NUMBER &&
                        key !== USER_DATA_APARTMENT_NAME &&
                        key !== USER_DATA_DISPLAY_NAME &&
                        key !== USER_DATA_USER_REMARKS &&
                        key !== USER_DATA_BANK_ACCOUNT_TYPE &&
                        key !== USER_DATA_PREF_CODE &&
                        key !== USER_DATA_PREF_NAME &&
                        key !== USER_DATA_STREET_ADDRESS &&
                        key !== USER_DATA_CWTV_NAME &&
                        key !== USER_DATA_USER_REMARKS &&
                        key !== USER_DATA_EMERGENCY_PERSON_ADDRESS &&
                        key !== USER_DATA_EMERGENCY_PERSON_NAME &&
                        key !== USER_DATA_EMERGENCY_PHONE_NUMBER &&
                        key !== USER_DATA_EMERGENCY_PERSON_TYPE &&
                        key !== USER_DATA_GRADUATE_SCHOOL_NAME &&
                        key !== USER_DATA_GRADUATE_SCHOOL_DEPARTMENT_NAME &&
                        key !== USER_DATA_GRADUATE_SCHOOL_COURSE_NAME &&
                        key !== USER_DATA_GRADUATE_SCHOOL_GRADUATION_YEAR &&
                        key !== USER_DATA_BANK_ACCOUNT_NAME &&
                        key !== USER_DATA_BANK_ACCOUNT_NUMBER &&
                        key !== USER_DATA_BANK_ACCOUNT_TYPE &&
                        key !== USER_DATA_BANK_BRANCHE_NAME &&
                        key !== USER_DATA_BANK_NAME){
                            dispachError({type:UPDATE, name:key, set:true });
                            check = false;
                    } 
                }
            }
        }
        
        // カナチェック
        if(checkIsKana(userData.firstNameKana)){
            dispachError({type:UPDATE, name:ERR_NAME_FIRSTNAMEKANAUNMATCH, set:true });
            check = false;
        }
        else{
            dispachError({type:UPDATE, name:ERR_NAME_FIRSTNAMEKANAUNMATCH, set:false });
        }
        if(checkIsKana(userData.lastNameKana)){
            dispachError({type:UPDATE, name:ERR_NAME_LASTNAMEKANAUNMATCH, set:true });
            check = false;
        }
        else{
            dispachError({type:UPDATE, name:ERR_NAME_LASTNAMEKANAUNMATCH, set:false });
        }
        
        // バリデーションが全て反応しなかった場合のみ、登録可能
        if(check){
            const res = await updateProfile();
            if(res.data !== null){
                if(updatedProcess){
                    updatedProcess(userData);
                }
                else{
                    setMessage(MESSAGE_SAVED);
                    setOpen(true);
                }
            }
        }
        else{
            setMessage(MESSAGE_SAVE_FAILED);
            setOpen(true);
        }

    }

    useEffect(() => {
        let abortCtrl = new AbortController();
        // アンマウント時処理
        return() => {
            abortCtrl.abort();
        }
    }, []);

    return(
        <FormGroup className={localStyle.formFont}>
            <Typography variant='h6'>基本情報</Typography>
            <FormControl className={localStyle.formSpan} fullWidth>
                <Box className={localStyle.centerContents}>
                    <input 
                        accept="image/*" 
                        className={localStyle.input} 
                        id="icon-button-file" 
                        type="file" 
                        value=""
                        onChange={onChangeIconImage}
                    />
                    <label htmlFor="icon-button-file">
                        <IconButton color="primary" aria-label="upload picture" component="span">
                            <Avatar
                                src={previewImage}
                                className={localStyle.avaterIcon}
                            />
                        </IconButton>
                    </label>
                </Box>
            </FormControl>
            <FormControl className={localStyle.formSpan} fullWidth>
                <Grid container>
                    <Grid item xs={6}>
                        <TextField
                            label={PROFILE_EDIT_CAPTION_LAST_NAME}
                            variant="outlined"
                            fullWidth
                            required
                            error={error.lastName}
                            helperText={error.lastName ? '空白では登録できません。' : ''}
                            InputLabelProps={{ shrink: true }}
                            value={userData.lastName}
                            onChange={(event) => (
                                dispatchUserData({
                                    type: UPDATE, 
                                    updateName: USER_DATA_LAST_NAME, 
                                    updateValue: event.target.value,
                                })
                            )}
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={PROFILE_EDIT_CAPTION_FIRST_NAME}
                            variant="outlined"
                            fullWidth
                            required
                            error={error.firstName}
                            helperText={error.firstName ? '空白では登録できません。' : ''}
                            InputLabelProps={{ shrink: true }}
                            value={userData.firstName}
                            onChange={(event) => (
                                dispatchUserData({
                                    type: UPDATE, 
                                    updateName: USER_DATA_FIRST_NAME, 
                                    updateValue: event.target.value,
                                })
                            )}
                        >
                        </TextField>
                    </Grid>
                </Grid>          
            </FormControl>
            <FormControl className={localStyle.formSpan} fullWidth>
                <Grid container>
                    <Grid item xs={6}>
                        <TextField
                            label={PROFILE_EDIT_CAPTION_LAST_NAME_KANA}
                            variant="outlined"
                            fullWidth
                            required
                            error={error.lastNameKana ? true : error.lastNameKanaUnmatch}
                            helperText={error.lastNameKana ? '空白では登録できません。' : error.lastNameKanaUnmatch ? 'カタカナ以外では登録できません。' : ''}
                            InputLabelProps={{ shrink: true }}
                            value={userData.lastNameKana}
                            onChange={(event) => (
                                dispatchUserData({
                                    type: UPDATE, 
                                    updateName: USER_DATA_LAST_NAME_KANA, 
                                    updateValue: event.target.value,
                                })
                            )}
                        >
                        </TextField>
                        <FormHelperText>
                            全角カタカナのみ入力可能
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={PROFILE_EDIT_CAPTION_FIRST_NAME_KANA}
                            variant="outlined"
                            fullWidth
                            required
                            error={error.firstNameKana ? true : error.firstNameKanaUnmatch}
                            helperText={error.firstNameKana ? '空白では登録できません。' : error.firstNameKanaUnmatch ? 'カタカナ以外では登録できません。' : ''}
                            InputLabelProps={{ shrink: true }}
                            value={userData.firstNameKana}
                            onChange={(event) => (
                                dispatchUserData({
                                    type: UPDATE, 
                                    updateName: USER_DATA_FIRST_NAME_KANA, 
                                    updateValue: event.target.value,
                                })
                            )}
                        >
                        </TextField>
                        <FormHelperText>
                            全角カタカナのみ入力可能
                        </FormHelperText>
                    </Grid>
                </Grid>
            </FormControl>
            {userData.userRole === USER_ROLE.Coach ?
                <FormControl className={localStyle.formSpan} fullWidth>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            label={PROFILE_EDIT_CAPTION_DISPLAY_NAME}
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={userData.displayName}
                            onChange={(event) => (
                                dispatchUserData({
                                    type: UPDATE, 
                                    updateName: USER_DATA_DISPLAY_NAME, 
                                    updateValue: event.target.value,
                                })
                            )}
                        >
                        </TextField>
                    </Grid>
                </Grid>
            </FormControl>
            :
            <Fragment></Fragment>
            }
            {/* <FormControl className={localStyle.formSpan} fullWidth>
                <Grid container>
                    <Grid item xs={6}>
                        <TextField
                            id="datetime-local"
                            label={PROFILE_EDIT_CAPTION_BIRTH_DATE}
                            variant="outlined"
                            fullWidth
                            required
                            error={error.birthDate}
                            helperText={error.birthDate ? '空白では登録できません。' : ''}
                            type="date"
                            className={localStyle.textField}
                            InputLabelProps={{ shrink: true }}
                            value={userData.birthDate}
                            onChange={(event) => (
                                dispatchUserData({
                                    type: UPDATE, 
                                    updateName: USER_DATA_BIRTH_DATE, 
                                    updateValue: event.target.value,
                                })
                            )}
                        >
                        </TextField>
                    </Grid>
                </Grid>
            </FormControl>
            <FormControl className={localStyle.formSpan} fullWidth>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            label={PROFILE_EDIT_CAPTION_WEEKDAY_PHONE_NUMBER}
                            variant="outlined"
                            fullWidth
                            required
                            error={error.weekdayPhoneNumber}
                            helperText={error.weekdayPhoneNumber ? '空白では登録できません。' : ''}
                            InputLabelProps={{ shrink: true }}
                            value={userData.weekdayPhoneNumber}
                            onChange={(event) => (
                                dispatchUserData({
                                    type: UPDATE, 
                                    updateName: USER_DATA_WEEKDAY_PHONE_NUMBER, 
                                    updateValue: stringLengthForinVariableSettingValue(validationHerfSizeNumber(event.target.value),11),
                                })
                            )}
                        >
                        </TextField>
                        <FormHelperText>
                            ハイフンなしの半角数字で入力をしてください。
                        </FormHelperText>
                    </Grid>
                </Grid>
            </FormControl> */}
            {/* <Typography variant='h6'>住所情報</Typography>
            <FormControl className={localStyle.formSpan} fullWidth>
                <TextField
                    label={PROFILE_EDIT_CAPTION_PREF_NAME}
                    variant="outlined"
                    className={localStyle.controlSpan}
                    required
                    error={error.prefName}
                    helperText={error.prefName ? '空白では登録できません。' : ''}
                    InputLabelProps={{ shrink: true }}
                    value={userData.prefName}
                    onChange={(event) => (
                        dispatchUserData({
                            type: UPDATE, 
                            updateName: USER_DATA_PREF_NAME, 
                            updateValue: event.target.value,
                        })
                    )}
                >
                </TextField>
                <TextField
                    label={PROFILE_EDIT_CAPTION_CWTV_NAME}
                    variant="outlined"
                    className={localStyle.controlSpan}
                    required
                    error={error.cwtvName}
                    helperText={error.cwtvName ? '空白では登録できません。' : ''}
                    InputLabelProps={{ shrink: true }}
                    value={userData.cwtvName}
                    onChange={(event) => (
                        dispatchUserData({
                            type: UPDATE, 
                            updateName: USER_DATA_CWTV_NAME, 
                            updateValue: event.target.value,
                        })
                    )}
                >
                </TextField>
                <TextField
                    label={PROFILE_EDIT_CAPTION_STREET_ADDRESS}
                    variant="outlined"
                    className={localStyle.controlSpan}
                    required
                    error={error.streetAddress}
                    helperText={error.streetAddress ? '空白では登録できません。' : ''}
                    InputLabelProps={{ shrink: true }}
                    value={userData.streetAddress}
                    onChange={(event) => (
                        dispatchUserData({
                            type: UPDATE, 
                            updateName: USER_DATA_STREET_ADDRESS, 
                            updateValue: event.target.value,
                        })
                    )}
                >
                </TextField>
                <TextField
                    label={PROFILE_EDIT_CAPTION_APARTMENT_NAME}
                    variant="outlined"
                    className={localStyle.controlSpan}
                    InputLabelProps={{ shrink: true }}
                    value={userData.apartmentName}
                    onChange={(event) => (
                        dispatchUserData({
                            type: UPDATE, 
                            updateName: USER_DATA_APARTMENT_NAME, 
                            updateValue: event.target.value,
                        })
                    )}
                >
                </TextField>
            </FormControl> */}
            <Typography variant='h6'>学校情報</Typography>
            <FormControl className={localStyle.formSpan} fullWidth>
                <TextField
                    label={PROFILE_EDIT_CAPTION_HIGHSCHOOL_NAME}
                    variant="outlined"
                    fullWidth
                    required
                    error={error.highSchoolName}
                    helperText={error.highSchoolName ? '空白では登録できません。' : ''}
                    className={localStyle.controlSpan}
                    InputLabelProps={{ shrink: true }}
                    value={userData.highSchoolName}
                    onChange={(event) => (
                        dispatchUserData({
                            type: UPDATE, 
                            updateName: USER_DATA_HIGH_SCHOOL_NAME, 
                            updateValue: event.target.value,
                        })
                    )}
                >
                </TextField>
                <TextField
                    label={PROFILE_EDIT_CAPTION_UNIVERSITY_NAME}
                    variant="outlined"
                    fullWidth
                    className={localStyle.controlSpan}
                    required
                    error={error.universityName}
                    helperText={error.universityName ? '空白では登録できません。' : ''}
                    InputLabelProps={{ shrink: true }}
                    value={userData.universityName}
                    onChange={(event) => (
                        dispatchUserData({
                            type: UPDATE, 
                            updateName: USER_DATA_UNIVERSITY_NAME, 
                            updateValue: event.target.value,
                        })
                    )}
                >
                </TextField>
                <TextField
                    label={PROFILE_EDIT_CAPTION_DEPARTMENT_NAME}
                    variant="outlined"
                    fullWidth
                    className={localStyle.controlSpan}
                    required
                    error={error.departmentName}
                    helperText={error.departmentName ? '空白では登録できません。' : ''}
                    InputLabelProps={{ shrink: true }}
                    value={userData.departmentName}
                    onChange={(event) => (
                        dispatchUserData({
                            type: UPDATE, 
                            updateName: USER_DATA_DEPARTMENT_NAME, 
                            updateValue: event.target.value,
                        })
                    )}
                >
                </TextField>
                <TextField
                    label={PROFILE_EDIT_CAPTION_COURCE_NAME}
                    variant="outlined"
                    fullWidth
                    className={localStyle.controlSpan}
                    required
                    error={error.courseName}
                    helperText={error.courseName ? '空白では登録できません。' : ''}
                    InputLabelProps={{ shrink: true }}
                    value={userData.courseName}
                    onChange={(event) => (
                        dispatchUserData({
                            type: UPDATE, 
                            updateName: USER_DATA_COURSE_NAME, 
                            updateValue: event.target.value,
                        })
                    )}
                >
                </TextField>
                <Grid container className={localStyle.selectSpan}>
                    <Grid item xs={6}>
                        <FormControl 
                            required 
                            fullWidth
                            variant="outlined" 
                            error={error.graduationYear}
                        >
                            <InputLabel shrink id="outlined-grauation-year">{PROFILE_EDIT_CAPTION_GRADUATION_YEAR}</InputLabel>
                            <Select
                                label={PROFILE_EDIT_CAPTION_GRADUATION_YEAR}
                                labelId="outlined-grauation-year"
                                id='select-grauationyear'
                                value={userData.graduationYear}
                                onChange={(event) => (
                                    dispatchUserData({
                                        type: UPDATE, 
                                        updateName: USER_DATA_GRADUATION_YEAR, 
                                        updateValue: event.target.value,
                                    })
                                )}
                            >
                                <MenuItem value={null}></MenuItem>
                                <MenuItem value={'2030-03-01'}>30卒</MenuItem>
                                <MenuItem value={'2029-03-01'}>29卒</MenuItem>
                                <MenuItem value={'2028-03-01'}>28卒</MenuItem>
                                <MenuItem value={'2027-03-01'}>27卒</MenuItem>
                                <MenuItem value={'2026-03-01'}>26卒</MenuItem>
                                <MenuItem value={'2025-03-01'}>25卒</MenuItem>
                                <MenuItem value={'2024-03-01'}>24卒</MenuItem>
                                <MenuItem value={'2023-03-01'}>23卒</MenuItem>
                                <MenuItem value={'2022-03-01'}>22卒</MenuItem>
                                <MenuItem value={'2021-03-01'}>21卒</MenuItem>
                                <MenuItem value={'2020-03-01'}>20卒</MenuItem>
                                <MenuItem value={'2019-03-01'}>19卒</MenuItem>
                                <MenuItem value={'2018-03-01'}>18卒</MenuItem>
                                <MenuItem value={'2017-03-01'}>17卒</MenuItem>
                                <MenuItem value={'2016-03-01'}>16卒</MenuItem>
                                <MenuItem value={'2015-03-01'}>15卒</MenuItem>
                                <MenuItem value={'2014-03-01'}>14卒</MenuItem>
                                <MenuItem value={'2013-03-01'}>13卒</MenuItem>
                                <MenuItem value={'2012-03-01'}>12卒</MenuItem>
                                <MenuItem value={'2011-03-01'}>11卒</MenuItem>
                                <MenuItem value={'2010-03-01'}>10卒</MenuItem>
                                <MenuItem value={'2009-03-01'}>09卒</MenuItem>
                                <MenuItem value={'2008-03-01'}>08卒</MenuItem>
                                <MenuItem value={'2007-03-01'}>07卒</MenuItem>
                                <MenuItem value={'2006-03-01'}>06卒</MenuItem>
                                <MenuItem value={'2005-03-01'}>05卒</MenuItem>
                                <MenuItem value={'2004-03-01'}>04卒</MenuItem>
                                <MenuItem value={'2003-03-01'}>03卒</MenuItem>
                                <MenuItem value={'2002-03-01'}>02卒</MenuItem>
                                <MenuItem value={'2001-03-01'}>01卒</MenuItem>
                            </Select>
                            <FormHelperText>{error.graduationYear ? '空白では登録できません。' : ''}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <TextField
                    label={PROFILE_EDIT_CAPTION_GRADUATE_SCHOOL_NAME}
                    variant="outlined"
                    fullWidth
                    className={localStyle.controlSpan}
                    InputLabelProps={{ shrink: true }}
                    value={userData.graduateSchoolName}
                    onChange={(event) => (
                        dispatchUserData({
                            type: UPDATE, 
                            updateName: USER_DATA_GRADUATE_SCHOOL_NAME, 
                            updateValue: event.target.value,
                        })
                    )}
                >
                </TextField>
                <TextField
                    label={PROFILE_EDIT_CAPTION_GRADUATE_SCHOOL_DEPARTMENT_NAME}
                    variant="outlined"
                    fullWidth
                    className={localStyle.controlSpan}
                    InputLabelProps={{ shrink: true }}
                    value={userData.graduateSchoolDepartmentName}
                    onChange={(event) => (
                        dispatchUserData({
                            type: UPDATE, 
                            updateName: USER_DATA_GRADUATE_SCHOOL_DEPARTMENT_NAME, 
                            updateValue: event.target.value,
                        })
                    )}
                >
                </TextField>
                <TextField
                    label={PROFILE_EDIT_CAPTION_GRADUATE_SCHOOL_COURSE_NAME}
                    variant="outlined"
                    fullWidth
                    className={localStyle.controlSpan}
                    InputLabelProps={{ shrink: true }}
                    value={userData.graduateSchoolCourseName}
                    onChange={(event) => (
                        dispatchUserData({
                            type: UPDATE, 
                            updateName: USER_DATA_GRADUATE_SCHOOL_COURSE_NAME, 
                            updateValue: event.target.value,
                        })
                    )}
                >
                </TextField>
                <Grid container className={localStyle.selectSpan}>
                    <Grid item xs={6}>
                        <FormControl 
                            fullWidth
                            variant="outlined" 
                        >
                            <InputLabel shrink id="outlined-graduate-school-graduation-year">{PROFILE_EDIT_CAPTION_GRADUATE_SCHOOL_GRADUATION_YEAR}</InputLabel>
                            <Select
                                label={PROFILE_EDIT_CAPTION_GRADUATE_SCHOOL_GRADUATION_YEAR}
                                labelId="outlined-graduate-school-graduation-year"
                                id="select-graduate-school-graduation-year"
                                InputLabelProps={{ shrink: true }}
                                value={userData.graduateSchoolGraduationYear}
                                onChange={(event) => (
                                    dispatchUserData({
                                        type: UPDATE, 
                                        updateName: USER_DATA_GRADUATE_SCHOOL_GRADUATION_YEAR, 
                                        updateValue: event.target.value,
                                    })
                                )}
                            >
                                <MenuItem value={null}></MenuItem>
                                <MenuItem value={'2030-03-01'}>30卒</MenuItem>
                                <MenuItem value={'2029-03-01'}>29卒</MenuItem>
                                <MenuItem value={'2028-03-01'}>28卒</MenuItem>
                                <MenuItem value={'2027-03-01'}>27卒</MenuItem>
                                <MenuItem value={'2026-03-01'}>26卒</MenuItem>
                                <MenuItem value={'2025-03-01'}>25卒</MenuItem>
                                <MenuItem value={'2024-03-01'}>24卒</MenuItem>
                                <MenuItem value={'2023-03-01'}>23卒</MenuItem>
                                <MenuItem value={'2022-03-01'}>22卒</MenuItem>
                                <MenuItem value={'2021-03-01'}>21卒</MenuItem>
                                <MenuItem value={'2020-03-01'}>20卒</MenuItem>
                                <MenuItem value={'2019-03-01'}>19卒</MenuItem>
                                <MenuItem value={'2018-03-01'}>18卒</MenuItem>
                                <MenuItem value={'2017-03-01'}>17卒</MenuItem>
                                <MenuItem value={'2016-03-01'}>16卒</MenuItem>
                                <MenuItem value={'2015-03-01'}>15卒</MenuItem>
                                <MenuItem value={'2014-03-01'}>14卒</MenuItem>
                                <MenuItem value={'2013-03-01'}>13卒</MenuItem>
                                <MenuItem value={'2012-03-01'}>12卒</MenuItem>
                                <MenuItem value={'2011-03-01'}>11卒</MenuItem>
                                <MenuItem value={'2010-03-01'}>10卒</MenuItem>
                                <MenuItem value={'2009-03-01'}>09卒</MenuItem>
                                <MenuItem value={'2008-03-01'}>08卒</MenuItem>
                                <MenuItem value={'2007-03-01'}>07卒</MenuItem>
                                <MenuItem value={'2006-03-01'}>06卒</MenuItem>
                                <MenuItem value={'2005-03-01'}>05卒</MenuItem>
                                <MenuItem value={'2004-03-01'}>04卒</MenuItem>
                                <MenuItem value={'2003-03-01'}>03卒</MenuItem>
                                <MenuItem value={'2002-03-01'}>02卒</MenuItem>
                                <MenuItem value={'2001-03-01'}>01卒</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </FormControl>
            {/* {userData.userRole === USER_ROLE.Trainee ?
            <Fragment>
                <Typography variant='h6'>緊急連絡先情報</Typography>
                <FormControl className={localStyle.formSpan} fullWidth>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                label={PROFILE_EDIT_CAPTION_EMERGENCY_PHONE_NUMBER}
                                variant="outlined"
                                fullWidth
                                required
                                error={error.emergencyPhoneNumber ? true : error.matchNumber}
                                helperText={error.emergencyPhoneNumber ? '空白では登録できません。' : error.matchNumber ? '平時と緊急時の電話番号が同じのため登録できません。' : ''}
                                InputLabelProps={{ shrink: true }}
                                value={userData.emergencyPhoneNumber}
                                onChange={(event) => (
                                    dispatchUserData({
                                        type: UPDATE, 
                                        updateName: USER_DATA_EMERGENCY_PHONE_NUMBER, 
                                        updateValue: stringLengthForinVariableSettingValue(validationHerfSizeNumber(event.target.value),11),
                                    })
                                )}
                            >
                            </TextField>
                            <FormHelperText className={localStyle.helperText}>
                                ハイフンなしの半角数字で入力をしてください。
                            </FormHelperText>
                        </Grid>
                    </Grid>    
                </FormControl>
                <FormControl className={localStyle.formSpan} fullWidth>
                    <Grid container>
                        <Grid item xs={6}>
                            <TextField
                                label={PROFILE_EDIT_CAPTION_EMERGENCY_PERSON_NAME}
                                variant="outlined"
                                fullWidth
                                required
                                error={error.emergencyPersonName}
                                helperText={error.emergencyPersonName ? '空白では登録できません。' : ''}
                                InputLabelProps={{ shrink: true }}
                                value={userData.emergencyPersonName}
                                onChange={(event) => (
                                    dispatchUserData({
                                        type: UPDATE, 
                                        updateName: USER_DATA_EMERGENCY_PERSON_NAME, 
                                        updateValue: event.target.value,
                                    })
                                )}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={PROFILE_EDIT_CAPTION_EMERGENCY_PERSON_TYPE}
                                variant="outlined"
                                fullWidth
                                required
                                error={error.emergencyPersonType}
                                helperText={error.emergencyPersonType ? '空白では登録できません。' : ''}
                                InputLabelProps={{ shrink: true }}
                                value={userData.emergencyPersonType}
                                onChange={(event) => (
                                    dispatchUserData({
                                        type: UPDATE, 
                                        updateName: USER_DATA_EMERGENCY_PERSON_TYPE, 
                                        updateValue: event.target.value,
                                    })
                                )}
                            >
                            </TextField>
                        </Grid>
                    </Grid>
                </FormControl>
                <FormControl className={localStyle.formSpan} fullWidth>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                label={PROFILE_EDIT_CAPTION_EMERGENCY_PERSON_ADDRESS}
                                variant="outlined"
                                fullWidth
                                required
                                error={error.emergencyPersonAddress}
                                helperText={error.emergencyPersonAddress ? '空白では登録できません。' : ''}
                                InputLabelProps={{ shrink: true }}
                                value={userData.emergencyPersonAddress}
                                onChange={(event) => (
                                    dispatchUserData({
                                        type: UPDATE, 
                                        updateName: USER_DATA_EMERGENCY_PERSON_ADDRESS, 
                                        updateValue: event.target.value,
                                    })
                                )}
                            >
                            </TextField>
                        </Grid>
                    </Grid>
                </FormControl>
            </Fragment>
            :
            <Fragment></Fragment>
            } */}
            {userData.userRole === USER_ROLE.Coach ?
            <FormControl className={localStyle.formSpan} fullWidth>
                <Grid container>
                    <Grid item xs={12}>
                    <TextField
                        label={PROFILE_EDIT_CAPTION_REMARKS}
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={1}
                        maxRows={8}
                        className={localStyle.controlSpan}
                        InputLabelProps={{ shrink: true }}
                        value={userData.userRemarks}
                        onChange={(event) => (
                            dispatchUserData({
                                type: UPDATE, 
                                updateName: USER_DATA_USER_REMARKS, 
                                updateValue: event.target.value,
                            })
                        )}
                    >
                    </TextField>
                    </Grid>
                </Grid>
            </FormControl>
            :
            <Fragment></Fragment>
            }
            <RegistButtonGrid noCancel={noCancel} onClickSaveButton={onClickSaveButton}></RegistButtonGrid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message={message}
                className={localStyle.alertBar}
                action={
                    <Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Fragment>
                }
            />
        </FormGroup>
    );
}