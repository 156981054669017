// AWS
import { API } from "aws-amplify";

// React
import { 
    useEffect, 
    useState, 
    useReducer,
} from 'react';
import { useHistory } from 'react-router-dom';

// Material UI
import { 
    Box, 
    Grid, 
    Typography, 
} from '@material-ui/core';


// GraphQL
import { 
    listScheduleEvents, 
    listCorporations, 
} from "../../graphql/queries";
import { 
    onCreateScheduleEvents, 
} from '../../graphql/subscriptions';

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import * as Consts from '../../Commons/Consts';
import { 
    getCurrentUserData, 
    redirectForUserRoleToRedorectPages, 
    consoleLog, 
} from "../../Commons/CommonFunctions";
import { 
    eventReducer, 
    corporationsReducer, 
    userDataReducer, 
} from "../../Commons/Reducers";
import { EntrysheetDataCard } from "../../components/SelectionSituationCalender/EntrysheetDataCard";
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import SideMenu from "../../Commons/SideMenu";

export default function MyEntrysheets(){
    const commonStyle = commonStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);

    const [userData, dispatchCurrentUserData] = useReducer(userDataReducer, []);
    const [corporations, dispatchCorporations] = useReducer(corporationsReducer, []);
    const [events, dispachEventData] = useReducer(eventReducer, []);
    const initSelectionDetail = async () =>{
        try{
            // ユーザーロールに基づき見てはいけないユーザーロールはリダイレクト
            const currentUserData = await getCurrentUserData();
            redirectForUserRoleToRedorectPages(history, currentUserData, Consts.USER_ROLE.Trainee);
            dispatchCurrentUserData({ type: Consts.INITIAL_QUERY, userData: currentUserData });

            // 閲覧ユーザーに関わる選考状況イベントデータリストをクエリにて取得し、State変数へ保持
            const filter = {
                userDatasID: {contains: currentUserData.userId},
                eventType: {eq: 1}
            }
            const eventRes = await API.graphql({
                query: listScheduleEvents,
                variables: { filter: filter, limit:10000 },
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            });
            dispachEventData({ type: Consts.INITIAL_QUERY, events:eventRes.data.listScheduleEvents.items });
            
            // 企業情報データテーブルからURLパラメータのIDを基に企業情報データを取得し、State変数へ保持
            const corpRes = await API.graphql({
                query: listCorporations,
                variables: { limit:10000 },
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            });
            dispatchCorporations({ type: Consts.INITIAL_QUERY, corporations:corpRes.data.listCorporations });
            
            // プログレス表示を停止
            setIsLoading(false);
            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }
    
    useEffect(() => {
        let abortCtrl = new AbortController();

        // マウント時処理
        initSelectionDetail();

        const subscription = API.graphql({
            query:onCreateScheduleEvents,
            authMode: Consts.AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
            next: (msg) => {
                const event = msg.value.data.onCreateScheduleEvents;
                if(event.userDatasID === userData.userId){
                    dispachEventData({ type: Consts.SUBSCRIPTION, events: event });
                }
            }
        });

        // アンマウント時処理
        return() => {
            abortCtrl.abort();
            subscription.unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header title='エントリーシート一覧' />
            <SideMenu/>
            {isLoading ?
            <LoadingIcon/>
            :
            <Box className={commonStyle.pageContent}>
                <Box pt={2}>
                    <Grid container spacing={0}>
                        {events.length === 0 ?
                            <Typography>作成されたエントリーシートがありません。</Typography>
                        :events.sort((a,b) => {return a.deadlineTimestamp < b.deadlineTimestamp ? -1 : 1}).map(eventData => (
                            <EntrysheetDataCard eventData={eventData} corporations={corporations}/>
                        ))}
                    </Grid>
                    <Box className={commonStyle.endBox}/>
                </Box>
            </Box>
            }
            <Footer/>
        </Box>
    );
}
