// React
import { 
    useEffect, 
    useState, 
} from 'react';

// Material UI
import { 
    Typography, 
    Container, 
    Box, 
} from '@material-ui/core';

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { commonStyles } from '../../Commons/CommonStyles';
import { LoadingIcon } from '../../Commons/LoadingIcon';
import SideMenu from '../../Commons/SideMenu';

export default function AbuildBible(){
    const commonStyle = commonStyles();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        // アンマウント時処理
        let abortCtrl = new AbortController();
        setIsLoading(false);
        return() => {
            abortCtrl.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header title="Abuild教典"/>
            <SideMenu/>
            {isLoading ?
            <LoadingIcon/>
            :
            <Container className={commonStyle.pageContent} >
                <Box pt={2}>
                    <Typography variant='h6'>
                        工事中
                    </Typography>
                    <Box className={commonStyle.endBox}/>
                </Box>
            </Container>
            }
            <Footer/>
        </Box>
    );
}
