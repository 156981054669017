// AWS
import { API } from "aws-amplify";

// GraphQL

import { 
    listTaskDatas, 
    listTasktimeManagementDatas, 
} from "../../graphql/queries";

// React
import { 
    useEffect, 
    useState, 
    useReducer,
} from 'react';
import { useParams } from 'react-router-dom';

// Material-ui
import { 
    Box, 
} from '@material-ui/core';

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { 
    AMAZON_COGNITO_USER_POOLS, 
    ERROR_TYPE_ABORTERROR, 
    INITIAL_QUERY, 
    TASK_TIME_DETAIL_HEADER_TITLE,  
} from '../../Commons/Consts';
import { 
    formatDisplayDateStr, 
    getCurrentUserData,
    getUserDataById,
    recursiveGrapheql,
    testrecursiveGrapheql,
    testrecursiveGrapheqlForNonDispatch, 
    consoleLog, 
} from '../../Commons/CommonFunctions';
import { 
    userDataReducer,
    commonDBDataReducer, 
} from '../../Commons/Reducers';
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import SideMenu from "../../Commons/SideMenu";
import TaskTimeManagementEditor from "../../components/TaskTimeManegement/TaskTimeManagementEditor";

export default function TestTaskTimeDetail(){
    const param = useParams();
    const commonStyle = commonStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [timestampDateStr] = useState(formatDisplayDateStr(param.timestamp));
    
    const [tasks, dispatchTasks] = useReducer(commonDBDataReducer, []);
    const [ttm, dispatchTtm] = useReducer(commonDBDataReducer, []);
    const [userData, dispatchUserData] = useReducer(userDataReducer, []);
    
    const initTaskTimeDetail = async (type) =>{
        try {
            // URLパラメータtraineeIdがある場合はコーチがトレーニーのデータを見ていることになる
            var currentUserData = await getCurrentUserData();
            if(param.tgduserid !== undefined){
                currentUserData = await getUserDataById(param.tgduserid);
            }
            dispatchUserData({ type: type, userData: currentUserData });
            
            // タスクタイムマネジメント管理データを検索
            const ttmFilter = {
                id: {contains: param.ttmid},
            }
            const ttmDatas = await testrecursiveGrapheqlForNonDispatch(listTasktimeManagementDatas, 'listTasktimeManagementDatas', { filter: ttmFilter , limit:100000 , nextToken: null});
            const ttmData = ttmDatas.at(0);
            // タスクタイムマネジメント管理データに紐づくタスクデータを取得
            dispatchTtm({ type: type, data:ttmData });
            const tassFilter = {
                parentTasktimeManagementDatasID: {contains:ttmData.id},
            }
            await recursiveGrapheql(listTaskDatas, 'listTaskDatas', { filter: tassFilter , limit:100000 , nextToken: null}, dispatchTasks);
            setIsLoading(false);
        } catch (e) {
            if (e.name === ERROR_TYPE_ABORTERROR) {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        // マウント時処理
        // 初期化処理
        let abortCtrl = new AbortController();
        initTaskTimeDetail(INITIAL_QUERY);

        
        // アンマウント時処理
        return () => {
            abortCtrl.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header title={TASK_TIME_DETAIL_HEADER_TITLE+timestampDateStr}/>
            <SideMenu/>
            {isLoading ?
            <LoadingIcon/>
            :
            <Box className={commonStyle.pageContent} >
                <TaskTimeManagementEditor
                    tasks={tasks}
                    dispatchTasks={dispatchTasks}
                    ttm={ttm}
                    dispatchTtm={dispatchTtm}
                    userData={userData}
                    timestamp={param.timestamp}
                    ttmId={param.ttmid}
                />
            </Box>
            }
            <Footer/>
        </Box>
    );
}