// AWS
import { API } from "aws-amplify";

// GraphQL
import { 
    listScheduleEvents, 
    getCorporations, 
} from "../../graphql/queries";
import { 
    onCreateScheduleEvents, onDeleteScheduleEvents, 
} from '../../graphql/subscriptions';
import { 
    createOfferDatas, 
    updateScheduleEvents, 
    createRefusalOfferDatas, 
} from "../../graphql/mutations";

// React
import { 
    useEffect, 
    useState, 
    useReducer,
} from 'react';
import { 
    useHistory, 
    useParams, 
} from 'react-router-dom';

// Material-ui
import { 
    Box, 
    Grid, 
    Fab, 
    Button, 
    Container,
    Typography, 
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { 
    AMAZON_COGNITO_USER_POOLS, 
    BUTTON_CAPTION_OFFER_REGIST, 
    BUTTON_CAPTION_REFUSAL_OFFER_REGIST, 
    DETAIL_FORMAT_HEADER_TITLE, 
    DIALOG_AGREE_TEXT_REFUSAL_CONFIRM, 
    DIALOG_AGREE_TEXT_REGIST_CONFIRM, 
    DIALOG_CANCEL_TEXT_REFUSAL_CONFIRM, 
    DIALOG_CANCEL_TEXT_REGIST_CONFIRM, 
    DIALOG_CAPTION_REFUSAL_CONFIRM, 
    DIALOG_CAPTION_REGIST_CONFIRM, 
    DIALOG_CONTENT_TEXT_REFUSAL_CONFIRM, 
    DIALOG_CONTENT_TEXT_REGIST_CONFIRM, 
    ERROR_TYPE_ABORTERROR, 
    FOOTER_TITLE_SCHEDULE, 
    INITIAL_QUERY, 
    MESSAGE_EVENT_NOT_COUNT, 
    SUBSCRIPTION_CREATE,
    SUBSCRIPTION_DELETE,
    URL_PARTS_SLASH,
    URL_PATH_SCHEDULE_READ,
    USER_ROLE, 
} from '../../Commons/Consts';
import { 
    getCurrentUserData, 
    redirectForUserRoleToRedorectPages, 
    consoleLog, 
} from "../../Commons/CommonFunctions";
import { CreateOnlyCorporationEventModal } from "../../components/SelectionSituationCalender/CreateOnlyCorporationEventModal";
import { 
    eventReducer, 
    corporationsReducer, 
    userDataReducer, 
} from "../../Commons/Reducers";
import { EvnetDataCard } from "../../components/SelectionSituationCalender/EventDataCard";
import ConfirmDialog from "../../components/SelectionSituationCalender/ConfirmDialog";
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import SideMenu from "../../Commons/SideMenu";

export default function SelectionDetail(){
    const commonStyle = commonStyles();
    const param = useParams();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [registConfirmOpen, setRegistConfirmOpen] = useState(false);
    const [refusalConfirmOpen, setRefusalConfirmOpen] = useState(false);

    const [userData, dispatchCurrentUserData] = useReducer(userDataReducer, []);
    const [corporations, dispatchCorporations] = useReducer(corporationsReducer, []);
    const [events, dispachEventData] = useReducer(eventReducer, []);
    const [initColor, setInitColor] = useState();
    const initSelectionDetail = async () =>{
        try{
            // ユーザーロールに基づき見てはいけないユーザーロールはリダイレクト
            const currentUserData = await getCurrentUserData();
            redirectForUserRoleToRedorectPages(history, currentUserData, USER_ROLE.Trainee);
            dispatchCurrentUserData({ type: INITIAL_QUERY, userData: currentUserData });

            // 閲覧ユーザーに関わる選考状況イベントデータリストをクエリにて取得し、State変数へ保持
            const filter = {
                userDatasID: {contains: currentUserData.userId},
                corporationsID: {contains: param.corporationId}
            }
            const eventRes = await API.graphql({
                query: listScheduleEvents,
                variables: { filter: filter, limit:1000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            const events = eventRes.data.listScheduleEvents.items;
            dispachEventData({ type: INITIAL_QUERY, events:events });
            if(0 < events.length){
                setInitColor(events[0].eventColorcode);
            }
            
            // 企業情報データテーブルからURLパラメータのIDを基に企業情報データを取得し、State変数へ保持
            const corpRes = await API.graphql({
                query: getCorporations,
                variables: { id: param.corporationId, limit:10000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            dispatchCorporations({ type: INITIAL_QUERY, corporations:corpRes.data.getCorporations });
            
            // プログレス表示を停止
            setIsLoading(false);
        } catch (e) {
            if (e.name === ERROR_TYPE_ABORTERROR) {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }

    const createRafusalData = async () =>{
        const latestEvent = events.sort((a, b) => {return a.deadlineTimestamp < b.deadlineTimestamp ? 1 : -1 })[0];
        await API.graphql({
            query: updateScheduleEvents,
            variables: {
                input: {
                    id: latestEvent.id, 
                    status: -1,
                }     
            }
        });
        
        await API.graphql({
            query: createRefusalOfferDatas,
            variables: {
                input: {
                    refusalOfferDatasScheduleEventsId: latestEvent.id,
                    corporationsID: corporations.id, 
                    userdatasID: userData.userId, 
                    userName: userData.lastName + userData.firstName, 
                    corporationName: corporations.corporationName,
                }     
            }
        });
        setRegistConfirmOpen(false);
        history.replace(URL_PATH_SCHEDULE_READ + URL_PARTS_SLASH + corporations.id);
    }
    const createOfferData = async () =>{
        const latestEvent = events.sort((a, b) => {return a.deadlineTimestamp < b.deadlineTimestamp ? 1 : -1 })[0];
        for(const eventData in events){
            await API.graphql({
                query: updateScheduleEvents,
                variables: {
                    input: {
                        id: eventData.id, 
                        status: 101,
                    }     
                }
            });
        }
        
        await API.graphql({
            query: createOfferDatas,
            variables: {
                input: {
                    offerDatasScheduleEventsId: latestEvent.id,
                    corporationsID: corporations.id, 
                    userdatasID: userData.userId, 
                    userName: userData.lastName + userData.firstName, 
                    corporationName: corporations.corporationName,
                    offerStatus: 1,
                }     
            }
        });
        setRegistConfirmOpen(false);
        history.replace(URL_PATH_SCHEDULE_READ + URL_PARTS_SLASH + corporations.id);
    }

    const onClickRafusalButton = () =>{
        setRefusalConfirmOpen(true);
    }

    const onClickOfferButton = () =>{
        setRegistConfirmOpen(true);
    }
    
    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();
        initSelectionDetail();

        const createSubscription = API.graphql({
            query:onCreateScheduleEvents,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
            next: (msg) => {
                const event = msg.value.data.onCreateScheduleEvents;
                dispachEventData({ type: SUBSCRIPTION_CREATE, events: event });
            }
        });

        const deleteSubscription = API.graphql({
            query:onDeleteScheduleEvents,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
            next: (msg) => {
                const event = msg.value.data.onDeleteScheduleEvents;
                dispachEventData({ type: SUBSCRIPTION_DELETE, events: event });
            }
        });

        // アンマウント時処理
        return () => {
            abortCtrl.abort();
            createSubscription.unsubscribe();
            deleteSubscription.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // モーダル用の設定値
    const [open, setOpen] = useState(false);
    
    const handleOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };
    
    return(
        <Box>
            <Header 
                title={corporations.corporationName != null ? DETAIL_FORMAT_HEADER_TITLE + corporations.corporationName : FOOTER_TITLE_SCHEDULE}
            />
            <SideMenu/>
            {isLoading ?
            <LoadingIcon/>
            :
            <Box className={commonStyle.pageContent}>
                {events.length === 0 ?
                <Box pt={2}>
                    <Container>
                        <Typography variant="caption">
                            {MESSAGE_EVENT_NOT_COUNT}
                        </Typography>
                    </Container>
                </Box>
                :
                <Box pt={2}>
                    <Grid container spacing={0}>
                        {events.length === 1 ?
                            events.map(eventData => {return (<EvnetDataCard eventData={eventData} />)
                                // return (<Fragment></Fragment>)
                            })
                            :events.sort((a,b) => {return a.deadlineTimestamp < b.deadlineTimestamp ? -1 : 1}).map(eventData => (
                                <EvnetDataCard eventData={eventData} />
                            ))}
                    </Grid>
                    <Box pt={3}>
                        <Grid 
                            container
                            display="flex"
                            direction="row"
                            alignItems="center"                      
                        >
                            <Grid item xs={6} className={commonStyle.centerContents} p={5}>
                                <Button 
                                    variant="contained" 
                                    color="secondary"
                                    onClick={onClickRafusalButton}
                                >
                                    {BUTTON_CAPTION_REFUSAL_OFFER_REGIST}
                                </Button>
                            </Grid>
                            <Grid item xs={6} className={commonStyle.centerContents} p={5}>
                                <Button 
                                    variant="contained" 
                                    color="primary"
                                    onClick={onClickOfferButton}
                                >
                                    {BUTTON_CAPTION_OFFER_REGIST}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                }
                <Fab 
                    color="primary" 
                    aria-label="add" 
                    className={commonStyle.fabbut} 
                    onClick={handleOpen}>
                    <AddIcon />
                </Fab>
            </Box>
            }
            <CreateOnlyCorporationEventModal
                userData={userData}
                corporations={corporations}
                setOpen={setOpen}
                open={open}
                handleClose={handleClose}
                initColor={initColor}
            />
            <Box className={commonStyle.endBox}/>
            <ConfirmDialog 
                confirmEvent={createRafusalData}
                open={refusalConfirmOpen}
                setOpen={setRefusalConfirmOpen}
                caption={DIALOG_CAPTION_REFUSAL_CONFIRM}
                contentText={DIALOG_CONTENT_TEXT_REFUSAL_CONFIRM}
                cancelText={DIALOG_CANCEL_TEXT_REFUSAL_CONFIRM}
                agreeText={DIALOG_AGREE_TEXT_REFUSAL_CONFIRM}
            />
            <ConfirmDialog 
                confirmEvent={createOfferData}
                open={registConfirmOpen}
                setOpen={setRegistConfirmOpen}
                caption={DIALOG_CAPTION_REGIST_CONFIRM}
                contentText={DIALOG_CONTENT_TEXT_REGIST_CONFIRM}
                cancelText={DIALOG_CANCEL_TEXT_REGIST_CONFIRM}
                agreeText={DIALOG_AGREE_TEXT_REGIST_CONFIRM}
            />
            <Footer/>
        </Box>
    );
}
