// AWS
import { API } from 'aws-amplify';

// React
import { 
    Fragment,
    useEffect, 
    useState, 
} from 'react';
import { useHistory } from 'react-router';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { 
    Box, 
    Container, 
} from '@material-ui/core';

// Self Making Files
import Header from '../../Commons/Header';
import { 
    getCurrentUserInfo, 
    getStringFromDateForFormatString, 
    getUserDataById, 
    consoleLog, 
} from '../../Commons/CommonFunctions';
import { ProfileEditForm } from '../../components/Mypage/ProfileEditForm';
import { createChatrooms, createConectIsChatroomMember, createConectIsCoachingToTargetTrainee, createCustomerChatrooms, createTraineeDatas, createUserDatas, updateConectIsCoachingToTargetTrainee, updateUserDatas } from '../../graphql/mutations';
import { 
    AMAZON_COGNITO_USER_POOLS,
    CustomerChatroomTypes,
    FORMAT_STRING_AWSDATE,
    FORMAT_STRING_TZDATESTRING,
    URL_PATH_CHATROOMS,
    URL_PATH_MYPAGE,
    USER_ROLE,
    USER_TYPE, 
} from '../../Commons/Consts';
import { commonStyles } from '../../Commons/CommonStyles';
import { listConectIsCoachingToTargetTrainees, listCorporations, listInitChatroomDatas } from '../../graphql/queries';
import { LoadingIcon } from '../../Commons/LoadingIcon';
import { Button } from '@aws-amplify/ui-react';

const localStyles = makeStyles(theme => ({
    avater:{ 
        width: 64, 
        height: 64
    },
    profileButton:{
        height: 50,
        marginBottom:10,
    },
    endBox:{
        height: "40vh"
    },
}));

export async function initCSChatrooms(initUserData, userType){
    const csRoomInitFilter = {
        initChatroomType: {eq: CustomerChatroomTypes.CUSTOMER_SUCCESS}
    }
    const csRoomInitDataRes = await API.graphql({
        query: listInitChatroomDatas,
        variables: { filter: csRoomInitFilter,limit: 100000 },
        authMode: AMAZON_COGNITO_USER_POOLS,
    });
    const csInitMemberUserdatasID = csRoomInitDataRes.data.listInitChatroomDatas.items.at(0).initMemberUserdatsID;
    const csRoomCreRes = await API.graphql({
        query: createChatrooms,
        variables: {
            input: {
                roomMasterUserDatasID : initUserData.userId,
                lastMessage: "",
                roomMasterName: initUserData.lastName + " " + initUserData.firstName,
                unReadFlg: 0,
                sendFromTimestamp: 0,
                sendToTimestamp: 0,
            }
        }
    });
    const csRoomId = csRoomCreRes.data.createChatrooms.id;
    await API.graphql({
        query: createConectIsChatroomMember,
        variables: {
            input: {
                chatroomMemberUserDatasID : csInitMemberUserdatasID,
                chatroomID : csRoomId,
            }
        }
    });
    await API.graphql({
        query: createCustomerChatrooms,
        variables: {
            input: {
                chatroomsID: csRoomId, 
                roomType: CustomerChatroomTypes.CUSTOMER_SUCCESS,
                userdatasID: initUserData.userId, 
                tag: "",
                userdatasType: userType,
                roomMasterName: initUserData.lastName + " " + initUserData.firstName,
            }
        }
    });
      
    
    const opRoomInitFilter = {
        initChatroomType: {eq: CustomerChatroomTypes.OPERATIONS}
    }
    const opRoomInitDataRes = await API.graphql({
        query: listInitChatroomDatas,
        variables: { filter: opRoomInitFilter,limit: 100000 },
        authMode: AMAZON_COGNITO_USER_POOLS,
    });
    const opInitMemberUserdatasID = opRoomInitDataRes.data.listInitChatroomDatas.items.at(0).initMemberUserdatsID;
    const opRoomCreRes = await API.graphql({
        query: createChatrooms,
        variables: {
            input: {
                roomMasterUserDatasID : initUserData.userId,
                lastMessage: "",
                roomMasterName: initUserData.lastName + " " + initUserData.firstName,
                unReadFlg: 0,
                sendFromTimestamp: 0,
                sendToTimestamp: 0,
            }
        }
    });
    const opRoomId = opRoomCreRes.data.createChatrooms.id;
    await API.graphql({
        query: createConectIsChatroomMember,
        variables: {
            input: {
                chatroomMemberUserDatasID : opInitMemberUserdatasID,
                chatroomID : opRoomId,
            }
        }
    });
    await API.graphql({
        query: createCustomerChatrooms,
        variables: {
            input: {
                chatroomsID: opRoomId, 
                roomType: CustomerChatroomTypes.OPERATIONS,
                userdatasID: initUserData.userId, 
                tag: "",
                userdatasType: userType,
                roomMasterName: initUserData.lastName + " " + initUserData.firstName,
            }
        }
    });

    const apRoomInitFilter = {
        initChatroomType: {eq: CustomerChatroomTypes.APP_SUPPORT}
    }
    const apRoomInitDataRes = await API.graphql({
        query: listInitChatroomDatas,
        variables: { filter: apRoomInitFilter,limit: 100000 },
        authMode: AMAZON_COGNITO_USER_POOLS,
    });
    const apInitMemberUserdatasID = apRoomInitDataRes.data.listInitChatroomDatas.items.at(0).initMemberUserdatsID;
    const apRoomCreRes = await API.graphql({
        query: createChatrooms,
        variables: {
            input: {
                roomMasterUserDatasID : initUserData.userId,
                lastMessage: "",
                roomMasterName: initUserData.lastName + " " + initUserData.firstName,
                unReadFlg: 0,
                sendFromTimestamp: 0,
                sendToTimestamp: 0,
            }
        }
    });
    const apRoomId = apRoomCreRes.data.createChatrooms.id;
    await API.graphql({
        query: createConectIsChatroomMember,
        variables: {
            input: {
                chatroomMemberUserDatasID : apInitMemberUserdatasID,
                chatroomID : apRoomId,
            }
        }
    });
    await API.graphql({
        query: createCustomerChatrooms,
        variables: {
            input: {
                chatroomsID: apRoomId, 
                roomType: CustomerChatroomTypes.APP_SUPPORT,
                userdatasID: initUserData.userId, 
                tag: "",
                userdatasType: userType,
                roomMasterName: initUserData.lastName + " " + initUserData.firstName,
            }
        }
    });
}

export default function TraineeRegistration(){
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    
    const [userData, setUserData] = useState([]);
    const [previewImage] = useState([]);

    const setInitData = async () =>{
        // Get a specific item
        try {
            const user = await getCurrentUserInfo();
            const userData = await getUserDataById(user.username);
            if(userData === void 0){
                // ユーザーデータの作成（userId、userName、新規登録状態のuserRoleを情報に持つ初期登録状態ユーザー）
                const res = await API.graphql({
                    query: createUserDatas,
                    variables: {
                        input: {
                            userId: user.username,
                            userName: user.id,
                            userRole: USER_ROLE.RegistingUser,
                            firstName: '',
                            firstNameKana: '',
                            lastName: '',
                            lastNameKana: '',
                            highSchoolName: '',
                            universityName: '',
                            departmentName: '',
                            courseName: '',
                            graduateSchoolName: '',
                            graduateSchoolDepartmentName: '',
                            graduateSchoolCourseName: '',
                        }
                    }
                });
                setUserData(res.data.createUserDatas);
            }else{
                if( userData.userRole === USER_ROLE.RegistingUser || 
                    userData.userRole === USER_ROLE.SuperAdministrator){
                    setUserData(userData);
                }
                else{
                    history.replace(URL_PATH_CHATROOMS);
                }
            }
            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
        }
    }

    const firstRegistarationOperation = async (userData) =>{
        setIsLoading(true);
        const initDate = new Date();
        const res = await API.graphql({
            query: updateUserDatas,
            variables: {
                input: {
                    id: userData.id,
                    userRole: USER_ROLE.Trainee,
                }, 
            },
        });
        
        await API.graphql({
            query: createConectIsCoachingToTargetTrainee,
            variables: {
                input: {
                    coachdatasID: userData.userId,
                    traineedatasID: userData.userId,
                }
            }
        });

        const nonCorp = {
            corporationName: {contains: '所属無し'}
        }
        const corpRes = await API.graphql({
            query: listCorporations,
            variables: { filter: nonCorp,limit: 10000 },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });

        await API.graphql({
            query: createTraineeDatas,
            variables: {
                input: {
                    userdatasID: userData.userId, 
                    registrationDateTime: getStringFromDateForFormatString(initDate, FORMAT_STRING_TZDATESTRING),
                    curriculumStartDate: getStringFromDateForFormatString(initDate, FORMAT_STRING_AWSDATE), 
                    traineeStatus: 10, 
                    intervewPublic: 0, 
                    interviewImplementation: 0, 
                    namePublic: 0, 
                    picturePublic: 0, 
                    corporationOfEmploymentID: corpRes.data.listCorporations.items.at(0).id,
                }
            }
        });
          
        await initCSChatrooms(userData, USER_TYPE.TRAINEE);

        history.replace(URL_PATH_CHATROOMS);
    }
    
    useEffect(() => {
        // アンマウント時処理
        let abortCtrl = new AbortController();

        setInitData();
        // アンマウント時処理
        return() => {
            abortCtrl.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            {isLoading?
            <LoadingIcon></LoadingIcon>
            :
            <Box>
                <Header title="新規登録"/>
                <Container className={commonStyle.pageContent} >
                    <Box pt={2}>
                        <ProfileEditForm
                            initUserData={userData} 
                            initPreviewImage={previewImage} 
                            noCancel
                            updatedProcess={firstRegistarationOperation}
                            setUserData={setUserData}
                        />
                        {userData.userRole === USER_ROLE.SuperAdministrator ?
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            className={localStyle.profileButton}
                            onClick={()=>{history.push(URL_PATH_MYPAGE);}}>
                            マイページへ
                        </Button>
                        :
                        <Fragment></Fragment>
                        }
                        <Box className={localStyle.endBox}/>
                    </Box>
                </Container>
            </Box>
            }
        </Box>
    );
}
