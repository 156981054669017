// React
import { useEffect, useState } from 'react';

// Material UI
//import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Box, Grid, makeStyles } from '@material-ui/core';

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { commonStyles } from '../../Commons/CommonStyles';
import { LoadingIcon } from '../../Commons/LoadingIcon';
import SideMenu from '../../Commons/SideMenu';
import { userReducer } from '../../Commons/Reducers';
import { 
    getCurrentUserData, 
    consoleLog, 
} from "../../Commons/CommonFunctions";
import { INITIAL_QUERY } from '../../Commons/Consts';

const localStyles = makeStyles(theme => ({
    buttonArea:{
        padding: "10px",
    },
}));

export default function UsersManual(){
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    
    const [isLoading, setIsLoading] = useState(true);
    const [userData, dispatchCurrentUserData] = userReducer(userReducer, []);
    
    const initCoachingManagement = async () =>{
        try {
            const currentUserData = await getCurrentUserData();
            dispatchCurrentUserData({ type: INITIAL_QUERY, user: currentUserData });
            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }
    useEffect(() => {
        let abortCtrl = new AbortController();

        initCoachingManagement();
        // アンマウント時処理
        return() => {
            abortCtrl.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header title="マニュアル"/>
            <Box>
                <Box className={commonStyle.mobileModeDisplay}>
                    <Box className={commonStyle.pageContent} >
                        <Box className={commonStyle.centerOfCenterContents} >
                            <Typography variant='h4'>マニュアル整備中</Typography>
                        </Box>
                    </Box>
                </Box>
                <SideMenu/>
                <Box className={commonStyle.pcModeDisplay}>
                    {isLoading ?
                    <LoadingIcon/>
                    :
                    <Box className={commonStyle.pageContent} >
                        <Box className={commonStyle.centerContents} >
                            <Container>
                                <Box pt={2}>
                                    <Grid container>
                                        <Grid xs={6} className={localStyle.buttonArea}>
                                            <a 
                                                rel="noreferrer" 
                                                target="_blank" 
                                                href="https://drive.google.com/file/d/1FhiQHCthrl6JbcnbYLATpranBGS_nv35/view?usp=share_link" 
                                                className={commonStyle.linkButton}
                                            >
                                                【スマホ版】アプリのホーム画面追加について
                                            </a>
                                        </Grid>
                                        <Grid xs={6} className={localStyle.buttonArea}>
                                            <a 
                                                rel="noreferrer" 
                                                target="_blank" 
                                                href="https://drive.google.com/file/d/1SCpNTGqpuFc8xiNOfRuJNsG3H8MKAPQx/view?usp=share_link" 
                                                className={commonStyle.linkButton}
                                            >
                                                トレーニー情報の管理
                                            </a>
                                        </Grid>
                                        <Grid xs={6} className={localStyle.buttonArea}>
                                            <a 
                                                rel="noreferrer" 
                                                target="_blank" 
                                                href="https://drive.google.com/file/d/1iHFR54xMoN9inURRfWDr8bhiL2DrNYrA/view?usp=share_link" 
                                                className={commonStyle.linkButton}
                                            >
                                                チャット機能について
                                            </a>
                                        </Grid>
                                        <Grid xs={6} className={localStyle.buttonArea}>
                                            <a 
                                                rel="noreferrer" 
                                                target="_blank" 
                                                href="https://drive.google.com/file/d/1zkfVkj98LAu1pZKSxwM6wbT6zjHwLerG/view?usp=share_link" 
                                                className={commonStyle.linkButton}
                                            >
                                                マイページについて
                                            </a>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Container>
                        </Box>
                    </Box>
                    }
                </Box>
            </Box>
            <Footer/>
        </Box>
    );
}

