/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCSMemberDatas = /* GraphQL */ `
  mutation CreateCSMemberDatas(
    $input: CreateCSMemberDatasInput!
    $condition: ModelCSMemberDatasConditionInput
  ) {
    createCSMemberDatas(input: $input, condition: $condition) {
      id
      userdatasID
      userName
      csChatroomType
      createdAt
      updatedAt
    }
  }
`;
export const updateCSMemberDatas = /* GraphQL */ `
  mutation UpdateCSMemberDatas(
    $input: UpdateCSMemberDatasInput!
    $condition: ModelCSMemberDatasConditionInput
  ) {
    updateCSMemberDatas(input: $input, condition: $condition) {
      id
      userdatasID
      userName
      csChatroomType
      createdAt
      updatedAt
    }
  }
`;
export const deleteCSMemberDatas = /* GraphQL */ `
  mutation DeleteCSMemberDatas(
    $input: DeleteCSMemberDatasInput!
    $condition: ModelCSMemberDatasConditionInput
  ) {
    deleteCSMemberDatas(input: $input, condition: $condition) {
      id
      userdatasID
      userName
      csChatroomType
      createdAt
      updatedAt
    }
  }
`;
export const createChatNotificationDatas = /* GraphQL */ `
  mutation CreateChatNotificationDatas(
    $input: CreateChatNotificationDatasInput!
    $condition: ModelChatNotificationDatasConditionInput
  ) {
    createChatNotificationDatas(input: $input, condition: $condition) {
      id
      sendFromUserdatasID
      sendFromUserdatasName
      sendToUserdatasID
      sendToUserdatasName
      sendMessage
      sendFromStatus
      sendToStatus
      csStatus
      chatroomName
      Chatrooms {
        id
        chatroomUrl
        roomMasterUserDatasID
        ConectIsChatroomMembers {
          items {
            id
            chatroomID
            chatroomMemberUserDatasID
            createdAt
            updatedAt
          }
          nextToken
        }
        ChatDatas {
          items {
            id
            chatMessage
            chatDateTime
            chatTimestamp
            checkOn
            chatroomID
            SendFromID
            chatOnImage
            chatDataStatus
            traineeCheckStatus
            coachCheckStatus
            csCheckStatus
            createdAt
            updatedAt
          }
          nextToken
        }
        CustomerChatrooms {
          items {
            id
            tag
            roomType
            userdatasID
            chatroomsID
            userdatasType
            roomMasterName
            createdAt
            updatedAt
          }
          nextToken
        }
        lastMessage
        unReadFlg
        roomMasterName
        sendFromTimestamp
        sendToTimestamp
        sendFromDateTime
        sendToDateTime
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      chatNotificationDatasChatroomsId
    }
  }
`;
export const updateChatNotificationDatas = /* GraphQL */ `
  mutation UpdateChatNotificationDatas(
    $input: UpdateChatNotificationDatasInput!
    $condition: ModelChatNotificationDatasConditionInput
  ) {
    updateChatNotificationDatas(input: $input, condition: $condition) {
      id
      sendFromUserdatasID
      sendFromUserdatasName
      sendToUserdatasID
      sendToUserdatasName
      sendMessage
      sendFromStatus
      sendToStatus
      csStatus
      chatroomName
      Chatrooms {
        id
        chatroomUrl
        roomMasterUserDatasID
        ConectIsChatroomMembers {
          items {
            id
            chatroomID
            chatroomMemberUserDatasID
            createdAt
            updatedAt
          }
          nextToken
        }
        ChatDatas {
          items {
            id
            chatMessage
            chatDateTime
            chatTimestamp
            checkOn
            chatroomID
            SendFromID
            chatOnImage
            chatDataStatus
            traineeCheckStatus
            coachCheckStatus
            csCheckStatus
            createdAt
            updatedAt
          }
          nextToken
        }
        CustomerChatrooms {
          items {
            id
            tag
            roomType
            userdatasID
            chatroomsID
            userdatasType
            roomMasterName
            createdAt
            updatedAt
          }
          nextToken
        }
        lastMessage
        unReadFlg
        roomMasterName
        sendFromTimestamp
        sendToTimestamp
        sendFromDateTime
        sendToDateTime
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      chatNotificationDatasChatroomsId
    }
  }
`;
export const deleteChatNotificationDatas = /* GraphQL */ `
  mutation DeleteChatNotificationDatas(
    $input: DeleteChatNotificationDatasInput!
    $condition: ModelChatNotificationDatasConditionInput
  ) {
    deleteChatNotificationDatas(input: $input, condition: $condition) {
      id
      sendFromUserdatasID
      sendFromUserdatasName
      sendToUserdatasID
      sendToUserdatasName
      sendMessage
      sendFromStatus
      sendToStatus
      csStatus
      chatroomName
      Chatrooms {
        id
        chatroomUrl
        roomMasterUserDatasID
        ConectIsChatroomMembers {
          items {
            id
            chatroomID
            chatroomMemberUserDatasID
            createdAt
            updatedAt
          }
          nextToken
        }
        ChatDatas {
          items {
            id
            chatMessage
            chatDateTime
            chatTimestamp
            checkOn
            chatroomID
            SendFromID
            chatOnImage
            chatDataStatus
            traineeCheckStatus
            coachCheckStatus
            csCheckStatus
            createdAt
            updatedAt
          }
          nextToken
        }
        CustomerChatrooms {
          items {
            id
            tag
            roomType
            userdatasID
            chatroomsID
            userdatasType
            roomMasterName
            createdAt
            updatedAt
          }
          nextToken
        }
        lastMessage
        unReadFlg
        roomMasterName
        sendFromTimestamp
        sendToTimestamp
        sendFromDateTime
        sendToDateTime
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      chatNotificationDatasChatroomsId
    }
  }
`;
export const createInitChatroomDatas = /* GraphQL */ `
  mutation CreateInitChatroomDatas(
    $input: CreateInitChatroomDatasInput!
    $condition: ModelInitChatroomDatasConditionInput
  ) {
    createInitChatroomDatas(input: $input, condition: $condition) {
      id
      initChatroomType
      initMemberUserdatsID
      createdAt
      updatedAt
    }
  }
`;
export const updateInitChatroomDatas = /* GraphQL */ `
  mutation UpdateInitChatroomDatas(
    $input: UpdateInitChatroomDatasInput!
    $condition: ModelInitChatroomDatasConditionInput
  ) {
    updateInitChatroomDatas(input: $input, condition: $condition) {
      id
      initChatroomType
      initMemberUserdatsID
      createdAt
      updatedAt
    }
  }
`;
export const deleteInitChatroomDatas = /* GraphQL */ `
  mutation DeleteInitChatroomDatas(
    $input: DeleteInitChatroomDatasInput!
    $condition: ModelInitChatroomDatasConditionInput
  ) {
    deleteInitChatroomDatas(input: $input, condition: $condition) {
      id
      initChatroomType
      initMemberUserdatsID
      createdAt
      updatedAt
    }
  }
`;
export const createAdditionalServiceUrls = /* GraphQL */ `
  mutation CreateAdditionalServiceUrls(
    $input: CreateAdditionalServiceUrlsInput!
    $condition: ModelAdditionalServiceUrlsConditionInput
  ) {
    createAdditionalServiceUrls(input: $input, condition: $condition) {
      id
      captionTitle
      url
      urlType
      createdAt
      updatedAt
    }
  }
`;
export const updateAdditionalServiceUrls = /* GraphQL */ `
  mutation UpdateAdditionalServiceUrls(
    $input: UpdateAdditionalServiceUrlsInput!
    $condition: ModelAdditionalServiceUrlsConditionInput
  ) {
    updateAdditionalServiceUrls(input: $input, condition: $condition) {
      id
      captionTitle
      url
      urlType
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdditionalServiceUrls = /* GraphQL */ `
  mutation DeleteAdditionalServiceUrls(
    $input: DeleteAdditionalServiceUrlsInput!
    $condition: ModelAdditionalServiceUrlsConditionInput
  ) {
    deleteAdditionalServiceUrls(input: $input, condition: $condition) {
      id
      captionTitle
      url
      urlType
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerChatdatas = /* GraphQL */ `
  mutation CreateCustomerChatdatas(
    $input: CreateCustomerChatdatasInput!
    $condition: ModelCustomerChatdatasConditionInput
  ) {
    createCustomerChatdatas(input: $input, condition: $condition) {
      id
      chatMessage
      chatDateTime
      chatDateTimestamp
      checkOn
      customerchatroomsID
      sendUserdatasID
      chatOnImage
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerChatdatas = /* GraphQL */ `
  mutation UpdateCustomerChatdatas(
    $input: UpdateCustomerChatdatasInput!
    $condition: ModelCustomerChatdatasConditionInput
  ) {
    updateCustomerChatdatas(input: $input, condition: $condition) {
      id
      chatMessage
      chatDateTime
      chatDateTimestamp
      checkOn
      customerchatroomsID
      sendUserdatasID
      chatOnImage
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerChatdatas = /* GraphQL */ `
  mutation DeleteCustomerChatdatas(
    $input: DeleteCustomerChatdatasInput!
    $condition: ModelCustomerChatdatasConditionInput
  ) {
    deleteCustomerChatdatas(input: $input, condition: $condition) {
      id
      chatMessage
      chatDateTime
      chatDateTimestamp
      checkOn
      customerchatroomsID
      sendUserdatasID
      chatOnImage
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerChatrooms = /* GraphQL */ `
  mutation CreateCustomerChatrooms(
    $input: CreateCustomerChatroomsInput!
    $condition: ModelCustomerChatroomsConditionInput
  ) {
    createCustomerChatrooms(input: $input, condition: $condition) {
      id
      tag
      roomType
      CustomerChatdatas {
        items {
          id
          chatMessage
          chatDateTime
          chatDateTimestamp
          checkOn
          customerchatroomsID
          sendUserdatasID
          chatOnImage
          createdAt
          updatedAt
        }
        nextToken
      }
      userdatasID
      chatroomsID
      userdatasType
      roomMasterName
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerChatrooms = /* GraphQL */ `
  mutation UpdateCustomerChatrooms(
    $input: UpdateCustomerChatroomsInput!
    $condition: ModelCustomerChatroomsConditionInput
  ) {
    updateCustomerChatrooms(input: $input, condition: $condition) {
      id
      tag
      roomType
      CustomerChatdatas {
        items {
          id
          chatMessage
          chatDateTime
          chatDateTimestamp
          checkOn
          customerchatroomsID
          sendUserdatasID
          chatOnImage
          createdAt
          updatedAt
        }
        nextToken
      }
      userdatasID
      chatroomsID
      userdatasType
      roomMasterName
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerChatrooms = /* GraphQL */ `
  mutation DeleteCustomerChatrooms(
    $input: DeleteCustomerChatroomsInput!
    $condition: ModelCustomerChatroomsConditionInput
  ) {
    deleteCustomerChatrooms(input: $input, condition: $condition) {
      id
      tag
      roomType
      CustomerChatdatas {
        items {
          id
          chatMessage
          chatDateTime
          chatDateTimestamp
          checkOn
          customerchatroomsID
          sendUserdatasID
          chatOnImage
          createdAt
          updatedAt
        }
        nextToken
      }
      userdatasID
      chatroomsID
      userdatasType
      roomMasterName
      createdAt
      updatedAt
    }
  }
`;
export const createTodayWords = /* GraphQL */ `
  mutation CreateTodayWords(
    $input: CreateTodayWordsInput!
    $condition: ModelTodayWordsConditionInput
  ) {
    createTodayWords(input: $input, condition: $condition) {
      id
      caption
      contentText
      displayNumber
      createdAt
      updatedAt
    }
  }
`;
export const updateTodayWords = /* GraphQL */ `
  mutation UpdateTodayWords(
    $input: UpdateTodayWordsInput!
    $condition: ModelTodayWordsConditionInput
  ) {
    updateTodayWords(input: $input, condition: $condition) {
      id
      caption
      contentText
      displayNumber
      createdAt
      updatedAt
    }
  }
`;
export const deleteTodayWords = /* GraphQL */ `
  mutation DeleteTodayWords(
    $input: DeleteTodayWordsInput!
    $condition: ModelTodayWordsConditionInput
  ) {
    deleteTodayWords(input: $input, condition: $condition) {
      id
      caption
      contentText
      displayNumber
      createdAt
      updatedAt
    }
  }
`;
export const createRefusalOfferDatas = /* GraphQL */ `
  mutation CreateRefusalOfferDatas(
    $input: CreateRefusalOfferDatasInput!
    $condition: ModelRefusalOfferDatasConditionInput
  ) {
    createRefusalOfferDatas(input: $input, condition: $condition) {
      id
      userName
      corporationName
      userdatasID
      corporationsID
      ScheduleEvents {
        id
        eventName
        eventType
        status
        deadlineDateTime
        deadlineTimestamp
        formType
        eventColorcode
        EventDetialDatas {
          items {
            id
            parentScheduleEventsID
            caption
            answer
            createdAt
            updatedAt
          }
          nextToken
        }
        userDatasID
        corporationsID
        createdAt
        updatedAt
      }
      refusalOfferStatus
      createdAt
      updatedAt
      refusalOfferDatasScheduleEventsId
    }
  }
`;
export const updateRefusalOfferDatas = /* GraphQL */ `
  mutation UpdateRefusalOfferDatas(
    $input: UpdateRefusalOfferDatasInput!
    $condition: ModelRefusalOfferDatasConditionInput
  ) {
    updateRefusalOfferDatas(input: $input, condition: $condition) {
      id
      userName
      corporationName
      userdatasID
      corporationsID
      ScheduleEvents {
        id
        eventName
        eventType
        status
        deadlineDateTime
        deadlineTimestamp
        formType
        eventColorcode
        EventDetialDatas {
          items {
            id
            parentScheduleEventsID
            caption
            answer
            createdAt
            updatedAt
          }
          nextToken
        }
        userDatasID
        corporationsID
        createdAt
        updatedAt
      }
      refusalOfferStatus
      createdAt
      updatedAt
      refusalOfferDatasScheduleEventsId
    }
  }
`;
export const deleteRefusalOfferDatas = /* GraphQL */ `
  mutation DeleteRefusalOfferDatas(
    $input: DeleteRefusalOfferDatasInput!
    $condition: ModelRefusalOfferDatasConditionInput
  ) {
    deleteRefusalOfferDatas(input: $input, condition: $condition) {
      id
      userName
      corporationName
      userdatasID
      corporationsID
      ScheduleEvents {
        id
        eventName
        eventType
        status
        deadlineDateTime
        deadlineTimestamp
        formType
        eventColorcode
        EventDetialDatas {
          items {
            id
            parentScheduleEventsID
            caption
            answer
            createdAt
            updatedAt
          }
          nextToken
        }
        userDatasID
        corporationsID
        createdAt
        updatedAt
      }
      refusalOfferStatus
      createdAt
      updatedAt
      refusalOfferDatasScheduleEventsId
    }
  }
`;
export const createOfferDatas = /* GraphQL */ `
  mutation CreateOfferDatas(
    $input: CreateOfferDatasInput!
    $condition: ModelOfferDatasConditionInput
  ) {
    createOfferDatas(input: $input, condition: $condition) {
      id
      userName
      corporationName
      corporationsID
      userdatasID
      ScheduleEvents {
        id
        eventName
        eventType
        status
        deadlineDateTime
        deadlineTimestamp
        formType
        eventColorcode
        EventDetialDatas {
          items {
            id
            parentScheduleEventsID
            caption
            answer
            createdAt
            updatedAt
          }
          nextToken
        }
        userDatasID
        corporationsID
        createdAt
        updatedAt
      }
      offerStatus
      createdAt
      updatedAt
      offerDatasScheduleEventsId
    }
  }
`;
export const updateOfferDatas = /* GraphQL */ `
  mutation UpdateOfferDatas(
    $input: UpdateOfferDatasInput!
    $condition: ModelOfferDatasConditionInput
  ) {
    updateOfferDatas(input: $input, condition: $condition) {
      id
      userName
      corporationName
      corporationsID
      userdatasID
      ScheduleEvents {
        id
        eventName
        eventType
        status
        deadlineDateTime
        deadlineTimestamp
        formType
        eventColorcode
        EventDetialDatas {
          items {
            id
            parentScheduleEventsID
            caption
            answer
            createdAt
            updatedAt
          }
          nextToken
        }
        userDatasID
        corporationsID
        createdAt
        updatedAt
      }
      offerStatus
      createdAt
      updatedAt
      offerDatasScheduleEventsId
    }
  }
`;
export const deleteOfferDatas = /* GraphQL */ `
  mutation DeleteOfferDatas(
    $input: DeleteOfferDatasInput!
    $condition: ModelOfferDatasConditionInput
  ) {
    deleteOfferDatas(input: $input, condition: $condition) {
      id
      userName
      corporationName
      corporationsID
      userdatasID
      ScheduleEvents {
        id
        eventName
        eventType
        status
        deadlineDateTime
        deadlineTimestamp
        formType
        eventColorcode
        EventDetialDatas {
          items {
            id
            parentScheduleEventsID
            caption
            answer
            createdAt
            updatedAt
          }
          nextToken
        }
        userDatasID
        corporationsID
        createdAt
        updatedAt
      }
      offerStatus
      createdAt
      updatedAt
      offerDatasScheduleEventsId
    }
  }
`;
export const createELearningMovieDatas = /* GraphQL */ `
  mutation CreateELearningMovieDatas(
    $input: CreateELearningMovieDatasInput!
    $condition: ModelELearningMovieDatasConditionInput
  ) {
    createELearningMovieDatas(input: $input, condition: $condition) {
      id
      captionTitle
      movieUrl
      number
      userdatasID
      createdAt
      updatedAt
    }
  }
`;
export const updateELearningMovieDatas = /* GraphQL */ `
  mutation UpdateELearningMovieDatas(
    $input: UpdateELearningMovieDatasInput!
    $condition: ModelELearningMovieDatasConditionInput
  ) {
    updateELearningMovieDatas(input: $input, condition: $condition) {
      id
      captionTitle
      movieUrl
      number
      userdatasID
      createdAt
      updatedAt
    }
  }
`;
export const deleteELearningMovieDatas = /* GraphQL */ `
  mutation DeleteELearningMovieDatas(
    $input: DeleteELearningMovieDatasInput!
    $condition: ModelELearningMovieDatasConditionInput
  ) {
    deleteELearningMovieDatas(input: $input, condition: $condition) {
      id
      captionTitle
      movieUrl
      number
      userdatasID
      createdAt
      updatedAt
    }
  }
`;
export const createWeeklySurveyDatas = /* GraphQL */ `
  mutation CreateWeeklySurveyDatas(
    $input: CreateWeeklySurveyDatasInput!
    $condition: ModelWeeklySurveyDatasConditionInput
  ) {
    createWeeklySurveyDatas(input: $input, condition: $condition) {
      id
      captionTitle
      surveyUrl
      weeklyNumber
      userdatasID
      createdAt
      updatedAt
    }
  }
`;
export const updateWeeklySurveyDatas = /* GraphQL */ `
  mutation UpdateWeeklySurveyDatas(
    $input: UpdateWeeklySurveyDatasInput!
    $condition: ModelWeeklySurveyDatasConditionInput
  ) {
    updateWeeklySurveyDatas(input: $input, condition: $condition) {
      id
      captionTitle
      surveyUrl
      weeklyNumber
      userdatasID
      createdAt
      updatedAt
    }
  }
`;
export const deleteWeeklySurveyDatas = /* GraphQL */ `
  mutation DeleteWeeklySurveyDatas(
    $input: DeleteWeeklySurveyDatasInput!
    $condition: ModelWeeklySurveyDatasConditionInput
  ) {
    deleteWeeklySurveyDatas(input: $input, condition: $condition) {
      id
      captionTitle
      surveyUrl
      weeklyNumber
      userdatasID
      createdAt
      updatedAt
    }
  }
`;
export const createAbuildSheetsDatas = /* GraphQL */ `
  mutation CreateAbuildSheetsDatas(
    $input: CreateAbuildSheetsDatasInput!
    $condition: ModelAbuildSheetsDatasConditionInput
  ) {
    createAbuildSheetsDatas(input: $input, condition: $condition) {
      id
      abuildSheetsUrl
      captionTitle
      weeklyNumber
      userDatasID
      createdAt
      updatedAt
    }
  }
`;
export const updateAbuildSheetsDatas = /* GraphQL */ `
  mutation UpdateAbuildSheetsDatas(
    $input: UpdateAbuildSheetsDatasInput!
    $condition: ModelAbuildSheetsDatasConditionInput
  ) {
    updateAbuildSheetsDatas(input: $input, condition: $condition) {
      id
      abuildSheetsUrl
      captionTitle
      weeklyNumber
      userDatasID
      createdAt
      updatedAt
    }
  }
`;
export const deleteAbuildSheetsDatas = /* GraphQL */ `
  mutation DeleteAbuildSheetsDatas(
    $input: DeleteAbuildSheetsDatasInput!
    $condition: ModelAbuildSheetsDatasConditionInput
  ) {
    deleteAbuildSheetsDatas(input: $input, condition: $condition) {
      id
      abuildSheetsUrl
      captionTitle
      weeklyNumber
      userDatasID
      createdAt
      updatedAt
    }
  }
`;
export const createConectIsCoachingToTargetTrainee = /* GraphQL */ `
  mutation CreateConectIsCoachingToTargetTrainee(
    $input: CreateConectIsCoachingToTargetTraineeInput!
    $condition: ModelConectIsCoachingToTargetTraineeConditionInput
  ) {
    createConectIsCoachingToTargetTrainee(
      input: $input
      condition: $condition
    ) {
      id
      coachdatasID
      traineedatasID
      weekoneStatus
      weekoneCurriculumData
      weekoneSurveyData
      weektwoStatus
      weektwoCurriculumData
      weektwoSurveyData
      weekthreeStatus
      weekthreeCurriculumData
      weekthreeSurveyData
      weekfourStatus
      weekfourCurriculumData
      weekfourSurveyData
      weekfiveStatus
      weekfiveCurriculumData
      weekfiveSurveyData
      weeksixStatus
      weeksixCurriculumData
      weeksixSurveyData
      weeksevenStatus
      weeksevenCurriculumData
      weeksevenSurveyData
      weekeightStatus
      weekeightCurriculumData
      weekeightSurveyData
      weeknineStatus
      weeknineCurriculumData
      weeknineSurveyData
      weektenStatus
      weektenCurriculumData
      weektenSurveyData
      weekelevenStatus
      weekelevenCurriculumData
      weekelevenSurveyData
      weektwelveStatus
      weektwelveCurriculumData
      weektwelveSurveyData
      othorStatus
      othorCurriculumData
      othorSurveyData
      createdAt
      updatedAt
    }
  }
`;
export const updateConectIsCoachingToTargetTrainee = /* GraphQL */ `
  mutation UpdateConectIsCoachingToTargetTrainee(
    $input: UpdateConectIsCoachingToTargetTraineeInput!
    $condition: ModelConectIsCoachingToTargetTraineeConditionInput
  ) {
    updateConectIsCoachingToTargetTrainee(
      input: $input
      condition: $condition
    ) {
      id
      coachdatasID
      traineedatasID
      weekoneStatus
      weekoneCurriculumData
      weekoneSurveyData
      weektwoStatus
      weektwoCurriculumData
      weektwoSurveyData
      weekthreeStatus
      weekthreeCurriculumData
      weekthreeSurveyData
      weekfourStatus
      weekfourCurriculumData
      weekfourSurveyData
      weekfiveStatus
      weekfiveCurriculumData
      weekfiveSurveyData
      weeksixStatus
      weeksixCurriculumData
      weeksixSurveyData
      weeksevenStatus
      weeksevenCurriculumData
      weeksevenSurveyData
      weekeightStatus
      weekeightCurriculumData
      weekeightSurveyData
      weeknineStatus
      weeknineCurriculumData
      weeknineSurveyData
      weektenStatus
      weektenCurriculumData
      weektenSurveyData
      weekelevenStatus
      weekelevenCurriculumData
      weekelevenSurveyData
      weektwelveStatus
      weektwelveCurriculumData
      weektwelveSurveyData
      othorStatus
      othorCurriculumData
      othorSurveyData
      createdAt
      updatedAt
    }
  }
`;
export const deleteConectIsCoachingToTargetTrainee = /* GraphQL */ `
  mutation DeleteConectIsCoachingToTargetTrainee(
    $input: DeleteConectIsCoachingToTargetTraineeInput!
    $condition: ModelConectIsCoachingToTargetTraineeConditionInput
  ) {
    deleteConectIsCoachingToTargetTrainee(
      input: $input
      condition: $condition
    ) {
      id
      coachdatasID
      traineedatasID
      weekoneStatus
      weekoneCurriculumData
      weekoneSurveyData
      weektwoStatus
      weektwoCurriculumData
      weektwoSurveyData
      weekthreeStatus
      weekthreeCurriculumData
      weekthreeSurveyData
      weekfourStatus
      weekfourCurriculumData
      weekfourSurveyData
      weekfiveStatus
      weekfiveCurriculumData
      weekfiveSurveyData
      weeksixStatus
      weeksixCurriculumData
      weeksixSurveyData
      weeksevenStatus
      weeksevenCurriculumData
      weeksevenSurveyData
      weekeightStatus
      weekeightCurriculumData
      weekeightSurveyData
      weeknineStatus
      weeknineCurriculumData
      weeknineSurveyData
      weektenStatus
      weektenCurriculumData
      weektenSurveyData
      weekelevenStatus
      weekelevenCurriculumData
      weekelevenSurveyData
      weektwelveStatus
      weektwelveCurriculumData
      weektwelveSurveyData
      othorStatus
      othorCurriculumData
      othorSurveyData
      createdAt
      updatedAt
    }
  }
`;
export const createCoachDatas = /* GraphQL */ `
  mutation CreateCoachDatas(
    $input: CreateCoachDatasInput!
    $condition: ModelCoachDatasConditionInput
  ) {
    createCoachDatas(input: $input, condition: $condition) {
      id
      userdatasID
      corporationAffiliationID
      registrationDateTime
      registrationTimestamp
      deregistrationDateTime
      deregistrationTimestamp
      picturePublic
      namePublic
      interviewImplementation
      interviewPublic
      ConectIsCoachingToTargetTrainees {
        items {
          id
          coachdatasID
          traineedatasID
          weekoneStatus
          weekoneCurriculumData
          weekoneSurveyData
          weektwoStatus
          weektwoCurriculumData
          weektwoSurveyData
          weekthreeStatus
          weekthreeCurriculumData
          weekthreeSurveyData
          weekfourStatus
          weekfourCurriculumData
          weekfourSurveyData
          weekfiveStatus
          weekfiveCurriculumData
          weekfiveSurveyData
          weeksixStatus
          weeksixCurriculumData
          weeksixSurveyData
          weeksevenStatus
          weeksevenCurriculumData
          weeksevenSurveyData
          weekeightStatus
          weekeightCurriculumData
          weekeightSurveyData
          weeknineStatus
          weeknineCurriculumData
          weeknineSurveyData
          weektenStatus
          weektenCurriculumData
          weektenSurveyData
          weekelevenStatus
          weekelevenCurriculumData
          weekelevenSurveyData
          weektwelveStatus
          weektwelveCurriculumData
          weektwelveSurveyData
          othorStatus
          othorCurriculumData
          othorSurveyData
          createdAt
          updatedAt
        }
        nextToken
      }
      coachManageSheetUrl
      coachReportSheetUrl
      tag
      createdAt
      updatedAt
    }
  }
`;
export const updateCoachDatas = /* GraphQL */ `
  mutation UpdateCoachDatas(
    $input: UpdateCoachDatasInput!
    $condition: ModelCoachDatasConditionInput
  ) {
    updateCoachDatas(input: $input, condition: $condition) {
      id
      userdatasID
      corporationAffiliationID
      registrationDateTime
      registrationTimestamp
      deregistrationDateTime
      deregistrationTimestamp
      picturePublic
      namePublic
      interviewImplementation
      interviewPublic
      ConectIsCoachingToTargetTrainees {
        items {
          id
          coachdatasID
          traineedatasID
          weekoneStatus
          weekoneCurriculumData
          weekoneSurveyData
          weektwoStatus
          weektwoCurriculumData
          weektwoSurveyData
          weekthreeStatus
          weekthreeCurriculumData
          weekthreeSurveyData
          weekfourStatus
          weekfourCurriculumData
          weekfourSurveyData
          weekfiveStatus
          weekfiveCurriculumData
          weekfiveSurveyData
          weeksixStatus
          weeksixCurriculumData
          weeksixSurveyData
          weeksevenStatus
          weeksevenCurriculumData
          weeksevenSurveyData
          weekeightStatus
          weekeightCurriculumData
          weekeightSurveyData
          weeknineStatus
          weeknineCurriculumData
          weeknineSurveyData
          weektenStatus
          weektenCurriculumData
          weektenSurveyData
          weekelevenStatus
          weekelevenCurriculumData
          weekelevenSurveyData
          weektwelveStatus
          weektwelveCurriculumData
          weektwelveSurveyData
          othorStatus
          othorCurriculumData
          othorSurveyData
          createdAt
          updatedAt
        }
        nextToken
      }
      coachManageSheetUrl
      coachReportSheetUrl
      tag
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoachDatas = /* GraphQL */ `
  mutation DeleteCoachDatas(
    $input: DeleteCoachDatasInput!
    $condition: ModelCoachDatasConditionInput
  ) {
    deleteCoachDatas(input: $input, condition: $condition) {
      id
      userdatasID
      corporationAffiliationID
      registrationDateTime
      registrationTimestamp
      deregistrationDateTime
      deregistrationTimestamp
      picturePublic
      namePublic
      interviewImplementation
      interviewPublic
      ConectIsCoachingToTargetTrainees {
        items {
          id
          coachdatasID
          traineedatasID
          weekoneStatus
          weekoneCurriculumData
          weekoneSurveyData
          weektwoStatus
          weektwoCurriculumData
          weektwoSurveyData
          weekthreeStatus
          weekthreeCurriculumData
          weekthreeSurveyData
          weekfourStatus
          weekfourCurriculumData
          weekfourSurveyData
          weekfiveStatus
          weekfiveCurriculumData
          weekfiveSurveyData
          weeksixStatus
          weeksixCurriculumData
          weeksixSurveyData
          weeksevenStatus
          weeksevenCurriculumData
          weeksevenSurveyData
          weekeightStatus
          weekeightCurriculumData
          weekeightSurveyData
          weeknineStatus
          weeknineCurriculumData
          weeknineSurveyData
          weektenStatus
          weektenCurriculumData
          weektenSurveyData
          weekelevenStatus
          weekelevenCurriculumData
          weekelevenSurveyData
          weektwelveStatus
          weektwelveCurriculumData
          weektwelveSurveyData
          othorStatus
          othorCurriculumData
          othorSurveyData
          createdAt
          updatedAt
        }
        nextToken
      }
      coachManageSheetUrl
      coachReportSheetUrl
      tag
      createdAt
      updatedAt
    }
  }
`;
export const createTraineeDatas = /* GraphQL */ `
  mutation CreateTraineeDatas(
    $input: CreateTraineeDatasInput!
    $condition: ModelTraineeDatasConditionInput
  ) {
    createTraineeDatas(input: $input, condition: $condition) {
      id
      userdatasID
      corporationOfEmploymentID
      traineeStatus
      graduationDateTime
      graduationTimestamp
      registrationDateTime
      registrationTimstamp
      picturePublic
      namePublic
      interviewImplementation
      intervewPublic
      ConectIsCoachingToTargetTrainees {
        items {
          id
          coachdatasID
          traineedatasID
          weekoneStatus
          weekoneCurriculumData
          weekoneSurveyData
          weektwoStatus
          weektwoCurriculumData
          weektwoSurveyData
          weekthreeStatus
          weekthreeCurriculumData
          weekthreeSurveyData
          weekfourStatus
          weekfourCurriculumData
          weekfourSurveyData
          weekfiveStatus
          weekfiveCurriculumData
          weekfiveSurveyData
          weeksixStatus
          weeksixCurriculumData
          weeksixSurveyData
          weeksevenStatus
          weeksevenCurriculumData
          weeksevenSurveyData
          weekeightStatus
          weekeightCurriculumData
          weekeightSurveyData
          weeknineStatus
          weeknineCurriculumData
          weeknineSurveyData
          weektenStatus
          weektenCurriculumData
          weektenSurveyData
          weekelevenStatus
          weekelevenCurriculumData
          weekelevenSurveyData
          weektwelveStatus
          weektwelveCurriculumData
          weektwelveSurveyData
          othorStatus
          othorCurriculumData
          othorSurveyData
          createdAt
          updatedAt
        }
        nextToken
      }
      curriculumStartDate
      traineeId
      tag
      createdAt
      updatedAt
    }
  }
`;
export const updateTraineeDatas = /* GraphQL */ `
  mutation UpdateTraineeDatas(
    $input: UpdateTraineeDatasInput!
    $condition: ModelTraineeDatasConditionInput
  ) {
    updateTraineeDatas(input: $input, condition: $condition) {
      id
      userdatasID
      corporationOfEmploymentID
      traineeStatus
      graduationDateTime
      graduationTimestamp
      registrationDateTime
      registrationTimstamp
      picturePublic
      namePublic
      interviewImplementation
      intervewPublic
      ConectIsCoachingToTargetTrainees {
        items {
          id
          coachdatasID
          traineedatasID
          weekoneStatus
          weekoneCurriculumData
          weekoneSurveyData
          weektwoStatus
          weektwoCurriculumData
          weektwoSurveyData
          weekthreeStatus
          weekthreeCurriculumData
          weekthreeSurveyData
          weekfourStatus
          weekfourCurriculumData
          weekfourSurveyData
          weekfiveStatus
          weekfiveCurriculumData
          weekfiveSurveyData
          weeksixStatus
          weeksixCurriculumData
          weeksixSurveyData
          weeksevenStatus
          weeksevenCurriculumData
          weeksevenSurveyData
          weekeightStatus
          weekeightCurriculumData
          weekeightSurveyData
          weeknineStatus
          weeknineCurriculumData
          weeknineSurveyData
          weektenStatus
          weektenCurriculumData
          weektenSurveyData
          weekelevenStatus
          weekelevenCurriculumData
          weekelevenSurveyData
          weektwelveStatus
          weektwelveCurriculumData
          weektwelveSurveyData
          othorStatus
          othorCurriculumData
          othorSurveyData
          createdAt
          updatedAt
        }
        nextToken
      }
      curriculumStartDate
      traineeId
      tag
      createdAt
      updatedAt
    }
  }
`;
export const deleteTraineeDatas = /* GraphQL */ `
  mutation DeleteTraineeDatas(
    $input: DeleteTraineeDatasInput!
    $condition: ModelTraineeDatasConditionInput
  ) {
    deleteTraineeDatas(input: $input, condition: $condition) {
      id
      userdatasID
      corporationOfEmploymentID
      traineeStatus
      graduationDateTime
      graduationTimestamp
      registrationDateTime
      registrationTimstamp
      picturePublic
      namePublic
      interviewImplementation
      intervewPublic
      ConectIsCoachingToTargetTrainees {
        items {
          id
          coachdatasID
          traineedatasID
          weekoneStatus
          weekoneCurriculumData
          weekoneSurveyData
          weektwoStatus
          weektwoCurriculumData
          weektwoSurveyData
          weekthreeStatus
          weekthreeCurriculumData
          weekthreeSurveyData
          weekfourStatus
          weekfourCurriculumData
          weekfourSurveyData
          weekfiveStatus
          weekfiveCurriculumData
          weekfiveSurveyData
          weeksixStatus
          weeksixCurriculumData
          weeksixSurveyData
          weeksevenStatus
          weeksevenCurriculumData
          weeksevenSurveyData
          weekeightStatus
          weekeightCurriculumData
          weekeightSurveyData
          weeknineStatus
          weeknineCurriculumData
          weeknineSurveyData
          weektenStatus
          weektenCurriculumData
          weektenSurveyData
          weekelevenStatus
          weekelevenCurriculumData
          weekelevenSurveyData
          weektwelveStatus
          weektwelveCurriculumData
          weektwelveSurveyData
          othorStatus
          othorCurriculumData
          othorSurveyData
          createdAt
          updatedAt
        }
        nextToken
      }
      curriculumStartDate
      traineeId
      tag
      createdAt
      updatedAt
    }
  }
`;
export const createChatDatas = /* GraphQL */ `
  mutation CreateChatDatas(
    $input: CreateChatDatasInput!
    $condition: ModelChatDatasConditionInput
  ) {
    createChatDatas(input: $input, condition: $condition) {
      id
      chatMessage
      chatDateTime
      chatTimestamp
      checkOn
      chatroomID
      SendFromID
      chatOnImage
      chatDataStatus
      traineeCheckStatus
      coachCheckStatus
      csCheckStatus
      createdAt
      updatedAt
    }
  }
`;
export const updateChatDatas = /* GraphQL */ `
  mutation UpdateChatDatas(
    $input: UpdateChatDatasInput!
    $condition: ModelChatDatasConditionInput
  ) {
    updateChatDatas(input: $input, condition: $condition) {
      id
      chatMessage
      chatDateTime
      chatTimestamp
      checkOn
      chatroomID
      SendFromID
      chatOnImage
      chatDataStatus
      traineeCheckStatus
      coachCheckStatus
      csCheckStatus
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatDatas = /* GraphQL */ `
  mutation DeleteChatDatas(
    $input: DeleteChatDatasInput!
    $condition: ModelChatDatasConditionInput
  ) {
    deleteChatDatas(input: $input, condition: $condition) {
      id
      chatMessage
      chatDateTime
      chatTimestamp
      checkOn
      chatroomID
      SendFromID
      chatOnImage
      chatDataStatus
      traineeCheckStatus
      coachCheckStatus
      csCheckStatus
      createdAt
      updatedAt
    }
  }
`;
export const createConectIsChatroomMember = /* GraphQL */ `
  mutation CreateConectIsChatroomMember(
    $input: CreateConectIsChatroomMemberInput!
    $condition: ModelConectIsChatroomMemberConditionInput
  ) {
    createConectIsChatroomMember(input: $input, condition: $condition) {
      id
      chatroomID
      chatroomMemberUserDatasID
      createdAt
      updatedAt
    }
  }
`;
export const updateConectIsChatroomMember = /* GraphQL */ `
  mutation UpdateConectIsChatroomMember(
    $input: UpdateConectIsChatroomMemberInput!
    $condition: ModelConectIsChatroomMemberConditionInput
  ) {
    updateConectIsChatroomMember(input: $input, condition: $condition) {
      id
      chatroomID
      chatroomMemberUserDatasID
      createdAt
      updatedAt
    }
  }
`;
export const deleteConectIsChatroomMember = /* GraphQL */ `
  mutation DeleteConectIsChatroomMember(
    $input: DeleteConectIsChatroomMemberInput!
    $condition: ModelConectIsChatroomMemberConditionInput
  ) {
    deleteConectIsChatroomMember(input: $input, condition: $condition) {
      id
      chatroomID
      chatroomMemberUserDatasID
      createdAt
      updatedAt
    }
  }
`;
export const createChatrooms = /* GraphQL */ `
  mutation CreateChatrooms(
    $input: CreateChatroomsInput!
    $condition: ModelChatroomsConditionInput
  ) {
    createChatrooms(input: $input, condition: $condition) {
      id
      chatroomUrl
      roomMasterUserDatasID
      ConectIsChatroomMembers {
        items {
          id
          chatroomID
          chatroomMemberUserDatasID
          createdAt
          updatedAt
        }
        nextToken
      }
      ChatDatas {
        items {
          id
          chatMessage
          chatDateTime
          chatTimestamp
          checkOn
          chatroomID
          SendFromID
          chatOnImage
          chatDataStatus
          traineeCheckStatus
          coachCheckStatus
          csCheckStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      CustomerChatrooms {
        items {
          id
          tag
          roomType
          CustomerChatdatas {
            nextToken
          }
          userdatasID
          chatroomsID
          userdatasType
          roomMasterName
          createdAt
          updatedAt
        }
        nextToken
      }
      lastMessage
      unReadFlg
      roomMasterName
      sendFromTimestamp
      sendToTimestamp
      sendFromDateTime
      sendToDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateChatrooms = /* GraphQL */ `
  mutation UpdateChatrooms(
    $input: UpdateChatroomsInput!
    $condition: ModelChatroomsConditionInput
  ) {
    updateChatrooms(input: $input, condition: $condition) {
      id
      chatroomUrl
      roomMasterUserDatasID
      ConectIsChatroomMembers {
        items {
          id
          chatroomID
          chatroomMemberUserDatasID
          createdAt
          updatedAt
        }
        nextToken
      }
      ChatDatas {
        items {
          id
          chatMessage
          chatDateTime
          chatTimestamp
          checkOn
          chatroomID
          SendFromID
          chatOnImage
          chatDataStatus
          traineeCheckStatus
          coachCheckStatus
          csCheckStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      CustomerChatrooms {
        items {
          id
          tag
          roomType
          CustomerChatdatas {
            nextToken
          }
          userdatasID
          chatroomsID
          userdatasType
          roomMasterName
          createdAt
          updatedAt
        }
        nextToken
      }
      lastMessage
      unReadFlg
      roomMasterName
      sendFromTimestamp
      sendToTimestamp
      sendFromDateTime
      sendToDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatrooms = /* GraphQL */ `
  mutation DeleteChatrooms(
    $input: DeleteChatroomsInput!
    $condition: ModelChatroomsConditionInput
  ) {
    deleteChatrooms(input: $input, condition: $condition) {
      id
      chatroomUrl
      roomMasterUserDatasID
      ConectIsChatroomMembers {
        items {
          id
          chatroomID
          chatroomMemberUserDatasID
          createdAt
          updatedAt
        }
        nextToken
      }
      ChatDatas {
        items {
          id
          chatMessage
          chatDateTime
          chatTimestamp
          checkOn
          chatroomID
          SendFromID
          chatOnImage
          chatDataStatus
          traineeCheckStatus
          coachCheckStatus
          csCheckStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      CustomerChatrooms {
        items {
          id
          tag
          roomType
          CustomerChatdatas {
            nextToken
          }
          userdatasID
          chatroomsID
          userdatasType
          roomMasterName
          createdAt
          updatedAt
        }
        nextToken
      }
      lastMessage
      unReadFlg
      roomMasterName
      sendFromTimestamp
      sendToTimestamp
      sendFromDateTime
      sendToDateTime
      createdAt
      updatedAt
    }
  }
`;
export const createConectIsReadUnreadToNotifications = /* GraphQL */ `
  mutation CreateConectIsReadUnreadToNotifications(
    $input: CreateConectIsReadUnreadToNotificationsInput!
    $condition: ModelConectIsReadUnreadToNotificationsConditionInput
  ) {
    createConectIsReadUnreadToNotifications(
      input: $input
      condition: $condition
    ) {
      id
      userdatasID
      notificationsID
      createdAt
      updatedAt
    }
  }
`;
export const updateConectIsReadUnreadToNotifications = /* GraphQL */ `
  mutation UpdateConectIsReadUnreadToNotifications(
    $input: UpdateConectIsReadUnreadToNotificationsInput!
    $condition: ModelConectIsReadUnreadToNotificationsConditionInput
  ) {
    updateConectIsReadUnreadToNotifications(
      input: $input
      condition: $condition
    ) {
      id
      userdatasID
      notificationsID
      createdAt
      updatedAt
    }
  }
`;
export const deleteConectIsReadUnreadToNotifications = /* GraphQL */ `
  mutation DeleteConectIsReadUnreadToNotifications(
    $input: DeleteConectIsReadUnreadToNotificationsInput!
    $condition: ModelConectIsReadUnreadToNotificationsConditionInput
  ) {
    deleteConectIsReadUnreadToNotifications(
      input: $input
      condition: $condition
    ) {
      id
      userdatasID
      notificationsID
      createdAt
      updatedAt
    }
  }
`;
export const createNotifications = /* GraphQL */ `
  mutation CreateNotifications(
    $input: CreateNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    createNotifications(input: $input, condition: $condition) {
      id
      notificationTitle
      notificationDateTime
      notificationTimestamp
      body
      ReadUnreadToNotifications {
        items {
          id
          userdatasID
          notificationsID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNotifications = /* GraphQL */ `
  mutation UpdateNotifications(
    $input: UpdateNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    updateNotifications(input: $input, condition: $condition) {
      id
      notificationTitle
      notificationDateTime
      notificationTimestamp
      body
      ReadUnreadToNotifications {
        items {
          id
          userdatasID
          notificationsID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotifications = /* GraphQL */ `
  mutation DeleteNotifications(
    $input: DeleteNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    deleteNotifications(input: $input, condition: $condition) {
      id
      notificationTitle
      notificationDateTime
      notificationTimestamp
      body
      ReadUnreadToNotifications {
        items {
          id
          userdatasID
          notificationsID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTaskDatas = /* GraphQL */ `
  mutation CreateTaskDatas(
    $input: CreateTaskDatasInput!
    $condition: ModelTaskDatasConditionInput
  ) {
    createTaskDatas(input: $input, condition: $condition) {
      id
      planTaskCreated
      planTaskStartTime
      planTaskEndTime
      planTaskPriority
      planTaskDetial
      resultTaskStartTime
      resultTaskEndTime
      resultTaskPriority
      resultTaskDetial
      tasktimeManagementTimestamp
      taskProgress
      parentTasktimeManagementDatasID
      createdAt
      updatedAt
    }
  }
`;
export const updateTaskDatas = /* GraphQL */ `
  mutation UpdateTaskDatas(
    $input: UpdateTaskDatasInput!
    $condition: ModelTaskDatasConditionInput
  ) {
    updateTaskDatas(input: $input, condition: $condition) {
      id
      planTaskCreated
      planTaskStartTime
      planTaskEndTime
      planTaskPriority
      planTaskDetial
      resultTaskStartTime
      resultTaskEndTime
      resultTaskPriority
      resultTaskDetial
      tasktimeManagementTimestamp
      taskProgress
      parentTasktimeManagementDatasID
      createdAt
      updatedAt
    }
  }
`;
export const deleteTaskDatas = /* GraphQL */ `
  mutation DeleteTaskDatas(
    $input: DeleteTaskDatasInput!
    $condition: ModelTaskDatasConditionInput
  ) {
    deleteTaskDatas(input: $input, condition: $condition) {
      id
      planTaskCreated
      planTaskStartTime
      planTaskEndTime
      planTaskPriority
      planTaskDetial
      resultTaskStartTime
      resultTaskEndTime
      resultTaskPriority
      resultTaskDetial
      tasktimeManagementTimestamp
      taskProgress
      parentTasktimeManagementDatasID
      createdAt
      updatedAt
    }
  }
`;
export const createTasktimeManagementDatas = /* GraphQL */ `
  mutation CreateTasktimeManagementDatas(
    $input: CreateTasktimeManagementDatasInput!
    $condition: ModelTasktimeManagementDatasConditionInput
  ) {
    createTasktimeManagementDatas(input: $input, condition: $condition) {
      id
      checkIn
      checkOut
      settingDate
      settingTimestamp
      tasktimeManagementOutput
      achievementScore
      userDatasID
      TaskDatas {
        items {
          id
          planTaskCreated
          planTaskStartTime
          planTaskEndTime
          planTaskPriority
          planTaskDetial
          resultTaskStartTime
          resultTaskEndTime
          resultTaskPriority
          resultTaskDetial
          tasktimeManagementTimestamp
          taskProgress
          parentTasktimeManagementDatasID
          createdAt
          updatedAt
        }
        nextToken
      }
      ttmActionToImprove
      ttmEffortTargets
      ttmIssuesToImprove
      ttmReflectionOnDay
      ttmStatus
      createdAt
      updatedAt
    }
  }
`;
export const updateTasktimeManagementDatas = /* GraphQL */ `
  mutation UpdateTasktimeManagementDatas(
    $input: UpdateTasktimeManagementDatasInput!
    $condition: ModelTasktimeManagementDatasConditionInput
  ) {
    updateTasktimeManagementDatas(input: $input, condition: $condition) {
      id
      checkIn
      checkOut
      settingDate
      settingTimestamp
      tasktimeManagementOutput
      achievementScore
      userDatasID
      TaskDatas {
        items {
          id
          planTaskCreated
          planTaskStartTime
          planTaskEndTime
          planTaskPriority
          planTaskDetial
          resultTaskStartTime
          resultTaskEndTime
          resultTaskPriority
          resultTaskDetial
          tasktimeManagementTimestamp
          taskProgress
          parentTasktimeManagementDatasID
          createdAt
          updatedAt
        }
        nextToken
      }
      ttmActionToImprove
      ttmEffortTargets
      ttmIssuesToImprove
      ttmReflectionOnDay
      ttmStatus
      createdAt
      updatedAt
    }
  }
`;
export const deleteTasktimeManagementDatas = /* GraphQL */ `
  mutation DeleteTasktimeManagementDatas(
    $input: DeleteTasktimeManagementDatasInput!
    $condition: ModelTasktimeManagementDatasConditionInput
  ) {
    deleteTasktimeManagementDatas(input: $input, condition: $condition) {
      id
      checkIn
      checkOut
      settingDate
      settingTimestamp
      tasktimeManagementOutput
      achievementScore
      userDatasID
      TaskDatas {
        items {
          id
          planTaskCreated
          planTaskStartTime
          planTaskEndTime
          planTaskPriority
          planTaskDetial
          resultTaskStartTime
          resultTaskEndTime
          resultTaskPriority
          resultTaskDetial
          tasktimeManagementTimestamp
          taskProgress
          parentTasktimeManagementDatasID
          createdAt
          updatedAt
        }
        nextToken
      }
      ttmActionToImprove
      ttmEffortTargets
      ttmIssuesToImprove
      ttmReflectionOnDay
      ttmStatus
      createdAt
      updatedAt
    }
  }
`;
export const createDetialAnswerHistories = /* GraphQL */ `
  mutation CreateDetialAnswerHistories(
    $input: CreateDetialAnswerHistoriesInput!
    $condition: ModelDetialAnswerHistoriesConditionInput
  ) {
    createDetialAnswerHistories(input: $input, condition: $condition) {
      id
      answer
      comment
      answerDateTime
      commentDateTime
      answerTimestamp
      commentTimestamp
      parentEventDetialDatasID
      createdAt
      updatedAt
    }
  }
`;
export const updateDetialAnswerHistories = /* GraphQL */ `
  mutation UpdateDetialAnswerHistories(
    $input: UpdateDetialAnswerHistoriesInput!
    $condition: ModelDetialAnswerHistoriesConditionInput
  ) {
    updateDetialAnswerHistories(input: $input, condition: $condition) {
      id
      answer
      comment
      answerDateTime
      commentDateTime
      answerTimestamp
      commentTimestamp
      parentEventDetialDatasID
      createdAt
      updatedAt
    }
  }
`;
export const deleteDetialAnswerHistories = /* GraphQL */ `
  mutation DeleteDetialAnswerHistories(
    $input: DeleteDetialAnswerHistoriesInput!
    $condition: ModelDetialAnswerHistoriesConditionInput
  ) {
    deleteDetialAnswerHistories(input: $input, condition: $condition) {
      id
      answer
      comment
      answerDateTime
      commentDateTime
      answerTimestamp
      commentTimestamp
      parentEventDetialDatasID
      createdAt
      updatedAt
    }
  }
`;
export const createEventDetialDatas = /* GraphQL */ `
  mutation CreateEventDetialDatas(
    $input: CreateEventDetialDatasInput!
    $condition: ModelEventDetialDatasConditionInput
  ) {
    createEventDetialDatas(input: $input, condition: $condition) {
      id
      parentScheduleEventsID
      caption
      answer
      DetialAnswerHistories {
        items {
          id
          answer
          comment
          answerDateTime
          commentDateTime
          answerTimestamp
          commentTimestamp
          parentEventDetialDatasID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEventDetialDatas = /* GraphQL */ `
  mutation UpdateEventDetialDatas(
    $input: UpdateEventDetialDatasInput!
    $condition: ModelEventDetialDatasConditionInput
  ) {
    updateEventDetialDatas(input: $input, condition: $condition) {
      id
      parentScheduleEventsID
      caption
      answer
      DetialAnswerHistories {
        items {
          id
          answer
          comment
          answerDateTime
          commentDateTime
          answerTimestamp
          commentTimestamp
          parentEventDetialDatasID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventDetialDatas = /* GraphQL */ `
  mutation DeleteEventDetialDatas(
    $input: DeleteEventDetialDatasInput!
    $condition: ModelEventDetialDatasConditionInput
  ) {
    deleteEventDetialDatas(input: $input, condition: $condition) {
      id
      parentScheduleEventsID
      caption
      answer
      DetialAnswerHistories {
        items {
          id
          answer
          comment
          answerDateTime
          commentDateTime
          answerTimestamp
          commentTimestamp
          parentEventDetialDatasID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createScheduleEvents = /* GraphQL */ `
  mutation CreateScheduleEvents(
    $input: CreateScheduleEventsInput!
    $condition: ModelScheduleEventsConditionInput
  ) {
    createScheduleEvents(input: $input, condition: $condition) {
      id
      eventName
      eventType
      status
      deadlineDateTime
      deadlineTimestamp
      formType
      eventColorcode
      EventDetialDatas {
        items {
          id
          parentScheduleEventsID
          caption
          answer
          DetialAnswerHistories {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      userDatasID
      corporationsID
      createdAt
      updatedAt
    }
  }
`;
export const updateScheduleEvents = /* GraphQL */ `
  mutation UpdateScheduleEvents(
    $input: UpdateScheduleEventsInput!
    $condition: ModelScheduleEventsConditionInput
  ) {
    updateScheduleEvents(input: $input, condition: $condition) {
      id
      eventName
      eventType
      status
      deadlineDateTime
      deadlineTimestamp
      formType
      eventColorcode
      EventDetialDatas {
        items {
          id
          parentScheduleEventsID
          caption
          answer
          DetialAnswerHistories {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      userDatasID
      corporationsID
      createdAt
      updatedAt
    }
  }
`;
export const deleteScheduleEvents = /* GraphQL */ `
  mutation DeleteScheduleEvents(
    $input: DeleteScheduleEventsInput!
    $condition: ModelScheduleEventsConditionInput
  ) {
    deleteScheduleEvents(input: $input, condition: $condition) {
      id
      eventName
      eventType
      status
      deadlineDateTime
      deadlineTimestamp
      formType
      eventColorcode
      EventDetialDatas {
        items {
          id
          parentScheduleEventsID
          caption
          answer
          DetialAnswerHistories {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      userDatasID
      corporationsID
      createdAt
      updatedAt
    }
  }
`;
export const createCorporations = /* GraphQL */ `
  mutation CreateCorporations(
    $input: CreateCorporationsInput!
    $condition: ModelCorporationsConditionInput
  ) {
    createCorporations(input: $input, condition: $condition) {
      id
      corporationName
      TraineeDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      CoachDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ScheduleEvents {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      OfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      RafusalOfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCorporations = /* GraphQL */ `
  mutation UpdateCorporations(
    $input: UpdateCorporationsInput!
    $condition: ModelCorporationsConditionInput
  ) {
    updateCorporations(input: $input, condition: $condition) {
      id
      corporationName
      TraineeDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      CoachDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ScheduleEvents {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      OfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      RafusalOfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCorporations = /* GraphQL */ `
  mutation DeleteCorporations(
    $input: DeleteCorporationsInput!
    $condition: ModelCorporationsConditionInput
  ) {
    deleteCorporations(input: $input, condition: $condition) {
      id
      corporationName
      TraineeDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      CoachDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ScheduleEvents {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      OfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      RafusalOfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createRoleDatas = /* GraphQL */ `
  mutation CreateRoleDatas(
    $input: CreateRoleDatasInput!
    $condition: ModelRoleDatasConditionInput
  ) {
    createRoleDatas(input: $input, condition: $condition) {
      id
      roleNumber
      roleName
      createdAt
      updatedAt
    }
  }
`;
export const updateRoleDatas = /* GraphQL */ `
  mutation UpdateRoleDatas(
    $input: UpdateRoleDatasInput!
    $condition: ModelRoleDatasConditionInput
  ) {
    updateRoleDatas(input: $input, condition: $condition) {
      id
      roleNumber
      roleName
      createdAt
      updatedAt
    }
  }
`;
export const deleteRoleDatas = /* GraphQL */ `
  mutation DeleteRoleDatas(
    $input: DeleteRoleDatasInput!
    $condition: ModelRoleDatasConditionInput
  ) {
    deleteRoleDatas(input: $input, condition: $condition) {
      id
      roleNumber
      roleName
      createdAt
      updatedAt
    }
  }
`;
export const createUserDatas = /* GraphQL */ `
  mutation CreateUserDatas(
    $input: CreateUserDatasInput!
    $condition: ModelUserDatasConditionInput
  ) {
    createUserDatas(input: $input, condition: $condition) {
      id
      userId
      iconImage
      userRole
      TasktimeManagementDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ScheduleEvents {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ReadUnreadToNotifications {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ChatroomMaster {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ChatroomMember {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      TraineeDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      CoachDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      userName
      firstName
      middleName
      lastName
      firstNameKana
      middleNameKana
      lastNameKana
      universityName
      departmentName
      courseName
      AbuildSheetsDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      WeeklySurveyDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ELearningMovieDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ChatDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      OfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      RafusalOfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      graduationYear
      graduateSchoolName
      graduateSchoolDepartmentName
      graduateSchoolCourseName
      graduateSchoolGraduationYear
      highSchoolName
      displayName
      userRemarks
      CustomerChatdatas {
        items {
          id
          chatMessage
          chatDateTime
          chatDateTimestamp
          checkOn
          customerchatroomsID
          sendUserdatasID
          chatOnImage
          createdAt
          updatedAt
        }
        nextToken
      }
      CustomerChatrooms {
        items {
          id
          tag
          roomType
          CustomerChatdatas {
            nextToken
          }
          userdatasID
          chatroomsID
          userdatasType
          roomMasterName
          createdAt
          updatedAt
        }
        nextToken
      }
      CSMemberDatas {
        items {
          id
          userdatasID
          userName
          csChatroomType
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserDatas = /* GraphQL */ `
  mutation UpdateUserDatas(
    $input: UpdateUserDatasInput!
    $condition: ModelUserDatasConditionInput
  ) {
    updateUserDatas(input: $input, condition: $condition) {
      id
      userId
      iconImage
      userRole
      TasktimeManagementDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ScheduleEvents {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ReadUnreadToNotifications {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ChatroomMaster {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ChatroomMember {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      TraineeDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      CoachDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      userName
      firstName
      middleName
      lastName
      firstNameKana
      middleNameKana
      lastNameKana
      universityName
      departmentName
      courseName
      AbuildSheetsDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      WeeklySurveyDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ELearningMovieDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ChatDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      OfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      RafusalOfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      graduationYear
      graduateSchoolName
      graduateSchoolDepartmentName
      graduateSchoolCourseName
      graduateSchoolGraduationYear
      highSchoolName
      displayName
      userRemarks
      CustomerChatdatas {
        items {
          id
          chatMessage
          chatDateTime
          chatDateTimestamp
          checkOn
          customerchatroomsID
          sendUserdatasID
          chatOnImage
          createdAt
          updatedAt
        }
        nextToken
      }
      CustomerChatrooms {
        items {
          id
          tag
          roomType
          CustomerChatdatas {
            nextToken
          }
          userdatasID
          chatroomsID
          userdatasType
          roomMasterName
          createdAt
          updatedAt
        }
        nextToken
      }
      CSMemberDatas {
        items {
          id
          userdatasID
          userName
          csChatroomType
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserDatas = /* GraphQL */ `
  mutation DeleteUserDatas(
    $input: DeleteUserDatasInput!
    $condition: ModelUserDatasConditionInput
  ) {
    deleteUserDatas(input: $input, condition: $condition) {
      id
      userId
      iconImage
      userRole
      TasktimeManagementDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ScheduleEvents {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ReadUnreadToNotifications {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ChatroomMaster {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ChatroomMember {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      TraineeDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      CoachDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      userName
      firstName
      middleName
      lastName
      firstNameKana
      middleNameKana
      lastNameKana
      universityName
      departmentName
      courseName
      AbuildSheetsDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      WeeklySurveyDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ELearningMovieDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      ChatDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      OfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      RafusalOfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
        }
        nextToken
      }
      graduationYear
      graduateSchoolName
      graduateSchoolDepartmentName
      graduateSchoolCourseName
      graduateSchoolGraduationYear
      highSchoolName
      displayName
      userRemarks
      CustomerChatdatas {
        items {
          id
          chatMessage
          chatDateTime
          chatDateTimestamp
          checkOn
          customerchatroomsID
          sendUserdatasID
          chatOnImage
          createdAt
          updatedAt
        }
        nextToken
      }
      CustomerChatrooms {
        items {
          id
          tag
          roomType
          CustomerChatdatas {
            nextToken
          }
          userdatasID
          chatroomsID
          userdatasType
          roomMasterName
          createdAt
          updatedAt
        }
        nextToken
      }
      CSMemberDatas {
        items {
          id
          userdatasID
          userName
          csChatroomType
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTraineeRules = /* GraphQL */ `
  mutation CreateTraineeRules(
    $input: CreateTraineeRulesInput!
    $condition: ModelTraineeRulesConditionInput
  ) {
    createTraineeRules(input: $input, condition: $condition) {
      id
      number
      caption
      body
      createdAt
      updatedAt
    }
  }
`;
export const updateTraineeRules = /* GraphQL */ `
  mutation UpdateTraineeRules(
    $input: UpdateTraineeRulesInput!
    $condition: ModelTraineeRulesConditionInput
  ) {
    updateTraineeRules(input: $input, condition: $condition) {
      id
      number
      caption
      body
      createdAt
      updatedAt
    }
  }
`;
export const deleteTraineeRules = /* GraphQL */ `
  mutation DeleteTraineeRules(
    $input: DeleteTraineeRulesInput!
    $condition: ModelTraineeRulesConditionInput
  ) {
    deleteTraineeRules(input: $input, condition: $condition) {
      id
      number
      caption
      body
      createdAt
      updatedAt
    }
  }
`;
