import '@aws-amplify/ui-react/styles.css';

import {
    HashRouter,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';

import { 
    URL_PATH_CHATROOMS,
    URL_PATH_CHATROOMS_DETAIL_CHATROOMID,
    URL_PATH_COACHING_MANAGEMENT,
    URL_PATH_CORPORATION_MASTER_MANAGEMENT,
    URL_PATH_ESEDITOR_PARAM_EVENTID,
    URL_PATH_IEEDITOR_PARAM_EVENTID,
    URL_PATH_MYPAGE,
    URL_PATH_MYPAGE_ABUILD_BIBLE,
    URL_PATH_MYPAGE_MY_ES_LIST,
    URL_PATH_MYPAGE_OFFER_LIST,
    URL_PATH_MYPAGE_PROFILE_EDIT,
    URL_PATH_MYPAGE_REFUSAL_LIST,
    URL_PATH_MYPAGE_TRAINEE_RULES,
    URL_PATH_NOTIFICATIONS,
    URL_PATH_NOTIFICATIONS_NOTIFID,
    URL_PATH_REGISTRATION, 
    URL_PATH_SCHEDULE, 
    URL_PATH_SCHEDULE_DETAIL_PARAM_CORPORATIONID,
    URL_PATH_SCHEDULE_READ_IEEDITOR_PARAM_EVENTID,
    URL_PATH_SCHEDULE_READ_PARAM_CORPORATIONID,
    URL_PATH_SUBJECTS,
    URL_PATH_TASKTIMES,
    URL_PATH_TASKTIMES_CREATE_TIMESTAMP,
    URL_PATH_TASKTIMES_PARAM_TRAINEEID,
    URL_PATH_TASKTIMES_DETAIL_TIMESTAMP_TTMID,
    URL_PATH_TASKTIMES_DETAIL_TIMESTAMP_TTMID_TRAINEEID,
    URL_PATH_TODAYS_WORDS_MASTER_MANAGEMENT,
    URL_PATH_TRAINEE_LIST,
    URL_PATH_TRAINEE_LIST_DETAIL_TRAINEEID,
    URL_PATH_TRAINEE_LIST_DETAIL_TRAINEEID_IEEDITOR_EVENTID,
    URL_PATH_USERS_MANUAL,
    URL_PATH_CS_CHAT_SUPPORT,
    URL_PATH_CS_CHAT_OPERATION,
    URL_PATH_CS_CHAT_PAYMENT,
    URL_PATH_CS_TRAINEE_MANAGEMENT,
    URL_PATH_CS_COACH_MANAGEMENT,
    URL_PATH_CS_EVENT_BOOKING_STATUS,
    URL_PATH_TRAINEE_MASTER_MANAGEMENT,
    URL_PATH_MASTER_MANAGEMENT,
    URL_PATH_CHATROOMS_DETAIL_CS,
    URL_PATH_CS_CHAT_SUPPORT_CHATROOMID,
    URL_PATH_TASKTIMES_PARAM_TIMESTAMP_TTMID,
    URL_PATH_TASKTIMES_CREATE_TIMESTAMP_PCMODE,
    URL_PATH_CS_TRAINEE_MANAGEMENT_TRAINEEID,
    URL_PATH_CS_CHAT_OPERATION_CHATROOMID,
    URL_PATH_CS_CHAT_APP_SUPPORT,
    URL_PATH_CS_CHAT_APP_SUPPORT_CHATROOMID,
    URL_PATH_CS_EVENT_TODAY_WORDS_HISTORY,
    URL_PATH_TRAINEE_LIST_TRAINEEID,
    URL_PATH_CS_CHAT_SUPPORT_COACH,
    URL_PATH_CS_CHAT_SUPPORT_COACH_CHATROOMID,
    URL_PATH_CS_CHAT_OPERATION_COACH,
    URL_PATH_CS_CHAT_OPERATION_COACH_CHATROOMID,
    URL_PATH_CS_CHAT_APP_SUPPORT_COACH,
    URL_PATH_CS_CHAT_APP_SUPPORT_COACH_CHATROOMID,
    URL_PATH_CS_MODULE_TEST_1,
    URL_PATH_CS_CHAT_COACHING,
    URL_PATH_CS_CHAT_COACHING_CHATROOMID,
    URL_PATH_CS_MODULE_TEST_1_TIMESTAMP_TTMID,
    URL_PATH_COACH_MASTER_MANAGEMENT,
    URL_PARTS_SLASH,
    URL_PATH_CHATROOMS_TEST,
    URL_PATH_MODULE_TEST_2,
    URL_PATH_CS_CONNECT_MANAGEMENT,
    URL_PATH_CS_TRAINEE_MANAGEMENT_TRAINEEID_TTMID_TIMESTAMP,
    URL_PATH_MODULE_TEST_3_UID,
} from './Consts';

import SelectionSituationCalendar from '../containers/SelectionSituationCalendar/SelectionSituationCalendar';
import TaskTimeManagement from '../containers/TaskTimeManagement/TaskTimeManagement';
import TaskTimeDetail from '../containers/TaskTimeManagement/TaskTimeDetail';
import ChatRooms from '../containers/ChatRoom/ChatRooms';
import ChatRoomDetial from '../containers/ChatRoom/ChatRoomDetial';
import AbuildSubjects from '../containers/AbuildSubjects/AbuildSubjects';
import MyPage from '../containers/Mypage/Mypage';
import Notifications from '../containers/Operation/Notifications';
import NotificationDetial from '../containers/Operation/NotificationDetial';
import SelectionDetail from '../containers/SelectionSituationCalendar/SelectionDetail';
import EventDetailEditor from '../containers/SelectionSituationCalendar/EventDetailEditor';
import TraineeRules from '../containers/Mypage/TraineeRules';
import MyEntrysheets from '../containers/Mypage/MyEntrysheets';
import ProfileEdit from '../containers/Mypage/ProfileEdit';
import AbuildBible from '../containers/Mypage/AbuildBible';
import CoachingManagement from '../containers/CoachingManagement/CoachingManagement'
import TraineeList from '../containers/CoachingManagement/TraineeList';
import TraineeDetail from '../containers/CoachingManagement/TraineeDetail';
import CoachingEventDetailEditor from '../containers/SelectionSituationCalendar/CoachingEventDetailEditor';
import OfferList from '../containers/Mypage/OfferList';
import RefusalList from '../containers/Mypage/RefusalList';
import SelectionDetailReadonly from '../containers/SelectionSituationCalendar/SelectionDetailReadonly';
import EventDetailEditorReadonly from '../containers/SelectionSituationCalendar/EventDetailEditorReadonly';
import UsersManual from '../containers/Operation/UsersManual';
import TraineeRegistration from '../containers/Operation/TraineeRegistration';
import TodaysWordsMasterManagement from '../containers/MasterManagement/TodaysWordsMasterManagement';
import CorporationMasterManagement from '../containers/MasterManagement/CorporationMasterManagement';
import TaskTimeManageDataCreate from '../containers/TaskTimeManagement/TaskTimeManageDataCreate';
import SupportChatrooms from '../containers/CustomerSuccess/SupportChatrooms';
import OperationChatrooms from '../containers/CustomerSuccess/OperationChatrooms';
import PaymentChatrooms from '../containers/CustomerSuccess/PaymentChatrooms';
import TranieeManagement from '../containers/CustomerSuccess/TranieeManagement';
import CoachManagement from '../containers/CustomerSuccess/CoachManagement';
import EventBookingStatus from '../containers/CustomerSuccess/EventBookingStatus';
import TraineeMasterManagement from '../containers/MasterManagement/TraineeMasterManagement';
import MasterManagement from '../containers/MasterManagement/MasterManagement';
import AppSupChatrooms from '../containers/CustomerSuccess/AppSupChatrooms';
import TodayWordsHistory from '../containers/ChatRoom/TodayWordsHistory';
import CoachSupportChatrooms from '../containers/CustomerSuccess/CoachSupportChatrooms';
import CoachOperationChatrooms from '../containers/CustomerSuccess/CoachOperationChatrooms';
import CoachAppSupChatrooms from '../containers/CustomerSuccess/CoachAppSupChatrooms';
import TestComponentNextToken from '../containers/CustomerSuccess/TestComponentNextToken';
import CoachingChatrooms from '../containers/CustomerSuccess/CoachingChatrooms';
import TestTaskTimeDetail from '../containers/TaskTimeManagement/TestTaskTimeDetail';
import CoachMasterManagement from '../containers/MasterManagement/CoachMasterManagement';
import { TestMessagingPage } from './TestMessagingPage';
import { useEffect } from 'react';
import { checkChatNotifications, getCurrentUserData } from './CommonFunctions';
import ConnectManagement from '../containers/MasterManagement/ConnectManagement';
import TestModule31 from '../containers/TaskTimeManagement/TestModule31';
import TestTaskTimeManagement from '../containers/TaskTimeManagement/TestTaskTimeManagement';
import TestChatRooms from '../containers/ChatRoom/TestChatRooms';

export default function PageContents(){
    return (
        <HashRouter>
            <Switch>
                <Route 
                exact 
                path={URL_PATH_REGISTRATION}
                component={TraineeRegistration}
                />
                <Route 
                exact 
                path={URL_PATH_SCHEDULE} 
                component={SelectionSituationCalendar}
                />
                <Route 
                exact 
                path={URL_PATH_SCHEDULE_DETAIL_PARAM_CORPORATIONID}
                component={SelectionDetail}
                />
                <Route 
                exact 
                path={URL_PATH_SCHEDULE_READ_PARAM_CORPORATIONID}
                component={SelectionDetailReadonly}
                />
                <Route 
                exact 
                path={URL_PATH_IEEDITOR_PARAM_EVENTID}
                component={EventDetailEditor}
                />
                <Route 
                exact 
                path={URL_PATH_ESEDITOR_PARAM_EVENTID}
                component={EventDetailEditor}
                />
                <Route 
                exact 
                path={URL_PATH_SCHEDULE_READ_IEEDITOR_PARAM_EVENTID}
                component={EventDetailEditorReadonly}
                />
                <Route 
                exact 
                path={URL_PATH_TASKTIMES} 
                component={TaskTimeManagement}
                />
                <Route 
                exact 
                path={URL_PATH_TASKTIMES_PARAM_TRAINEEID}
                component={TaskTimeManagement}
                />
                <Route 
                exact 
                path={URL_PATH_TASKTIMES_PARAM_TIMESTAMP_TTMID} 
                component={TaskTimeManagement}
                />
                <Route 
                exact 
                path={URL_PATH_TASKTIMES_DETAIL_TIMESTAMP_TTMID} 
                component={TaskTimeDetail}
                />
                <Route 
                exact 
                path={URL_PATH_TASKTIMES_DETAIL_TIMESTAMP_TTMID_TRAINEEID}  
                component={TaskTimeDetail}
                />
                <Route 
                exact 
                path={URL_PATH_TASKTIMES_CREATE_TIMESTAMP}
                component={TaskTimeManageDataCreate}
                />
                <Route 
                exact 
                path={URL_PATH_TASKTIMES_CREATE_TIMESTAMP_PCMODE}
                component={TaskTimeManageDataCreate}
                />
                <Route 
                exact 
                path={URL_PATH_CHATROOMS} 
                component={ChatRooms}
                />
                <Route 
                exact 
                path={URL_PATH_CS_EVENT_TODAY_WORDS_HISTORY} 
                component={TodayWordsHistory}
                />
                <Route
                exact 
                path={URL_PATH_CHATROOMS_DETAIL_CHATROOMID} 
                component={ChatRoomDetial}
                />
                <Route
                exact 
                path={URL_PATH_CHATROOMS_DETAIL_CS} 
                component={ChatRooms}
                />
                <Route
                exact 
                path={URL_PATH_CHATROOMS_TEST} 
                component={TestChatRooms}
                />
                <Route 
                exact 
                path={URL_PATH_SUBJECTS}
                component={AbuildSubjects}
                />
                <Route 
                exact 
                path={URL_PATH_MYPAGE}
                component={MyPage}
                />
                <Route 
                exact 
                path={URL_PATH_MYPAGE_TRAINEE_RULES}
                component={TraineeRules}
                />
                <Route 
                exact 
                path={URL_PATH_MYPAGE_MY_ES_LIST}
                component={MyEntrysheets}
                />
                <Route 
                exact 
                path={URL_PATH_MYPAGE_PROFILE_EDIT}
                component={ProfileEdit}
                />
                <Route 
                exact 
                path={URL_PATH_MYPAGE_ABUILD_BIBLE}
                component={AbuildBible}
                />
                <Route 
                exact 
                path={URL_PATH_NOTIFICATIONS}
                component={Notifications}
                />
                <Route 
                exact 
                path={URL_PATH_NOTIFICATIONS_NOTIFID}  
                component={NotificationDetial}
                />
                <Route 
                exact 
                path={URL_PATH_COACHING_MANAGEMENT}
                component={CoachingManagement}
                />
                <Route 
                exact 
                path={URL_PATH_TRAINEE_LIST}
                component={TraineeList}
                />
                <Route 
                exact 
                path={URL_PATH_TRAINEE_LIST_TRAINEEID}
                component={TraineeList}
                />
                <Route 
                exact 
                path={URL_PATH_TRAINEE_LIST_DETAIL_TRAINEEID}  
                component={TraineeDetail}
                />
                <Route 
                exact 
                path={URL_PATH_TRAINEE_LIST_DETAIL_TRAINEEID_IEEDITOR_EVENTID}
                component={CoachingEventDetailEditor}
                />
                <Route 
                exact 
                path={URL_PATH_MYPAGE_OFFER_LIST}
                component={OfferList}
                />
                <Route 
                exact 
                path={URL_PATH_MYPAGE_REFUSAL_LIST}
                component={RefusalList}
                />
                <Route 
                exact 
                path={URL_PATH_USERS_MANUAL}
                component={UsersManual}
                />
                <Route 
                exact 
                path={URL_PATH_MASTER_MANAGEMENT}
                component={MasterManagement}
                />
                <Route 
                exact 
                path={URL_PATH_TODAYS_WORDS_MASTER_MANAGEMENT}
                component={TodaysWordsMasterManagement}
                />
                <Route 
                exact 
                path={URL_PATH_CORPORATION_MASTER_MANAGEMENT}
                component={CorporationMasterManagement}
                />
                <Route 
                exact 
                path={URL_PATH_TRAINEE_MASTER_MANAGEMENT}
                component={TraineeMasterManagement}
                />
                <Route 
                exact 
                path={URL_PATH_COACH_MASTER_MANAGEMENT}
                component={CoachMasterManagement}
                />
                <Route 
                exact 
                path={URL_PATH_CS_CHAT_SUPPORT}
                component={SupportChatrooms}
                />
                <Route 
                exact 
                path={URL_PATH_CS_CHAT_SUPPORT_CHATROOMID}
                component={SupportChatrooms}
                />
                <Route 
                exact 
                path={URL_PATH_CS_CHAT_SUPPORT_COACH}
                component={CoachSupportChatrooms}
                />
                <Route 
                exact 
                path={URL_PATH_CS_CHAT_SUPPORT_COACH_CHATROOMID}
                component={CoachSupportChatrooms}
                />
                <Route 
                exact 
                path={URL_PATH_CS_CHAT_OPERATION}
                component={OperationChatrooms}
                />
                <Route 
                exact 
                path={URL_PATH_CS_CHAT_OPERATION_CHATROOMID}
                component={OperationChatrooms}
                />
                <Route 
                exact 
                path={URL_PATH_CS_CHAT_OPERATION_COACH}
                component={CoachOperationChatrooms}
                />
                <Route 
                exact 
                path={URL_PATH_CS_CHAT_OPERATION_COACH_CHATROOMID}
                component={CoachOperationChatrooms}
                />
                <Route 
                exact 
                path={URL_PATH_CS_CHAT_APP_SUPPORT}
                component={AppSupChatrooms}
                />
                <Route 
                exact 
                path={URL_PATH_CS_CHAT_APP_SUPPORT_CHATROOMID}
                component={AppSupChatrooms}
                />
                <Route 
                exact 
                path={URL_PATH_CS_CHAT_APP_SUPPORT_COACH}
                component={CoachAppSupChatrooms}
                />
                <Route 
                exact 
                path={URL_PATH_CS_CHAT_APP_SUPPORT_COACH_CHATROOMID}
                component={CoachAppSupChatrooms}
                />
                <Route 
                exact 
                path={URL_PATH_CS_CHAT_PAYMENT}
                component={PaymentChatrooms}
                />
                <Route 
                exact 
                path={URL_PATH_CS_CHAT_COACHING}
                component={CoachingChatrooms}
                />
                <Route 
                exact 
                path={URL_PATH_CS_CHAT_COACHING_CHATROOMID}
                component={CoachingChatrooms}
                />
                <Route 
                exact 
                path={URL_PATH_CS_TRAINEE_MANAGEMENT}
                component={TranieeManagement}
                />
                <Route 
                exact 
                path={URL_PATH_CS_TRAINEE_MANAGEMENT_TRAINEEID}
                component={TranieeManagement}
                />
                <Route 
                exact 
                path={URL_PATH_CS_TRAINEE_MANAGEMENT_TRAINEEID_TTMID_TIMESTAMP}
                component={TranieeManagement}
                />
                <Route 
                exact 
                path={URL_PATH_CS_COACH_MANAGEMENT}
                component={CoachManagement}
                />
                <Route 
                exact 
                path={URL_PATH_CS_EVENT_BOOKING_STATUS}
                component={EventBookingStatus}
                />
                <Route 
                exact 
                path={URL_PATH_CS_EVENT_BOOKING_STATUS}
                component={EventBookingStatus}
                />
                <Route 
                exact 
                path={URL_PATH_CS_MODULE_TEST_1}
                component={TestTaskTimeDetail}
                />
                <Route 
                exact 
                path={URL_PATH_CS_MODULE_TEST_1_TIMESTAMP_TTMID}
                component={TestTaskTimeDetail}
                />
                <Route 
                exact 
                path={URL_PATH_MODULE_TEST_2}
                component={TestModule31}
                />
                <Route 
                exact 
                path={URL_PATH_MODULE_TEST_3_UID}
                component={TestTaskTimeManagement}
                />
                <Route 
                exact 
                path={URL_PATH_CS_CONNECT_MANAGEMENT}
                component={ConnectManagement}
                />
                <Redirect 
                path="*" 
                to={URL_PATH_REGISTRATION}
                />
            </Switch>
        </HashRouter>
    );
}