// AWS React
import { API } from "aws-amplify";
import { 
    useEffect, 
    useState, 
    useReducer,
} from 'react';
import { 
    Box, 
    Grid, 
    Typography, 
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

// GraphQL
import { listRefusalOfferDatas } from "../../graphql/queries";

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import * as Consts from '../../Commons/Consts';
import { 
    getCurrentUserData, 
    redirectForUserRoleToRedorectPages, 
    consoleLog, 
} from "../../Commons/CommonFunctions";
import { offerReducer, userReducer } from "../../Commons/Reducers";
import { RefusalOfferDataCard } from "../../components/SelectionSituationCalender/RefusalOfferDataCard";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import { commonStyles } from "../../Commons/CommonStyles";
import SideMenu from "../../Commons/SideMenu";

export default function RefusalList(){
    const commonStyle = commonStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);

    const [offers, dispachOfferData] = useReducer(offerReducer, []);
    const [userData, dispatchCurrentUserData] = useReducer(userReducer, []);
    const initRefusalList = async () =>{
        try {
            // ユーザーロールに基づき見てはいけないユーザーロールはリダイレクト
            const currentUserData = await getCurrentUserData();
            redirectForUserRoleToRedorectPages(history, currentUserData, Consts.USER_ROLE.Trainee);
            dispatchCurrentUserData({ type: Consts.INITIAL_QUERY, user: currentUserData });
            
            // 閲覧ユーザーに関わる選考状況イベントデータリストをクエリにて取得し、State変数へ保持
            const filter = {
                userdatasID: {contains: currentUserData.userId},
            }
            const offersRes = await API.graphql({
                query: listRefusalOfferDatas,
                variables: { filter: filter, limit:1000 },
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            });
            dispachOfferData({ type: Consts.INITIAL_QUERY, offers:offersRes.data.listRefusalOfferDatas.items });
            
            // プログレス表示を停止
            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }
    
    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();

        initRefusalList();
        // アンマウント時処理
        return() => {
            abortCtrl.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header 
                title='辞退企業一覧'
            />
            {isLoading ?
            <LoadingIcon/>
            :
            <Box>
                <SideMenu/>
                <Box className={commonStyle.pageContent}>
                    <Box pt={2}>
                        <Grid container spacing={0}>
                            {0 < offers.length?
                                offers.map(refusalOfferData => (
                                    <RefusalOfferDataCard refusalOfferData={refusalOfferData}/>
                                ))
                            :
                                <Typography>辞退として登録した企業はありません。</Typography>
                            }
                        </Grid>
                        <Box className={commonStyle.endBox}/>
                    </Box>
                </Box>
            </Box>
            }            
            <Footer/>
        </Box>
    );
}
