// AWS React
import { API } from "aws-amplify";

// GraphQL
import { 
    listScheduleEvents, 
    listCorporations,
    listOfferDatas,
    listRefusalOfferDatas,
} from "../../graphql/queries";
import { 
    onCreateScheduleEvents, 
    onCreateCorporations, 
} from '../../graphql/subscriptions';

// React
import { 
    useEffect, 
    useState, 
    useReducer,
    Fragment, 
} from 'react';
import { useHistory } from 'react-router-dom';

// React-Calendar
import Calendar from 'react-calendar';
import styled from 'styled-components';
import 'react-calendar/dist/Calendar.css';

// Material-ui
import { makeStyles } from '@material-ui/core/styles';
import { 
    Typography, 
    Box, 
} from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { 
    AMAZON_COGNITO_USER_POOLS, 
    CALENDAR_VIEW_MONTH, 
    ERROR_TYPE_ABORTERROR, 
    FOOTER_TITLE_SCHEDULE, 
    INITIAL_QUERY, 
    SUBSCRIPTION, 
    SUBSCRIPTION_CREATE, 
    TAB_MENU_TITLE_CARENDAR, 
    TAB_MENU_TITLE_LIST, 
    URL_PARTS_SLASH, 
    URL_PATH_SCHEDULE_DETAIL, 
    USER_ROLE, 
} from '../../Commons/Consts'
import { 
    getCurrentUserData, 
    redirectForUserRoleToRedorectPages,
    getFormatDate, 
    consoleLog, 
} from '../../Commons/CommonFunctions';
import { 
    corporationsReducer, 
    eventReducer, 
    offerReducer, 
    userDataReducer, 
} from '../../Commons/Reducers';
import { CreateEventModal } from "../../components/SelectionSituationCalender/CreateEventModal";
import { 
    a11yProps, 
    TabPanel 
} from "../../Commons/TabPanel";
import { EventCorporationCardList } from "../../components/SelectionSituationCalender/EventCorporationDataList";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import { commonStyles } from "../../Commons/CommonStyles";
import SideMenu from "../../Commons/SideMenu";

const CalendarContainer = styled.div`
    /* ~~~ container styles ~~~ */
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: 100%;
    border-radius: 3px;
    .react-calendar{
        width: 100%;
        max-height: 72vh;
        
        /* ~~~ navigation styles ~~~ */
        .react-calendar__navigation {
            display: flex;
            max-height: 5vh;
            margin-bottom: 0px;

            .react-calendar__navigation__label {
                font-weight: bold;
                background-color:#ffffff;
                color:#000000;
            }
            .react-calendar__navigation__arrow {
                flex-grow: 0.333;
            }
        }
        .react-calendar__tile{
            min-height: 6.8vh;
            padding-top: 5px;
            padding-right: 0px;
            padding-bottom: 0px;
            padding-left: 0px;
        }
        .react-calendar__tile--active.react-calendar__tile--now{
            background: Yellow;
        }
        .react-calendar__tile--active{
            background: none;
            color: black;
        }
        .react-calendar__tile:nth-child(7n){
            color: #00F;
        }
        .react-calendar__month-view__weekdays{
            border: solid #aaaaaa;
            border-top-width: 0.1vmin;
            border-bottom-width: 0.1vmin;
            border-left-width: 0;
            border-right-width: 0;
        }
        .react-calendar__month-view__days__day--neighboringMonth {
            color: #757575 !important;
          }
        /* ~~~ button styles ~~~ */
        .react-calendar__viewContainer {
            button {
                border: solid #aaaaaa 0.1vmin;
            }
        }
    }
`;
  
const localStyles = makeStyles(theme => ({
    calenderTile:{
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-start",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "stretch",
    },
    eventBar:{
        borderRadius: "4px",
        padding: "1px",
        cursor: "pointer",
        color: "#000000",
        '& .MuiTypography-root':{
            fontSize: "2vmin",
            textAlign: "left",
        },
    },
    tabMenu:{
        minWidth: "100%",
        maxWidth: "100%",
        width: "100%",
        height: '5vh',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
    },
    widthHerf:{
        minWidth: "50%",
        maxWidth: "50%",
        width: "50%",
    },
}));

export default function SelectionSituationCalendar(){
    const history = useHistory();
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    const [isLoading, setIsLoading] = useState(true);

    // モーダル用の設定値
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    
    // タブ用の設定値
    const [tabNumber, setTabNumber] = useState(0);
    const handleChange = (event, newValue) => {
        setTabNumber(newValue);
    };

    const [userData, dispachCurrentUserData] = useReducer(userDataReducer, [])
    const [corporations, dispatchCorporations] = useReducer(corporationsReducer, []);
    const [events, dispachEventData] = useReducer(eventReducer, []);
    const [offers, dispachOfferData] = useReducer(offerReducer, []);
    const [refusalOffers, dispachRefusalOfferData] = useReducer(offerReducer, []);
    const [hiddenEventForCorpIds, setHiddenEventForCorpIds] = useState([]);
    const [clickDate, setClickDate]= useState();
    const initSelectionSituationCalendar = async () =>{
        try {
            // ユーザーロールに基づき見てはいけないユーザーロールはリダイレクトし、リダイレクトしない場合は閲覧ユーザーデータをState変数へ保持
            const currentUserData = await getCurrentUserData();
            redirectForUserRoleToRedorectPages(history, currentUserData, USER_ROLE.Trainee);
            dispachCurrentUserData({ type: INITIAL_QUERY, userData: currentUserData })

            // 企業名検索ボックス用の企業データリストをクエリにて取得し、State変数に保持
            const corpRes = await API.graphql({
                query: listCorporations,
                variables: { limit: 10000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            dispatchCorporations({ type: INITIAL_QUERY, corporations:corpRes.data.listCorporations.items });

            // 現在の閲覧ユーザーが作成したイベントデータリストをクエリにて取得し、State関数に保持
            const eventsFilter = {
                userDatasID: {contains: currentUserData.userId}
            }
            const eventRes = await API.graphql({
                query: listScheduleEvents,
                variables: { filter: eventsFilter, limit: 1000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            dispachEventData({ type: INITIAL_QUERY, events:eventRes.data.listScheduleEvents.items });

            // 閲覧ユーザーに紐づく内定情報を取得し、State関数に保持
            const offerFilter = {
                userdatasID: {contains: currentUserData.userId}
            }
            const offerRes = await API.graphql({
                query: listOfferDatas,
                variables: { filter: offerFilter, limit: 1000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            dispachOfferData({ type: INITIAL_QUERY, offers:offerRes.data.listOfferDatas.items });

            // 閲覧ユーザーに紐づく辞退情報を取得し、State関数に保持
            const refusalOfferRes = await API.graphql({
                query: listRefusalOfferDatas,
                variables: { filter: offerFilter, limit: 1000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            dispachRefusalOfferData({ type: INITIAL_QUERY, offers:refusalOfferRes.data.listRefusalOfferDatas.items });

            // 内定、辞退した予定は過去分も全て表示しないため、確認用の企業データIDを配列にし、State変数へ保持
            var localIds = [];
            localIds.push(offerRes.data.listOfferDatas.items.map((item) => {return item.corporationsID}));
            localIds.push(refusalOfferRes.data.listRefusalOfferDatas.items.map((item) => {return item.corporationsID}));
            setHiddenEventForCorpIds(localIds.flatMap(data => data));

            // プログレス表示を停止
            setIsLoading(false);
        } catch (e) {
            if (e.name === ERROR_TYPE_ABORTERROR) {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }

    // const onClickAddButton = () => {
    //     setClickDate(undefined);
    //     setOpen(true);
    // }
    
    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();
        initSelectionSituationCalendar();
        
        const createSchEveSubs = API.graphql({
            query:onCreateScheduleEvents,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
            next: (msg) => {
                const events = msg.value.data.onCreateScheduleEvents;
                dispachEventData({ type: SUBSCRIPTION_CREATE, events: events });
            }
        });

        const createCorpSubs = API.graphql({
            query:onCreateCorporations,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
            next: (msg) => {
                const corps = msg.value.data.onCreateCorporations;
                dispatchCorporations({ type: SUBSCRIPTION, corporations: corps });
            }
        });
        
        // アンマウント時処理
        return () => {
            abortCtrl.abort();
            createSchEveSubs.unsubscribe();
            createCorpSubs.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const DayTile = (props) => {
        const onClickEvent = () => {
            setClickDate(props.date);
            setOpen(true);
        }
        
        return (<Box minHeight="7vh" onClick={onClickEvent}>{props.children}</Box>);
    }
    
    const getTileContent = (calenderData) => {
        // 月表示のときのみ
        if (calenderData.view !== CALENDAR_VIEW_MONTH) {
          return null;
        }
        const day = getFormatDate(calenderData.date);
        const setDate = calenderData.date;
        setDate.setHours(19);
        return (
            <DayTile date={setDate}>
                {
                    events.filter(data => { 
                        const checkDate = new Date(data.deadlineDateTime);
                        return getFormatDate(new Date(checkDate.setHours(checkDate.getHours() - 9))) === day}).length ?
                        events.filter(data => { 
                            const checkDate = new Date(data.deadlineDateTime);
                            return getFormatDate(new Date(checkDate.setHours(checkDate.getHours() - 9))) === day
                        }).map(data =>{
                            if(hiddenEventForCorpIds.some((id) => (id === data.corporationsID))){
                                return (<Fragment></Fragment>);
                            }
                            else{
                                const eventName = corporations.filter(corporation => {return corporation.id === data.corporationsID})[0].corporationName + data.eventName;
                                return (
                                <Box
                                    className={localStyle.eventBar}
                                    p={1}
                                    style={{backgroundColor: data.eventColorcode}}
                                    onClick={() => {
                                        history.push(URL_PATH_SCHEDULE_DETAIL + URL_PARTS_SLASH + data.corporationsID);
                                    }}
                                    noWrap
                                >
                                    <Typography noWrap>{eventName}</Typography>
                                </Box>);
                            }
                        })
                    :
                    <Fragment></Fragment>
                }
            </DayTile>
        );
    }
    
    return(
        <Box>
            <Header title={FOOTER_TITLE_SCHEDULE}/>
            <SideMenu/>
            {isLoading?
            <LoadingIcon/>
            :
            <Box className={commonStyle.pageContent} >
                <Box sx={{ borderBottom: 1, borderColor: 'primary' }}  >
                    <Tabs value={tabNumber} onChange={handleChange} className={localStyle.tabMenu} aria-label="basic tabs example">
                        <Tab fullWidth label={TAB_MENU_TITLE_CARENDAR} {...a11yProps(0)} className={localStyle.widthHerf} />
                        <Tab fullWidth label={TAB_MENU_TITLE_LIST} {...a11yProps(1)} className={localStyle.widthHerf} />
                    </Tabs>
                </Box>
                <TabPanel value={tabNumber} index={0}>
                    <CalendarContainer>
                        <Calendar 
                            calendarType="US"
                            tileContent={getTileContent}
                            tileClassName={localStyle.calenderTile}
                            maxDetail={CALENDAR_VIEW_MONTH}
                            minDetail={CALENDAR_VIEW_MONTH}
                            prev2Label={null}
                            next2Label={null}
                        />
                    </CalendarContainer>
                </TabPanel>
                <TabPanel value={tabNumber} index={1}>
                    <EventCorporationCardList
                        events={events}
                        refusalOffers={refusalOffers}
                        offers={offers}
                        corporations={corporations}
                        userRole={userData.userRole}
                    />
                    <Box className={commonStyle.endBox}/>
                </TabPanel>
                
            </Box>
            }
            <CreateEventModal
                open={open} 
                setOpen={setOpen} 
                handleClose={handleClose} 
                hiddenEventForCorpIds={hiddenEventForCorpIds}
                setDate={clickDate}
            />
            <Footer/>
        </Box>
    );
}

