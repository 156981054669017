import { makeStyles, Radio, Typography } from "@material-ui/core";
import { useState } from "react";

const Colors = {
    red:'#ffcdd2',
    purple:'#ce93d8',
    blue:'#90caf9',
    lightBlue:'#b3e5fc',
    green:'#b2dfdb',
    yellow:'#f4ff81',
    orenge:'#ffcc80',
    brown:'#bcaaa4',
}
const Border = 'Solid #2a3eb1 4px';
const BoxShadow = 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)';

const useRadioStyles = makeStyles({
    root: {
        color: Colors.red,
    },
    blueIcon: {
        borderRadius: '0%',
        width: 25,
        height: 25,
        boxShadow: BoxShadow,
        backgroundColor: Colors.blue,
    },
    checkedBlueIcon: {
        borderRadius: '0%',
        width: 25,
        height: 25,
        backgroundColor: Colors.blue,
        border: Border,
    },
    lightBlueIcon: {
        borderRadius: '0%',
        width: 25,
        height: 25,
        boxShadow: BoxShadow,
        backgroundColor: Colors.lightBlue,
    },
    checkedLightBlueIcon: {
        borderRadius: '0%',
        width: 25,
        height: 25,
        backgroundColor: Colors.lightBlue,
        border: Border,
    },
    redIcon: {
        borderRadius: '0%',
        width: 25,
        height: 25,
        boxShadow: BoxShadow,
        backgroundColor: Colors.red,
    },
    checkedRedIcon: {
        borderRadius: '0%',
        width: 25,
        height: 25,
        backgroundColor: Colors.red,
        border: Border,
    },
    orengeIcon: {
        borderRadius: '0%',
        width: 25,
        height: 25,
        boxShadow: BoxShadow,
        backgroundColor: Colors.orenge,
    },
    checkedOrengeIcon: {
        borderRadius: '0%',
        width: 25,
        height: 25,
        backgroundColor: Colors.orenge,
        border: Border,
    },
    greenIcon: {
        borderRadius: '0%',
        width: 25,
        height: 25,
        boxShadow: BoxShadow,
        backgroundColor: Colors.green,
    },
    checkedGreenIcon: {
        borderRadius: '0%',
        width: 25,
        height: 25,
        backgroundColor: Colors.green,
        border: Border,
    },
    purpleIcon: {
        borderRadius: '0%',
        width: 25,
        height: 25,
        boxShadow: BoxShadow,
        backgroundColor: Colors.purple,
    },
    checkedPurpleIcon: {
        borderRadius: '0%',
        width: 25,
        height: 25,
        backgroundColor: Colors.purple,
        border: Border,
    },
    yellowIcon: {
        borderRadius: '0%',
        width: 25,
        height: 25,
        boxShadow: BoxShadow,
        backgroundColor: Colors.yellow,
    },
    checkedYellowIcon: {
        borderRadius: '0%',
        width: 25,
        height: 25,
        backgroundColor: Colors.yellow,
        border: Border,
    },
    formLabel:{
        display:'block',
        height:'12px',
    }
});

export function ColorSelector({ setColor, initColor }) {
    const classes = useRadioStyles();
    const initColorValue = (initColor === void 0 ? Colors.blue : initColor );
    const [selectedValue, setSelectedValue] = useState(initColorValue);
  
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        setColor(event.target.value);
    };
    
    return (
        <div>
            <Typography variant="caption">イベントバーの色</Typography>
            <div>
                <Radio
                    checked={selectedValue === Colors.blue}
                    onChange={handleChange}
                    value={Colors.blue}
                    name="color-radio-blue"
                    inputProps={{ 'aria-label': 'Blue' }}
                    className={classes.root}
                    checkedIcon={<span className={(classes.checkedBlueIcon)} />}
                    icon={<span className={classes.blueIcon} />}
                />
                <Radio
                    checked={selectedValue === Colors.lightBlue}
                    onChange={handleChange}
                    value={Colors.lightBlue}
                    name="color-radio-lightblue"
                    inputProps={{ 'aria-label': 'LightBlue' }}
                    className={classes.root}
                    checkedIcon={<span className={(classes.checkedLightBlueIcon)} />}
                    icon={<span className={classes.lightBlueIcon} />}
                />
                <Radio
                    checked={selectedValue === Colors.orenge}
                    onChange={handleChange}
                    value={Colors.orenge}
                    name="color-radio-orenge"
                    inputProps={{ 'aria-label': 'Orenge' }}
                    className={classes.root}
                    checkedIcon={<span className={(classes.checkedOrengeIcon)} />}
                    icon={<span className={classes.orengeIcon} />}
                />
                <Radio
                    checked={selectedValue === Colors.red}
                    onChange={handleChange}
                    value={Colors.red}
                    name="color-radio-red"
                    inputProps={{ 'aria-label': 'Red' }}
                    className={classes.root}
                    checkedIcon={<span className={(classes.checkedRedIcon)} />}
                    icon={<span className={classes.redIcon} />}
                />
                <Radio
                    checked={selectedValue === Colors.green}
                    onChange={handleChange}
                    value={Colors.green}
                    name="color-radio-green"
                    inputProps={{ 'aria-label': 'Green' }}
                    className={classes.root}
                    checkedIcon={<span className={(classes.checkedGreenIcon)} />}
                    icon={<span className={classes.greenIcon} />}
                />
                <Radio
                    checked={selectedValue === Colors.purple}
                    onChange={handleChange}
                    value={Colors.purple}
                    name="color-radio-purple"
                    inputProps={{ 'aria-label': 'Purple' }}
                    className={classes.root}
                    checkedIcon={<span className={(classes.checkedPurpleIcon)} />}
                    icon={<span className={classes.purpleIcon} />}
                />
                <Radio
                    checked={selectedValue === Colors.yellow}
                    onChange={handleChange}
                    value={Colors.yellow}
                    name="color-radio-yellow"
                    inputProps={{ 'aria-label': 'Yellow' }}
                    className={classes.root}
                    checkedIcon={<span className={(classes.checkedYellowIcon)} />}
                    icon={<span className={classes.yellowIcon} />}
                />
            </div>
        </div>
    );
}

