// AWS
import { API } from "aws-amplify";

// React
import { 
    useEffect, 
    useState, 
    useReducer, 
} from 'react';
import { useParams } from 'react-router-dom';

// Material UI
import { 
    Box,  
} from '@material-ui/core';

// GraphQL
import { listChatrooms, listTodayWords, listTraineeDatas } from "../../graphql/queries";
import { onCreateChatDatas } from '../../graphql/subscriptions';

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import * as Consts from '../../Commons/Consts';
import { 
    getCurrentUserData, 
    consoleLog, 
    getUserDataById, 
} from "../../Commons/CommonFunctions";
import { 
    userReducer, 
    chatDatasReducer,
    commonDBDataReducer, 
} from "../../Commons/Reducers";
import { ChatDataList } from "../../components/ChatRoom/ChatDataList";
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import SideMenu from "../../Commons/SideMenu";
import ChatMessageSender from "../../components/ChatRoom/ChatMessageSender";
import { TodayWordsList } from "../../components/ChatRoom/TodayWordsList";

export default function TodayWordsHistory(){
    const commonStyle = commonStyles();   
    const [isLoading, setIsLoading] = useState(true);

    const [sendToUserName, setSendToUserName] = useState('');

    const [sendFromUserData, dispachSendFromUserData] = useReducer(userReducer, []);
    const [todaysWordsData, dispatchTodaysWordsData] = useReducer(commonDBDataReducer, []);
    
    const initChatroomDetail = async () =>{
        try{
            // 閲覧ユーザーの情報を取得し、State変数へ保持
            const currentUserData = await getCurrentUserData();
            dispachSendFromUserData({type: Consts.INITIAL_QUERY, user:currentUserData});
            
            // 新井翔太の今日の一言を取得
            const traineeFilter = { userdatasID: {contains: currentUserData.userId} }
            const traineeRes = await API.graphql({
                query: listTraineeDatas,
                variables: { filter: traineeFilter , limit:10000 },
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            });
            const traineeData = traineeRes.data.listTraineeDatas.items;
            
            const wordsRes = await API.graphql({
                query: listTodayWords,
                variables: { limit: 100 },
                authMode: Consts.AMAZON_COGNITO_USER_POOLS,
            });
            var word = wordsRes.data.listTodayWords.items.find((word) => word.displayNumber === 1);
            if(0 < traineeData.length){
                dispatchTodaysWordsData({ type: Consts.INITIAL_QUERY, data:word});
                const curriculumStartDate = new Date(traineeData.at(0).curriculumStartDate);
                const nowDate = new Date();
                const diffDays = parseInt((nowDate - curriculumStartDate) / 1000 / 60 / 60 / 24);
                if(diffDays <= 1){
                    word = wordsRes.data.listTodayWords.items.filter((word) => word.displayNumber === 1);
                }else{
                    word = wordsRes.data.listTodayWords.items.filter((word) => word.displayNumber <= diffDays);
                }
            }
            dispatchTodaysWordsData({ type: Consts.INITIAL_QUERY, data:word });
            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                // something to do when error occur
                consoleLog(sendFromUserData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();

        initChatroomDetail();

        return () => {
            abortCtrl.abort()
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header title={Consts.TODAYS_SHOTA_ARAI_HEADER_TITLE}/>
            <SideMenu/>
            {isLoading ?
            <LoadingIcon/>
            :
            <Box className={commonStyle.pageContent} >
                <Box mt={3}>
                    <TodayWordsList
                        todayWordsData={todaysWordsData}
                    />
                </Box>
            </Box>
            }
            <Footer/>
        </Box>
    );
}
