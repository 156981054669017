import { TextField } from "@material-ui/core";

export function SearchBox({ searchText, setSearchText }){
    const onChangeSearchText = (event) => {
        setSearchText(event.target.value);
    }

    return (
        <TextField
            id="message-editor"
            fullWidth
            size="small"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            label="検索"
            value={searchText}
            onChange={onChangeSearchText}
        />
    );
}