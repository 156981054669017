// AWS
import { API } from "aws-amplify";

// React
import { 
    useEffect, 
    useState, 
    useReducer,
} from 'react';
import { useHistory } from 'react-router-dom';

// Material UI
import { 
    Typography, 
    Box,
    makeStyles, 
} from '@material-ui/core';

// GraphQL
import { 
    listConectIsCoachingToTargetTrainees,
    listCorporations, 
    listCustomerChatrooms, 
    listOfferDatas, 
    listRefusalOfferDatas, 
    listScheduleEvents, 
    listTraineeDatas 
} from "../../graphql/queries";

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import * as Consts from '../../Commons/Consts';
import { 
    getCurrentUserData,
    getUserDataById, 
    getUserIconImage, 
    redirectForUserRoleToRedorectPages, 
    consoleLog, 
} from "../../Commons/CommonFunctions";
import { 
    traineeListReducer, 
} from "../../Commons/Reducers";
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import SideMenu from "../../Commons/SideMenu";
import CoachingTraineeList from "../../components/CoachingManagement/CoachingTraineeList";
 
import { AMAZON_COGNITO_USER_POOLS, HEADER_TITLE_TRAINEE_MANAGEMENT } from '../../Commons/Consts';
import CoachingTraineeDetail from "../../components/CoachingManagement/CoachingTraineeDetail";
import { useParams } from "react-router";
import CustomerTraineeList from "../../components/CustomerSuccess/CustomerTraineeList";

const localStyles = makeStyles(theme => ({
    centerOfCenterContents:{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
    },
}));

export default function TraineeList(){
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    const history = useHistory();
    
    const param = useParams();
    const [userData, setUserData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [traineeList, setTraineeList] = useState([]);
    const [traineeData, setTraineeUserData] = useState([]);
    const [previewImage, setPreviewImage] = useState();
    const [events, setEventData] = useState([]);
    const [corporations, setCorporations] = useState([]);
    const [offers, setOffers] = useState([]);
    const [refOffers, setRefOffers] = useState([]);
    const [csChatrooms, setcsChatrooms] = useState([]);
    
    const initTraineeList = async () =>{
        try {
            setIsLoading(true);
            const currentUserData = await getCurrentUserData();
            setUserData(currentUserData);

            redirectForUserRoleToRedorectPages(history, currentUserData, Consts.USER_ROLE.Coach);
            const traineeListFilter = {
                coachdatasID: {contains: currentUserData.userId},
            }
            const targetTraineeDataRes = await API.graphql({
                query: listConectIsCoachingToTargetTrainees,
                variables: { filter: traineeListFilter, limit:10000 },
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            });
            setTraineeList(targetTraineeDataRes.data.listConectIsCoachingToTargetTrainees.items);

            // Get a specific item
            if(param.traineeId){
                const traineeUserData = await getUserDataById(param.traineeId);
                setTraineeUserData(traineeUserData);
                if(traineeUserData.iconImage !== null){
                    const file = await getUserIconImage(traineeUserData);
                    setPreviewImage(file);
                }
                else{
                    setPreviewImage(null);
                }
                
                const eventFilter = {
                    userDatasID: {contains: traineeUserData.userId}
                }
                const eventRes = await API.graphql({
                    query: listScheduleEvents,
                    variables: { filter: eventFilter, limit:10000 },
                    authMode: AMAZON_COGNITO_USER_POOLS,
                });
                setEventData(eventRes.data.listScheduleEvents.items);
            
                const corpRes = await API.graphql({
                    query: listCorporations,
                    variables: { limit:10000 },
                    authMode: AMAZON_COGNITO_USER_POOLS,
                });
                setCorporations(corpRes.data.listCorporations.items);

                // CS向けのチャットルーム遷移情報の取得
                const cusfilter = {userdatasID: {contains: param.traineeId}};
                const cusChatroomsRes = await API.graphql({
                    query: listCustomerChatrooms,
                    variables: { filter: cusfilter, limit: 10000 },
                    authMode: AMAZON_COGNITO_USER_POOLS,
                });
                setcsChatrooms(cusChatroomsRes.data.listCustomerChatrooms.items);
                
                // 閲覧対象のトレーニーユーザーに紐づく内定情報と辞退情報を取得し、State関数に保持
                const offerFilter = {
                    userdatasID: {contains: traineeUserData.userId}
                }
                const offerRes = await API.graphql({
                    query: listOfferDatas,
                    variables: { filter: offerFilter, limit:1000 },
                    authMode: AMAZON_COGNITO_USER_POOLS,
                });
                setOffers(offerRes.data.listOfferDatas.items);
    
                const refusalOfferRes = await API.graphql({
                    query: listRefusalOfferDatas,
                    variables: { filter: offerFilter, limit:1000 },
                    authMode: AMAZON_COGNITO_USER_POOLS,
                });
                setRefOffers(refusalOfferRes.data.listRefusalOfferDatas.items);  
            }
            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
        }
    }
    
    useEffect(() => {
        let abortCtrl = new AbortController();

        initTraineeList();
        // アンマウント時処理
        return() => {
            abortCtrl.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param.traineeId]);

    return(
        <Box>
            <Header title="トレーニーリスト"/>
            <Box>
                {isLoading ?
                <LoadingIcon/>
                :
                <Box className={commonStyle.mobileModeDisplay}>
                    <Box className={commonStyle.pageContent} >
                        <CoachingTraineeList
                            trainees={traineeList}
                        />
                    </Box>
                </Box>
                }
                <Box className={commonStyle.pcModeDisplay}>
                    <SideMenu/>
                    {isLoading ?
                    <LoadingIcon/>
                    :
                    <Box>
                        <Box className={commonStyle.secondSideMenu}>
                            <Box className={commonStyle.scrollArea}>
                                <CoachingTraineeList
                                    trainees={traineeList}
                                    pcMode
                                />
                            </Box>
                        </Box>
                        <Box className={commonStyle.secondSideBarOnPageContent} >
                            {param.traineeId !== undefined?
                                isLoading ?
                                <LoadingIcon/>
                                :
                                <CoachingTraineeDetail
                                    traineeId={param.traineeId}
                                    traineeData={traineeData}
                                    previewImage={previewImage}
                                    events={events}
                                    corporations={corporations}
                                    offers={offers}
                                    refOffers={refOffers}
                                    csChatrooms={csChatrooms}
                                    pcMode
                                />
                            :
                            <Box className={localStyle.centerOfCenterContents}>
                                <Typography variant="h4">トレーニーを選択してください</Typography>
                            </Box>
                            }
                        </Box>
                    </Box>
                    }
                </Box>
            </Box>
            <Footer/>
        </Box>
    );
}
