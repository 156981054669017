import { API, Auth } from "aws-amplify";
import { listConectIsReadUnreadToNotifications } from "../../graphql/queries";
import { listNotifications } from "../../graphql/queries";
import { useEffect, useState, useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Box, Grid } from '@material-ui/core';
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { useHistory } from 'react-router-dom';
import * as Consts from '../../Commons/Consts';
import { commonStyles } from "../../Commons/CommonStyles";
import SideMenu from "../../Commons/SideMenu";

const localStyles = makeStyles(theme => ({
    pointerCursor:{
        cursor: "pointer",
        marginBottom: "0.5vh",
        border: "solid #0A0A0A 1px",
        display: "flex",
        alignItems: "normal",
    },
    raedStatus:{
        backgroundColor: "#A0A0A0",
    },
    unraedStatus:{
        backgroundColor: "#FFA0A0",
    },
    borders:{
        whiteSpace: "pre-wrap",
        border: "solid #0A0A0A 1px",
    },
}));

const SUBSCRIPTION = 'SUBSCRIPTION';
const INITIAL_QUERY = 'INITIAL_QUERY';
const ADDITIONAL_QUERY = 'ADDITIONAL_QUERY';

const notificationReducer = (state, action) => {
    switch (action.type) {
      case INITIAL_QUERY:
        return action.notifications;
      case ADDITIONAL_QUERY:
        return [...state, ...action.notifications]
      case SUBSCRIPTION:
        return [action.notifications, ...state]
      default:
        return state;
    }
};  

const readUnreadReducer = (state, action) => {
    switch (action.type) {
      case INITIAL_QUERY:
        return action.readUnread;
      case ADDITIONAL_QUERY:
        return [...state, ...action.readUnread]
      case SUBSCRIPTION:
        return [action.readUnread, ...state]
      default:
        return state;
    }
};  

// common行き
// Dateから表示表の文字列を取得
function getStringFromDate(date) {
    var year_str = date.getFullYear();
    var month_str = 1 + date.getMonth(); //月だけ+1すること
    var day_str = date.getDate();
    var hour_str = date.getHours();
    var minute_str = date.getMinutes();
    
    month_str = ('0' + month_str).slice(-2);
    day_str = ('0' + day_str).slice(-2);
    hour_str = ('0' + hour_str).slice(-2);
    minute_str = ('0' + minute_str).slice(-2);
    
    // フォーマット
    var format_str = 'YYYY-MM-DD hh:mm';
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    format_str = format_str.replace(/hh/g, hour_str);
    format_str = format_str.replace(/mm/g, minute_str);
    
    return format_str;
};

function NotificationCard( { notifData, user, readUnread } ){
    const history = useHistory();
    const localStyle = localStyles();
    const idStr = notifData.id;

    const clickEvnet = () => {
        const notifiUrl = Consts.URL_PATH_NOTIFICATIONS + Consts.URL_PARTS_SLASH + idStr;
        history.push(notifiUrl);
    };

    return (
        <Grid 
            container spacing={0}
            justifycontent="center"
            alignItems="center"
            className={localStyle.pointerCursor}
            onClick={clickEvnet}
        >
            {readUnread
                .filter(data => { return data.notificationsID === notifData.id})
                .filter(data => { return data.userdatasID === user.username})
                .length <= 0 ?
                <Grid item xs={1} className={localStyle.unraedStatus}/>
                :
                <Grid item xs={1} className={localStyle.raedStatus}/>
            }
            <Grid item xs={11} >
                <Container>
                    <Typography variant="caption">
                        {getStringFromDate(new Date(notifData.notificationDateTime))}
                    </Typography>
                    <Typography>
                        {notifData.notificationTitle}
                    </Typography>                                
                </Container>
            </Grid>
        </Grid>
    );
}

export default function Notifications(){
    const commonStyle = commonStyles
    
    const [user, setUser] = useState();
    const getUser = async () => {
        const attributes = await Auth.currentAuthenticatedUser();
        setUser(attributes);
    }
    
    const [notifications, dispatch] = useReducer(notificationReducer, []);
    const getNotifications = async (type) =>{
        const res = await API.graphql({
            query: listNotifications,
            variables: { limit: 1000 },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
        });        
        dispatch({ type: type, notifications:res.data.listNotifications.items });
    }
    
    const [readUnread, readUnreadispatch] = useReducer(readUnreadReducer, []);
    const getReadUnread = async (type) =>{
        const res = await API.graphql({
            query: listConectIsReadUnreadToNotifications,
            variables: { limit: 1000 },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
        });
        readUnreadispatch({ type: type, readUnread:res.data.listConectIsReadUnreadToNotifications.items });
    }
    
    useEffect(() => {
        getUser();
        getNotifications(INITIAL_QUERY);
        getReadUnread(INITIAL_QUERY);
        // アンマウント時処理
        return;
    }, []);

    return(
        <Box>
            <Header title="通知一覧"/>
                <Box>
                    <SideMenu/>
                    <Container className={commonStyle.pageContent} >
                        <Box pt={2}>
                            {notifications
                            .sort((a, b) => {return a.notificationDateValue < b.notificationDateValue ? 1 : -1})
                            .map(data => (
                                <NotificationCard
                                    notifData={data}
                                    user={user}
                                    readUnread={readUnread}
                                />
                            ))}
                        </Box>
                    </Container>
                </Box>
            <Footer/>
        </Box>
    );
}

