// React
import { 
    Fragment,
    useEffect, 
    useState, 
} from 'react';
import { useHistory } from 'react-router-dom';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { 
    Container, 
    Box, 
    Button, 
} from '@material-ui/core';

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { 
    CORPORATION_MANAGEMENT_HEADER_TITLE,
    TODAYS_SHOTA_ARAI_HEADER_TITLE,
    URL_PATH_CORPORATION_MASTER_MANAGEMENT,
    URL_PATH_TRAINEE_MASTER_MANAGEMENT,
    URL_PATH_REGISTRATION,
    URL_PATH_TODAYS_WORDS_MASTER_MANAGEMENT,
    USER_ROLE,
    HEADER_TITLE_TRAINEE_DATA_MANAGEMENT,
    AMAZON_COGNITO_USER_POOLS,
    USER_TYPE,
    HEADER_TITLE_MASTER_MANAGEMENT,
    URL_PATH_CS_MODULE_TEST_1,
    CustomerChatroomTypes,
    HEADER_TITLE_COACH_DATA_MANAGEMENT,
    URL_PATH_COACH_MASTER_MANAGEMENT,
    URL_PATH_CS_CONNECT_MANAGEMENT,
    HEADER_TITLE_TRAINEE_MASTER_MANAGEMENT,
    HEADER_TITLE_COACH_MASTER_MANAGEMENT,
    HEADER_TITLE_COACH_ASSGIN_MANAGEMENT,
    HEADER_TITLE_TODAY_WORDS_MASTER_MANAGEMENT,
    HEADER_TITLE_CORPORATION_MASTER_MANAGEMENT,
    INITIAL_QUERY, 
} from '../../Commons/Consts';
import { 
    getCurrentUserData, 
    redirectForUserRoleToRedorectPages, 
    consoleLog, 
} from "../../Commons/CommonFunctions";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import { commonStyles } from "../../Commons/CommonStyles";
import SideMenu from "../../Commons/SideMenu";
import { listChatrooms, listCoachDatas, listCustomerChatrooms, listTraineeDatas, listUserDatas } from '../../graphql/queries';
import { API } from 'aws-amplify';
import { updateChatrooms, updateCoachDatas, updateCustomerChatrooms, updateTraineeDatas } from '../../graphql/mutations';
import CommonDialog from '../../Commons/CommonDialog';
import { userDataReducer, userReducer } from '../../Commons/Reducers';
import { useReducer } from 'react';

const localStyles = makeStyles(theme => ({
    avater:{ 
        width: 64, 
        height: 64
    },
    profileButton:{
        height: 50,
        marginBottom:10,
    },
    userName:{
        fontSize: "5vmin",
    },
    universityName:{
        fontSize: "4vmin",
    },
}));

export default function MasterManagement(){
    const history = useHistory();
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    
    const [userDataObj, setUserData] = useState([]);
    const [userData, dispatchCurrentUserData] = useReducer(userDataReducer, []);
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);
    
    const setInitData = async () =>{
        try {
            // Get a specific item
            const currentUserData = await getCurrentUserData();
            redirectForUserRoleToRedorectPages(history, currentUserData, USER_ROLE.CustomerSuccess);
            dispatchCurrentUserData({ type: INITIAL_QUERY, userData: currentUserData });
            
            setUserData(currentUserData);
            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
        }
    }

    const initDatas = async () =>{
        const cusChatRoomRes = await API.graphql({
            query: listCustomerChatrooms,
            variables: { limit:100000 },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
        for(const cusChatroom of cusChatRoomRes.data.listCustomerChatrooms.items){
            if(cusChatroom.userdatasType === USER_TYPE.TRAINEE &&
                cusChatroom.roomType === CustomerChatroomTypes.CUSTOMER_SUCCESS){
                consoleLog(userData.userRole, cusChatroom);
                const filter = {userdatasID: {contains: cusChatroom.userdatasID }};
                const traRes = await API.graphql({
                    query: listTraineeDatas,
                    variables: { filter:filter, limit:100000 },
                    authMode: AMAZON_COGNITO_USER_POOLS,
                });
                consoleLog(userData.userRole, traRes);
                const res = await API.graphql({
                    query: updateTraineeDatas,
                    variables: {
                        input: {
                            id: traRes.data.listTraineeDatas.items.at(0).id,
                            tag: cusChatroom.tag,
                        }, 
                    },
                });
                consoleLog(userData.userRole, res);     
            }
            else if(cusChatroom.userdatasType === USER_TYPE.COACH &&
                cusChatroom.roomType === CustomerChatroomTypes.CUSTOMER_SUCCESS){
                const filter = {userdatasID: {contains: cusChatroom.userdatasID }};
                const coaRes = await API.graphql({
                    query: listCoachDatas,
                    variables: { filter:filter, limit:100000 },
                    authMode: AMAZON_COGNITO_USER_POOLS,
                });
                const res = await API.graphql({
                    query: updateCoachDatas,
                    variables: {
                        input: {
                            id: coaRes.data.listCoachDatas.items.at(0).id,
                            tag: cusChatroom.tag,
                        }, 
                    },
                });
                consoleLog(userData.userRole, res);     
            }            
        }
    }

    useEffect(() => {
        // アンマウント時処理
        let abortCtrl = new AbortController();

        setInitData();
        return() => {
            abortCtrl.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header title={HEADER_TITLE_MASTER_MANAGEMENT}/>
            <SideMenu/>
            {isLoading?
            <LoadingIcon/>
            :
            <Box className={commonStyle.pageContent} >
                <Box pt={2}>
                    <Container>
                        <Button 
                            variant="contained"
                            color="primary"
                            fullWidth
                            className={localStyle.profileButton}
                            onClick={()=>{history.push(URL_PATH_CS_CONNECT_MANAGEMENT);}}>
                            {HEADER_TITLE_COACH_ASSGIN_MANAGEMENT}
                        </Button>
                        {userDataObj.userRole === USER_ROLE.SuperAdministrator ?
                        <div>
                            <Button 
                                variant="contained"
                                color="primary"
                                fullWidth
                                className={localStyle.profileButton}
                                onClick={()=>{history.push(URL_PATH_TODAYS_WORDS_MASTER_MANAGEMENT);}}>
                                {HEADER_TITLE_TODAY_WORDS_MASTER_MANAGEMENT}
                            </Button>
                            <Button 
                                variant="contained"
                                color="primary"
                                fullWidth
                                className={localStyle.profileButton}
                                onClick={()=>{history.push(URL_PATH_CORPORATION_MASTER_MANAGEMENT);}}>
                                {HEADER_TITLE_CORPORATION_MASTER_MANAGEMENT}
                            </Button>                            
                            <Button 
                                variant="contained"
                                color="primary"
                                fullWidth
                                className={localStyle.profileButton}
                                onClick={()=>{history.push(URL_PATH_TRAINEE_MASTER_MANAGEMENT);}}>
                                {HEADER_TITLE_TRAINEE_MASTER_MANAGEMENT}
                            </Button>
                            <Button 
                                variant="contained"
                                color="primary"
                                fullWidth
                                className={localStyle.profileButton}
                                onClick={()=>{history.push(URL_PATH_COACH_MASTER_MANAGEMENT);}}>
                                {HEADER_TITLE_COACH_MASTER_MANAGEMENT}
                            </Button>
                            <Button 
                                variant="contained"
                                color="primary"
                                fullWidth
                                className={localStyle.profileButton}
                                onClick={()=>{history.push(URL_PATH_REGISTRATION);}}>
                                テスト用初期登録画面
                            </Button>
                            <Button 
                                variant="contained"
                                color="primary"
                                fullWidth
                                className={localStyle.profileButton}
                                onClick={() => {setOpen(true)}}>
                                チャットルーム最適化
                            </Button>
                            <Button 
                                variant="contained"
                                color="primary"
                                fullWidth
                                className={localStyle.profileButton}
                                onClick={()=>{history.push(URL_PATH_CS_MODULE_TEST_1);}}>
                                モジュールテスト画面1
                            </Button>
                        </div>
                        :
                        <Fragment></Fragment>}
                    </Container>
                    <Box className={commonStyle.endBox}/>
                </Box>
            </Box>
            }
            <CommonDialog
                agreeEvent={initDatas}
                open={open}
                setOpen={setOpen}
                caption="チャットルーム最適化"
                contentText="チャットルームの最適化を実施しますか？"
                cancelText="実施しない"
                agreeText="実施する"
            />
            <Footer/>
        </Box>
    );
}
