// AWS
import { API } from "aws-amplify";

// GraphQL
import { listTaskDatas, listTasktimeManagementDatas, listTodayWords, listTraineeDatas } from "../../graphql/queries";
import { onUpdateTasktimeManagementDatas } from '../../graphql/subscriptions';

// React
import { 
    Fragment,
    useEffect, 
    useReducer,
    useState, 
} from 'react';
import { 
    useParams, 
} from 'react-router-dom';

// React-Calendar
import 'react-calendar/dist/Calendar.css';

// Material=ui
import { makeStyles } from '@material-ui/core/styles';
import { 
    Box, 
    Container, 
    Grid, 
    Typography, 
} from '@material-ui/core';

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { 
    AMAZON_COGNITO_USER_POOLS,
    ERROR_TYPE_ABORTERROR,
    HEADER_TITLE_TASKTIMES,
    INITIAL_QUERY,
    SUBSCRIPTION_UPDATE,
    TODAYS_SHOTA_ARAI_HEADER_TITLE,
} from '../../Commons/Consts';
import { 
    isTraineeIdReturnToCurrentUserIdOrTraineeUserId, 
    getCurrentUserData, 
    LineTextComponent,
    recursiveGrapheql,
    testrecursiveGrapheql, 
    consoleLog, 
} from '../../Commons/CommonFunctions';
import { 
    commonDBDataReducer,
    ttmReducer,
    userDataReducer,
} from "../../Commons/Reducers";
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import SideMenu from "../../Commons/SideMenu";
import TasktimeCalendar from "../../components/TaskTimeManegement/TasktimeCalendar";
import styled from 'styled-components';
import PCTaskTimeManagementEditor from "../../components/TaskTimeManegement/PCTaskTimeManagementEditor";

const useStyles = makeStyles(theme => ({
    calenderTile:{
        minHeight: "5vh",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    dayTile:{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    fullHeight:{
        height: "100%",
    },
    calendarBox:{
        maxHeight: "100%",
        marginRight: "5px",
        marginLeft: "5px",
    },
    contentsBox:{
        backgroundColor: theme.palette.background.boxPaper,
        height: "100vh",
        marginLeft: "5px",
        paddingRight: "1%",
        paddingLeft: "1%",
    },
    todayWordsCard:{
        borderRadius: "5px",
        paddingTop: "5px",
        paddingRight: "5px",
        paddingLeft: "4px",
        paddingBottom: "5px",
        margin: "3px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        overflowY: "auto",
        [theme.breakpoints.down('sm')]: {
            '& .MuiTypography-h4':{
                fontSize: "4vmin",
                fontWeight: "bold",
            },
            '& .MuiTypography-h5':{
                fontSize: "4vmin",
            },
            '& .MuiTypography-h6':{
                fontSize: "3vmin",
                wordBreak: "break-word",
                maxHeight: "10vh",
                overflow: "scroll",
            }
        },
        [theme.breakpoints.up('md')]: {
            '& .MuiTypography-h4':{
                fontSize: "3vmin",
                fontWeight: "bold",
            },
            '& .MuiTypography-h5':{
                fontSize: "3vmin",
            },
            '& .MuiTypography-h6':{
                fontSize: "2vmin",
                wordBreak: "break-word",
                maxHeight: "190px",
                overflow: "scroll",
            }
        },
    },
    scrolldown4:{
        /*描画位置※位置は適宜調整してください*/
        position:"fixed",
        bottom:"1%",
        /*矢印の動き1秒かけて永遠にループ*/
        animation:"arrowmove 1s ease-in-out infinite",
    },
    calendarArea:{
        maxHeight: "50vh",
    }
}));

const TodaysWords = styled.div`
    /* ~~~ container styles ~~~ */
    .MuiTypography-h6{
        /*要素の高さを指定*/
        height: 30vh;
        max-height: 40%;
        /*上下方向にはみ出した要素ををスクロールさせる*/
        overflow-y: scroll;
        /*スクロールバー非表示（IE・Edge）*/
        -ms-overflow-style: none;
        /*スクロールバー非表示（Firefox）*/
        scrollbar-width: none;
    }
    /*スクロールバー非表示（Chrome・Safari）*/
    .MuiTypography-h6::-webkit-scrollbar{
        display:none;
    }
`;

export default function TaskTimeManagement(){
    const param = useParams();
    const commonStyle = commonStyles();
    const localStyle = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [ttmData, dispatchTtmData] = useReducer(commonDBDataReducer, []);
    const [ttms, dispatchTtms] = useReducer(commonDBDataReducer, []);
    const [tasks, dispatchTasks] = useReducer(commonDBDataReducer, []);
    const [todaysWordsData, dispatchTodaysWordsData] = useReducer(commonDBDataReducer, []);
    const [userData, dispatchUserData] = useReducer(userDataReducer, []);
    const initTaskTimeManagement = async () =>{
        try {
            // URLパラメータtraineeIdがある場合はコーチがトレーニーのデータを見ていることになる
            const currentUserData = await getCurrentUserData();
            dispatchUserData({ type: INITIAL_QUERY, userData: currentUserData });     
            const checkUserId = isTraineeIdReturnToCurrentUserIdOrTraineeUserId(param.traineeId, currentUserData.userId);
            
            // 閲覧トレーニーユーザー、または閲覧コーチユーザーの担当トレーニーのタスクタイムマネジメント管理データを検索し、State変数へ保持
            const filter = { userDatasID: {contains: checkUserId} }
            const ttms = await testrecursiveGrapheql(listTasktimeManagementDatas, 'listTasktimeManagementDatas', { filter: filter , limit:100000 , nextToken: null}, dispatchTtms);
            if(param.ttmid !== undefined){
                dispatchTtmData({ type: INITIAL_QUERY, data:ttms.find((t) => t.id === param.ttmid) })
                const tassFilter = {
                    parentTasktimeManagementDatasID: {contains:param.ttmid},
                }
                await recursiveGrapheql(listTaskDatas, 'listTaskDatas', { filter: tassFilter , limit:100000 , nextToken: null}, dispatchTasks);
            }
            
            // 新井翔太の今日の一言を取得
            const traineeFilter = { userdatasID: {contains: checkUserId} }
            const traineeRes = await API.graphql({
                query: listTraineeDatas,
                variables: { filter: traineeFilter , limit:10000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            const traineeData = traineeRes.data.listTraineeDatas.items;
            
            const wordsRes = await API.graphql({
                query: listTodayWords,
                variables: { limit: 10000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            if(0 < wordsRes.data.listTodayWords.items.length){
                var word = wordsRes.data.listTodayWords.items.find((word) => word.displayNumber === 1);
                if(0 < traineeData.length){
                    dispatchTodaysWordsData({ type: INITIAL_QUERY, data:word});
                    const curriculumStartDate = new Date(traineeData.at(0).curriculumStartDate);
                    const nowDate = new Date();
                    const diffDays = parseInt((nowDate - curriculumStartDate) / 1000 / 60 / 60 / 24);
                    if(diffDays <= 1){
                        word = wordsRes.data.listTodayWords.items.find((word) => word.displayNumber === 1);
                    }else if(diffDays <= 90){
                        word = wordsRes.data.listTodayWords.items.find((word) => word.displayNumber === diffDays);
                    }else{
                        word = wordsRes.data.listTodayWords.items.find((word) => word.displayNumber === 90);
                    }
                }
                dispatchTodaysWordsData({ type: INITIAL_QUERY, data:word});
            }
            
            setIsLoading(false);
        } catch (e) {
            if (e.name === ERROR_TYPE_ABORTERROR) {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }
    
    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();
        initTaskTimeManagement();

        // サブスクリプション登録
        const updateTtmSubscription = API.graphql({
            query:onUpdateTasktimeManagementDatas,
            authMode: AMAZON_COGNITO_USER_POOLS// 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
            next: (msg) => {
                const ttm = msg.value.data.onUpdateTasktimeManagementDatas;
                dispatchTtms({ type: SUBSCRIPTION_UPDATE, data:ttm });
            }
        });

        // アンマウント時処理
        return() => {
            abortCtrl.abort();
            updateTtmSubscription.unsubscribe();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param.ttmid]);

    
    
    return(
        <Box>
            <Header title={HEADER_TITLE_TASKTIMES}/>
            <Box>
                <Box className={commonStyle.mobileModeDisplay}>
                    {isLoading ?
                    <LoadingIcon/>
                    :
                    <Box className={commonStyle.pageContent} >
                        {0 < todaysWordsData.length ?
                        <Box className={localStyle.todayWordsCard} >
                            <Typography variant="h5">{TODAYS_SHOTA_ARAI_HEADER_TITLE}</Typography>
                            <Typography variant="h4">{todaysWordsData.caption}</Typography>
                            <Typography variant="h6">
                                <LineTextComponent msg={todaysWordsData.contentText} />
                            </Typography>
                        </Box>
                        :
                        <Fragment></Fragment>
                        }
                        <Container>
                            {param.traineeId !== undefined ?
                                <TasktimeCalendar ttm={ttms} userData={userData} traineeId={param.traineeId}/>
                            :
                                <TasktimeCalendar ttm={ttms} userData={userData}/>
                            }
                        </Container>
                        <Box className={commonStyle.endBox} />
                    </Box>
                    }
                </Box>
                <Box className={commonStyle.pcModeDisplay}>
                    <SideMenu/>
                    {isLoading ?
                    <LoadingIcon/>
                    :
                    <Box className={commonStyle.pageContent} >
                        <Grid container className={localStyle.fullHeight} >
                            <Grid item xs={5}>
                                <Box className={localStyle.calendarBox}>
                                    <Box className={localStyle.calendarArea}>
                                        {param.traineeId !== undefined ?
                                            <TasktimeCalendar ttm={ttms} userData={userData} traineeId={param.traineeId} pcMode selectDate={param.timestamp} />
                                        :
                                            <TasktimeCalendar ttm={ttms} userData={userData} pcMode selectDate={param.timestamp}/>
                                        }
                                    </Box>
                                    <TodaysWords>
                                        <Box className={localStyle.todayWordsCard} >
                                            <Typography variant="h5">{TODAYS_SHOTA_ARAI_HEADER_TITLE}</Typography>
                                            <Typography variant="h4">{todaysWordsData.caption}</Typography>
                                            <Typography variant="h6">
                                                <LineTextComponent msg={todaysWordsData.contentText} />
                                            </Typography>
                                            <Box className={localStyle.scrolldown4}>▼</Box>
                                        </Box>
                                    </TodaysWords>
                                </Box>
                            </Grid>
                            <Grid item xs={7}>
                                <Box className={localStyle.contentsBox}>
                                    {param.ttmid !== undefined ?
                                    <PCTaskTimeManagementEditor
                                        tasks={tasks}
                                        dispatchTasks={dispatchTasks}
                                        ttm={ttmData}
                                        dispatchTtm={dispatchTtmData}
                                        userData={userData}
                                        timestamp={param.timestamp}
                                        ttmId={param.ttmid}
                                    />
                                    :
                                    <Fragment></Fragment>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    }
                </Box>
            </Box>
            <Footer/>
        </Box>
    );
}
