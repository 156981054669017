// AWS
import { API } from "aws-amplify";

// GraphQL
import { 
    updateEventDetialDatas, 
    createDetialAnswerHistories, 
} from '../../graphql/mutations';

// React
import { 
    useState, 
    useReducer,
    Fragment, 
} from 'react';

// Material-ui
import { makeStyles } from '@material-ui/core/styles';
import { 
    Typography, 
    Container, 
    Box, 
    Grid, 
    Button, 
    TextField, 
    Collapse, 
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

// Self Making Files
import { 
    BUTTON_CAPTION_SAVE,
    DETAIL_EDITOR_CARD_CAPTION_ANSWERS_HISTORY,
    DETAIL_EDITOR_CARD_CAPTION_ANSWERS_HISTORY_CHECK,
    DETAIL_EDITOR_CARD_CHAR_COUNT,
    DETAIL_EDITOR_CARD_HELPER_TEXT,
    DETAIL_EDITOR_CARD_NUMBER_CAPTION,
    EVEMT_DETAIL_ANSWER,
    EVENT_DETAIL_CAPTION,
    FORMAT_STRING_TZDATESTRING, 
    INITIAL_QUERY, 
    UPDATE,
    USER_ROLE,
} from "../../Commons/Consts";
import { 
    eventDetailReducer,
} from "../../Commons/Reducers";
import { getStringFromDateForFormatString } from "../../Commons/CommonFunctions";
import { AnswersList } from "./AnswersList";
import { commonStyles } from "../../Commons/CommonStyles";

const localStyles = makeStyles(theme => ({
    bordersBlack:{
        border: "solid #0A0A0A 0.1px",
    },
    charCounter: {
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-start",
        flexWrap: "nowrap",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        fontSize: "4vmin",
    },
    leftTypography: {
        display: "flex",
        flexDirection: "row",
        alignContent: "flex-start",
        flexWrap: "nowrap",
        justifyContent: "flex-end",
        alignItems: "center",
        fontSize: "4vmin",
    },
}));

function QuestionCardListItem({ eventDetail, index, userRole, readOnly=false }){
    const localStyle = localStyles();
    const [charCount, setCharCount] = useState(eventDetail.answer.length);
    const [open, setOpen] = useState(false);
    const [itemEventDetail, setItemEventDetail] = useReducer(eventDetailReducer, eventDetail);
    
    const handleClick = () => {
        setOpen(!open);
    }

    const updateCaptionAndAnwer = async () =>{
        const res = await API.graphql({
            query: updateEventDetialDatas,
            variables: {
                input: {
                    id: itemEventDetail.id,
                    answer: itemEventDetail.answer,
                    caption: itemEventDetail.caption,
                }        
            }
        });
        setItemEventDetail({ type:INITIAL_QUERY, detail:res.data.updateEventDetialDatas });
    }

    const updateEventDetialData = async () =>{
        // 
        const updateRes = await API.graphql({
            query: updateEventDetialDatas,
            variables: {
                input: {
                    id: itemEventDetail.id,
                    answer: itemEventDetail.answer,
                    caption: itemEventDetail.caption,
                }        
            }
        });
        setItemEventDetail({ type: INITIAL_QUERY, detail: updateRes.data.updateEventDetialDatas });

        // 
        const createdDate = new Date();
        await API.graphql({
            query: createDetialAnswerHistories,
            variables: {
                input: {
                    answer: itemEventDetail.answer,
                    answerDateTime: getStringFromDateForFormatString(createdDate, FORMAT_STRING_TZDATESTRING),
                    answerTimestamp: createdDate.valueOf(),
                    comment: '',
                    parentEventDetialDatasID: itemEventDetail.id,
                }        
            }
        });
    }

    const saveButtonClick = () =>{
        updateEventDetialData();
    };

    const captionChange = (event) =>{
        setItemEventDetail({ type:UPDATE, updateName:EVENT_DETAIL_CAPTION, updateValue:event.target.value });
    };

    const answerChange = (event) =>{
        setItemEventDetail({ type:UPDATE, updateName:EVEMT_DETAIL_ANSWER, updateValue:event.target.value });
        setCharCount(event.target.value.length);
    };

    return(
        <div>
            <Grid container spacing={0}>
                <Grid item xs={12} className={localStyle.borders}>
                    {userRole === USER_ROLE.Coach || readOnly?
                    <TextField
                        fullWidth
                        label={DETAIL_EDITOR_CARD_NUMBER_CAPTION+(index+1)}
                        helperText={DETAIL_EDITOR_CARD_HELPER_TEXT}
                        InputLabelProps={{ shrink: true }}
                        value={itemEventDetail.caption}
                        InputProps={{ readOnly: true }}
                    />
                    :
                    <TextField
                        fullWidth
                        label={DETAIL_EDITOR_CARD_NUMBER_CAPTION+(index+1)}
                        helperText={DETAIL_EDITOR_CARD_HELPER_TEXT}
                        InputLabelProps={{ shrink: true }}
                        value={itemEventDetail.caption}
                        onChange={captionChange}
                        onBlur={updateCaptionAndAnwer}
                    />
                    }
                </Grid>
            </Grid>
            <Box pt={1}></Box>
            <Grid container spacing={0}>
                <Grid item xs={8}>
                </Grid>
                <Grid item xs={4}>
                    <Typography className={localStyle.charCounter}>{DETAIL_EDITOR_CARD_CHAR_COUNT + charCount}</Typography>
                </Grid>
            </Grid>
            <Grid container>
                {userRole === USER_ROLE.Coach || readOnly?
                <TextField
                    id="outlined-multiline-flexible"
                    fullWidth
                    multiline
                    value={itemEventDetail.answer}
                    InputProps={{ readOnly: true }}
                />
                :
                <TextField
                    id="outlined-multiline-flexible"
                    fullWidth
                    multiline
                    maxRows={20}
                    value={itemEventDetail.answer}
                    onChange={answerChange}
                    onBlur={updateCaptionAndAnwer}
                />
                }
            </Grid>
            <Box pt={1}></Box>
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
            >
                <Grid item xs={5}></Grid>
                <Grid item xs={2}>
                    {readOnly?
                    <Fragment></Fragment>
                    :
                    <Button
                        onClick={saveButtonClick}
                        variant="contained"
                        color="primary"
                    >
                        {BUTTON_CAPTION_SAVE}
                    </Button>
                    }
                </Grid>
                <Grid item xs={5}></Grid>  
            </Grid>
            <Box pt={3}></Box>
            <Collapse id="answer_detial" in={open} timeout="auto">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    rowSacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                        <Container className={commonStyles.centerContents}>
                            <Typography>{DETAIL_EDITOR_CARD_CAPTION_ANSWERS_HISTORY}</Typography>
                        </Container>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <AnswersList
                        parentEventDetail={itemEventDetail}
                        userRole={userRole}
                        readOnly={readOnly}
                    />
                </Grid>
            </Collapse>
            <Grid container>
                <Grid item xs={3}>
                </Grid>
                <Grid container xs={9} onClick={handleClick} className={localStyle.leftTypography}>
                    {open ?  <ExpandLessIcon /> : <ExpandMoreIcon /> }
                    <Typography variant="caption" >{DETAIL_EDITOR_CARD_CAPTION_ANSWERS_HISTORY_CHECK}</Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export function QuestionCardList({ eventDetails, userRole, readOnly=false }){
    const localStyle = localStyles();
    return (
        <div>
        {eventDetails.sort((a, b) => {return a.createdAt < b.createdAt ? -1 : 1}).map((data, index) => (
            <Box className={localStyle.bordersBlack}>
                <Container>
                    <QuestionCardListItem
                        eventDetail={data}
                        index={index}
                        userRole={userRole}
                        readOnly={readOnly}
                    />
                </Container>
            </Box>
        ))}
        </div>
    ); 
}
