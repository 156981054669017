// AWS
import { API } from "aws-amplify";

// GraphQL
import { onCreateDetialAnswerHistories } from '../../graphql/subscriptions';

// React
import { 
    useEffect, 
    useReducer, 
    useState,
} from 'react';

// Material-ui
import { 
    Typography, 
    Grid, 
    TextField,
} from '@material-ui/core';

// Self Making Files
import { 
    AMAZON_COGNITO_USER_POOLS, 
    ANSWER_CARD_CAPTION_COACH_COMMENT, 
    FORMAT_STRING_YYYYMMDDHHMM, 
    SUBSCRIPTION,
    USER_ROLE,
} from "../../Commons/Consts";
import { 
    answersReducer, 
} from "../../Commons/Reducers";
import { 
    getStringFromDateForFormatString, 
    ReadOnlyContent, 
} from "../../Commons/CommonFunctions";
import { updateDetialAnswerHistories } from "../../graphql/mutations";
import { commonStyles } from "../../Commons/CommonStyles";

function AnswersListItem({ data, userRole, readOnly=false }){
    const [coachComment, setCoachComment] = useState(data.comment);
    const updateCoachComment = async () =>{
        await API.graphql({
            query: updateDetialAnswerHistories,
            variables: { 
                input: {
                    id: data.id, 
                    comment: coachComment,
                },
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
        setCoachComment(coachComment);
    };

    return (<Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={commonStyles.borders}
        rowSacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
        <Grid container>
            <Typography variant="caption">{getStringFromDateForFormatString(new Date(data.answerDateTime), FORMAT_STRING_YYYYMMDDHHMM)}の回答</Typography>
        </Grid>
        <Grid container>
            <TextField
                id="trainee-anser"
                fullWidth
                multiline
                maxRows={20}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    readOnly: true,
                }}
                value={data.answer}
            />
            {userRole !== USER_ROLE.Coach || !readOnly ? 
            <ReadOnlyContent>
                <TextField
                    id="coach-comment"
                    fullWidth
                    multiline
                    disabled
                    maxRows={20}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        readOnly: true,
                    }}
                    label={ANSWER_CARD_CAPTION_COACH_COMMENT}
                    value={coachComment}
                />
            </ReadOnlyContent>
            :
            <TextField
                id="coach-comment"
                fullWidth
                multiline
                maxRows={20}
                InputLabelProps={{ shrink: true }}
                label={ANSWER_CARD_CAPTION_COACH_COMMENT}
                value={coachComment}
                onChange={(event) => {setCoachComment(event.target.value)}}
                onBlur={updateCoachComment}
            />}
        </Grid>
    </Grid>);
}

export function AnswersList({ parentEventDetail, userRole, readOnly=false }){
    const [answers, dispatchAnswers] = useReducer(answersReducer, parentEventDetail.DetialAnswerHistories.items);
    useEffect(() => {
        const detailAnswerHistoriesSubscription = API.graphql({
            query:onCreateDetialAnswerHistories,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
            next: (msg) => {
                const answer = msg.value.data.onCreateDetialAnswerHistories;
                if(answer.parentEventDetialDatasID === parentEventDetail.id){
                    dispatchAnswers({ type: SUBSCRIPTION, answers: answer });
                }

            }
        });
        
        return () => {detailAnswerHistoriesSubscription.unsubscribe()};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <div>
        {answers.sort((a, b) => {return a.answerTimestamp < b.answerTimestamp ? 1 : -1 }).map(data => (
            <AnswersListItem
                data={data}
                userRole={userRole}
                readOnly={readOnly}
            />
        ))}
        </div>
    );
}
