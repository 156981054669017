// AWS
import { API } from "aws-amplify";

// GraphQL
import { 
    listScheduleEvents, 
    getCorporations,
    listRefusalOfferDatas,
    listOfferDatas, 
} from "../../graphql/queries";
import { deleteOfferDatas, deleteRefusalOfferDatas, updateOfferDatas, updateScheduleEvents } from "../../graphql/mutations";

// React
import { 
    useEffect, 
    useState, 
    useReducer,
    Fragment,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Material-ui
import { 
    Box, 
    Grid, 
    Button, 
} from '@material-ui/core';

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { 
    AMAZON_COGNITO_USER_POOLS, 
    BUTTON_CAPTION_OFFER_CONFIRM, 
    BUTTON_CAPTION_OFFER_DELETE, 
    BUTTON_CAPTION_REF_OFFER_DELETE, 
    DETAIL_OFFER_COMPLETE_HEADER_TITLE, 
    DETAIL_OFFER_HEADER_TITLE, 
    DETAIL_REF_OFFER_HEADER_TITLE, 
    DIALOG_AGREE_TEXT_CONSENT_CONFIRM, 
    DIALOG_AGREE_TEXT_OFFER_DELETE_CONFIRM, 
    DIALOG_AGREE_TEXT_REF_OFFER_DELETE_CONFIRM, 
    DIALOG_CANCEL_TEXT_CONSENT_CONFIRM, 
    DIALOG_CANCEL_TEXT_OFFER_DELETE_CONFIRM, 
    DIALOG_CANCEL_TEXT_REF_OFFER_DELETE_CONFIRM, 
    DIALOG_CAPTION_CONSENT_CONFIRM, 
    DIALOG_CAPTION_OFFER_DELETE_CONFIRM, 
    DIALOG_CAPTION_REF_OFFER_DELETE_CONFIRM, 
    DIALOG_CONTENT_TEXT_CONSENT_CONFIRM, 
    DIALOG_CONTENT_TEXT_OFFER_DELETE_CONFIRM, 
    DIALOG_CONTENT_TEXT_REF_OFFER_DELETE_CONFIRM, 
    ERROR_TYPE_ABORTERROR, 
    EVENT_TYPE, 
    INITIAL_QUERY, 
    SUBSCRIPTION, 
    URL_PARTS_SLASH, 
    URL_PATH_MYPAGE_OFFER_LIST, 
    URL_PATH_SCHEDULE_DETAIL, 
    USER_ROLE 
} from '../../Commons/Consts';
import { 
    getCurrentUserData, 
    redirectForUserRoleToRedorectPages, 
    consoleLog, 
} from "../../Commons/CommonFunctions";
import { 
    eventReducer, 
    corporationsReducer,
    userDataReducer, 
} from "../../Commons/Reducers";
import { EvnetDataCard } from "../../components/SelectionSituationCalender/EventDataCard";
import ConfirmDialog from "../../components/SelectionSituationCalender/ConfirmDialog";
import { onCreateScheduleEvents } from "../../graphql/subscriptions";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import { commonStyles } from "../../Commons/CommonStyles";
import SideMenu from "../../Commons/SideMenu";

export default function SelectionDetailReadonly(){
    const commonStyle = commonStyles();
    const param = useParams();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [refOfferDeleteConfirmOpen, setRefOfferDeleteConfirmOpen] = useState(false);
    const [offerDeleteConfirmOpen, setOfferDeleteConfirmOpen] = useState(false);
    const [offerConsentConfirmOpen, setOfferConsentConfirmOpen] = useState(false);

    const [userData, dispatchCurrentUserData] = useReducer(userDataReducer, []);
    const [corporations, dispatchCorporations] = useReducer(corporationsReducer, []);
    const [events, dispachEventData] = useReducer(eventReducer, []);
    const [offer, setOffer] = useState(0);
    const [refOffer, setRefOffer] = useState(0);
    
    const initSelectionDetail = async () =>{
        try{
            // ユーザーロールに基づき見てはいけないユーザーロールはリダイレクト
            const currentUserData = await getCurrentUserData();
            redirectForUserRoleToRedorectPages(history, currentUserData, USER_ROLE.Trainee);
            dispatchCurrentUserData({ type: INITIAL_QUERY, userData: currentUserData });

            // 閲覧ユーザーに関わる選考状況イベントデータリストをクエリにて取得し、State変数へ保持
            const filter = {
                userDatasID: {contains: currentUserData.userId},
                corporationsID: {contains: param.corporationId}
            }
            const eventRes = await API.graphql({
                query: listScheduleEvents,
                variables: { filter: filter, limit:1000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            dispachEventData({ type: INITIAL_QUERY, events:eventRes.data.listScheduleEvents.items });
            
            // 企業情報データテーブルからURLパラメータのIDを基に企業情報データを取得し、State変数へ保持
            const corpRes = await API.graphql({
                query: getCorporations,
                variables: { id: param.corporationId, limit:10000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            dispatchCorporations({ type: INITIAL_QUERY, corporations:corpRes.data.getCorporations });
            
            const offerFilter = {
                userdatasID: {contains: currentUserData.userId},
                corporationsID: {contains: param.corporationId},
            }
            const offersRes = await API.graphql({
                query: listOfferDatas,
                variables: { filter: offerFilter, limit:1000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            setOffer(offersRes.data.listOfferDatas.items);
            
            const refOffersRes = await API.graphql({
                query: listRefusalOfferDatas,
                variables: { filter: offerFilter, limit:1000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            setRefOffer(refOffersRes.data.listRefusalOfferDatas.items);
            
            // プログレス表示を停止
            setIsLoading(false);
        } catch (e) {
            if (e.name === ERROR_TYPE_ABORTERROR) {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }

    const offerConsent = async () =>{
        await API.graphql({
            query: updateOfferDatas,
            variables: {
                input: { 
                    id: offer[0].id,
                    offerStatus: 4,
                }
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        })
        setOfferConsentConfirmOpen(false);
        history.replace(URL_PATH_MYPAGE_OFFER_LIST);
    }

    const offerUnlock = async () =>{
        const targetEventId = offer[0].offerDatasScheduleEventsId;
        await API.graphql({
            query: updateScheduleEvents,
            variables: { 
                input: {
                    id: targetEventId, 
                    status: EVENT_TYPE.Intern.StatusMarks[1].value,
                },
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
        await API.graphql({
            query: deleteOfferDatas,
            variables: {
                input: { id: offer[0].id}
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        })
        setOfferDeleteConfirmOpen(false);
        history.replace(URL_PATH_SCHEDULE_DETAIL + URL_PARTS_SLASH + param.corporationId);
    }

    const refusalOfferUnlock = async () =>{
        const targetEventId = refOffer[0].refusalOfferDatasScheduleEventsId;
        await API.graphql({
            query: updateScheduleEvents,
            variables: { 
                input: {
                    id: targetEventId, 
                    status: EVENT_TYPE.Intern.StatusMarks[1].value,
                },
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
        await API.graphql({
            query: deleteRefusalOfferDatas,
            variables: {
                input: { id: refOffer[0].id}
            }
        })
        setRefOfferDeleteConfirmOpen(false);
        history.replace(URL_PATH_SCHEDULE_DETAIL + URL_PARTS_SLASH + param.corporationId);
    }

    const onClickOfferConsentButton = () =>{
        setOfferConsentConfirmOpen(true);
    }

    const onClickOfferCancelButton = () =>{
        setOfferDeleteConfirmOpen(true);
    }

    const onClickRefusalUnlockButton = () =>{
        setRefOfferDeleteConfirmOpen(true);
    }

    useEffect(() => {
        // マウント時処理
        let abortCtrl = new AbortController();

        initSelectionDetail();
        const subscription = API.graphql({
            query:onCreateScheduleEvents,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
            next: (msg) => {
                const event = msg.value.data.onCreateScheduleEvents;
                if(event.userDatasID === userData.userId){
                    dispachEventData({ type: SUBSCRIPTION, events: event });
                }
            }
        });
        
        // アンマウント時処理
        return() => {
            abortCtrl.abort();
            subscription.unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header 
                title={ 0 < refOffer.length ?
                        DETAIL_REF_OFFER_HEADER_TITLE+corporations.corporationName
                        :
                            0 < offer.length ? 
                                offer[0].offerStatus === 1 ? 
                                DETAIL_OFFER_HEADER_TITLE+corporations.corporationName
                                :
                                DETAIL_OFFER_COMPLETE_HEADER_TITLE+corporations.corporationName
                            :
                            ''
                    }
            />
            <SideMenu/>
                {isLoading ?
            <LoadingIcon/>
            :
            <Box className={commonStyle.pageContent}>
                <Box pt={2}></Box>
                <Grid container spacing={0}>
                    {events.length === 1 ?
                        events.map(eventData => {
                            return (<EvnetDataCard eventData={eventData} readOnly={true}/>)
                        })
                        :events.sort((a,b) => {return a.deadlineTimestamp < b.deadlineTimestamp ? -1 : 1}).map(eventData => (
                            <EvnetDataCard eventData={eventData} readOnly={true}/>
                        ))}
                </Grid>
                <Box pt={3}></Box>
                {0 < offer.length?
                    offer[0].offerStatus === 1 ? 
                    <Grid 
                        container
                        display="flex"
                        direction="row"
                        alignItems="center"                      
                    >   
                        <Grid item xs={6} className={commonStyle.centerContents}>
                            <Button 
                                variant="contained" 
                                color="secondary"
                                onClick={onClickOfferCancelButton}
                            >
                                {BUTTON_CAPTION_OFFER_DELETE}
                            </Button>
                        </Grid>
                        <Grid item xs={6} className={commonStyle.centerContents}>
                            <Button 
                                variant="contained" 
                                color="primary"
                                onClick={onClickOfferConsentButton}
                            >
                                {BUTTON_CAPTION_OFFER_CONFIRM}
                            </Button>
                        </Grid>
                    </Grid>
                    :
                    <Fragment/>
                :
                <Grid 
                    container
                    display="flex"
                    direction="row"
                    alignItems="center"
                >   
                    <Grid item xs={12} className={commonStyle.centerContents}>
                        <Button 
                            variant="contained" 
                            color="secondary"
                            onClick={onClickRefusalUnlockButton}
                        >
                            {BUTTON_CAPTION_REF_OFFER_DELETE}
                        </Button>
                    </Grid>
                </Grid>
                }
                <Box className={commonStyle.endBox}/>
                                
            </Box>
            }
            <ConfirmDialog 
                confirmEvent={refusalOfferUnlock}
                open={refOfferDeleteConfirmOpen}
                setOpen={setRefOfferDeleteConfirmOpen}
                caption={DIALOG_CAPTION_REF_OFFER_DELETE_CONFIRM}
                contentText={DIALOG_CONTENT_TEXT_REF_OFFER_DELETE_CONFIRM}
                cancelText={DIALOG_CANCEL_TEXT_REF_OFFER_DELETE_CONFIRM}
                agreeText={DIALOG_AGREE_TEXT_REF_OFFER_DELETE_CONFIRM}
            />
            <ConfirmDialog 
                confirmEvent={offerUnlock}
                open={offerDeleteConfirmOpen}
                setOpen={setOfferDeleteConfirmOpen}
                caption={DIALOG_CAPTION_OFFER_DELETE_CONFIRM}
                contentText={DIALOG_CONTENT_TEXT_OFFER_DELETE_CONFIRM}
                cancelText={DIALOG_CANCEL_TEXT_OFFER_DELETE_CONFIRM}
                agreeText={DIALOG_AGREE_TEXT_OFFER_DELETE_CONFIRM}
            />
            <ConfirmDialog 
                confirmEvent={offerConsent}
                open={offerConsentConfirmOpen}
                setOpen={setOfferConsentConfirmOpen}
                caption={DIALOG_CAPTION_CONSENT_CONFIRM}
                contentText={DIALOG_CONTENT_TEXT_CONSENT_CONFIRM}
                cancelText={DIALOG_CANCEL_TEXT_CONSENT_CONFIRM}
                agreeText={DIALOG_AGREE_TEXT_CONSENT_CONFIRM}
            />
            <Footer/>
        </Box>
    );
}
